import { createContext, useContext, useState, useEffect, useCallback, PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

import { message } from 'src/components/Misc';
import usePagination from 'src/hooks/use-pagination';
import {
  useEscrowsCountQuery,
  useEscrowsQuery,
  IEscrowsCountQuery,
  IEscrowsQuery,
  EscrowStatusFilterEnum,
} from 'src/graphql/schema';

interface IActionContext {
  statusFilter: EscrowStatusFilterEnum;
  handleTabSelection: (key: string) => void;
  onDateChange: (value: string) => void;
  escrows: IEscrowsQuery;
  count: IEscrowsCountQuery;
  currentPage: number;
  countLoading: boolean;
  loadingData: boolean;
  handlePages: (page: number) => void;
  setCurrentPage: (page: number) => void;
}

export const ActionsContext = createContext<IActionContext>(null as never);

export const ActionsProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [statusFilter, setStatusFilter] = useState<EscrowStatusFilterEnum>(EscrowStatusFilterEnum.All);
  const [createdAt, setCreatedAt] = useState<Date | null>(null);

  const location = useLocation();
  const [currentPage, setCurrentPage] = usePagination();

  const {
    error,
    data: escrowsData,
    refetch,
    loading: loadingData,
  } = useEscrowsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: currentPage,
      perPage: 10,
      statusFilter: statusFilter,
      actionItemsFilter: null,
      createdAfter: createdAt,
    },
  });
  const { data: count, refetch: refetchCount, loading: countLoading } = useEscrowsCountQuery();

  useEffect(() => {
    refetch();
    refetchCount();
  }, [refetch, refetchCount]);

  useEffect(() => {
    if (location?.state?.shouldRefetch) {
      refetch();
      refetchCount();
    }
  }, [location, refetch, refetchCount]);

  const handleTabSelection = useCallback((key: string) => {
    setStatusFilter(key as EscrowStatusFilterEnum);
    refetchCount();
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePages = useCallback(
    (page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const onDateChange = (value: string) => {
    if (value === '6') {
      setCreatedAt(null);
      refetch();
    } else {
      let days;
      let createdAfter: Date | null = null;
      switch (value) {
        case '1':
          days = 1;
          break;
        case '2':
          days = 7;
          break;
        case '3':
          days = 30;
          break;
        case '4':
          days = 180;
          break;
        default:
          days = 365;
      }

      createdAfter = new Date(new Date().setDate(new Date().getDate() - days));
      setCreatedAt(createdAfter);
    }

    refetch();
  };

  if (error) {
    message.error('Something went wrong');

    return null;
  }

  const providerValue: IActionContext = {
    statusFilter,
    handleTabSelection,
    onDateChange,
    countLoading,
    count: count as IEscrowsCountQuery,
    escrows: escrowsData as IEscrowsQuery,
    currentPage,
    handlePages,
    setCurrentPage,
    loadingData,
  };

  return <ActionsContext.Provider value={providerValue}>{children}</ActionsContext.Provider>;
};

export const useHomeActions = () => {
  const result = useContext(ActionsContext);

  return result;
};
