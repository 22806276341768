import { Link } from 'react-router';

const HelpMenuItems = () => [
  {
    label: <p className="nav__menu-title pl-0">Help</p>,
    key: 'help',
  },
  {
    label: (
      <Link className="nav__menu-link" target="_blank" rel="noopener noreferrer" to="//codekeeper.co/how-it-works.html">
        Documentation
      </Link>
    ),
    key: 'doc',
  },
  {
    label: (
      <Link
        className="nav__menu-link"
        target="_blank"
        rel="noopener noreferrer"
        to="//codekeeper.co/contact.html?support#hs-chat-open"
      >
        Support site
      </Link>
    ),
    key: 'support',
  },
  {
    label: (
      <Link className="nav__menu-link" target="_blank" rel="noopener noreferrer" to="//codekeeper.co/verification.html">
        Verification
      </Link>
    ),
    key: 'verification',
  },
  {
    label: (
      <Link className="nav__menu-link" target="_blank" rel="noopener noreferrer" to="//codekeeper.co/resources.html">
        Resources
      </Link>
    ),
    key: 'resources',
  },
];

export default HelpMenuItems;
