import { Button } from 'antd';
import { Link } from 'react-router';

import { HomeContainer, MigrationAlertContainer } from 'src/containers/Home';
import { Main } from 'src/components/Layout';

const HomePage: React.FunctionComponent = () => {
  return (
    <Main
      title="Home"
      headerActions={
        <Link to="/escrows/new">
          <Button type="primary">+ New Escrow</Button>
        </Link>
      }
      className="active-homepage"
      mainContentClassName="active-homepage__main-content"
      headerChildren={<MigrationAlertContainer />}
    >
      <HomeContainer />
    </Main>
  );
};

export default HomePage;
