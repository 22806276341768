/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line import/named */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: { input: any; output: any };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
};

/** Autogenerated input type of AcceptEscrowInvitation */
export type IAcceptEscrowInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowInvitationId: Scalars['ID']['input'];
};

/** Autogenerated return type of AcceptEscrowInvitation. */
export type IAcceptEscrowInvitationPayload = {
  __typename?: 'AcceptEscrowInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowInvitation: IEscrowInvitation;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of AcceptExclusiveOffer */
export type IAcceptExclusiveOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcceptExclusiveOffer. */
export type IAcceptExclusiveOfferPayload = {
  __typename?: 'AcceptExclusiveOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: ICompany;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Accept */
export type IAcceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowTerminationId: Scalars['ID']['input'];
};

/** Autogenerated return type of Accept. */
export type IAcceptPayload = {
  __typename?: 'AcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowTermination: IEscrowTermination;
  success: Scalars['Boolean']['output'];
};

export enum ActionItemFilterEnum {
  Administrative = 'administrative',
  Technical = 'technical',
}

/** Autogenerated input type of ActivateEscrow */
export type IActivateEscrowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of ActivateEscrow. */
export type IActivateEscrowPayload = {
  __typename?: 'ActivateEscrowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ActivateSaasEnvironment */
export type IActivateSaasEnvironmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  saasEnvironmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of ActivateSaasEnvironment. */
export type IActivateSaasEnvironmentPayload = {
  __typename?: 'ActivateSaasEnvironmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  saasEnvironment?: Maybe<ISaasEnvironment>;
  success: Scalars['Boolean']['output'];
};

export type IActivity = {
  __typename?: 'Activity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  klass: Scalars['String']['output'];
  payload: IPayload;
  viewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IActivityPage = {
  __typename?: 'ActivityPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IActivity>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Autogenerated input type of Add */
export type IAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  users: Array<IUserInput>;
};

/** Autogenerated return type of Add. */
export type IAddPayload = {
  __typename?: 'AddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  users?: Maybe<Array<IUser>>;
};

export type IAddon = {
  __typename?: 'Addon';
  currencyCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  period: Scalars['Int']['output'];
  periodUnit: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  unitPrice: Scalars['Int']['output'];
};

export type IAgreement = {
  __typename?: 'Agreement';
  agreementForm?: Maybe<Scalars['String']['output']>;
  approved: Scalars['Boolean']['output'];
  approvedByBeneficiary?: Maybe<Scalars['Boolean']['output']>;
  approvedByCodekeeper?: Maybe<Scalars['Boolean']['output']>;
  approvedByDepositor?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  depositType: Array<DepositTypeEnum>;
  depositTypeOther?: Maybe<Scalars['String']['output']>;
  draft: Scalars['Boolean']['output'];
  events: Array<IAgreementEvent>;
  id: Scalars['ID']['output'];
  jurisdiction?: Maybe<Scalars['String']['output']>;
  legalContext: Array<LegalContextEnum>;
  legalContextOther?: Maybe<Scalars['String']['output']>;
  liability?: Maybe<Scalars['String']['output']>;
  policy: IAgreementPolicy;
  releaseBankruptcy: Scalars['Boolean']['output'];
  releaseCustom: Scalars['Boolean']['output'];
  releaseCustomConditions?: Maybe<Scalars['String']['output']>;
  releaseInsolvency: Scalars['Boolean']['output'];
  releaseMaintenance: Scalars['Boolean']['output'];
  terms?: Maybe<Scalars['String']['output']>;
  termsDownloadDocxUrl?: Maybe<Scalars['String']['output']>;
  termsDownloadPdfUrl?: Maybe<Scalars['String']['output']>;
  termsSignedDownloadPdfUrl?: Maybe<Scalars['String']['output']>;
  thirdPartyAgreement: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IAgreementChangeInput = {
  depositType?: InputMaybe<Array<DepositTypeEnum>>;
  depositTypeOther?: InputMaybe<Scalars['String']['input']>;
  jurisdiction?: InputMaybe<JurisdictionEnum>;
  legalContext?: InputMaybe<Array<LegalContextEnum>>;
  legalContextOther?: InputMaybe<Scalars['String']['input']>;
  liability?: InputMaybe<LiabilityEnum>;
  releaseBankruptcy?: InputMaybe<Scalars['Boolean']['input']>;
  releaseCustom?: InputMaybe<Scalars['Boolean']['input']>;
  releaseCustomConditions?: InputMaybe<Scalars['String']['input']>;
  releaseInsolvency?: InputMaybe<Scalars['Boolean']['input']>;
  releaseMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IAgreementEvent = IEventInterface & {
  __typename?: 'AgreementEvent';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventType: Scalars['String']['output'];
  payload: IAgreement;
};

export enum AgreementFormEnum {
  Bipartite = 'bipartite',
  Tripartite = 'tripartite',
}

export type IAgreementInput = {
  depositType?: InputMaybe<Array<DepositTypeEnum>>;
  depositTypeOther?: InputMaybe<Scalars['String']['input']>;
  jurisdiction?: InputMaybe<JurisdictionEnum>;
  legalContext?: InputMaybe<Array<LegalContextEnum>>;
  legalContextOther?: InputMaybe<Scalars['String']['input']>;
  liability?: InputMaybe<LiabilityEnum>;
  releaseBankruptcy?: InputMaybe<Scalars['Boolean']['input']>;
  releaseCustom?: InputMaybe<Scalars['Boolean']['input']>;
  releaseCustomConditions?: InputMaybe<Scalars['String']['input']>;
  releaseInsolvency?: InputMaybe<Scalars['Boolean']['input']>;
  releaseMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyAgreement?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IAgreementPayload = {
  __typename?: 'AgreementPayload';
  agreementForm?: Maybe<Scalars['String']['output']>;
  approved: Scalars['Boolean']['output'];
  approvedByBeneficiary?: Maybe<Scalars['Boolean']['output']>;
  approvedByCodekeeper?: Maybe<Scalars['Boolean']['output']>;
  approvedByDepositor?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  depositType: Array<DepositTypeEnum>;
  depositTypeOther?: Maybe<Scalars['String']['output']>;
  draft: Scalars['Boolean']['output'];
  events: Array<IAgreementEvent>;
  id: Scalars['ID']['output'];
  jurisdiction?: Maybe<Scalars['String']['output']>;
  legalContext: Array<LegalContextEnum>;
  legalContextOther?: Maybe<Scalars['String']['output']>;
  liability?: Maybe<Scalars['String']['output']>;
  policy: IAgreementPolicy;
  releaseBankruptcy: Scalars['Boolean']['output'];
  releaseCustom: Scalars['Boolean']['output'];
  releaseCustomConditions?: Maybe<Scalars['String']['output']>;
  releaseInsolvency: Scalars['Boolean']['output'];
  releaseMaintenance: Scalars['Boolean']['output'];
  terms?: Maybe<Scalars['String']['output']>;
  termsDownloadDocxUrl?: Maybe<Scalars['String']['output']>;
  termsDownloadPdfUrl?: Maybe<Scalars['String']['output']>;
  termsSignedDownloadPdfUrl?: Maybe<Scalars['String']['output']>;
  thirdPartyAgreement: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IAgreementPolicy = {
  __typename?: 'AgreementPolicy';
  approve: Scalars['Boolean']['output'];
  generate: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  write: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ApplyCoupon */
export type IApplyCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  couponCode: Scalars['String']['input'];
};

/** Autogenerated return type of ApplyCoupon. */
export type IApplyCouponPayload = {
  __typename?: 'ApplyCouponPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: ICompany;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ApproveAgreement */
export type IApproveAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of ApproveAgreement. */
export type IApproveAgreementPayload = {
  __typename?: 'ApproveAgreementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

export type IAsset = {
  __typename?: 'Asset';
  createdAt: Scalars['ISO8601DateTime']['output'];
  depositMethod: DepositMethodEnum;
  depositSource?: Maybe<Scalars['String']['output']>;
  depositStatus: DepositStatusEnum;
  error?: Maybe<Scalars['String']['output']>;
  escrow: IEscrow;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integration?: Maybe<IIntegrationInterface>;
  lastUpdateCheck?: Maybe<Scalars['ISO8601DateTime']['output']>;
  latestDeposit?: Maybe<Scalars['ISO8601DateTime']['output']>;
  policy: IAssetPolicy;
  removalStatus?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
  syncStatus?: Maybe<SyncStatusEnum>;
  type: AssetTypeEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IAssetPage = {
  __typename?: 'AssetPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IAsset>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type IAssetPolicy = {
  __typename?: 'AssetPolicy';
  remove: Scalars['Boolean']['output'];
};

export type IAssetReport = {
  __typename?: 'AssetReport';
  asset: IAsset;
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: IReportData;
  id: Scalars['ID']['output'];
  period: Scalars['ISO8601Date']['output'];
};

export type IAssetReportGroup = {
  __typename?: 'AssetReportGroup';
  asset: IAsset;
  assetReports: Array<IAssetReport>;
  id: Scalars['ID']['output'];
  lastGenerated: Scalars['ISO8601Date']['output'];
};

export type IAssetReportGroupPage = {
  __typename?: 'AssetReportGroupPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IAssetReportGroup>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum AssetTypeEnum {
  CustomRepo = 'custom_repo',
  OauthRepo = 'oauth_repo',
  SecureUpload = 'secure_upload',
}

export enum AssetsOrderByEnum {
  AssetType = 'asset_type',
  CreatedAt = 'created_at',
  DepositMethod = 'deposit_method',
  DepositSource = 'deposit_source',
  DepositStatus = 'deposit_status',
  LastUpdateCheck = 'last_update_check',
  LatestDeposit = 'latest_deposit',
  SyncStatus = 'sync_status',
  UpdatedAt = 'updated_at',
}

/** Autogenerated input type of AssignBackupMembers */
export type IAssignBackupMembersInput = {
  backupId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of AssignBackupMembers. */
export type IAssignBackupMembersPayload = {
  __typename?: 'AssignBackupMembersPayload';
  backupMembers?: Maybe<Array<IBackupMember>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type IBackup = {
  __typename?: 'Backup';
  backupAssets: Array<IBackupAsset>;
  backupMembers: Array<IBackupMember>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator?: Maybe<IUser>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  disruptionNotification: DisruptionNotificationEnum;
  disruptionNotificationGracePeriod?: Maybe<DisruptionNotificationGracePeriodEnum>;
  id: Scalars['ID']['output'];
  integration: IIntegrationInterface;
  policy: IBackupPolicy;
  reportsEnabled: Scalars['Boolean']['output'];
  reportsEnabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  status: BackupStatusEnum;
  stoppedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  storageRegion: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  versionsLimit?: Maybe<Scalars['Int']['output']>;
};

export type IBackupAsset = {
  __typename?: 'BackupAsset';
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastBackup?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastBackupVersionId?: Maybe<Scalars['ID']['output']>;
  lastUpdateCheck?: Maybe<Scalars['ISO8601DateTime']['output']>;
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['BigInt']['output']>;
  status: BackupAssetStatusEnum;
  type: BackupAssetTypeEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type IBackupAssetCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type IBackupAssetReport = {
  __typename?: 'BackupAssetReport';
  backupAsset: IBackupAsset;
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: IReportData;
  id: Scalars['ID']['output'];
  period: Scalars['ISO8601Date']['output'];
};

export type IBackupAssetReportGroup = {
  __typename?: 'BackupAssetReportGroup';
  backupAsset: IBackupAsset;
  backupAssetReports: Array<IBackupAssetReport>;
  id: Scalars['ID']['output'];
  lastGenerated: Scalars['ISO8601Date']['output'];
};

export type IBackupAssetReportGroupPage = {
  __typename?: 'BackupAssetReportGroupPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IBackupAssetReportGroup>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum BackupAssetStatusEnum {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Stopped = 'stopped',
}

export enum BackupAssetTypeEnum {
  Custom = 'custom',
  Oauth = 'oauth',
}

export type IBackupCreateInput = {
  disruptionNotification?: InputMaybe<DisruptionNotificationEnum>;
  disruptionNotificationGracePeriod?: InputMaybe<DisruptionNotificationGracePeriodEnum>;
  storageRegion?: InputMaybe<Scalars['String']['input']>;
  versionsLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type IBackupMember = {
  __typename?: 'BackupMember';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  role: BackupMemberRoleEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: IUser;
};

export enum BackupMemberRoleEnum {
  Administrator = 'administrator',
  Owner = 'owner',
}

export type IBackupPage = {
  __typename?: 'BackupPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IBackup>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type IBackupPolicy = {
  __typename?: 'BackupPolicy';
  assignMembers: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  download: Scalars['Boolean']['output'];
  enableReports: Scalars['Boolean']['output'];
  start: Scalars['Boolean']['output'];
  stop: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum BackupStatusEnum {
  Active = 'active',
  Draft = 'draft',
  Removed = 'removed',
  Stopped = 'stopped',
}

export type IBackupUpdateInput = {
  disruptionNotification?: InputMaybe<DisruptionNotificationEnum>;
  disruptionNotificationGracePeriod?: InputMaybe<DisruptionNotificationGracePeriodEnum>;
  storageRegion?: InputMaybe<Scalars['String']['input']>;
  versionsLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type IBackupVersion = {
  __typename?: 'BackupVersion';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  status: BackupVersionStatusEnum;
};

export type IBackupVersionPage = {
  __typename?: 'BackupVersionPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IBackupVersion>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum BackupVersionStatusEnum {
  Failed = 'failed',
  Finished = 'finished',
  Skipped = 'skipped',
}

export enum BackupsOrderByEnum {
  CreatedAt = 'created_at',
  Status = 'status',
}

export type IBeneficiary = IEscrowPartyInterface & {
  __typename?: 'Beneficiary';
  beneficiaryOptions?: Maybe<IBeneficiaryOptions>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<ICompany>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  contacts: Array<IContact>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  escrow: IEscrow;
  id: Scalars['ID']['output'];
  isStatic: Scalars['Boolean']['output'];
  policy: IBeneficiaryPolicy;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IBeneficiaryChangeInput = {
  activationNotification?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  deletedContacts?: InputMaybe<Array<IDeleteContactChangeInput>>;
  editedContacts?: InputMaybe<Array<IEditContactChangeInput>>;
  newContacts?: InputMaybe<Array<IContactCreateInput>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type IBeneficiaryOptions = {
  __typename?: 'BeneficiaryOptions';
  confirmationMessage?: Maybe<Scalars['Boolean']['output']>;
  escrowInformation?: Maybe<Scalars['Boolean']['output']>;
  escrowReview?: Maybe<Scalars['Boolean']['output']>;
  escrowSignatory?: Maybe<Scalars['Boolean']['output']>;
};

export type IBeneficiaryPolicy = {
  __typename?: 'BeneficiaryPolicy';
  createEscrowMembers: Scalars['Boolean']['output'];
  readEscrowMembers: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type IBilling = {
  __typename?: 'Billing';
  billingAddress?: Maybe<IBillingAddress>;
  paymentMethod?: Maybe<IPaymentMethod>;
  subscriptions?: Maybe<Array<ISubscription>>;
};

export type IBillingAddress = {
  __typename?: 'BillingAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type IBillingAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyRegistrationNumber: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

/** Autogenerated input type of CancelSecureUpload */
export type ICancelSecureUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
  uploadId: Scalars['String']['input'];
};

/** Autogenerated return type of CancelSecureUpload. */
export type ICancelSecureUploadPayload = {
  __typename?: 'CancelSecureUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CancelSubscription */
export type ICancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CancelSubscription. */
export type ICancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: ICompany;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type IClientChangesRequested = {
  __typename?: 'ClientChangesRequested';
  creator: Scalars['String']['output'];
  id: Scalars['String']['output'];
  payload?: Maybe<Scalars['JSON']['output']>;
  receiver: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type IClientChangesRequestedPayload = {
  __typename?: 'ClientChangesRequestedPayload';
  agreement?: Maybe<IClientChangesRequested>;
  beneficiary?: Maybe<IClientChangesRequested>;
  depositor?: Maybe<IClientChangesRequested>;
  deposits?: Maybe<IClientChangesRequested>;
  isRequested: Scalars['Boolean']['output'];
  notifications?: Maybe<IClientChangesRequested>;
};

export type ICompany = {
  __typename?: 'Company';
  assets: IAssetPage;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyRegistration?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitationStatus: CompanyInvitationStatusEnum;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  subscriptionCancellationRequestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  subscriptionExclusiveCouponAppliedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updateableBilling: Scalars['Boolean']['output'];
  users: Array<IUser>;
};

export type ICompanyAssetsArgs = {
  escrowId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<AssetsOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
  statusFilter?: InputMaybe<DepositStatusEnum>;
};

export type ICompanyInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyRegistration?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum CompanyInvitationStatusEnum {
  Completed = 'completed',
  Pending = 'pending',
}

/** Autogenerated input type of CompleteSecureUpload */
export type ICompleteSecureUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  parts: Array<ISecureUploadPartInput>;
  size: Scalars['BigInt']['input'];
  uploadId: Scalars['String']['input'];
};

/** Autogenerated return type of CompleteSecureUpload. */
export type ICompleteSecureUploadPayload = {
  __typename?: 'CompleteSecureUploadPayload';
  asset?: Maybe<IAsset>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ConfirmEmailChange */
export type IConfirmEmailChangeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

/** Autogenerated return type of ConfirmEmailChange. */
export type IConfirmEmailChangePayload = {
  __typename?: 'ConfirmEmailChangePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type IContact = {
  __typename?: 'Contact';
  contactType: ContactTypeEnum;
  email: Scalars['String']['output'];
  escrow: IEscrow;
  escrowParty: IEscrowPartyInterface;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  signatory: Scalars['Boolean']['output'];
};

export type IContactCreateInput = {
  contactType: ContactTypeEnum;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  signatory: Scalars['Boolean']['input'];
};

export enum ContactTypeEnum {
  Administrative = 'administrative',
  AllTypes = 'all_types',
  Legal = 'legal',
  Technical = 'technical',
}

export type IContactUpdateInput = {
  contactType?: InputMaybe<ContactTypeEnum>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  signatory?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of CreateBackupAssets */
export type ICreateBackupAssetsInput = {
  backupAssetsParams: Array<IBackupAssetCreateInput>;
  backupId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateBackupAssets. */
export type ICreateBackupAssetsPayload = {
  __typename?: 'CreateBackupAssetsPayload';
  backupAssets?: Maybe<Array<IBackupAsset>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateBackup */
export type ICreateBackupInput = {
  backupParams: IBackupCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateBackup. */
export type ICreateBackupPayload = {
  __typename?: 'CreateBackupPayload';
  backup?: Maybe<IBackup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateBeneficiary */
export type ICreateBeneficiaryInput = {
  beneficiaryInputParams: IEscrowPartyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateBeneficiary. */
export type ICreateBeneficiaryPayload = {
  __typename?: 'CreateBeneficiaryPayload';
  beneficiary?: Maybe<IBeneficiary>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateContact */
export type ICreateContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactParams: IContactCreateInput;
  escrowPartyId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateContact. */
export type ICreateContactPayload = {
  __typename?: 'CreateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<IContact>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCustodianOperation */
export type ICreateCustodianOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  custodianOperationParams: ICustodianOperationCreateInput;
  custodianProfileId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateCustodianOperation. */
export type ICreateCustodianOperationPayload = {
  __typename?: 'CreateCustodianOperationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodianOperation?: Maybe<ICustodianOperation>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCustodianProfile */
export type ICreateCustodianProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCustodianProfile. */
export type ICreateCustodianProfilePayload = {
  __typename?: 'CreateCustodianProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodianProfile?: Maybe<ICustodianProfile>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCustomAssets */
export type ICreateCustomAssetsInput = {
  assetsInput: Array<ICustomAssetCreateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateCustomAssets. */
export type ICreateCustomAssetsPayload = {
  __typename?: 'CreateCustomAssetsPayload';
  assets?: Maybe<Array<IAsset>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCustomIntegration */
export type ICreateCustomIntegrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customIntegrationInput: ICustomIntegrationInputAttributes;
};

/** Autogenerated return type of CreateCustomIntegration. */
export type ICreateCustomIntegrationPayload = {
  __typename?: 'CreateCustomIntegrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customIntegration?: Maybe<ICustomIntegration>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateEscrow */
export type ICreateEscrowInput = {
  agreementForm: AgreementFormEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowRole: EscrowPartyTypeEnum;
};

/** Autogenerated input type of CreateEscrowMember */
export type ICreateEscrowMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowMemberParams: IEscrowMemberInput;
  escrowPartyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEscrowMember. */
export type ICreateEscrowMemberPayload = {
  __typename?: 'CreateEscrowMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowMember?: Maybe<IEscrowMember>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of CreateEscrow. */
export type ICreateEscrowPayload = {
  __typename?: 'CreateEscrowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateFeedback */
export type ICreateFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
};

/** Autogenerated return type of CreateFeedback. */
export type ICreateFeedbackPayload = {
  __typename?: 'CreateFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateOauthAssets */
export type ICreateOauthAssetsInput = {
  assetsInput: Array<IOauthAssetCreateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateOauthAssets. */
export type ICreateOauthAssetsPayload = {
  __typename?: 'CreateOauthAssetsPayload';
  assets?: Maybe<Array<IAsset>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateSaasEnvironment */
export type ICreateSaasEnvironmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSaasEnvironment. */
export type ICreateSaasEnvironmentPayload = {
  __typename?: 'CreateSaasEnvironmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  saasEnvironment?: Maybe<ISaasEnvironment>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateSaasProvider */
export type ICreateSaasProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  saasEnvironmentId: Scalars['ID']['input'];
  saasProviderParams: ISaasProviderCreateInput;
};

/** Autogenerated return type of CreateSaasProvider. */
export type ICreateSaasProviderPayload = {
  __typename?: 'CreateSaasProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  saasProvider?: Maybe<ISaasProvider>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateShareFileIntegration */
export type ICreateShareFileIntegrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareFileIntegrationInput: IShareFileIntegrationInputAttributes;
};

/** Autogenerated return type of CreateShareFileIntegration. */
export type ICreateShareFileIntegrationPayload = {
  __typename?: 'CreateShareFileIntegrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  shareFileIntegration?: Maybe<IShareFileIntegration>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateSuggestedChangeEscrow */
export type ICreateSuggestedChangeEscrowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
  suggestedChangeParams: ISuggestedChangeInput;
};

/** Autogenerated return type of CreateSuggestedChangeEscrow. */
export type ICreateSuggestedChangeEscrowPayload = {
  __typename?: 'CreateSuggestedChangeEscrowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  suggestedChange?: Maybe<ISuggestChange>;
};

export type ICustodianAsset = {
  __typename?: 'CustodianAsset';
  createdAt: Scalars['ISO8601DateTime']['output'];
  custodianIntegration: ICustodianIntegration;
  custodianProfile: ICustodianProfile;
  custodianStatus: CustodianAssetCustodianStatusEnum;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  operationalStatus: CustodianAssetOperationalStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ICustodianAssetCreateInput = {
  oauthRepositoryId: Scalars['String']['input'];
  operationalStatus: CustodianAssetOperationalStatusEnum;
};

export enum CustodianAssetCustodianStatusEnum {
  Pending = 'pending',
  Secured = 'secured',
}

export enum CustodianAssetOperationalStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}

export type ICustodianIntegration = {
  __typename?: 'CustodianIntegration';
  createdAt: Scalars['ISO8601DateTime']['output'];
  custodianAssets: Array<ICustodianAsset>;
  custodianProfile: ICustodianProfile;
  id: Scalars['ID']['output'];
  integration: IOauthIntegration;
  status: CustodianIntegrationStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum CustodianIntegrationStatusEnum {
  Pending = 'pending',
  SetupCompleted = 'setup_completed',
}

export type ICustodianOperation = {
  __typename?: 'CustodianOperation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  custodianProfile: ICustodianProfile;
  custodianServiceEvents: Array<CustodianOperationCustodianServiceEventEnum>;
  custodianServiceEventsCustom?: Maybe<Scalars['String']['output']>;
  dataRetention?: Maybe<Scalars['Int']['output']>;
  endOfRetentionPeriod: CustodianOperationEndOfRetentionPeriodEnum;
  endOfRetentionPeriodCustom?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  operationType: CustodianOperationTypeEnum;
  operationTypeCustom?: Maybe<Scalars['String']['output']>;
  regulatoryRequirements: Array<CustodianOperationRegulatoryRequirementsEnum>;
  regulatoryRequirementsCustom?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ICustodianOperationCreateInput = {
  custodianServiceEvents: Array<CustodianOperationCustodianServiceEventEnum>;
  custodianServiceEventsCustom?: InputMaybe<Scalars['String']['input']>;
  dataRetention?: InputMaybe<Scalars['Int']['input']>;
  endOfRetentionPeriod: CustodianOperationEndOfRetentionPeriodEnum;
  endOfRetentionPeriodCustom?: InputMaybe<Scalars['String']['input']>;
  operationType: CustodianOperationTypeEnum;
  operationTypeCustom?: InputMaybe<Scalars['String']['input']>;
  regulatoryRequirements: Array<CustodianOperationRegulatoryRequirementsEnum>;
  regulatoryRequirementsCustom?: InputMaybe<Scalars['String']['input']>;
};

export enum CustodianOperationCustodianServiceEventEnum {
  Bankrupcy = 'bankrupcy',
  Custom = 'custom',
  Insolvency = 'insolvency',
  MaintenanceObligationsNotMet = 'maintenance_obligations_not_met',
}

export enum CustodianOperationEndOfRetentionPeriodEnum {
  Archive = 'archive',
  Custom = 'custom',
  Delete = 'delete',
  Relabel = 'relabel',
}

export enum CustodianOperationRegulatoryRequirementsEnum {
  App = 'app',
  Ccpa = 'ccpa',
  Custom = 'custom',
  Gdpr = 'gdpr',
  Hippa = 'hippa',
}

export enum CustodianOperationTypeEnum {
  Custom = 'custom',
  Finance = 'finance',
  Health = 'health',
  Kyc = 'kyc',
  Privacy = 'privacy',
  Regulatory = 'regulatory',
  Security = 'security',
  ServiceAgreement = 'service_agreement',
}

export type ICustodianOperationUpdateInput = {
  custodianServiceEvents: Array<CustodianOperationCustodianServiceEventEnum>;
  custodianServiceEventsCustom?: InputMaybe<Scalars['String']['input']>;
  dataRetention?: InputMaybe<Scalars['Int']['input']>;
  endOfRetentionPeriod: CustodianOperationEndOfRetentionPeriodEnum;
  endOfRetentionPeriodCustom?: InputMaybe<Scalars['String']['input']>;
  operationTypeCustom?: InputMaybe<Scalars['String']['input']>;
  regulatoryRequirements: Array<CustodianOperationRegulatoryRequirementsEnum>;
  regulatoryRequirementsCustom?: InputMaybe<Scalars['String']['input']>;
};

export type ICustodianProfile = {
  __typename?: 'CustodianProfile';
  activatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: IUser;
  custodianAssets: Array<ICustodianAsset>;
  custodianIntegrations: Array<ICustodianIntegration>;
  custodianOperations: Array<ICustodianOperation>;
  custodianTermination?: Maybe<ICustodianTermination>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  policy: ICustodianProfilePolicy;
  status: CustodianProfileStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ICustodianProfilePage = {
  __typename?: 'CustodianProfilePage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<ICustodianProfile>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ICustodianProfilePolicy = {
  __typename?: 'CustodianProfilePolicy';
  activate: Scalars['Boolean']['output'];
  remove: Scalars['Boolean']['output'];
  requestTermination: Scalars['Boolean']['output'];
  submitForReview: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum CustodianProfileStatusEnum {
  AccessSetup = 'access_setup',
  Active = 'active',
  Draft = 'draft',
  Terminated = 'terminated',
}

export type ICustodianTermination = {
  __typename?: 'CustodianTermination';
  createdAt: Scalars['ISO8601DateTime']['output'];
  custodianProfile: ICustodianProfile;
  id: Scalars['ID']['output'];
  requesterUser: IUser;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  status: CustodianTerminationStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum CustodianTerminationStatusEnum {
  Accepted = 'accepted',
  Finished = 'finished',
  Pending = 'pending',
  Rejected = 'rejected',
}

export type ICustomAssetCreateInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type ICustomIntegration = IIntegrationInterface & {
  __typename?: 'CustomIntegration';
  accountName?: Maybe<Scalars['String']['output']>;
  accountUrl?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  backupable: Scalars['Boolean']['output'];
  creator?: Maybe<IUser>;
  id: Scalars['ID']['output'];
  isClientOwner?: Maybe<Scalars['Boolean']['output']>;
  provider: Scalars['String']['output'];
  status: IntegrationStatusEnum;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type ICustomIntegrationInputAttributes = {
  accountName: Scalars['String']['input'];
  accountUrl?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider: CustomIntegrationProviderEnum;
  region?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum CustomIntegrationProviderEnum {
  Aws = 'aws',
  AzureDevops = 'azure_devops',
  Beanstalk = 'beanstalk',
  Bitbucket = 'bitbucket',
  BitbucketServer = 'bitbucket_server',
  Codecommit = 'codecommit',
  Gcp = 'gcp',
  Github = 'github',
  GithubServer = 'github_server',
  Gitlab = 'gitlab',
  GitlabServer = 'gitlab_server',
  Svn = 'svn',
}

export type ICustomIntegrationUpdateInputAttributes = {
  accountName: Scalars['String']['input'];
  accountUrl?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type IDeleteContactChangeInput = {
  id: Scalars['String']['input'];
};

export type IDeletedAssetInput = {
  id: Scalars['String']['input'];
};

export type IDepositChangeInput = {
  deletedAssets?: InputMaybe<Array<IDeletedAssetInput>>;
};

export enum DepositMethodEnum {
  Automatic = 'automatic',
  Manual = 'manual',
}

export enum DepositNotificationEnum {
  EachDeposit = 'each_deposit',
  FirstDeposit = 'first_deposit',
  NoDepositNotifications = 'no_deposit_notifications',
}

export type IDepositSettingsInput = {
  beneficiaryDepositNotification?: InputMaybe<DepositNotificationEnum>;
  beneficiaryDisruptionNotification?: InputMaybe<DisruptionNotificationEnum>;
  beneficiaryDisruptionNotificationGracePeriod?: InputMaybe<DisruptionNotificationGracePeriodEnum>;
  depositorDepositNotification?: InputMaybe<DepositNotificationEnum>;
  depositorDisruptionNotification?: InputMaybe<DisruptionNotificationEnum>;
  depositorDisruptionNotificationGracePeriod?: InputMaybe<DisruptionNotificationGracePeriodEnum>;
  showDepositInformation?: InputMaybe<Scalars['Boolean']['input']>;
  storageRegion?: InputMaybe<Scalars['String']['input']>;
};

export enum DepositStatusEnum {
  Awaiting = 'awaiting',
  Error = 'error',
  NoConfiguration = 'no_configuration',
  Taken = 'taken',
}

export enum DepositTypeEnum {
  Data = 'data',
  Information = 'information',
  Other = 'other',
  Saas = 'saas',
  Source = 'source',
}

export type IDepositor = IEscrowPartyInterface & {
  __typename?: 'Depositor';
  city?: Maybe<Scalars['String']['output']>;
  company: ICompany;
  companyName?: Maybe<Scalars['String']['output']>;
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  contacts: Array<IContact>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  escrow: IEscrow;
  id: Scalars['ID']['output'];
  policy: IDepositorPolicy;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IDepositorChangeInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  deletedContacts?: InputMaybe<Array<IDeleteContactChangeInput>>;
  editedContacts?: InputMaybe<Array<IEditContactChangeInput>>;
  newContacts?: InputMaybe<Array<IContactCreateInput>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type IDepositorPolicy = {
  __typename?: 'DepositorPolicy';
  createEscrowMembers: Scalars['Boolean']['output'];
  readEscrowMembers: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Destroy */
export type IDestroyInput = {
  assetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Destroy. */
export type IDestroyPayload = {
  __typename?: 'DestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow: IEscrow;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DisableBackupAsset */
export type IDisableBackupAssetInput = {
  backupAssetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DisableBackupAsset. */
export type IDisableBackupAssetPayload = {
  __typename?: 'DisableBackupAssetPayload';
  backup: IBackup;
  backupAsset?: Maybe<IBackupAsset>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export enum DisruptionNotificationEnum {
  ImmediateNotification = 'immediate_notification',
  NoDistruptionNotifications = 'no_distruption_notifications',
}

export enum DisruptionNotificationGracePeriodEnum {
  SevenDays = 'seven_days',
  ThirtyDays = 'thirty_days',
  TwentyFourHours = 'twenty_four_hours',
}

export type IEditContactChangeInput = {
  contactType?: InputMaybe<ContactTypeEnum>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  signatory?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of Edit */
export type IEditInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  user: IEditUserInput;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of Edit. */
export type IEditPayload = {
  __typename?: 'EditPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  user?: Maybe<IUser>;
};

export type IEditUserInput = {
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRoleEnum>;
};

/** Autogenerated input type of EnableBackupAsset */
export type IEnableBackupAssetInput = {
  backupId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  oauthRepositoryId: Scalars['ID']['input'];
};

/** Autogenerated return type of EnableBackupAsset. */
export type IEnableBackupAssetPayload = {
  __typename?: 'EnableBackupAssetPayload';
  backupAsset?: Maybe<IBackupAsset>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EnableBackupReports */
export type IEnableBackupReportsInput = {
  backupId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnableBackupReports. */
export type IEnableBackupReportsPayload = {
  __typename?: 'EnableBackupReportsPayload';
  backup: IBackup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EnableEscrowReports */
export type IEnableEscrowReportsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of EnableEscrowReports. */
export type IEnableEscrowReportsPayload = {
  __typename?: 'EnableEscrowReportsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

export type IEscrow = {
  __typename?: 'Escrow';
  activationNotification: Scalars['Boolean']['output'];
  agreement?: Maybe<IAgreement>;
  agreementSettingsConfigured: Scalars['Boolean']['output'];
  beneficiary?: Maybe<IBeneficiary>;
  beneficiaryConfigured: Scalars['Boolean']['output'];
  beneficiaryDepositNotification: DepositNotificationEnum;
  beneficiaryDisruptionNotification: DisruptionNotificationEnum;
  beneficiaryDisruptionNotificationGracePeriod?: Maybe<DisruptionNotificationGracePeriodEnum>;
  changesRequested: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentCompanyRole: EscrowPartyTypeEnum;
  depositStatus: DepositStatusEnum;
  depositor?: Maybe<IDepositor>;
  depositorConfigured: Scalars['Boolean']['output'];
  depositorDepositNotification: DepositNotificationEnum;
  depositorDisruptionNotification: DisruptionNotificationEnum;
  depositorDisruptionNotificationGracePeriod?: Maybe<DisruptionNotificationGracePeriodEnum>;
  escrowInvitation?: Maybe<IEscrowInvitation>;
  escrowTermination?: Maybe<IEscrowTermination>;
  events: Array<IEscrowEvent>;
  id: Scalars['ID']['output'];
  legalStatus: Array<ILegalStatus>;
  pendingActivation: Scalars['Boolean']['output'];
  policy: IEscrowPolicy;
  reportsEnabled: Scalars['Boolean']['output'];
  reportsEnabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  showDepositInformation: Scalars['Boolean']['output'];
  signatures: Array<ISignature>;
  startedBy: EscrowPartyTypeEnum;
  status: EscrowStatusEnum;
  storageRegion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IEscrowEvent = IEventInterface & {
  __typename?: 'EscrowEvent';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventType: Scalars['String']['output'];
  payload: IEscrow;
};

export type IEscrowInput = {
  activationNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IEscrowInvitation = {
  __typename?: 'EscrowInvitation';
  companyName?: Maybe<Scalars['String']['output']>;
  escrow: IEscrow;
  id: Scalars['ID']['output'];
  partyType: Scalars['String']['output'];
  policy: IEscrowInvitationPolicy;
  status: InvitationStatusEnum;
  user?: Maybe<IUser>;
};

export type IEscrowInvitationPage = {
  __typename?: 'EscrowInvitationPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IEscrowInvitation>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type IEscrowInvitationPolicy = {
  __typename?: 'EscrowInvitationPolicy';
  accept: Scalars['Boolean']['output'];
  reject: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EscrowMarkAsViewed */
export type IEscrowMarkAsViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of EscrowMarkAsViewed. */
export type IEscrowMarkAsViewedPayload = {
  __typename?: 'EscrowMarkAsViewedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type IEscrowMember = {
  __typename?: 'EscrowMember';
  escrowParty: IEscrowPartyInterface;
  id: Scalars['ID']['output'];
  policy: IEscrowMemberPolicy;
  role: EscrowMemberRoleEnum;
  user: IUser;
};

export type IEscrowMemberInput = {
  role: EscrowMemberRoleEnum;
};

export type IEscrowMemberPage = {
  __typename?: 'EscrowMemberPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IEscrowMember>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type IEscrowMemberPolicy = {
  __typename?: 'EscrowMemberPolicy';
  remove: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum EscrowMemberRoleEnum {
  Administrative = 'administrative',
  Legal = 'legal',
  Technical = 'technical',
  Viewer = 'viewer',
}

export enum EscrowMembersOrderByEnum {
  Email = 'email',
  Name = 'name',
  Role = 'role',
}

export type IEscrowPage = {
  __typename?: 'EscrowPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IEscrow>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type IEscrowPartyInput = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type IEscrowPartyInterface = {
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  contacts: Array<IContact>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  escrow: IEscrow;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum EscrowPartyTypeEnum {
  Beneficiary = 'beneficiary',
  Depositor = 'depositor',
}

export type IEscrowPayload = {
  __typename?: 'EscrowPayload';
  activationNotification: Scalars['Boolean']['output'];
  agreement?: Maybe<IAgreement>;
  agreementSettingsConfigured: Scalars['Boolean']['output'];
  beneficiary?: Maybe<IBeneficiary>;
  beneficiaryConfigured: Scalars['Boolean']['output'];
  beneficiaryDepositNotification: DepositNotificationEnum;
  beneficiaryDisruptionNotification: DisruptionNotificationEnum;
  beneficiaryDisruptionNotificationGracePeriod?: Maybe<DisruptionNotificationGracePeriodEnum>;
  changesRequested: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentCompanyRole: EscrowPartyTypeEnum;
  depositStatus: DepositStatusEnum;
  depositor?: Maybe<IDepositor>;
  depositorConfigured: Scalars['Boolean']['output'];
  depositorDepositNotification: DepositNotificationEnum;
  depositorDisruptionNotification: DisruptionNotificationEnum;
  depositorDisruptionNotificationGracePeriod?: Maybe<DisruptionNotificationGracePeriodEnum>;
  escrowInvitation?: Maybe<IEscrowInvitation>;
  escrowTermination?: Maybe<IEscrowTermination>;
  events: Array<IEscrowEvent>;
  id: Scalars['ID']['output'];
  legalStatus: Array<ILegalStatus>;
  pendingActivation: Scalars['Boolean']['output'];
  policy: IEscrowPolicy;
  reportsEnabled: Scalars['Boolean']['output'];
  reportsEnabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  showDepositInformation: Scalars['Boolean']['output'];
  signatures: Array<ISignature>;
  startedBy: EscrowPartyTypeEnum;
  status: EscrowStatusEnum;
  storageRegion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IEscrowPolicy = {
  __typename?: 'EscrowPolicy';
  activate: Scalars['Boolean']['output'];
  bipartiteUpdate: Scalars['Boolean']['output'];
  createAgreementSuggestedChange: Scalars['Boolean']['output'];
  createBeneficiary: Scalars['Boolean']['output'];
  createBeneficiarySuggestedChange: Scalars['Boolean']['output'];
  createDepositSuggestedChange: Scalars['Boolean']['output'];
  createDepositorSuggestedChange: Scalars['Boolean']['output'];
  createNotificationSuggestedChange: Scalars['Boolean']['output'];
  deferActivation: Scalars['Boolean']['output'];
  depositAssets: Scalars['Boolean']['output'];
  enableReports: Scalars['Boolean']['output'];
  inviteBeneficiary: Scalars['Boolean']['output'];
  inviteDepositor: Scalars['Boolean']['output'];
  read: Scalars['Boolean']['output'];
  remove: Scalars['Boolean']['output'];
  requestTermination: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  write: Scalars['Boolean']['output'];
};

export enum EscrowStatusEnum {
  Active = 'active',
  AdminReview = 'admin_review',
  Draft = 'draft',
  Error = 'error',
  Onboarding = 'onboarding',
  PendingTermination = 'pending_termination',
  Signed = 'signed',
  Terminated = 'terminated',
}

export enum EscrowStatusFilterEnum {
  Active = 'active',
  AdminReview = 'admin_review',
  All = 'all',
  ChangesSuggested = 'changes_suggested',
  Draft = 'draft',
  DraftAndOnboarding = 'draft_and_onboarding',
  Error = 'error',
  Invited = 'invited',
  Onboarding = 'onboarding',
  Signed = 'signed',
  Terminated = 'terminated',
  TerminationRequested = 'termination_requested',
}

export type IEscrowTermination = {
  __typename?: 'EscrowTermination';
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  adminReviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  escrow: IEscrow;
  id: Scalars['ID']['output'];
  noticePeriod: Scalars['Int']['output'];
  policy: IEscrowTerminationPolicy;
  requestedAt: Scalars['ISO8601DateTime']['output'];
  requesterParty: EscrowPartyTypeEnum;
  requesterUser?: Maybe<IUser>;
  status: EscrowTerminationStatusEnum;
  terminateAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IEscrowTerminationPolicy = {
  __typename?: 'EscrowTerminationPolicy';
  accept: Scalars['Boolean']['output'];
  reject: Scalars['Boolean']['output'];
};

export enum EscrowTerminationStatusEnum {
  Accepted = 'accepted',
  AdminAccepted = 'admin_accepted',
  AdminDeclined = 'admin_declined',
  Finished = 'finished',
  Pending = 'pending',
  Rejected = 'rejected',
}

export type IEventInterface = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventType: Scalars['String']['output'];
};

export type IExclusiveOfferPlan = {
  __typename?: 'ExclusiveOfferPlan';
  currencyCode: Scalars['String']['output'];
  discountPercentage: Scalars['Float']['output'];
  discounted: Scalars['Boolean']['output'];
  discountedPrice?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  period: Scalars['Int']['output'];
  periodUnit: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

/** Autogenerated input type of FinishAuth */
export type IFinishAuthInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clientPayload: IIntegrationAuthClientPayloadInput;
  options?: InputMaybe<IIntegrationAuthOptionsInput>;
  provider: OauthIntegrationProviderEnum;
  stateToken: Scalars['String']['input'];
};

/** Autogenerated return type of FinishAuth. */
export type IFinishAuthPayload = {
  __typename?: 'FinishAuthPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  oauthIntegration?: Maybe<IOauthIntegration>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of GenerateAgreement */
export type IGenerateAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of GenerateAgreement. */
export type IGenerateAgreementPayload = {
  __typename?: 'GenerateAgreementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Impersonate */
export type IImpersonateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of Impersonate. */
export type IImpersonatePayload = {
  __typename?: 'ImpersonatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of InitAuth */
export type IInitAuthInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<IIntegrationAuthOptionsInput>;
  provider: OauthIntegrationProviderEnum;
};

/** Autogenerated return type of InitAuth. */
export type IInitAuthPayload = {
  __typename?: 'InitAuthPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  integrationAuth?: Maybe<IIntegrationAuth>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of InitSecureUpload */
export type IInitSecureUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  numParts: Scalars['Int']['input'];
};

/** Autogenerated return type of InitSecureUpload. */
export type IInitSecureUploadPayload = {
  __typename?: 'InitSecureUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  uploadId?: Maybe<Scalars['String']['output']>;
  uploadUrls?: Maybe<Array<Scalars['String']['output']>>;
};

export type IIntegrationAuth = {
  __typename?: 'IntegrationAuth';
  stateToken: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type IIntegrationAuthClientPayloadInput = {
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type IIntegrationAuthOptionsInput = {
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type IIntegrationInterface = {
  accountName?: Maybe<Scalars['String']['output']>;
  accountUrl?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  backupable: Scalars['Boolean']['output'];
  creator?: Maybe<IUser>;
  id: Scalars['ID']['output'];
  provider: Scalars['String']['output'];
  status: IntegrationStatusEnum;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type IIntegrationInterfacePage = {
  __typename?: 'IntegrationInterfacePage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IIntegrationInterface>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum IntegrationStatusEnum {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Pending = 'pending',
}

export enum IntegrationTypeEnum {
  Custom = 'custom',
  Oauth = 'oauth',
  ShareFile = 'share_file',
}

export enum InvitationStatusEnum {
  Accepted = 'accepted',
  Draft = 'draft',
  Pending = 'pending',
  Rejected = 'rejected',
}

/** Autogenerated input type of InviteOwnerBeneficiary */
export type IInviteOwnerBeneficiaryInput = {
  administrativeInputUser: IUserInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of InviteOwnerBeneficiary. */
export type IInviteOwnerBeneficiaryPayload = {
  __typename?: 'InviteOwnerBeneficiaryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowInvitation: IEscrowInvitation;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of InviteOwnerDepositor */
export type IInviteOwnerDepositorInput = {
  administrativeInputUser: IUserInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of InviteOwnerDepositor. */
export type IInviteOwnerDepositorPayload = {
  __typename?: 'InviteOwnerDepositorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowInvitation: IEscrowInvitation;
  success: Scalars['Boolean']['output'];
};

export type IInvoice = {
  __typename?: 'Invoice';
  currencyCode: Scalars['String']['output'];
  date: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  status: InvoiceStatusEnum;
  total: Scalars['Int']['output'];
};

export type IInvoicePage = {
  __typename?: 'InvoicePage';
  nextOffset?: Maybe<Scalars['String']['output']>;
  nodes: Array<IInvoice>;
};

export type IInvoicePdf = {
  __typename?: 'InvoicePdf';
  downloadUrl: Scalars['String']['output'];
};

export enum InvoiceStatusEnum {
  NotPaid = 'not_paid',
  Paid = 'paid',
  PaymentDue = 'payment_due',
  Pending = 'pending',
  Posted = 'posted',
  Voided = 'voided',
}

export enum JurisdictionEnum {
  BeneficiaryJurisdiction = 'beneficiary_jurisdiction',
  DepositorJurisdiction = 'depositor_jurisdiction',
  EscrowAgentJurisdiction = 'escrow_agent_jurisdiction',
}

export type ILegacyRepo = {
  __typename?: 'LegacyRepo';
  escrow: IEscrow;
  id: Scalars['ID']['output'];
  migrationStatus: LegacyRepoMigrationStatusEnum;
  platform: LegacyRepoPlatformEnum;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum LegacyRepoMigrationStatusEnum {
  Awaiting = 'awaiting',
  Migrated = 'migrated',
}

export type ILegacyRepoPage = {
  __typename?: 'LegacyRepoPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<ILegacyRepo>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum LegacyRepoPlatformEnum {
  Assembla = 'assembla',
  AwsCodecommit = 'aws_codecommit',
  AzureDevops = 'azure_devops',
  Bitbucket = 'bitbucket',
  Gcp = 'gcp',
  Github = 'github',
  Gitlab = 'gitlab',
  GitlabServer = 'gitlab_server',
  S3 = 's3',
  SecureUploadFolder = 'secure_upload_folder',
  Sourceforge = 'sourceforge',
  Subvision = 'subvision',
}

export enum LegacyReposOrderByEnum {
  MigrationStatus = 'migration_status',
  Platform = 'platform',
  Title = 'title',
  Url = 'url',
}

export enum LegalContextEnum {
  Development = 'development',
  License = 'license',
  Other = 'other',
  Saas = 'saas',
  Transaction = 'transaction',
}

export type ILegalStatus = {
  __typename?: 'LegalStatus';
  role: Scalars['String']['output'];
  signed?: Maybe<Scalars['Boolean']['output']>;
};

export enum LiabilityEnum {
  Total_0 = 'total_0',
  Total_29 = 'total_29',
  Total_89 = 'total_89',
  Total_149 = 'total_149',
}

/** Autogenerated input type of MarkAsViewed */
export type IMarkAsViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notificationIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of MarkAsViewed. */
export type IMarkAsViewedPayload = {
  __typename?: 'MarkAsViewedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MigrateLegacyRepo */
export type IMigrateLegacyRepoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['ID']['input'];
  legacyRepoId: Scalars['ID']['input'];
  oauthRepoId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of MigrateLegacyRepo. */
export type IMigrateLegacyRepoPayload = {
  __typename?: 'MigrateLegacyRepoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MigrateLegacyRepos */
export type IMigrateLegacyReposInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['ID']['input'];
  legacyRepoIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of MigrateLegacyRepos. */
export type IMigrateLegacyReposPayload = {
  __typename?: 'MigrateLegacyReposPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  migratedLegacyRepos: Array<ILegacyRepo>;
  success: Scalars['Boolean']['output'];
};

export type IMutation = {
  __typename?: 'Mutation';
  acceptEscrowInvitation?: Maybe<IAcceptEscrowInvitationPayload>;
  acceptEscrowTermination?: Maybe<IAcceptPayload>;
  acceptExclusiveOffer?: Maybe<IAcceptExclusiveOfferPayload>;
  activateEscrow?: Maybe<IActivateEscrowPayload>;
  activateSaasEnvironment?: Maybe<IActivateSaasEnvironmentPayload>;
  addUser?: Maybe<IAddPayload>;
  applyCoupon?: Maybe<IApplyCouponPayload>;
  approveAgreement?: Maybe<IApproveAgreementPayload>;
  assignBackupMembers?: Maybe<IAssignBackupMembersPayload>;
  cancelSecureUpload?: Maybe<ICancelSecureUploadPayload>;
  cancelSubscription?: Maybe<ICancelSubscriptionPayload>;
  completeSecureUpload?: Maybe<ICompleteSecureUploadPayload>;
  confirmEmailChange?: Maybe<IConfirmEmailChangePayload>;
  createBackup?: Maybe<ICreateBackupPayload>;
  createBackupAssets?: Maybe<ICreateBackupAssetsPayload>;
  createBeneficiary?: Maybe<ICreateBeneficiaryPayload>;
  createContact?: Maybe<ICreateContactPayload>;
  createCustodianOperation?: Maybe<ICreateCustodianOperationPayload>;
  createCustodianProfile?: Maybe<ICreateCustodianProfilePayload>;
  createCustomAssets?: Maybe<ICreateCustomAssetsPayload>;
  createCustomIntegration?: Maybe<ICreateCustomIntegrationPayload>;
  createEscrow?: Maybe<ICreateEscrowPayload>;
  createEscrowMember?: Maybe<ICreateEscrowMemberPayload>;
  createFeedback?: Maybe<ICreateFeedbackPayload>;
  createOauthAssets?: Maybe<ICreateOauthAssetsPayload>;
  createSaasEnvironment?: Maybe<ICreateSaasEnvironmentPayload>;
  createSaasProvider?: Maybe<ICreateSaasProviderPayload>;
  createShareFileIntegration?: Maybe<ICreateShareFileIntegrationPayload>;
  createSuggestedChangeEscrow?: Maybe<ICreateSuggestedChangeEscrowPayload>;
  destroyAsset?: Maybe<IDestroyPayload>;
  disableBackupAsset?: Maybe<IDisableBackupAssetPayload>;
  editUser?: Maybe<IEditPayload>;
  enableBackupAsset?: Maybe<IEnableBackupAssetPayload>;
  enableBackupReports?: Maybe<IEnableBackupReportsPayload>;
  enableEscrowReports?: Maybe<IEnableEscrowReportsPayload>;
  finishOauthIntegrationAuth?: Maybe<IFinishAuthPayload>;
  generateAgreement?: Maybe<IGenerateAgreementPayload>;
  impersonateUser?: Maybe<IImpersonatePayload>;
  initOauthIntegrationAuth?: Maybe<IInitAuthPayload>;
  initSecureUpload?: Maybe<IInitSecureUploadPayload>;
  inviteOwnerBeneficiary?: Maybe<IInviteOwnerBeneficiaryPayload>;
  inviteOwnerDepositor?: Maybe<IInviteOwnerDepositorPayload>;
  markAsViewedEscrow?: Maybe<IEscrowMarkAsViewedPayload>;
  markAsViewedNotification?: Maybe<IMarkAsViewedPayload>;
  migrateLegacyRepo?: Maybe<IMigrateLegacyRepoPayload>;
  migrateLegacyRepos?: Maybe<IMigrateLegacyReposPayload>;
  rejectEscrowInvitation?: Maybe<IRejectEscrowInvitationPayload>;
  rejectEscrowTermination?: Maybe<IRejectPayload>;
  removeBackup?: Maybe<IRemoveBackupPayload>;
  removeBackupAsset?: Maybe<IRemoveBackupAssetPayload>;
  removeContact?: Maybe<IRemoveContactPayload>;
  removeCustodianOperation?: Maybe<IRemoveCustodianOperationPayload>;
  removeCustodianProfile?: Maybe<IRemoveCustodianProfilePayload>;
  removeEscrow?: Maybe<IRemoveEscrowPayload>;
  removeEscrowMember?: Maybe<IRemoveEscrowMemberPayload>;
  removeSaasEnvironment?: Maybe<IRemoveSaasEnvironmentPayload>;
  removeSaasProvider?: Maybe<IRemoveSaasProviderPayload>;
  removeUser?: Maybe<IRemovePayload>;
  requestBackupVersionDownload?: Maybe<IRequestBackupVersionDownloadPayload>;
  requestEscrowTermination?: Maybe<ITerminatePayload>;
  reviewSuggestedChangeEscrow?: Maybe<IReviewSuggestedChangeEscrowPayload>;
  sendForReviewCustodianProfile?: Maybe<ISendForReviewCustodianProfilePayload>;
  startBackup?: Maybe<IStartBackupPayload>;
  stopBackup?: Maybe<IStopBackupPayload>;
  switchToBackupPlan?: Maybe<ISwitchToBackupPlanPayload>;
  terminateCustodianProfile?: Maybe<ITerminateCustodianProfilePayload>;
  terminateSaasEnvironment?: Maybe<ITerminateSaasEnvironmentPayload>;
  toggleIntegration?: Maybe<ITogglePayload>;
  updateAgreement?: Maybe<IUpdateAgreementPayload>;
  updateBackup?: Maybe<IUpdateBackupPayload>;
  updateBeneficiary?: Maybe<IUpdateBeneficiaryPayload>;
  updateBillingAddress?: Maybe<IUpdateBillingAddressPayload>;
  updateCompanyDetails?: Maybe<IUpdateCompanyDetailsPayload>;
  updateContact?: Maybe<IUpdateContactPayload>;
  updateCustodianAssets?: Maybe<IUpdateCustodianAssetsPayload>;
  updateCustodianOperation?: Maybe<IUpdateCustodianOperationPayload>;
  updateCustomIntegration?: Maybe<IUpdateCustomIntegrationPayload>;
  updateDepositSettings?: Maybe<IUpdateDepositSettingsPayload>;
  updateDepositor?: Maybe<IUpdateDepositorPayload>;
  updateEscrow?: Maybe<IUpdateEscrowPayload>;
  updateEscrowInvitation?: Maybe<IUpdateEscrowInvitationPayload>;
  updateEscrowMember?: Maybe<IUpdateEscrowMemberPayload>;
  updatePaymentMethod?: Maybe<IRequestUpdatePaymentPagePayload>;
  updateProfileInfo?: Maybe<IUpdateProfileInfoPayload>;
  updateSaasProvider?: Maybe<IUpdateSaasProviderPayload>;
  updateSuggestedChangeEscrow?: Maybe<IUpdateSuggestedChangeEscrowPayload>;
};

export type IMutationAcceptEscrowInvitationArgs = {
  input: IAcceptEscrowInvitationInput;
};

export type IMutationAcceptEscrowTerminationArgs = {
  input: IAcceptInput;
};

export type IMutationAcceptExclusiveOfferArgs = {
  input: IAcceptExclusiveOfferInput;
};

export type IMutationActivateEscrowArgs = {
  input: IActivateEscrowInput;
};

export type IMutationActivateSaasEnvironmentArgs = {
  input: IActivateSaasEnvironmentInput;
};

export type IMutationAddUserArgs = {
  input: IAddInput;
};

export type IMutationApplyCouponArgs = {
  input: IApplyCouponInput;
};

export type IMutationApproveAgreementArgs = {
  input: IApproveAgreementInput;
};

export type IMutationAssignBackupMembersArgs = {
  input: IAssignBackupMembersInput;
};

export type IMutationCancelSecureUploadArgs = {
  input: ICancelSecureUploadInput;
};

export type IMutationCancelSubscriptionArgs = {
  input: ICancelSubscriptionInput;
};

export type IMutationCompleteSecureUploadArgs = {
  input: ICompleteSecureUploadInput;
};

export type IMutationConfirmEmailChangeArgs = {
  input: IConfirmEmailChangeInput;
};

export type IMutationCreateBackupArgs = {
  input: ICreateBackupInput;
};

export type IMutationCreateBackupAssetsArgs = {
  input: ICreateBackupAssetsInput;
};

export type IMutationCreateBeneficiaryArgs = {
  input: ICreateBeneficiaryInput;
};

export type IMutationCreateContactArgs = {
  input: ICreateContactInput;
};

export type IMutationCreateCustodianOperationArgs = {
  input: ICreateCustodianOperationInput;
};

export type IMutationCreateCustodianProfileArgs = {
  input: ICreateCustodianProfileInput;
};

export type IMutationCreateCustomAssetsArgs = {
  input: ICreateCustomAssetsInput;
};

export type IMutationCreateCustomIntegrationArgs = {
  input: ICreateCustomIntegrationInput;
};

export type IMutationCreateEscrowArgs = {
  input: ICreateEscrowInput;
};

export type IMutationCreateEscrowMemberArgs = {
  input: ICreateEscrowMemberInput;
};

export type IMutationCreateFeedbackArgs = {
  input: ICreateFeedbackInput;
};

export type IMutationCreateOauthAssetsArgs = {
  input: ICreateOauthAssetsInput;
};

export type IMutationCreateSaasEnvironmentArgs = {
  input: ICreateSaasEnvironmentInput;
};

export type IMutationCreateSaasProviderArgs = {
  input: ICreateSaasProviderInput;
};

export type IMutationCreateShareFileIntegrationArgs = {
  input: ICreateShareFileIntegrationInput;
};

export type IMutationCreateSuggestedChangeEscrowArgs = {
  input: ICreateSuggestedChangeEscrowInput;
};

export type IMutationDestroyAssetArgs = {
  input: IDestroyInput;
};

export type IMutationDisableBackupAssetArgs = {
  input: IDisableBackupAssetInput;
};

export type IMutationEditUserArgs = {
  input: IEditInput;
};

export type IMutationEnableBackupAssetArgs = {
  input: IEnableBackupAssetInput;
};

export type IMutationEnableBackupReportsArgs = {
  input: IEnableBackupReportsInput;
};

export type IMutationEnableEscrowReportsArgs = {
  input: IEnableEscrowReportsInput;
};

export type IMutationFinishOauthIntegrationAuthArgs = {
  input: IFinishAuthInput;
};

export type IMutationGenerateAgreementArgs = {
  input: IGenerateAgreementInput;
};

export type IMutationImpersonateUserArgs = {
  input: IImpersonateInput;
};

export type IMutationInitOauthIntegrationAuthArgs = {
  input: IInitAuthInput;
};

export type IMutationInitSecureUploadArgs = {
  input: IInitSecureUploadInput;
};

export type IMutationInviteOwnerBeneficiaryArgs = {
  input: IInviteOwnerBeneficiaryInput;
};

export type IMutationInviteOwnerDepositorArgs = {
  input: IInviteOwnerDepositorInput;
};

export type IMutationMarkAsViewedEscrowArgs = {
  input: IEscrowMarkAsViewedInput;
};

export type IMutationMarkAsViewedNotificationArgs = {
  input: IMarkAsViewedInput;
};

export type IMutationMigrateLegacyRepoArgs = {
  input: IMigrateLegacyRepoInput;
};

export type IMutationMigrateLegacyReposArgs = {
  input: IMigrateLegacyReposInput;
};

export type IMutationRejectEscrowInvitationArgs = {
  input: IRejectEscrowInvitationInput;
};

export type IMutationRejectEscrowTerminationArgs = {
  input: IRejectInput;
};

export type IMutationRemoveBackupArgs = {
  input: IRemoveBackupInput;
};

export type IMutationRemoveBackupAssetArgs = {
  input: IRemoveBackupAssetInput;
};

export type IMutationRemoveContactArgs = {
  input: IRemoveContactInput;
};

export type IMutationRemoveCustodianOperationArgs = {
  input: IRemoveCustodianOperationInput;
};

export type IMutationRemoveCustodianProfileArgs = {
  input: IRemoveCustodianProfileInput;
};

export type IMutationRemoveEscrowArgs = {
  input: IRemoveEscrowInput;
};

export type IMutationRemoveEscrowMemberArgs = {
  input: IRemoveEscrowMemberInput;
};

export type IMutationRemoveSaasEnvironmentArgs = {
  input: IRemoveSaasEnvironmentInput;
};

export type IMutationRemoveSaasProviderArgs = {
  input: IRemoveSaasProviderInput;
};

export type IMutationRemoveUserArgs = {
  input: IRemoveInput;
};

export type IMutationRequestBackupVersionDownloadArgs = {
  input: IRequestBackupVersionDownloadInput;
};

export type IMutationRequestEscrowTerminationArgs = {
  input: ITerminateInput;
};

export type IMutationReviewSuggestedChangeEscrowArgs = {
  input: IReviewSuggestedChangeEscrowInput;
};

export type IMutationSendForReviewCustodianProfileArgs = {
  input: ISendForReviewCustodianProfileInput;
};

export type IMutationStartBackupArgs = {
  input: IStartBackupInput;
};

export type IMutationStopBackupArgs = {
  input: IStopBackupInput;
};

export type IMutationSwitchToBackupPlanArgs = {
  input: ISwitchToBackupPlanInput;
};

export type IMutationTerminateCustodianProfileArgs = {
  input: ITerminateCustodianProfileInput;
};

export type IMutationTerminateSaasEnvironmentArgs = {
  input: ITerminateSaasEnvironmentInput;
};

export type IMutationToggleIntegrationArgs = {
  input: IToggleInput;
};

export type IMutationUpdateAgreementArgs = {
  input: IUpdateAgreementInput;
};

export type IMutationUpdateBackupArgs = {
  input: IUpdateBackupInput;
};

export type IMutationUpdateBeneficiaryArgs = {
  input: IUpdateBeneficiaryInput;
};

export type IMutationUpdateBillingAddressArgs = {
  input: IUpdateBillingAddressInput;
};

export type IMutationUpdateCompanyDetailsArgs = {
  input: IUpdateCompanyDetailsInput;
};

export type IMutationUpdateContactArgs = {
  input: IUpdateContactInput;
};

export type IMutationUpdateCustodianAssetsArgs = {
  input: IUpdateCustodianAssetsInput;
};

export type IMutationUpdateCustodianOperationArgs = {
  input: IUpdateCustodianOperationInput;
};

export type IMutationUpdateCustomIntegrationArgs = {
  input: IUpdateCustomIntegrationInput;
};

export type IMutationUpdateDepositSettingsArgs = {
  input: IUpdateDepositSettingsInput;
};

export type IMutationUpdateDepositorArgs = {
  input: IUpdateDepositorInput;
};

export type IMutationUpdateEscrowArgs = {
  input: IUpdateEscrowInput;
};

export type IMutationUpdateEscrowInvitationArgs = {
  input: IUpdateEscrowInvitationInput;
};

export type IMutationUpdateEscrowMemberArgs = {
  input: IUpdateEscrowMemberInput;
};

export type IMutationUpdatePaymentMethodArgs = {
  input: IRequestUpdatePaymentPageInput;
};

export type IMutationUpdateProfileInfoArgs = {
  input: IUpdateProfileInfoInput;
};

export type IMutationUpdateSaasProviderArgs = {
  input: IUpdateSaasProviderInput;
};

export type IMutationUpdateSuggestedChangeEscrowArgs = {
  input: IUpdateSuggestedChangeEscrowInput;
};

export type INotificationChangeInput = {
  beneficiaryDepositNotification?: InputMaybe<DepositNotificationEnum>;
  beneficiaryDisruptionNotification?: InputMaybe<DisruptionNotificationEnum>;
  beneficiaryDisruptionNotificationGracePeriod?: InputMaybe<DisruptionNotificationGracePeriodEnum>;
  depositorDepositNotification?: InputMaybe<DepositNotificationEnum>;
  depositorDisruptionNotification?: InputMaybe<DisruptionNotificationEnum>;
  depositorDisruptionNotificationGracePeriod?: InputMaybe<DisruptionNotificationGracePeriodEnum>;
  showDepositInformation?: InputMaybe<Scalars['Boolean']['input']>;
  storageRegion?: InputMaybe<Scalars['String']['input']>;
};

export type IOauthAssetCreateInput = {
  oauthRepositoryFullName: Scalars['String']['input'];
  oauthRepositoryId: Scalars['String']['input'];
};

export type IOauthIntegration = IIntegrationInterface & {
  __typename?: 'OauthIntegration';
  accountName?: Maybe<Scalars['String']['output']>;
  accountUrl?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  backupable: Scalars['Boolean']['output'];
  creator?: Maybe<IUser>;
  id: Scalars['ID']['output'];
  isClientOwner?: Maybe<Scalars['Boolean']['output']>;
  provider: Scalars['String']['output'];
  status: IntegrationStatusEnum;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export enum OauthIntegrationProviderEnum {
  Azure = 'azure',
  AzureDevops = 'azure_devops',
  Bitbucket = 'bitbucket',
  Gcp = 'gcp',
  Github = 'github',
  Gitlab = 'gitlab',
}

export type IOauthReposConn = {
  __typename?: 'OauthReposConn';
  repositories: Array<IOauthRepository>;
  success: Scalars['Boolean']['output'];
};

export type IOauthRepository = {
  __typename?: 'OauthRepository';
  description?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: RepositoryTypeEnum;
  url?: Maybe<Scalars['String']['output']>;
};

export type IPayload = IAgreementPayload | IEscrowPayload;

export type IPaymentMethod = {
  __typename?: 'PaymentMethod';
  cardLast4?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type IPlan = {
  __typename?: 'Plan';
  currencyCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  period: Scalars['Int']['output'];
  periodUnit: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type IProfile = {
  __typename?: 'Profile';
  email?: Maybe<Scalars['String']['output']>;
  gravatarUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UserRoleEnum>;
};

export type IQuery = {
  __typename?: 'Query';
  /** Recent activities */
  activities: IActivityPage;
  /** Get escrow agreement */
  agreement?: Maybe<IAgreement>;
  assetReport?: Maybe<IAssetReport>;
  /** Asset report groups */
  assetReportGroups: IAssetReportGroupPage;
  assets: IAssetPage;
  /** A single backup */
  backup: IBackup;
  backupAssetReport?: Maybe<IBackupAssetReport>;
  /** Backup asset report groups */
  backupAssetReportGroups: IBackupAssetReportGroupPage;
  backupVersions: IBackupVersionPage;
  backups: IBackupPage;
  /** Billing information */
  billing?: Maybe<IBilling>;
  clientChangesRequested: IClientChangesRequestedPayload;
  company: ICompany;
  custodianProfile?: Maybe<ICustodianProfile>;
  custodianProfiles: ICustodianProfilePage;
  /** A single escrow */
  escrow: IEscrow;
  escrowInvitations?: Maybe<IEscrowInvitationPage>;
  /** Escrow members */
  escrowMembers: IEscrowMemberPage;
  /** Current company escrows */
  escrows: IEscrowPage;
  exclusiveOfferPlans?: Maybe<Array<IExclusiveOfferPlan>>;
  integrations: IIntegrationInterfacePage;
  invoicePdf?: Maybe<IInvoicePdf>;
  invoices: IInvoicePage;
  /** Legacy repos ready for migration */
  legacyRepos: ILegacyRepoPage;
  /** Connection to the integration for OAuth repos */
  oauthReposConn: IOauthReposConn;
  profile: IProfile;
  saasEnvironment: ISaasEnvironment;
  saasEnvironments: ISaasEnvironmentPage;
  /** Escrow suggested changes */
  suggestedChanges: ISuggestChangePage;
  users: IUserPage;
};

export type IQueryActivitiesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryAgreementArgs = {
  escrowId: Scalars['ID']['input'];
};

export type IQueryAssetReportArgs = {
  assetReportId: Scalars['ID']['input'];
  escrowId: Scalars['ID']['input'];
};

export type IQueryAssetReportGroupsArgs = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  escrowId: Scalars['ID']['input'];
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<AssetsOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type IQueryAssetsArgs = {
  escrowId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<AssetsOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
  statusFilter?: InputMaybe<DepositStatusEnum>;
};

export type IQueryBackupArgs = {
  backupId: Scalars['ID']['input'];
};

export type IQueryBackupAssetReportArgs = {
  backupAssetReportId: Scalars['ID']['input'];
  backupId: Scalars['ID']['input'];
};

export type IQueryBackupAssetReportGroupsArgs = {
  backupAssetId?: InputMaybe<Scalars['ID']['input']>;
  backupId: Scalars['ID']['input'];
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<AssetsOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type IQueryBackupVersionsArgs = {
  backupAssetId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryBackupsArgs = {
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<BackupsOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BackupStatusEnum>;
};

export type IQueryCustodianProfileArgs = {
  custodianProfileId: Scalars['ID']['input'];
};

export type IQueryCustodianProfilesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryEscrowArgs = {
  escrowId: Scalars['ID']['input'];
};

export type IQueryEscrowInvitationsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryEscrowMembersArgs = {
  escrowPartyId: Scalars['ID']['input'];
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<EscrowMembersOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryEscrowsArgs = {
  actionItemsFilter?: InputMaybe<ActionItemFilterEnum>;
  actionableItems?: InputMaybe<Scalars['Boolean']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  currentCompanyRole?: InputMaybe<EscrowPartyTypeEnum>;
  order?: InputMaybe<QueryOrderEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
  statusFilter?: InputMaybe<EscrowStatusFilterEnum>;
};

export type IQueryIntegrationsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<IntegrationTypeEnum>;
};

export type IQueryInvoicePdfArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type IQueryInvoicesArgs = {
  offset?: InputMaybe<Scalars['String']['input']>;
  unpaid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IQueryLegacyReposArgs = {
  escrowId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<LegacyReposOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
  statusFilter?: InputMaybe<LegacyRepoMigrationStatusEnum>;
};

export type IQueryOauthReposConnArgs = {
  integrationId: Scalars['ID']['input'];
};

export type IQuerySaasEnvironmentArgs = {
  saasEnvironmentId: Scalars['ID']['input'];
};

export type IQuerySaasEnvironmentsArgs = {
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<SaasEnvironmentsOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SaasEnvironmentStatusEnum>;
};

export type IQuerySuggestedChangesArgs = {
  changeType?: InputMaybe<SuggestedChangeChangeTypeEnum>;
  creatorType?: InputMaybe<EscrowPartyTypeEnum>;
  escrowId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  partyStatus?: InputMaybe<SuggestedChangeStatusEnum>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  receiverType?: InputMaybe<EscrowPartyTypeEnum>;
  status?: InputMaybe<SuggestedChangeStatusEnum>;
};

export type IQueryUsersArgs = {
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<UserColumnsEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
};

export enum QueryOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Autogenerated input type of RejectEscrowInvitation */
export type IRejectEscrowInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowInvitationId: Scalars['ID']['input'];
};

/** Autogenerated return type of RejectEscrowInvitation. */
export type IRejectEscrowInvitationPayload = {
  __typename?: 'RejectEscrowInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowInvitation: IEscrowInvitation;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Reject */
export type IRejectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowTerminationId: Scalars['ID']['input'];
};

/** Autogenerated return type of Reject. */
export type IRejectPayload = {
  __typename?: 'RejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowTermination: IEscrowTermination;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveBackupAsset */
export type IRemoveBackupAssetInput = {
  backupAssetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RemoveBackupAsset. */
export type IRemoveBackupAssetPayload = {
  __typename?: 'RemoveBackupAssetPayload';
  backup: IBackup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveBackup */
export type IRemoveBackupInput = {
  backupId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RemoveBackup. */
export type IRemoveBackupPayload = {
  __typename?: 'RemoveBackupPayload';
  backup: IBackup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveContact */
export type IRemoveContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveContact. */
export type IRemoveContactPayload = {
  __typename?: 'RemoveContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveCustodianOperation */
export type IRemoveCustodianOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  custodianOperationId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveCustodianOperation. */
export type IRemoveCustodianOperationPayload = {
  __typename?: 'RemoveCustodianOperationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveCustodianProfile */
export type IRemoveCustodianProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  custodianProfileId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveCustodianProfile. */
export type IRemoveCustodianProfilePayload = {
  __typename?: 'RemoveCustodianProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveEscrow */
export type IRemoveEscrowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated input type of RemoveEscrowMember */
export type IRemoveEscrowMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowMemberId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveEscrowMember. */
export type IRemoveEscrowMemberPayload = {
  __typename?: 'RemoveEscrowMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of RemoveEscrow. */
export type IRemoveEscrowPayload = {
  __typename?: 'RemoveEscrowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Remove */
export type IRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of Remove. */
export type IRemovePayload = {
  __typename?: 'RemovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveSaasEnvironment */
export type IRemoveSaasEnvironmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  saasEnvironmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveSaasEnvironment. */
export type IRemoveSaasEnvironmentPayload = {
  __typename?: 'RemoveSaasEnvironmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  saasEnvironment?: Maybe<ISaasEnvironment>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveSaasProvider */
export type IRemoveSaasProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  saasProviderId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveSaasProvider. */
export type IRemoveSaasProviderPayload = {
  __typename?: 'RemoveSaasProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type IReportData = {
  __typename?: 'ReportData';
  collaborators?: Maybe<Scalars['Int']['output']>;
  commitsPerDay?: Maybe<Scalars['JSON']['output']>;
  languages?: Maybe<Scalars['JSON']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
  totalCommits?: Maybe<Scalars['Int']['output']>;
};

export enum RepositoryTypeEnum {
  Bucket = 'bucket',
  Git = 'git',
  Tfvc = 'tfvc',
}

/** Autogenerated input type of RequestBackupVersionDownload */
export type IRequestBackupVersionDownloadInput = {
  backupAssetId: Scalars['ID']['input'];
  backupVersionId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestBackupVersionDownload. */
export type IRequestBackupVersionDownloadPayload = {
  __typename?: 'RequestBackupVersionDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RequestUpdatePaymentPage */
export type IRequestUpdatePaymentPageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestUpdatePaymentPage. */
export type IRequestUpdatePaymentPagePayload = {
  __typename?: 'RequestUpdatePaymentPagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ReviewSuggestedChangeEscrow */
export type IReviewSuggestedChangeEscrowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  suggestedChangeId: Scalars['ID']['input'];
  verdict: SuggestedChangeVerdictEnum;
};

/** Autogenerated return type of ReviewSuggestedChangeEscrow. */
export type IReviewSuggestedChangeEscrowPayload = {
  __typename?: 'ReviewSuggestedChangeEscrowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  suggestedChange: ISuggestChange;
};

export type ISaasEnvironment = {
  __typename?: 'SaasEnvironment';
  activatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: IUser;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  policy: ISaasEnvironmentPolicy;
  saasEnvironmentTermination?: Maybe<ISaasEnvironmentTermination>;
  saasProviders: Array<ISaasProvider>;
  status: SaasEnvironmentStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ISaasEnvironmentPage = {
  __typename?: 'SaasEnvironmentPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<ISaasEnvironment>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ISaasEnvironmentPolicy = {
  __typename?: 'SaasEnvironmentPolicy';
  activate: Scalars['Boolean']['output'];
  remove: Scalars['Boolean']['output'];
  requestActivation: Scalars['Boolean']['output'];
  requestTermination: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum SaasEnvironmentStatusEnum {
  Active = 'active',
  Draft = 'draft',
  Pending = 'pending',
  Terminated = 'terminated',
}

export type ISaasEnvironmentTermination = {
  __typename?: 'SaasEnvironmentTermination';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  requesterUser: IUser;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  saasEnvironment: ISaasEnvironment;
  status: SaasEnvironmentTerminationStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum SaasEnvironmentTerminationStatusEnum {
  Finished = 'finished',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum SaasEnvironmentsOrderByEnum {
  CreatedAt = 'created_at',
  Status = 'status',
}

export type ISaasProvider = {
  __typename?: 'SaasProvider';
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  contactEmail: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  monthlyCost: Scalars['Float']['output'];
  platform: Scalars['String']['output'];
  protectionPeriod: SaasProviderProtectionPeriodEnum;
  role: SaasProviderRoleEnum;
  status: SaasProviderStatusEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ISaasProviderCreateInput = {
  contactEmail: Scalars['String']['input'];
  description: Scalars['String']['input'];
  monthlyCost: Scalars['Float']['input'];
  platform: Scalars['String']['input'];
  protectionPeriod: SaasProviderProtectionPeriodEnum;
  role: SaasProviderRoleEnum;
};

export enum SaasProviderProtectionPeriodEnum {
  M_1 = 'm_1',
  M_3 = 'm_3',
  M_6 = 'm_6',
  Y_1 = 'y_1',
  Y_2 = 'y_2',
  Y_3 = 'y_3',
}

export enum SaasProviderRoleEnum {
  Hosting = 'hosting',
  ThirdParty = 'third_party',
}

export enum SaasProviderStatusEnum {
  Pending = 'pending',
  SetupCompleted = 'setup_completed',
}

export type ISaasProviderUpdateInput = {
  contactEmail: Scalars['String']['input'];
  description: Scalars['String']['input'];
  monthlyCost: Scalars['Float']['input'];
  protectionPeriod: SaasProviderProtectionPeriodEnum;
  role: SaasProviderRoleEnum;
};

export type ISecureUploadPartInput = {
  etag: Scalars['String']['input'];
  partNumber: Scalars['Int']['input'];
};

/** Autogenerated input type of SendForReviewCustodianProfile */
export type ISendForReviewCustodianProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  custodianProfileId: Scalars['ID']['input'];
};

/** Autogenerated return type of SendForReviewCustodianProfile. */
export type ISendForReviewCustodianProfilePayload = {
  __typename?: 'SendForReviewCustodianProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodianProfile: ICustodianProfile;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type IShareFileIntegration = IIntegrationInterface & {
  __typename?: 'ShareFileIntegration';
  accountName?: Maybe<Scalars['String']['output']>;
  accountUrl?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  backupable: Scalars['Boolean']['output'];
  creator?: Maybe<IUser>;
  id: Scalars['ID']['output'];
  provider: Scalars['String']['output'];
  status: IntegrationStatusEnum;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type IShareFileIntegrationInputAttributes = {
  email: Scalars['String']['input'];
};

export type ISignature = {
  __typename?: 'Signature';
  email: Scalars['String']['output'];
  escrow: IEscrow;
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

/** Autogenerated input type of StartBackup */
export type IStartBackupInput = {
  backupId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StartBackup. */
export type IStartBackupPayload = {
  __typename?: 'StartBackupPayload';
  backup: IBackup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of StopBackup */
export type IStopBackupInput = {
  backupId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StopBackup. */
export type IStopBackupPayload = {
  __typename?: 'StopBackupPayload';
  backup: IBackup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type ISubscription = {
  __typename?: 'Subscription';
  addons: Array<IAddon>;
  billingPeriod: Scalars['Int']['output'];
  billingPeriodUnit: Scalars['String']['output'];
  coupons?: Maybe<Array<Scalars['String']['output']>>;
  currencyCode: Scalars['String']['output'];
  nextBillingAt?: Maybe<Scalars['ISO8601Date']['output']>;
  plans: Array<IPlan>;
  status: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type ISuggestChange = {
  __typename?: 'SuggestChange';
  changeType: SuggestedChangeChangeTypeEnum;
  creatorType: EscrowPartyTypeEnum;
  escrow: IEscrow;
  id: Scalars['ID']['output'];
  partyStatus?: Maybe<SuggestedChangeStatusEnum>;
  payload: Scalars['JSON']['output'];
  policy: ISuggestedChangePolicy;
  receiverType?: Maybe<EscrowPartyTypeEnum>;
  status: SuggestedChangeStatusEnum;
};

export type ISuggestChangePage = {
  __typename?: 'SuggestChangePage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<ISuggestChange>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum SuggestedChangeChangeTypeEnum {
  AgreementSuggestedChange = 'AgreementSuggestedChange',
  BeneficiarySuggestedChange = 'BeneficiarySuggestedChange',
  DepositSuggestedChange = 'DepositSuggestedChange',
  DepositorSuggestedChange = 'DepositorSuggestedChange',
  NotificationSuggestedChange = 'NotificationSuggestedChange',
}

export type ISuggestedChangeInput = {
  agreementSuggestedChange?: InputMaybe<IAgreementChangeInput>;
  beneficiarySuggestedChange?: InputMaybe<IBeneficiaryChangeInput>;
  depositSuggestedChange?: InputMaybe<IDepositChangeInput>;
  depositorSuggestedChange?: InputMaybe<IDepositorChangeInput>;
  notificationSuggestedChange?: InputMaybe<INotificationChangeInput>;
};

export type ISuggestedChangePolicy = {
  __typename?: 'SuggestedChangePolicy';
  review: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum SuggestedChangeStatusEnum {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum SuggestedChangeVerdictEnum {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

/** Autogenerated input type of SwitchToBackupPlan */
export type ISwitchToBackupPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SwitchToBackupPlan. */
export type ISwitchToBackupPlanPayload = {
  __typename?: 'SwitchToBackupPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export enum SyncStatusEnum {
  Active = 'active',
  AwaitingSync = 'awaiting_sync',
  Disconnected = 'disconnected',
  GracePeriod = 'grace_period',
  Pending = 'pending',
}

/** Autogenerated input type of TerminateCustodianProfile */
export type ITerminateCustodianProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  custodianProfileId: Scalars['ID']['input'];
};

/** Autogenerated return type of TerminateCustodianProfile. */
export type ITerminateCustodianProfilePayload = {
  __typename?: 'TerminateCustodianProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodianTermination?: Maybe<ICustodianTermination>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Terminate */
export type ITerminateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of Terminate. */
export type ITerminatePayload = {
  __typename?: 'TerminatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowTerminationId: Scalars['ID']['output'];
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of TerminateSaasEnvironment */
export type ITerminateSaasEnvironmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  saasEnvironmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of TerminateSaasEnvironment. */
export type ITerminateSaasEnvironmentPayload = {
  __typename?: 'TerminateSaasEnvironmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  saasEnvironmentTermination?: Maybe<ISaasEnvironmentTermination>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Toggle */
export type IToggleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['ID']['input'];
};

/** Autogenerated return type of Toggle. */
export type ITogglePayload = {
  __typename?: 'TogglePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  integration?: Maybe<IIntegrationInterface>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateAgreement */
export type IUpdateAgreementInput = {
  agreementInput: IAgreementInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAgreement. */
export type IUpdateAgreementPayload = {
  __typename?: 'UpdateAgreementPayload';
  agreement?: Maybe<IAgreement>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateBackup */
export type IUpdateBackupInput = {
  backupId: Scalars['ID']['input'];
  backupParams: IBackupUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateBackup. */
export type IUpdateBackupPayload = {
  __typename?: 'UpdateBackupPayload';
  backup: IBackup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateBeneficiary */
export type IUpdateBeneficiaryInput = {
  beneficiaryId: Scalars['ID']['input'];
  beneficiaryInputParams: IEscrowPartyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateBeneficiary. */
export type IUpdateBeneficiaryPayload = {
  __typename?: 'UpdateBeneficiaryPayload';
  beneficiary: IBeneficiary;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateBillingAddress */
export type IUpdateBillingAddressInput = {
  billingAddress: IBillingAddressInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateBillingAddress. */
export type IUpdateBillingAddressPayload = {
  __typename?: 'UpdateBillingAddressPayload';
  billingAddress?: Maybe<IBillingAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCompanyDetails */
export type IUpdateCompanyDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: ICompanyInput;
};

/** Autogenerated return type of UpdateCompanyDetails. */
export type IUpdateCompanyDetailsPayload = {
  __typename?: 'UpdateCompanyDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<ICompany>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateContact */
export type IUpdateContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  contactParams: IContactUpdateInput;
};

/** Autogenerated return type of UpdateContact. */
export type IUpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<IContact>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCustodianAssets */
export type IUpdateCustodianAssetsInput = {
  assetsInput: Array<ICustodianAssetCreateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  custodianProfileId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCustodianAssets. */
export type IUpdateCustodianAssetsPayload = {
  __typename?: 'UpdateCustodianAssetsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodianAssets?: Maybe<Array<ICustodianAsset>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCustodianOperation */
export type IUpdateCustodianOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  custodianOperationId: Scalars['ID']['input'];
  custodianOperationParams: ICustodianOperationUpdateInput;
};

/** Autogenerated return type of UpdateCustodianOperation. */
export type IUpdateCustodianOperationPayload = {
  __typename?: 'UpdateCustodianOperationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodianOperation: ICustodianOperation;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCustomIntegration */
export type IUpdateCustomIntegrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customIntegrationInput: ICustomIntegrationUpdateInputAttributes;
  integrationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCustomIntegration. */
export type IUpdateCustomIntegrationPayload = {
  __typename?: 'UpdateCustomIntegrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customIntegration?: Maybe<ICustomIntegration>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateDepositSettings */
export type IUpdateDepositSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  depositSettings: IDepositSettingsInput;
  escrowId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDepositSettings. */
export type IUpdateDepositSettingsPayload = {
  __typename?: 'UpdateDepositSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow?: Maybe<IEscrow>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateDepositor */
export type IUpdateDepositorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  depositorId: Scalars['ID']['input'];
  depositorInputParams: IEscrowPartyInput;
};

/** Autogenerated return type of UpdateDepositor. */
export type IUpdateDepositorPayload = {
  __typename?: 'UpdateDepositorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  depositor: IDepositor;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEscrow */
export type IUpdateEscrowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowId: Scalars['ID']['input'];
  escrowInput: IEscrowInput;
};

/** Autogenerated input type of UpdateEscrowInvitation */
export type IUpdateEscrowInvitationInput = {
  administrativeInputUser: IUserInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowInvitationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEscrowInvitation. */
export type IUpdateEscrowInvitationPayload = {
  __typename?: 'UpdateEscrowInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowInvitation: IEscrowInvitation;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEscrowMember */
export type IUpdateEscrowMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  escrowMemberId: Scalars['ID']['input'];
  escrowMemberParams: IEscrowMemberInput;
};

/** Autogenerated return type of UpdateEscrowMember. */
export type IUpdateEscrowMemberPayload = {
  __typename?: 'UpdateEscrowMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrowMember?: Maybe<IEscrowMember>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of UpdateEscrow. */
export type IUpdateEscrowPayload = {
  __typename?: 'UpdateEscrowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  escrow: IEscrow;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateProfileInfo */
export type IUpdateProfileInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateProfileInfo. */
export type IUpdateProfileInfoPayload = {
  __typename?: 'UpdateProfileInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  profile?: Maybe<IProfile>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateSaasProvider */
export type IUpdateSaasProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  saasProviderId: Scalars['ID']['input'];
  saasProviderParams: ISaasProviderUpdateInput;
};

/** Autogenerated return type of UpdateSaasProvider. */
export type IUpdateSaasProviderPayload = {
  __typename?: 'UpdateSaasProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  saasProvider?: Maybe<ISaasProvider>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateSuggestedChangeEscrow */
export type IUpdateSuggestedChangeEscrowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  suggestedChangeId: Scalars['ID']['input'];
  suggestedChangeParams: ISuggestedChangeInput;
};

/** Autogenerated return type of UpdateSuggestedChangeEscrow. */
export type IUpdateSuggestedChangeEscrowPayload = {
  __typename?: 'UpdateSuggestedChangeEscrowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  suggestedChange: ISuggestChange;
};

export type IUser = {
  __typename?: 'User';
  contacts?: Maybe<Array<IContact>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role: UserRoleEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum UserColumnsEnum {
  CreatedAt = 'created_at',
  Email = 'email',
  Name = 'name',
  Phone = 'phone',
  Role = 'role',
}

export type IUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRoleEnum>;
};

export type IUserPage = {
  __typename?: 'UserPage';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodes: Array<IUser>;
  nodesCount: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum UserRoleEnum {
  Administrator = 'administrator',
  Legal = 'legal',
  Owner = 'owner',
  Technical = 'technical',
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type IResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  Payload:
    | IAgreementPayload
    | (Omit<
        IEscrowPayload,
        'beneficiary' | 'depositor' | 'escrowInvitation' | 'escrowTermination' | 'events' | 'signatures'
      > & {
        beneficiary?: Maybe<_RefType['Beneficiary']>;
        depositor?: Maybe<_RefType['Depositor']>;
        escrowInvitation?: Maybe<_RefType['EscrowInvitation']>;
        escrowTermination?: Maybe<_RefType['EscrowTermination']>;
        events: Array<_RefType['EscrowEvent']>;
        signatures: Array<_RefType['Signature']>;
      });
};

/** Mapping of interface types */
export type IResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  EscrowPartyInterface:
    | (Omit<IBeneficiary, 'beneficiaryOptions' | 'company' | 'contacts' | 'escrow'> & {
        beneficiaryOptions?: Maybe<_RefType['BeneficiaryOptions']>;
        company?: Maybe<_RefType['Company']>;
        contacts: Array<_RefType['Contact']>;
        escrow: _RefType['Escrow'];
      })
    | (Omit<IDepositor, 'company' | 'contacts' | 'escrow'> & {
        company: _RefType['Company'];
        contacts: Array<_RefType['Contact']>;
        escrow: _RefType['Escrow'];
      });
  EventInterface: IAgreementEvent | (Omit<IEscrowEvent, 'payload'> & { payload: _RefType['Escrow'] });
  IntegrationInterface:
    | (Omit<ICustomIntegration, 'creator'> & { creator?: Maybe<_RefType['User']> })
    | (Omit<IOauthIntegration, 'creator'> & { creator?: Maybe<_RefType['User']> })
    | (Omit<IShareFileIntegration, 'creator'> & { creator?: Maybe<_RefType['User']> });
};

/** Mapping between all available schema types and the resolvers types */
export type IResolversTypes = {
  AcceptEscrowInvitationInput: IAcceptEscrowInvitationInput;
  AcceptEscrowInvitationPayload: ResolverTypeWrapper<
    Omit<IAcceptEscrowInvitationPayload, 'escrowInvitation'> & { escrowInvitation: IResolversTypes['EscrowInvitation'] }
  >;
  AcceptExclusiveOfferInput: IAcceptExclusiveOfferInput;
  AcceptExclusiveOfferPayload: ResolverTypeWrapper<
    Omit<IAcceptExclusiveOfferPayload, 'company'> & { company: IResolversTypes['Company'] }
  >;
  AcceptInput: IAcceptInput;
  AcceptPayload: ResolverTypeWrapper<
    Omit<IAcceptPayload, 'escrowTermination'> & { escrowTermination: IResolversTypes['EscrowTermination'] }
  >;
  ActionItemFilterEnum: ActionItemFilterEnum;
  ActivateEscrowInput: IActivateEscrowInput;
  ActivateEscrowPayload: ResolverTypeWrapper<
    Omit<IActivateEscrowPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  ActivateSaasEnvironmentInput: IActivateSaasEnvironmentInput;
  ActivateSaasEnvironmentPayload: ResolverTypeWrapper<
    Omit<IActivateSaasEnvironmentPayload, 'saasEnvironment'> & {
      saasEnvironment?: Maybe<IResolversTypes['SaasEnvironment']>;
    }
  >;
  Activity: ResolverTypeWrapper<Omit<IActivity, 'payload'> & { payload: IResolversTypes['Payload'] }>;
  ActivityPage: ResolverTypeWrapper<Omit<IActivityPage, 'nodes'> & { nodes: Array<IResolversTypes['Activity']> }>;
  AddInput: IAddInput;
  AddPayload: ResolverTypeWrapper<Omit<IAddPayload, 'users'> & { users?: Maybe<Array<IResolversTypes['User']>> }>;
  Addon: ResolverTypeWrapper<IAddon>;
  Agreement: ResolverTypeWrapper<IAgreement>;
  AgreementChangeInput: IAgreementChangeInput;
  AgreementEvent: ResolverTypeWrapper<IAgreementEvent>;
  AgreementFormEnum: AgreementFormEnum;
  AgreementInput: IAgreementInput;
  AgreementPayload: ResolverTypeWrapper<IAgreementPayload>;
  AgreementPolicy: ResolverTypeWrapper<IAgreementPolicy>;
  ApplyCouponInput: IApplyCouponInput;
  ApplyCouponPayload: ResolverTypeWrapper<
    Omit<IApplyCouponPayload, 'company'> & { company: IResolversTypes['Company'] }
  >;
  ApproveAgreementInput: IApproveAgreementInput;
  ApproveAgreementPayload: ResolverTypeWrapper<
    Omit<IApproveAgreementPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  Asset: ResolverTypeWrapper<
    Omit<IAsset, 'escrow' | 'integration'> & {
      escrow: IResolversTypes['Escrow'];
      integration?: Maybe<IResolversTypes['IntegrationInterface']>;
    }
  >;
  AssetPage: ResolverTypeWrapper<Omit<IAssetPage, 'nodes'> & { nodes: Array<IResolversTypes['Asset']> }>;
  AssetPolicy: ResolverTypeWrapper<IAssetPolicy>;
  AssetReport: ResolverTypeWrapper<Omit<IAssetReport, 'asset'> & { asset: IResolversTypes['Asset'] }>;
  AssetReportGroup: ResolverTypeWrapper<
    Omit<IAssetReportGroup, 'asset' | 'assetReports'> & {
      asset: IResolversTypes['Asset'];
      assetReports: Array<IResolversTypes['AssetReport']>;
    }
  >;
  AssetReportGroupPage: ResolverTypeWrapper<
    Omit<IAssetReportGroupPage, 'nodes'> & { nodes: Array<IResolversTypes['AssetReportGroup']> }
  >;
  AssetTypeEnum: AssetTypeEnum;
  AssetsOrderByEnum: AssetsOrderByEnum;
  AssignBackupMembersInput: IAssignBackupMembersInput;
  AssignBackupMembersPayload: ResolverTypeWrapper<
    Omit<IAssignBackupMembersPayload, 'backupMembers'> & {
      backupMembers?: Maybe<Array<IResolversTypes['BackupMember']>>;
    }
  >;
  Backup: ResolverTypeWrapper<
    Omit<IBackup, 'backupMembers' | 'creator' | 'integration'> & {
      backupMembers: Array<IResolversTypes['BackupMember']>;
      creator?: Maybe<IResolversTypes['User']>;
      integration: IResolversTypes['IntegrationInterface'];
    }
  >;
  BackupAsset: ResolverTypeWrapper<IBackupAsset>;
  BackupAssetCreateInput: IBackupAssetCreateInput;
  BackupAssetReport: ResolverTypeWrapper<IBackupAssetReport>;
  BackupAssetReportGroup: ResolverTypeWrapper<IBackupAssetReportGroup>;
  BackupAssetReportGroupPage: ResolverTypeWrapper<IBackupAssetReportGroupPage>;
  BackupAssetStatusEnum: BackupAssetStatusEnum;
  BackupAssetTypeEnum: BackupAssetTypeEnum;
  BackupCreateInput: IBackupCreateInput;
  BackupMember: ResolverTypeWrapper<Omit<IBackupMember, 'user'> & { user: IResolversTypes['User'] }>;
  BackupMemberRoleEnum: BackupMemberRoleEnum;
  BackupPage: ResolverTypeWrapper<Omit<IBackupPage, 'nodes'> & { nodes: Array<IResolversTypes['Backup']> }>;
  BackupPolicy: ResolverTypeWrapper<IBackupPolicy>;
  BackupStatusEnum: BackupStatusEnum;
  BackupUpdateInput: IBackupUpdateInput;
  BackupVersion: ResolverTypeWrapper<IBackupVersion>;
  BackupVersionPage: ResolverTypeWrapper<IBackupVersionPage>;
  BackupVersionStatusEnum: BackupVersionStatusEnum;
  BackupsOrderByEnum: BackupsOrderByEnum;
  Beneficiary: ResolverTypeWrapper<
    Omit<IBeneficiary, 'beneficiaryOptions' | 'company' | 'contacts' | 'escrow'> & {
      beneficiaryOptions?: Maybe<IResolversTypes['BeneficiaryOptions']>;
      company?: Maybe<IResolversTypes['Company']>;
      contacts: Array<IResolversTypes['Contact']>;
      escrow: IResolversTypes['Escrow'];
    }
  >;
  BeneficiaryChangeInput: IBeneficiaryChangeInput;
  BeneficiaryOptions: ResolverTypeWrapper<IBeneficiaryOptions>;
  BeneficiaryPolicy: ResolverTypeWrapper<IBeneficiaryPolicy>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']['output']>;
  Billing: ResolverTypeWrapper<IBilling>;
  BillingAddress: ResolverTypeWrapper<IBillingAddress>;
  BillingAddressInput: IBillingAddressInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CancelSecureUploadInput: ICancelSecureUploadInput;
  CancelSecureUploadPayload: ResolverTypeWrapper<ICancelSecureUploadPayload>;
  CancelSubscriptionInput: ICancelSubscriptionInput;
  CancelSubscriptionPayload: ResolverTypeWrapper<
    Omit<ICancelSubscriptionPayload, 'company'> & { company: IResolversTypes['Company'] }
  >;
  ClientChangesRequested: ResolverTypeWrapper<IClientChangesRequested>;
  ClientChangesRequestedPayload: ResolverTypeWrapper<IClientChangesRequestedPayload>;
  Company: ResolverTypeWrapper<
    Omit<ICompany, 'assets' | 'users'> & { assets: IResolversTypes['AssetPage']; users: Array<IResolversTypes['User']> }
  >;
  CompanyInput: ICompanyInput;
  CompanyInvitationStatusEnum: CompanyInvitationStatusEnum;
  CompleteSecureUploadInput: ICompleteSecureUploadInput;
  CompleteSecureUploadPayload: ResolverTypeWrapper<
    Omit<ICompleteSecureUploadPayload, 'asset'> & { asset?: Maybe<IResolversTypes['Asset']> }
  >;
  ConfirmEmailChangeInput: IConfirmEmailChangeInput;
  ConfirmEmailChangePayload: ResolverTypeWrapper<IConfirmEmailChangePayload>;
  Contact: ResolverTypeWrapper<
    Omit<IContact, 'escrow' | 'escrowParty'> & {
      escrow: IResolversTypes['Escrow'];
      escrowParty: IResolversTypes['EscrowPartyInterface'];
    }
  >;
  ContactCreateInput: IContactCreateInput;
  ContactTypeEnum: ContactTypeEnum;
  ContactUpdateInput: IContactUpdateInput;
  CreateBackupAssetsInput: ICreateBackupAssetsInput;
  CreateBackupAssetsPayload: ResolverTypeWrapper<ICreateBackupAssetsPayload>;
  CreateBackupInput: ICreateBackupInput;
  CreateBackupPayload: ResolverTypeWrapper<
    Omit<ICreateBackupPayload, 'backup'> & { backup?: Maybe<IResolversTypes['Backup']> }
  >;
  CreateBeneficiaryInput: ICreateBeneficiaryInput;
  CreateBeneficiaryPayload: ResolverTypeWrapper<
    Omit<ICreateBeneficiaryPayload, 'beneficiary'> & { beneficiary?: Maybe<IResolversTypes['Beneficiary']> }
  >;
  CreateContactInput: ICreateContactInput;
  CreateContactPayload: ResolverTypeWrapper<
    Omit<ICreateContactPayload, 'contact'> & { contact?: Maybe<IResolversTypes['Contact']> }
  >;
  CreateCustodianOperationInput: ICreateCustodianOperationInput;
  CreateCustodianOperationPayload: ResolverTypeWrapper<
    Omit<ICreateCustodianOperationPayload, 'custodianOperation'> & {
      custodianOperation?: Maybe<IResolversTypes['CustodianOperation']>;
    }
  >;
  CreateCustodianProfileInput: ICreateCustodianProfileInput;
  CreateCustodianProfilePayload: ResolverTypeWrapper<
    Omit<ICreateCustodianProfilePayload, 'custodianProfile'> & {
      custodianProfile?: Maybe<IResolversTypes['CustodianProfile']>;
    }
  >;
  CreateCustomAssetsInput: ICreateCustomAssetsInput;
  CreateCustomAssetsPayload: ResolverTypeWrapper<
    Omit<ICreateCustomAssetsPayload, 'assets'> & { assets?: Maybe<Array<IResolversTypes['Asset']>> }
  >;
  CreateCustomIntegrationInput: ICreateCustomIntegrationInput;
  CreateCustomIntegrationPayload: ResolverTypeWrapper<
    Omit<ICreateCustomIntegrationPayload, 'customIntegration'> & {
      customIntegration?: Maybe<IResolversTypes['CustomIntegration']>;
    }
  >;
  CreateEscrowInput: ICreateEscrowInput;
  CreateEscrowMemberInput: ICreateEscrowMemberInput;
  CreateEscrowMemberPayload: ResolverTypeWrapper<
    Omit<ICreateEscrowMemberPayload, 'escrowMember'> & { escrowMember?: Maybe<IResolversTypes['EscrowMember']> }
  >;
  CreateEscrowPayload: ResolverTypeWrapper<
    Omit<ICreateEscrowPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  CreateFeedbackInput: ICreateFeedbackInput;
  CreateFeedbackPayload: ResolverTypeWrapper<ICreateFeedbackPayload>;
  CreateOauthAssetsInput: ICreateOauthAssetsInput;
  CreateOauthAssetsPayload: ResolverTypeWrapper<
    Omit<ICreateOauthAssetsPayload, 'assets'> & { assets?: Maybe<Array<IResolversTypes['Asset']>> }
  >;
  CreateSaasEnvironmentInput: ICreateSaasEnvironmentInput;
  CreateSaasEnvironmentPayload: ResolverTypeWrapper<
    Omit<ICreateSaasEnvironmentPayload, 'saasEnvironment'> & {
      saasEnvironment?: Maybe<IResolversTypes['SaasEnvironment']>;
    }
  >;
  CreateSaasProviderInput: ICreateSaasProviderInput;
  CreateSaasProviderPayload: ResolverTypeWrapper<ICreateSaasProviderPayload>;
  CreateShareFileIntegrationInput: ICreateShareFileIntegrationInput;
  CreateShareFileIntegrationPayload: ResolverTypeWrapper<
    Omit<ICreateShareFileIntegrationPayload, 'shareFileIntegration'> & {
      shareFileIntegration?: Maybe<IResolversTypes['ShareFileIntegration']>;
    }
  >;
  CreateSuggestedChangeEscrowInput: ICreateSuggestedChangeEscrowInput;
  CreateSuggestedChangeEscrowPayload: ResolverTypeWrapper<
    Omit<ICreateSuggestedChangeEscrowPayload, 'suggestedChange'> & {
      suggestedChange?: Maybe<IResolversTypes['SuggestChange']>;
    }
  >;
  CustodianAsset: ResolverTypeWrapper<
    Omit<ICustodianAsset, 'custodianIntegration' | 'custodianProfile'> & {
      custodianIntegration: IResolversTypes['CustodianIntegration'];
      custodianProfile: IResolversTypes['CustodianProfile'];
    }
  >;
  CustodianAssetCreateInput: ICustodianAssetCreateInput;
  CustodianAssetCustodianStatusEnum: CustodianAssetCustodianStatusEnum;
  CustodianAssetOperationalStatusEnum: CustodianAssetOperationalStatusEnum;
  CustodianIntegration: ResolverTypeWrapper<
    Omit<ICustodianIntegration, 'custodianAssets' | 'custodianProfile' | 'integration'> & {
      custodianAssets: Array<IResolversTypes['CustodianAsset']>;
      custodianProfile: IResolversTypes['CustodianProfile'];
      integration: IResolversTypes['OauthIntegration'];
    }
  >;
  CustodianIntegrationStatusEnum: CustodianIntegrationStatusEnum;
  CustodianOperation: ResolverTypeWrapper<
    Omit<ICustodianOperation, 'custodianProfile'> & { custodianProfile: IResolversTypes['CustodianProfile'] }
  >;
  CustodianOperationCreateInput: ICustodianOperationCreateInput;
  CustodianOperationCustodianServiceEventEnum: CustodianOperationCustodianServiceEventEnum;
  CustodianOperationEndOfRetentionPeriodEnum: CustodianOperationEndOfRetentionPeriodEnum;
  CustodianOperationRegulatoryRequirementsEnum: CustodianOperationRegulatoryRequirementsEnum;
  CustodianOperationTypeEnum: CustodianOperationTypeEnum;
  CustodianOperationUpdateInput: ICustodianOperationUpdateInput;
  CustodianProfile: ResolverTypeWrapper<
    Omit<
      ICustodianProfile,
      'creator' | 'custodianAssets' | 'custodianIntegrations' | 'custodianOperations' | 'custodianTermination'
    > & {
      creator: IResolversTypes['User'];
      custodianAssets: Array<IResolversTypes['CustodianAsset']>;
      custodianIntegrations: Array<IResolversTypes['CustodianIntegration']>;
      custodianOperations: Array<IResolversTypes['CustodianOperation']>;
      custodianTermination?: Maybe<IResolversTypes['CustodianTermination']>;
    }
  >;
  CustodianProfilePage: ResolverTypeWrapper<
    Omit<ICustodianProfilePage, 'nodes'> & { nodes: Array<IResolversTypes['CustodianProfile']> }
  >;
  CustodianProfilePolicy: ResolverTypeWrapper<ICustodianProfilePolicy>;
  CustodianProfileStatusEnum: CustodianProfileStatusEnum;
  CustodianTermination: ResolverTypeWrapper<
    Omit<ICustodianTermination, 'custodianProfile' | 'requesterUser'> & {
      custodianProfile: IResolversTypes['CustodianProfile'];
      requesterUser: IResolversTypes['User'];
    }
  >;
  CustodianTerminationStatusEnum: CustodianTerminationStatusEnum;
  CustomAssetCreateInput: ICustomAssetCreateInput;
  CustomIntegration: ResolverTypeWrapper<
    Omit<ICustomIntegration, 'creator'> & { creator?: Maybe<IResolversTypes['User']> }
  >;
  CustomIntegrationInputAttributes: ICustomIntegrationInputAttributes;
  CustomIntegrationProviderEnum: CustomIntegrationProviderEnum;
  CustomIntegrationUpdateInputAttributes: ICustomIntegrationUpdateInputAttributes;
  DeleteContactChangeInput: IDeleteContactChangeInput;
  DeletedAssetInput: IDeletedAssetInput;
  DepositChangeInput: IDepositChangeInput;
  DepositMethodEnum: DepositMethodEnum;
  DepositNotificationEnum: DepositNotificationEnum;
  DepositSettingsInput: IDepositSettingsInput;
  DepositStatusEnum: DepositStatusEnum;
  DepositTypeEnum: DepositTypeEnum;
  Depositor: ResolverTypeWrapper<
    Omit<IDepositor, 'company' | 'contacts' | 'escrow'> & {
      company: IResolversTypes['Company'];
      contacts: Array<IResolversTypes['Contact']>;
      escrow: IResolversTypes['Escrow'];
    }
  >;
  DepositorChangeInput: IDepositorChangeInput;
  DepositorPolicy: ResolverTypeWrapper<IDepositorPolicy>;
  DestroyInput: IDestroyInput;
  DestroyPayload: ResolverTypeWrapper<Omit<IDestroyPayload, 'escrow'> & { escrow: IResolversTypes['Escrow'] }>;
  DisableBackupAssetInput: IDisableBackupAssetInput;
  DisableBackupAssetPayload: ResolverTypeWrapper<
    Omit<IDisableBackupAssetPayload, 'backup'> & { backup: IResolversTypes['Backup'] }
  >;
  DisruptionNotificationEnum: DisruptionNotificationEnum;
  DisruptionNotificationGracePeriodEnum: DisruptionNotificationGracePeriodEnum;
  EditContactChangeInput: IEditContactChangeInput;
  EditInput: IEditInput;
  EditPayload: ResolverTypeWrapper<Omit<IEditPayload, 'user'> & { user?: Maybe<IResolversTypes['User']> }>;
  EditUserInput: IEditUserInput;
  EnableBackupAssetInput: IEnableBackupAssetInput;
  EnableBackupAssetPayload: ResolverTypeWrapper<IEnableBackupAssetPayload>;
  EnableBackupReportsInput: IEnableBackupReportsInput;
  EnableBackupReportsPayload: ResolverTypeWrapper<
    Omit<IEnableBackupReportsPayload, 'backup'> & { backup: IResolversTypes['Backup'] }
  >;
  EnableEscrowReportsInput: IEnableEscrowReportsInput;
  EnableEscrowReportsPayload: ResolverTypeWrapper<
    Omit<IEnableEscrowReportsPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  Escrow: ResolverTypeWrapper<
    Omit<IEscrow, 'beneficiary' | 'depositor' | 'escrowInvitation' | 'escrowTermination' | 'events' | 'signatures'> & {
      beneficiary?: Maybe<IResolversTypes['Beneficiary']>;
      depositor?: Maybe<IResolversTypes['Depositor']>;
      escrowInvitation?: Maybe<IResolversTypes['EscrowInvitation']>;
      escrowTermination?: Maybe<IResolversTypes['EscrowTermination']>;
      events: Array<IResolversTypes['EscrowEvent']>;
      signatures: Array<IResolversTypes['Signature']>;
    }
  >;
  EscrowEvent: ResolverTypeWrapper<Omit<IEscrowEvent, 'payload'> & { payload: IResolversTypes['Escrow'] }>;
  EscrowInput: IEscrowInput;
  EscrowInvitation: ResolverTypeWrapper<
    Omit<IEscrowInvitation, 'escrow' | 'user'> & {
      escrow: IResolversTypes['Escrow'];
      user?: Maybe<IResolversTypes['User']>;
    }
  >;
  EscrowInvitationPage: ResolverTypeWrapper<
    Omit<IEscrowInvitationPage, 'nodes'> & { nodes: Array<IResolversTypes['EscrowInvitation']> }
  >;
  EscrowInvitationPolicy: ResolverTypeWrapper<IEscrowInvitationPolicy>;
  EscrowMarkAsViewedInput: IEscrowMarkAsViewedInput;
  EscrowMarkAsViewedPayload: ResolverTypeWrapper<IEscrowMarkAsViewedPayload>;
  EscrowMember: ResolverTypeWrapper<
    Omit<IEscrowMember, 'escrowParty' | 'user'> & {
      escrowParty: IResolversTypes['EscrowPartyInterface'];
      user: IResolversTypes['User'];
    }
  >;
  EscrowMemberInput: IEscrowMemberInput;
  EscrowMemberPage: ResolverTypeWrapper<
    Omit<IEscrowMemberPage, 'nodes'> & { nodes: Array<IResolversTypes['EscrowMember']> }
  >;
  EscrowMemberPolicy: ResolverTypeWrapper<IEscrowMemberPolicy>;
  EscrowMemberRoleEnum: EscrowMemberRoleEnum;
  EscrowMembersOrderByEnum: EscrowMembersOrderByEnum;
  EscrowPage: ResolverTypeWrapper<Omit<IEscrowPage, 'nodes'> & { nodes: Array<IResolversTypes['Escrow']> }>;
  EscrowPartyInput: IEscrowPartyInput;
  EscrowPartyInterface: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['EscrowPartyInterface']>;
  EscrowPartyTypeEnum: EscrowPartyTypeEnum;
  EscrowPayload: ResolverTypeWrapper<
    Omit<
      IEscrowPayload,
      'beneficiary' | 'depositor' | 'escrowInvitation' | 'escrowTermination' | 'events' | 'signatures'
    > & {
      beneficiary?: Maybe<IResolversTypes['Beneficiary']>;
      depositor?: Maybe<IResolversTypes['Depositor']>;
      escrowInvitation?: Maybe<IResolversTypes['EscrowInvitation']>;
      escrowTermination?: Maybe<IResolversTypes['EscrowTermination']>;
      events: Array<IResolversTypes['EscrowEvent']>;
      signatures: Array<IResolversTypes['Signature']>;
    }
  >;
  EscrowPolicy: ResolverTypeWrapper<IEscrowPolicy>;
  EscrowStatusEnum: EscrowStatusEnum;
  EscrowStatusFilterEnum: EscrowStatusFilterEnum;
  EscrowTermination: ResolverTypeWrapper<
    Omit<IEscrowTermination, 'escrow' | 'requesterUser'> & {
      escrow: IResolversTypes['Escrow'];
      requesterUser?: Maybe<IResolversTypes['User']>;
    }
  >;
  EscrowTerminationPolicy: ResolverTypeWrapper<IEscrowTerminationPolicy>;
  EscrowTerminationStatusEnum: EscrowTerminationStatusEnum;
  EventInterface: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['EventInterface']>;
  ExclusiveOfferPlan: ResolverTypeWrapper<IExclusiveOfferPlan>;
  FinishAuthInput: IFinishAuthInput;
  FinishAuthPayload: ResolverTypeWrapper<
    Omit<IFinishAuthPayload, 'oauthIntegration'> & { oauthIntegration?: Maybe<IResolversTypes['OauthIntegration']> }
  >;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GenerateAgreementInput: IGenerateAgreementInput;
  GenerateAgreementPayload: ResolverTypeWrapper<
    Omit<IGenerateAgreementPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  ISO8601Date: ResolverTypeWrapper<Scalars['ISO8601Date']['output']>;
  ISO8601DateTime: ResolverTypeWrapper<Scalars['ISO8601DateTime']['output']>;
  ImpersonateInput: IImpersonateInput;
  ImpersonatePayload: ResolverTypeWrapper<IImpersonatePayload>;
  InitAuthInput: IInitAuthInput;
  InitAuthPayload: ResolverTypeWrapper<IInitAuthPayload>;
  InitSecureUploadInput: IInitSecureUploadInput;
  InitSecureUploadPayload: ResolverTypeWrapper<IInitSecureUploadPayload>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntegrationAuth: ResolverTypeWrapper<IIntegrationAuth>;
  IntegrationAuthClientPayloadInput: IIntegrationAuthClientPayloadInput;
  IntegrationAuthOptionsInput: IIntegrationAuthOptionsInput;
  IntegrationInterface: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['IntegrationInterface']>;
  IntegrationInterfacePage: ResolverTypeWrapper<
    Omit<IIntegrationInterfacePage, 'nodes'> & { nodes: Array<IResolversTypes['IntegrationInterface']> }
  >;
  IntegrationStatusEnum: IntegrationStatusEnum;
  IntegrationTypeEnum: IntegrationTypeEnum;
  InvitationStatusEnum: InvitationStatusEnum;
  InviteOwnerBeneficiaryInput: IInviteOwnerBeneficiaryInput;
  InviteOwnerBeneficiaryPayload: ResolverTypeWrapper<
    Omit<IInviteOwnerBeneficiaryPayload, 'escrowInvitation'> & { escrowInvitation: IResolversTypes['EscrowInvitation'] }
  >;
  InviteOwnerDepositorInput: IInviteOwnerDepositorInput;
  InviteOwnerDepositorPayload: ResolverTypeWrapper<
    Omit<IInviteOwnerDepositorPayload, 'escrowInvitation'> & { escrowInvitation: IResolversTypes['EscrowInvitation'] }
  >;
  Invoice: ResolverTypeWrapper<IInvoice>;
  InvoicePage: ResolverTypeWrapper<IInvoicePage>;
  InvoicePdf: ResolverTypeWrapper<IInvoicePdf>;
  InvoiceStatusEnum: InvoiceStatusEnum;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JurisdictionEnum: JurisdictionEnum;
  LegacyRepo: ResolverTypeWrapper<Omit<ILegacyRepo, 'escrow'> & { escrow: IResolversTypes['Escrow'] }>;
  LegacyRepoMigrationStatusEnum: LegacyRepoMigrationStatusEnum;
  LegacyRepoPage: ResolverTypeWrapper<Omit<ILegacyRepoPage, 'nodes'> & { nodes: Array<IResolversTypes['LegacyRepo']> }>;
  LegacyRepoPlatformEnum: LegacyRepoPlatformEnum;
  LegacyReposOrderByEnum: LegacyReposOrderByEnum;
  LegalContextEnum: LegalContextEnum;
  LegalStatus: ResolverTypeWrapper<ILegalStatus>;
  LiabilityEnum: LiabilityEnum;
  MarkAsViewedInput: IMarkAsViewedInput;
  MarkAsViewedPayload: ResolverTypeWrapper<IMarkAsViewedPayload>;
  MigrateLegacyRepoInput: IMigrateLegacyRepoInput;
  MigrateLegacyRepoPayload: ResolverTypeWrapper<IMigrateLegacyRepoPayload>;
  MigrateLegacyReposInput: IMigrateLegacyReposInput;
  MigrateLegacyReposPayload: ResolverTypeWrapper<
    Omit<IMigrateLegacyReposPayload, 'migratedLegacyRepos'> & {
      migratedLegacyRepos: Array<IResolversTypes['LegacyRepo']>;
    }
  >;
  Mutation: ResolverTypeWrapper<{}>;
  NotificationChangeInput: INotificationChangeInput;
  OauthAssetCreateInput: IOauthAssetCreateInput;
  OauthIntegration: ResolverTypeWrapper<
    Omit<IOauthIntegration, 'creator'> & { creator?: Maybe<IResolversTypes['User']> }
  >;
  OauthIntegrationProviderEnum: OauthIntegrationProviderEnum;
  OauthReposConn: ResolverTypeWrapper<IOauthReposConn>;
  OauthRepository: ResolverTypeWrapper<IOauthRepository>;
  Payload: ResolverTypeWrapper<IResolversUnionTypes<IResolversTypes>['Payload']>;
  PaymentMethod: ResolverTypeWrapper<IPaymentMethod>;
  Plan: ResolverTypeWrapper<IPlan>;
  Profile: ResolverTypeWrapper<IProfile>;
  Query: ResolverTypeWrapper<{}>;
  QueryOrderEnum: QueryOrderEnum;
  RejectEscrowInvitationInput: IRejectEscrowInvitationInput;
  RejectEscrowInvitationPayload: ResolverTypeWrapper<
    Omit<IRejectEscrowInvitationPayload, 'escrowInvitation'> & { escrowInvitation: IResolversTypes['EscrowInvitation'] }
  >;
  RejectInput: IRejectInput;
  RejectPayload: ResolverTypeWrapper<
    Omit<IRejectPayload, 'escrowTermination'> & { escrowTermination: IResolversTypes['EscrowTermination'] }
  >;
  RemoveBackupAssetInput: IRemoveBackupAssetInput;
  RemoveBackupAssetPayload: ResolverTypeWrapper<
    Omit<IRemoveBackupAssetPayload, 'backup'> & { backup: IResolversTypes['Backup'] }
  >;
  RemoveBackupInput: IRemoveBackupInput;
  RemoveBackupPayload: ResolverTypeWrapper<
    Omit<IRemoveBackupPayload, 'backup'> & { backup: IResolversTypes['Backup'] }
  >;
  RemoveContactInput: IRemoveContactInput;
  RemoveContactPayload: ResolverTypeWrapper<
    Omit<IRemoveContactPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  RemoveCustodianOperationInput: IRemoveCustodianOperationInput;
  RemoveCustodianOperationPayload: ResolverTypeWrapper<IRemoveCustodianOperationPayload>;
  RemoveCustodianProfileInput: IRemoveCustodianProfileInput;
  RemoveCustodianProfilePayload: ResolverTypeWrapper<IRemoveCustodianProfilePayload>;
  RemoveEscrowInput: IRemoveEscrowInput;
  RemoveEscrowMemberInput: IRemoveEscrowMemberInput;
  RemoveEscrowMemberPayload: ResolverTypeWrapper<IRemoveEscrowMemberPayload>;
  RemoveEscrowPayload: ResolverTypeWrapper<IRemoveEscrowPayload>;
  RemoveInput: IRemoveInput;
  RemovePayload: ResolverTypeWrapper<IRemovePayload>;
  RemoveSaasEnvironmentInput: IRemoveSaasEnvironmentInput;
  RemoveSaasEnvironmentPayload: ResolverTypeWrapper<
    Omit<IRemoveSaasEnvironmentPayload, 'saasEnvironment'> & {
      saasEnvironment?: Maybe<IResolversTypes['SaasEnvironment']>;
    }
  >;
  RemoveSaasProviderInput: IRemoveSaasProviderInput;
  RemoveSaasProviderPayload: ResolverTypeWrapper<IRemoveSaasProviderPayload>;
  ReportData: ResolverTypeWrapper<IReportData>;
  RepositoryTypeEnum: RepositoryTypeEnum;
  RequestBackupVersionDownloadInput: IRequestBackupVersionDownloadInput;
  RequestBackupVersionDownloadPayload: ResolverTypeWrapper<IRequestBackupVersionDownloadPayload>;
  RequestUpdatePaymentPageInput: IRequestUpdatePaymentPageInput;
  RequestUpdatePaymentPagePayload: ResolverTypeWrapper<IRequestUpdatePaymentPagePayload>;
  ReviewSuggestedChangeEscrowInput: IReviewSuggestedChangeEscrowInput;
  ReviewSuggestedChangeEscrowPayload: ResolverTypeWrapper<
    Omit<IReviewSuggestedChangeEscrowPayload, 'suggestedChange'> & { suggestedChange: IResolversTypes['SuggestChange'] }
  >;
  SaasEnvironment: ResolverTypeWrapper<
    Omit<ISaasEnvironment, 'creator' | 'saasEnvironmentTermination'> & {
      creator: IResolversTypes['User'];
      saasEnvironmentTermination?: Maybe<IResolversTypes['SaasEnvironmentTermination']>;
    }
  >;
  SaasEnvironmentPage: ResolverTypeWrapper<
    Omit<ISaasEnvironmentPage, 'nodes'> & { nodes: Array<IResolversTypes['SaasEnvironment']> }
  >;
  SaasEnvironmentPolicy: ResolverTypeWrapper<ISaasEnvironmentPolicy>;
  SaasEnvironmentStatusEnum: SaasEnvironmentStatusEnum;
  SaasEnvironmentTermination: ResolverTypeWrapper<
    Omit<ISaasEnvironmentTermination, 'requesterUser' | 'saasEnvironment'> & {
      requesterUser: IResolversTypes['User'];
      saasEnvironment: IResolversTypes['SaasEnvironment'];
    }
  >;
  SaasEnvironmentTerminationStatusEnum: SaasEnvironmentTerminationStatusEnum;
  SaasEnvironmentsOrderByEnum: SaasEnvironmentsOrderByEnum;
  SaasProvider: ResolverTypeWrapper<ISaasProvider>;
  SaasProviderCreateInput: ISaasProviderCreateInput;
  SaasProviderProtectionPeriodEnum: SaasProviderProtectionPeriodEnum;
  SaasProviderRoleEnum: SaasProviderRoleEnum;
  SaasProviderStatusEnum: SaasProviderStatusEnum;
  SaasProviderUpdateInput: ISaasProviderUpdateInput;
  SecureUploadPartInput: ISecureUploadPartInput;
  SendForReviewCustodianProfileInput: ISendForReviewCustodianProfileInput;
  SendForReviewCustodianProfilePayload: ResolverTypeWrapper<
    Omit<ISendForReviewCustodianProfilePayload, 'custodianProfile'> & {
      custodianProfile: IResolversTypes['CustodianProfile'];
    }
  >;
  ShareFileIntegration: ResolverTypeWrapper<
    Omit<IShareFileIntegration, 'creator'> & { creator?: Maybe<IResolversTypes['User']> }
  >;
  ShareFileIntegrationInputAttributes: IShareFileIntegrationInputAttributes;
  Signature: ResolverTypeWrapper<Omit<ISignature, 'escrow'> & { escrow: IResolversTypes['Escrow'] }>;
  StartBackupInput: IStartBackupInput;
  StartBackupPayload: ResolverTypeWrapper<Omit<IStartBackupPayload, 'backup'> & { backup: IResolversTypes['Backup'] }>;
  StopBackupInput: IStopBackupInput;
  StopBackupPayload: ResolverTypeWrapper<Omit<IStopBackupPayload, 'backup'> & { backup: IResolversTypes['Backup'] }>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Subscription: ResolverTypeWrapper<{}>;
  SuggestChange: ResolverTypeWrapper<Omit<ISuggestChange, 'escrow'> & { escrow: IResolversTypes['Escrow'] }>;
  SuggestChangePage: ResolverTypeWrapper<
    Omit<ISuggestChangePage, 'nodes'> & { nodes: Array<IResolversTypes['SuggestChange']> }
  >;
  SuggestedChangeChangeTypeEnum: SuggestedChangeChangeTypeEnum;
  SuggestedChangeInput: ISuggestedChangeInput;
  SuggestedChangePolicy: ResolverTypeWrapper<ISuggestedChangePolicy>;
  SuggestedChangeStatusEnum: SuggestedChangeStatusEnum;
  SuggestedChangeVerdictEnum: SuggestedChangeVerdictEnum;
  SwitchToBackupPlanInput: ISwitchToBackupPlanInput;
  SwitchToBackupPlanPayload: ResolverTypeWrapper<ISwitchToBackupPlanPayload>;
  SyncStatusEnum: SyncStatusEnum;
  TerminateCustodianProfileInput: ITerminateCustodianProfileInput;
  TerminateCustodianProfilePayload: ResolverTypeWrapper<
    Omit<ITerminateCustodianProfilePayload, 'custodianTermination'> & {
      custodianTermination?: Maybe<IResolversTypes['CustodianTermination']>;
    }
  >;
  TerminateInput: ITerminateInput;
  TerminatePayload: ResolverTypeWrapper<ITerminatePayload>;
  TerminateSaasEnvironmentInput: ITerminateSaasEnvironmentInput;
  TerminateSaasEnvironmentPayload: ResolverTypeWrapper<
    Omit<ITerminateSaasEnvironmentPayload, 'saasEnvironmentTermination'> & {
      saasEnvironmentTermination?: Maybe<IResolversTypes['SaasEnvironmentTermination']>;
    }
  >;
  ToggleInput: IToggleInput;
  TogglePayload: ResolverTypeWrapper<
    Omit<ITogglePayload, 'integration'> & { integration?: Maybe<IResolversTypes['IntegrationInterface']> }
  >;
  UpdateAgreementInput: IUpdateAgreementInput;
  UpdateAgreementPayload: ResolverTypeWrapper<
    Omit<IUpdateAgreementPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  UpdateBackupInput: IUpdateBackupInput;
  UpdateBackupPayload: ResolverTypeWrapper<
    Omit<IUpdateBackupPayload, 'backup'> & { backup: IResolversTypes['Backup'] }
  >;
  UpdateBeneficiaryInput: IUpdateBeneficiaryInput;
  UpdateBeneficiaryPayload: ResolverTypeWrapper<
    Omit<IUpdateBeneficiaryPayload, 'beneficiary'> & { beneficiary: IResolversTypes['Beneficiary'] }
  >;
  UpdateBillingAddressInput: IUpdateBillingAddressInput;
  UpdateBillingAddressPayload: ResolverTypeWrapper<IUpdateBillingAddressPayload>;
  UpdateCompanyDetailsInput: IUpdateCompanyDetailsInput;
  UpdateCompanyDetailsPayload: ResolverTypeWrapper<
    Omit<IUpdateCompanyDetailsPayload, 'company'> & { company?: Maybe<IResolversTypes['Company']> }
  >;
  UpdateContactInput: IUpdateContactInput;
  UpdateContactPayload: ResolverTypeWrapper<
    Omit<IUpdateContactPayload, 'contact'> & { contact?: Maybe<IResolversTypes['Contact']> }
  >;
  UpdateCustodianAssetsInput: IUpdateCustodianAssetsInput;
  UpdateCustodianAssetsPayload: ResolverTypeWrapper<
    Omit<IUpdateCustodianAssetsPayload, 'custodianAssets'> & {
      custodianAssets?: Maybe<Array<IResolversTypes['CustodianAsset']>>;
    }
  >;
  UpdateCustodianOperationInput: IUpdateCustodianOperationInput;
  UpdateCustodianOperationPayload: ResolverTypeWrapper<
    Omit<IUpdateCustodianOperationPayload, 'custodianOperation'> & {
      custodianOperation: IResolversTypes['CustodianOperation'];
    }
  >;
  UpdateCustomIntegrationInput: IUpdateCustomIntegrationInput;
  UpdateCustomIntegrationPayload: ResolverTypeWrapper<
    Omit<IUpdateCustomIntegrationPayload, 'customIntegration'> & {
      customIntegration?: Maybe<IResolversTypes['CustomIntegration']>;
    }
  >;
  UpdateDepositSettingsInput: IUpdateDepositSettingsInput;
  UpdateDepositSettingsPayload: ResolverTypeWrapper<
    Omit<IUpdateDepositSettingsPayload, 'escrow'> & { escrow?: Maybe<IResolversTypes['Escrow']> }
  >;
  UpdateDepositorInput: IUpdateDepositorInput;
  UpdateDepositorPayload: ResolverTypeWrapper<
    Omit<IUpdateDepositorPayload, 'depositor'> & { depositor: IResolversTypes['Depositor'] }
  >;
  UpdateEscrowInput: IUpdateEscrowInput;
  UpdateEscrowInvitationInput: IUpdateEscrowInvitationInput;
  UpdateEscrowInvitationPayload: ResolverTypeWrapper<
    Omit<IUpdateEscrowInvitationPayload, 'escrowInvitation'> & { escrowInvitation: IResolversTypes['EscrowInvitation'] }
  >;
  UpdateEscrowMemberInput: IUpdateEscrowMemberInput;
  UpdateEscrowMemberPayload: ResolverTypeWrapper<
    Omit<IUpdateEscrowMemberPayload, 'escrowMember'> & { escrowMember?: Maybe<IResolversTypes['EscrowMember']> }
  >;
  UpdateEscrowPayload: ResolverTypeWrapper<
    Omit<IUpdateEscrowPayload, 'escrow'> & { escrow: IResolversTypes['Escrow'] }
  >;
  UpdateProfileInfoInput: IUpdateProfileInfoInput;
  UpdateProfileInfoPayload: ResolverTypeWrapper<IUpdateProfileInfoPayload>;
  UpdateSaasProviderInput: IUpdateSaasProviderInput;
  UpdateSaasProviderPayload: ResolverTypeWrapper<IUpdateSaasProviderPayload>;
  UpdateSuggestedChangeEscrowInput: IUpdateSuggestedChangeEscrowInput;
  UpdateSuggestedChangeEscrowPayload: ResolverTypeWrapper<
    Omit<IUpdateSuggestedChangeEscrowPayload, 'suggestedChange'> & { suggestedChange: IResolversTypes['SuggestChange'] }
  >;
  User: ResolverTypeWrapper<Omit<IUser, 'contacts'> & { contacts?: Maybe<Array<IResolversTypes['Contact']>> }>;
  UserColumnsEnum: UserColumnsEnum;
  UserInput: IUserInput;
  UserPage: ResolverTypeWrapper<Omit<IUserPage, 'nodes'> & { nodes: Array<IResolversTypes['User']> }>;
  UserRoleEnum: UserRoleEnum;
};

/** Mapping between all available schema types and the resolvers parents */
export type IResolversParentTypes = {
  AcceptEscrowInvitationInput: IAcceptEscrowInvitationInput;
  AcceptEscrowInvitationPayload: Omit<IAcceptEscrowInvitationPayload, 'escrowInvitation'> & {
    escrowInvitation: IResolversParentTypes['EscrowInvitation'];
  };
  AcceptExclusiveOfferInput: IAcceptExclusiveOfferInput;
  AcceptExclusiveOfferPayload: Omit<IAcceptExclusiveOfferPayload, 'company'> & {
    company: IResolversParentTypes['Company'];
  };
  AcceptInput: IAcceptInput;
  AcceptPayload: Omit<IAcceptPayload, 'escrowTermination'> & {
    escrowTermination: IResolversParentTypes['EscrowTermination'];
  };
  ActivateEscrowInput: IActivateEscrowInput;
  ActivateEscrowPayload: Omit<IActivateEscrowPayload, 'escrow'> & { escrow?: Maybe<IResolversParentTypes['Escrow']> };
  ActivateSaasEnvironmentInput: IActivateSaasEnvironmentInput;
  ActivateSaasEnvironmentPayload: Omit<IActivateSaasEnvironmentPayload, 'saasEnvironment'> & {
    saasEnvironment?: Maybe<IResolversParentTypes['SaasEnvironment']>;
  };
  Activity: Omit<IActivity, 'payload'> & { payload: IResolversParentTypes['Payload'] };
  ActivityPage: Omit<IActivityPage, 'nodes'> & { nodes: Array<IResolversParentTypes['Activity']> };
  AddInput: IAddInput;
  AddPayload: Omit<IAddPayload, 'users'> & { users?: Maybe<Array<IResolversParentTypes['User']>> };
  Addon: IAddon;
  Agreement: IAgreement;
  AgreementChangeInput: IAgreementChangeInput;
  AgreementEvent: IAgreementEvent;
  AgreementInput: IAgreementInput;
  AgreementPayload: IAgreementPayload;
  AgreementPolicy: IAgreementPolicy;
  ApplyCouponInput: IApplyCouponInput;
  ApplyCouponPayload: Omit<IApplyCouponPayload, 'company'> & { company: IResolversParentTypes['Company'] };
  ApproveAgreementInput: IApproveAgreementInput;
  ApproveAgreementPayload: Omit<IApproveAgreementPayload, 'escrow'> & {
    escrow?: Maybe<IResolversParentTypes['Escrow']>;
  };
  Asset: Omit<IAsset, 'escrow' | 'integration'> & {
    escrow: IResolversParentTypes['Escrow'];
    integration?: Maybe<IResolversParentTypes['IntegrationInterface']>;
  };
  AssetPage: Omit<IAssetPage, 'nodes'> & { nodes: Array<IResolversParentTypes['Asset']> };
  AssetPolicy: IAssetPolicy;
  AssetReport: Omit<IAssetReport, 'asset'> & { asset: IResolversParentTypes['Asset'] };
  AssetReportGroup: Omit<IAssetReportGroup, 'asset' | 'assetReports'> & {
    asset: IResolversParentTypes['Asset'];
    assetReports: Array<IResolversParentTypes['AssetReport']>;
  };
  AssetReportGroupPage: Omit<IAssetReportGroupPage, 'nodes'> & {
    nodes: Array<IResolversParentTypes['AssetReportGroup']>;
  };
  AssignBackupMembersInput: IAssignBackupMembersInput;
  AssignBackupMembersPayload: Omit<IAssignBackupMembersPayload, 'backupMembers'> & {
    backupMembers?: Maybe<Array<IResolversParentTypes['BackupMember']>>;
  };
  Backup: Omit<IBackup, 'backupMembers' | 'creator' | 'integration'> & {
    backupMembers: Array<IResolversParentTypes['BackupMember']>;
    creator?: Maybe<IResolversParentTypes['User']>;
    integration: IResolversParentTypes['IntegrationInterface'];
  };
  BackupAsset: IBackupAsset;
  BackupAssetCreateInput: IBackupAssetCreateInput;
  BackupAssetReport: IBackupAssetReport;
  BackupAssetReportGroup: IBackupAssetReportGroup;
  BackupAssetReportGroupPage: IBackupAssetReportGroupPage;
  BackupCreateInput: IBackupCreateInput;
  BackupMember: Omit<IBackupMember, 'user'> & { user: IResolversParentTypes['User'] };
  BackupPage: Omit<IBackupPage, 'nodes'> & { nodes: Array<IResolversParentTypes['Backup']> };
  BackupPolicy: IBackupPolicy;
  BackupUpdateInput: IBackupUpdateInput;
  BackupVersion: IBackupVersion;
  BackupVersionPage: IBackupVersionPage;
  Beneficiary: Omit<IBeneficiary, 'beneficiaryOptions' | 'company' | 'contacts' | 'escrow'> & {
    beneficiaryOptions?: Maybe<IResolversParentTypes['BeneficiaryOptions']>;
    company?: Maybe<IResolversParentTypes['Company']>;
    contacts: Array<IResolversParentTypes['Contact']>;
    escrow: IResolversParentTypes['Escrow'];
  };
  BeneficiaryChangeInput: IBeneficiaryChangeInput;
  BeneficiaryOptions: IBeneficiaryOptions;
  BeneficiaryPolicy: IBeneficiaryPolicy;
  BigInt: Scalars['BigInt']['output'];
  Billing: IBilling;
  BillingAddress: IBillingAddress;
  BillingAddressInput: IBillingAddressInput;
  Boolean: Scalars['Boolean']['output'];
  CancelSecureUploadInput: ICancelSecureUploadInput;
  CancelSecureUploadPayload: ICancelSecureUploadPayload;
  CancelSubscriptionInput: ICancelSubscriptionInput;
  CancelSubscriptionPayload: Omit<ICancelSubscriptionPayload, 'company'> & {
    company: IResolversParentTypes['Company'];
  };
  ClientChangesRequested: IClientChangesRequested;
  ClientChangesRequestedPayload: IClientChangesRequestedPayload;
  Company: Omit<ICompany, 'assets' | 'users'> & {
    assets: IResolversParentTypes['AssetPage'];
    users: Array<IResolversParentTypes['User']>;
  };
  CompanyInput: ICompanyInput;
  CompleteSecureUploadInput: ICompleteSecureUploadInput;
  CompleteSecureUploadPayload: Omit<ICompleteSecureUploadPayload, 'asset'> & {
    asset?: Maybe<IResolversParentTypes['Asset']>;
  };
  ConfirmEmailChangeInput: IConfirmEmailChangeInput;
  ConfirmEmailChangePayload: IConfirmEmailChangePayload;
  Contact: Omit<IContact, 'escrow' | 'escrowParty'> & {
    escrow: IResolversParentTypes['Escrow'];
    escrowParty: IResolversParentTypes['EscrowPartyInterface'];
  };
  ContactCreateInput: IContactCreateInput;
  ContactUpdateInput: IContactUpdateInput;
  CreateBackupAssetsInput: ICreateBackupAssetsInput;
  CreateBackupAssetsPayload: ICreateBackupAssetsPayload;
  CreateBackupInput: ICreateBackupInput;
  CreateBackupPayload: Omit<ICreateBackupPayload, 'backup'> & { backup?: Maybe<IResolversParentTypes['Backup']> };
  CreateBeneficiaryInput: ICreateBeneficiaryInput;
  CreateBeneficiaryPayload: Omit<ICreateBeneficiaryPayload, 'beneficiary'> & {
    beneficiary?: Maybe<IResolversParentTypes['Beneficiary']>;
  };
  CreateContactInput: ICreateContactInput;
  CreateContactPayload: Omit<ICreateContactPayload, 'contact'> & { contact?: Maybe<IResolversParentTypes['Contact']> };
  CreateCustodianOperationInput: ICreateCustodianOperationInput;
  CreateCustodianOperationPayload: Omit<ICreateCustodianOperationPayload, 'custodianOperation'> & {
    custodianOperation?: Maybe<IResolversParentTypes['CustodianOperation']>;
  };
  CreateCustodianProfileInput: ICreateCustodianProfileInput;
  CreateCustodianProfilePayload: Omit<ICreateCustodianProfilePayload, 'custodianProfile'> & {
    custodianProfile?: Maybe<IResolversParentTypes['CustodianProfile']>;
  };
  CreateCustomAssetsInput: ICreateCustomAssetsInput;
  CreateCustomAssetsPayload: Omit<ICreateCustomAssetsPayload, 'assets'> & {
    assets?: Maybe<Array<IResolversParentTypes['Asset']>>;
  };
  CreateCustomIntegrationInput: ICreateCustomIntegrationInput;
  CreateCustomIntegrationPayload: Omit<ICreateCustomIntegrationPayload, 'customIntegration'> & {
    customIntegration?: Maybe<IResolversParentTypes['CustomIntegration']>;
  };
  CreateEscrowInput: ICreateEscrowInput;
  CreateEscrowMemberInput: ICreateEscrowMemberInput;
  CreateEscrowMemberPayload: Omit<ICreateEscrowMemberPayload, 'escrowMember'> & {
    escrowMember?: Maybe<IResolversParentTypes['EscrowMember']>;
  };
  CreateEscrowPayload: Omit<ICreateEscrowPayload, 'escrow'> & { escrow?: Maybe<IResolversParentTypes['Escrow']> };
  CreateFeedbackInput: ICreateFeedbackInput;
  CreateFeedbackPayload: ICreateFeedbackPayload;
  CreateOauthAssetsInput: ICreateOauthAssetsInput;
  CreateOauthAssetsPayload: Omit<ICreateOauthAssetsPayload, 'assets'> & {
    assets?: Maybe<Array<IResolversParentTypes['Asset']>>;
  };
  CreateSaasEnvironmentInput: ICreateSaasEnvironmentInput;
  CreateSaasEnvironmentPayload: Omit<ICreateSaasEnvironmentPayload, 'saasEnvironment'> & {
    saasEnvironment?: Maybe<IResolversParentTypes['SaasEnvironment']>;
  };
  CreateSaasProviderInput: ICreateSaasProviderInput;
  CreateSaasProviderPayload: ICreateSaasProviderPayload;
  CreateShareFileIntegrationInput: ICreateShareFileIntegrationInput;
  CreateShareFileIntegrationPayload: Omit<ICreateShareFileIntegrationPayload, 'shareFileIntegration'> & {
    shareFileIntegration?: Maybe<IResolversParentTypes['ShareFileIntegration']>;
  };
  CreateSuggestedChangeEscrowInput: ICreateSuggestedChangeEscrowInput;
  CreateSuggestedChangeEscrowPayload: Omit<ICreateSuggestedChangeEscrowPayload, 'suggestedChange'> & {
    suggestedChange?: Maybe<IResolversParentTypes['SuggestChange']>;
  };
  CustodianAsset: Omit<ICustodianAsset, 'custodianIntegration' | 'custodianProfile'> & {
    custodianIntegration: IResolversParentTypes['CustodianIntegration'];
    custodianProfile: IResolversParentTypes['CustodianProfile'];
  };
  CustodianAssetCreateInput: ICustodianAssetCreateInput;
  CustodianIntegration: Omit<ICustodianIntegration, 'custodianAssets' | 'custodianProfile' | 'integration'> & {
    custodianAssets: Array<IResolversParentTypes['CustodianAsset']>;
    custodianProfile: IResolversParentTypes['CustodianProfile'];
    integration: IResolversParentTypes['OauthIntegration'];
  };
  CustodianOperation: Omit<ICustodianOperation, 'custodianProfile'> & {
    custodianProfile: IResolversParentTypes['CustodianProfile'];
  };
  CustodianOperationCreateInput: ICustodianOperationCreateInput;
  CustodianOperationUpdateInput: ICustodianOperationUpdateInput;
  CustodianProfile: Omit<
    ICustodianProfile,
    'creator' | 'custodianAssets' | 'custodianIntegrations' | 'custodianOperations' | 'custodianTermination'
  > & {
    creator: IResolversParentTypes['User'];
    custodianAssets: Array<IResolversParentTypes['CustodianAsset']>;
    custodianIntegrations: Array<IResolversParentTypes['CustodianIntegration']>;
    custodianOperations: Array<IResolversParentTypes['CustodianOperation']>;
    custodianTermination?: Maybe<IResolversParentTypes['CustodianTermination']>;
  };
  CustodianProfilePage: Omit<ICustodianProfilePage, 'nodes'> & {
    nodes: Array<IResolversParentTypes['CustodianProfile']>;
  };
  CustodianProfilePolicy: ICustodianProfilePolicy;
  CustodianTermination: Omit<ICustodianTermination, 'custodianProfile' | 'requesterUser'> & {
    custodianProfile: IResolversParentTypes['CustodianProfile'];
    requesterUser: IResolversParentTypes['User'];
  };
  CustomAssetCreateInput: ICustomAssetCreateInput;
  CustomIntegration: Omit<ICustomIntegration, 'creator'> & { creator?: Maybe<IResolversParentTypes['User']> };
  CustomIntegrationInputAttributes: ICustomIntegrationInputAttributes;
  CustomIntegrationUpdateInputAttributes: ICustomIntegrationUpdateInputAttributes;
  DeleteContactChangeInput: IDeleteContactChangeInput;
  DeletedAssetInput: IDeletedAssetInput;
  DepositChangeInput: IDepositChangeInput;
  DepositSettingsInput: IDepositSettingsInput;
  Depositor: Omit<IDepositor, 'company' | 'contacts' | 'escrow'> & {
    company: IResolversParentTypes['Company'];
    contacts: Array<IResolversParentTypes['Contact']>;
    escrow: IResolversParentTypes['Escrow'];
  };
  DepositorChangeInput: IDepositorChangeInput;
  DepositorPolicy: IDepositorPolicy;
  DestroyInput: IDestroyInput;
  DestroyPayload: Omit<IDestroyPayload, 'escrow'> & { escrow: IResolversParentTypes['Escrow'] };
  DisableBackupAssetInput: IDisableBackupAssetInput;
  DisableBackupAssetPayload: Omit<IDisableBackupAssetPayload, 'backup'> & { backup: IResolversParentTypes['Backup'] };
  EditContactChangeInput: IEditContactChangeInput;
  EditInput: IEditInput;
  EditPayload: Omit<IEditPayload, 'user'> & { user?: Maybe<IResolversParentTypes['User']> };
  EditUserInput: IEditUserInput;
  EnableBackupAssetInput: IEnableBackupAssetInput;
  EnableBackupAssetPayload: IEnableBackupAssetPayload;
  EnableBackupReportsInput: IEnableBackupReportsInput;
  EnableBackupReportsPayload: Omit<IEnableBackupReportsPayload, 'backup'> & { backup: IResolversParentTypes['Backup'] };
  EnableEscrowReportsInput: IEnableEscrowReportsInput;
  EnableEscrowReportsPayload: Omit<IEnableEscrowReportsPayload, 'escrow'> & {
    escrow?: Maybe<IResolversParentTypes['Escrow']>;
  };
  Escrow: Omit<
    IEscrow,
    'beneficiary' | 'depositor' | 'escrowInvitation' | 'escrowTermination' | 'events' | 'signatures'
  > & {
    beneficiary?: Maybe<IResolversParentTypes['Beneficiary']>;
    depositor?: Maybe<IResolversParentTypes['Depositor']>;
    escrowInvitation?: Maybe<IResolversParentTypes['EscrowInvitation']>;
    escrowTermination?: Maybe<IResolversParentTypes['EscrowTermination']>;
    events: Array<IResolversParentTypes['EscrowEvent']>;
    signatures: Array<IResolversParentTypes['Signature']>;
  };
  EscrowEvent: Omit<IEscrowEvent, 'payload'> & { payload: IResolversParentTypes['Escrow'] };
  EscrowInput: IEscrowInput;
  EscrowInvitation: Omit<IEscrowInvitation, 'escrow' | 'user'> & {
    escrow: IResolversParentTypes['Escrow'];
    user?: Maybe<IResolversParentTypes['User']>;
  };
  EscrowInvitationPage: Omit<IEscrowInvitationPage, 'nodes'> & {
    nodes: Array<IResolversParentTypes['EscrowInvitation']>;
  };
  EscrowInvitationPolicy: IEscrowInvitationPolicy;
  EscrowMarkAsViewedInput: IEscrowMarkAsViewedInput;
  EscrowMarkAsViewedPayload: IEscrowMarkAsViewedPayload;
  EscrowMember: Omit<IEscrowMember, 'escrowParty' | 'user'> & {
    escrowParty: IResolversParentTypes['EscrowPartyInterface'];
    user: IResolversParentTypes['User'];
  };
  EscrowMemberInput: IEscrowMemberInput;
  EscrowMemberPage: Omit<IEscrowMemberPage, 'nodes'> & { nodes: Array<IResolversParentTypes['EscrowMember']> };
  EscrowMemberPolicy: IEscrowMemberPolicy;
  EscrowPage: Omit<IEscrowPage, 'nodes'> & { nodes: Array<IResolversParentTypes['Escrow']> };
  EscrowPartyInput: IEscrowPartyInput;
  EscrowPartyInterface: IResolversInterfaceTypes<IResolversParentTypes>['EscrowPartyInterface'];
  EscrowPayload: Omit<
    IEscrowPayload,
    'beneficiary' | 'depositor' | 'escrowInvitation' | 'escrowTermination' | 'events' | 'signatures'
  > & {
    beneficiary?: Maybe<IResolversParentTypes['Beneficiary']>;
    depositor?: Maybe<IResolversParentTypes['Depositor']>;
    escrowInvitation?: Maybe<IResolversParentTypes['EscrowInvitation']>;
    escrowTermination?: Maybe<IResolversParentTypes['EscrowTermination']>;
    events: Array<IResolversParentTypes['EscrowEvent']>;
    signatures: Array<IResolversParentTypes['Signature']>;
  };
  EscrowPolicy: IEscrowPolicy;
  EscrowTermination: Omit<IEscrowTermination, 'escrow' | 'requesterUser'> & {
    escrow: IResolversParentTypes['Escrow'];
    requesterUser?: Maybe<IResolversParentTypes['User']>;
  };
  EscrowTerminationPolicy: IEscrowTerminationPolicy;
  EventInterface: IResolversInterfaceTypes<IResolversParentTypes>['EventInterface'];
  ExclusiveOfferPlan: IExclusiveOfferPlan;
  FinishAuthInput: IFinishAuthInput;
  FinishAuthPayload: Omit<IFinishAuthPayload, 'oauthIntegration'> & {
    oauthIntegration?: Maybe<IResolversParentTypes['OauthIntegration']>;
  };
  Float: Scalars['Float']['output'];
  GenerateAgreementInput: IGenerateAgreementInput;
  GenerateAgreementPayload: Omit<IGenerateAgreementPayload, 'escrow'> & {
    escrow?: Maybe<IResolversParentTypes['Escrow']>;
  };
  ID: Scalars['ID']['output'];
  ISO8601Date: Scalars['ISO8601Date']['output'];
  ISO8601DateTime: Scalars['ISO8601DateTime']['output'];
  ImpersonateInput: IImpersonateInput;
  ImpersonatePayload: IImpersonatePayload;
  InitAuthInput: IInitAuthInput;
  InitAuthPayload: IInitAuthPayload;
  InitSecureUploadInput: IInitSecureUploadInput;
  InitSecureUploadPayload: IInitSecureUploadPayload;
  Int: Scalars['Int']['output'];
  IntegrationAuth: IIntegrationAuth;
  IntegrationAuthClientPayloadInput: IIntegrationAuthClientPayloadInput;
  IntegrationAuthOptionsInput: IIntegrationAuthOptionsInput;
  IntegrationInterface: IResolversInterfaceTypes<IResolversParentTypes>['IntegrationInterface'];
  IntegrationInterfacePage: Omit<IIntegrationInterfacePage, 'nodes'> & {
    nodes: Array<IResolversParentTypes['IntegrationInterface']>;
  };
  InviteOwnerBeneficiaryInput: IInviteOwnerBeneficiaryInput;
  InviteOwnerBeneficiaryPayload: Omit<IInviteOwnerBeneficiaryPayload, 'escrowInvitation'> & {
    escrowInvitation: IResolversParentTypes['EscrowInvitation'];
  };
  InviteOwnerDepositorInput: IInviteOwnerDepositorInput;
  InviteOwnerDepositorPayload: Omit<IInviteOwnerDepositorPayload, 'escrowInvitation'> & {
    escrowInvitation: IResolversParentTypes['EscrowInvitation'];
  };
  Invoice: IInvoice;
  InvoicePage: IInvoicePage;
  InvoicePdf: IInvoicePdf;
  JSON: Scalars['JSON']['output'];
  LegacyRepo: Omit<ILegacyRepo, 'escrow'> & { escrow: IResolversParentTypes['Escrow'] };
  LegacyRepoPage: Omit<ILegacyRepoPage, 'nodes'> & { nodes: Array<IResolversParentTypes['LegacyRepo']> };
  LegalStatus: ILegalStatus;
  MarkAsViewedInput: IMarkAsViewedInput;
  MarkAsViewedPayload: IMarkAsViewedPayload;
  MigrateLegacyRepoInput: IMigrateLegacyRepoInput;
  MigrateLegacyRepoPayload: IMigrateLegacyRepoPayload;
  MigrateLegacyReposInput: IMigrateLegacyReposInput;
  MigrateLegacyReposPayload: Omit<IMigrateLegacyReposPayload, 'migratedLegacyRepos'> & {
    migratedLegacyRepos: Array<IResolversParentTypes['LegacyRepo']>;
  };
  Mutation: {};
  NotificationChangeInput: INotificationChangeInput;
  OauthAssetCreateInput: IOauthAssetCreateInput;
  OauthIntegration: Omit<IOauthIntegration, 'creator'> & { creator?: Maybe<IResolversParentTypes['User']> };
  OauthReposConn: IOauthReposConn;
  OauthRepository: IOauthRepository;
  Payload: IResolversUnionTypes<IResolversParentTypes>['Payload'];
  PaymentMethod: IPaymentMethod;
  Plan: IPlan;
  Profile: IProfile;
  Query: {};
  RejectEscrowInvitationInput: IRejectEscrowInvitationInput;
  RejectEscrowInvitationPayload: Omit<IRejectEscrowInvitationPayload, 'escrowInvitation'> & {
    escrowInvitation: IResolversParentTypes['EscrowInvitation'];
  };
  RejectInput: IRejectInput;
  RejectPayload: Omit<IRejectPayload, 'escrowTermination'> & {
    escrowTermination: IResolversParentTypes['EscrowTermination'];
  };
  RemoveBackupAssetInput: IRemoveBackupAssetInput;
  RemoveBackupAssetPayload: Omit<IRemoveBackupAssetPayload, 'backup'> & { backup: IResolversParentTypes['Backup'] };
  RemoveBackupInput: IRemoveBackupInput;
  RemoveBackupPayload: Omit<IRemoveBackupPayload, 'backup'> & { backup: IResolversParentTypes['Backup'] };
  RemoveContactInput: IRemoveContactInput;
  RemoveContactPayload: Omit<IRemoveContactPayload, 'escrow'> & { escrow?: Maybe<IResolversParentTypes['Escrow']> };
  RemoveCustodianOperationInput: IRemoveCustodianOperationInput;
  RemoveCustodianOperationPayload: IRemoveCustodianOperationPayload;
  RemoveCustodianProfileInput: IRemoveCustodianProfileInput;
  RemoveCustodianProfilePayload: IRemoveCustodianProfilePayload;
  RemoveEscrowInput: IRemoveEscrowInput;
  RemoveEscrowMemberInput: IRemoveEscrowMemberInput;
  RemoveEscrowMemberPayload: IRemoveEscrowMemberPayload;
  RemoveEscrowPayload: IRemoveEscrowPayload;
  RemoveInput: IRemoveInput;
  RemovePayload: IRemovePayload;
  RemoveSaasEnvironmentInput: IRemoveSaasEnvironmentInput;
  RemoveSaasEnvironmentPayload: Omit<IRemoveSaasEnvironmentPayload, 'saasEnvironment'> & {
    saasEnvironment?: Maybe<IResolversParentTypes['SaasEnvironment']>;
  };
  RemoveSaasProviderInput: IRemoveSaasProviderInput;
  RemoveSaasProviderPayload: IRemoveSaasProviderPayload;
  ReportData: IReportData;
  RequestBackupVersionDownloadInput: IRequestBackupVersionDownloadInput;
  RequestBackupVersionDownloadPayload: IRequestBackupVersionDownloadPayload;
  RequestUpdatePaymentPageInput: IRequestUpdatePaymentPageInput;
  RequestUpdatePaymentPagePayload: IRequestUpdatePaymentPagePayload;
  ReviewSuggestedChangeEscrowInput: IReviewSuggestedChangeEscrowInput;
  ReviewSuggestedChangeEscrowPayload: Omit<IReviewSuggestedChangeEscrowPayload, 'suggestedChange'> & {
    suggestedChange: IResolversParentTypes['SuggestChange'];
  };
  SaasEnvironment: Omit<ISaasEnvironment, 'creator' | 'saasEnvironmentTermination'> & {
    creator: IResolversParentTypes['User'];
    saasEnvironmentTermination?: Maybe<IResolversParentTypes['SaasEnvironmentTermination']>;
  };
  SaasEnvironmentPage: Omit<ISaasEnvironmentPage, 'nodes'> & { nodes: Array<IResolversParentTypes['SaasEnvironment']> };
  SaasEnvironmentPolicy: ISaasEnvironmentPolicy;
  SaasEnvironmentTermination: Omit<ISaasEnvironmentTermination, 'requesterUser' | 'saasEnvironment'> & {
    requesterUser: IResolversParentTypes['User'];
    saasEnvironment: IResolversParentTypes['SaasEnvironment'];
  };
  SaasProvider: ISaasProvider;
  SaasProviderCreateInput: ISaasProviderCreateInput;
  SaasProviderUpdateInput: ISaasProviderUpdateInput;
  SecureUploadPartInput: ISecureUploadPartInput;
  SendForReviewCustodianProfileInput: ISendForReviewCustodianProfileInput;
  SendForReviewCustodianProfilePayload: Omit<ISendForReviewCustodianProfilePayload, 'custodianProfile'> & {
    custodianProfile: IResolversParentTypes['CustodianProfile'];
  };
  ShareFileIntegration: Omit<IShareFileIntegration, 'creator'> & { creator?: Maybe<IResolversParentTypes['User']> };
  ShareFileIntegrationInputAttributes: IShareFileIntegrationInputAttributes;
  Signature: Omit<ISignature, 'escrow'> & { escrow: IResolversParentTypes['Escrow'] };
  StartBackupInput: IStartBackupInput;
  StartBackupPayload: Omit<IStartBackupPayload, 'backup'> & { backup: IResolversParentTypes['Backup'] };
  StopBackupInput: IStopBackupInput;
  StopBackupPayload: Omit<IStopBackupPayload, 'backup'> & { backup: IResolversParentTypes['Backup'] };
  String: Scalars['String']['output'];
  Subscription: {};
  SuggestChange: Omit<ISuggestChange, 'escrow'> & { escrow: IResolversParentTypes['Escrow'] };
  SuggestChangePage: Omit<ISuggestChangePage, 'nodes'> & { nodes: Array<IResolversParentTypes['SuggestChange']> };
  SuggestedChangeInput: ISuggestedChangeInput;
  SuggestedChangePolicy: ISuggestedChangePolicy;
  SwitchToBackupPlanInput: ISwitchToBackupPlanInput;
  SwitchToBackupPlanPayload: ISwitchToBackupPlanPayload;
  TerminateCustodianProfileInput: ITerminateCustodianProfileInput;
  TerminateCustodianProfilePayload: Omit<ITerminateCustodianProfilePayload, 'custodianTermination'> & {
    custodianTermination?: Maybe<IResolversParentTypes['CustodianTermination']>;
  };
  TerminateInput: ITerminateInput;
  TerminatePayload: ITerminatePayload;
  TerminateSaasEnvironmentInput: ITerminateSaasEnvironmentInput;
  TerminateSaasEnvironmentPayload: Omit<ITerminateSaasEnvironmentPayload, 'saasEnvironmentTermination'> & {
    saasEnvironmentTermination?: Maybe<IResolversParentTypes['SaasEnvironmentTermination']>;
  };
  ToggleInput: IToggleInput;
  TogglePayload: Omit<ITogglePayload, 'integration'> & {
    integration?: Maybe<IResolversParentTypes['IntegrationInterface']>;
  };
  UpdateAgreementInput: IUpdateAgreementInput;
  UpdateAgreementPayload: Omit<IUpdateAgreementPayload, 'escrow'> & { escrow?: Maybe<IResolversParentTypes['Escrow']> };
  UpdateBackupInput: IUpdateBackupInput;
  UpdateBackupPayload: Omit<IUpdateBackupPayload, 'backup'> & { backup: IResolversParentTypes['Backup'] };
  UpdateBeneficiaryInput: IUpdateBeneficiaryInput;
  UpdateBeneficiaryPayload: Omit<IUpdateBeneficiaryPayload, 'beneficiary'> & {
    beneficiary: IResolversParentTypes['Beneficiary'];
  };
  UpdateBillingAddressInput: IUpdateBillingAddressInput;
  UpdateBillingAddressPayload: IUpdateBillingAddressPayload;
  UpdateCompanyDetailsInput: IUpdateCompanyDetailsInput;
  UpdateCompanyDetailsPayload: Omit<IUpdateCompanyDetailsPayload, 'company'> & {
    company?: Maybe<IResolversParentTypes['Company']>;
  };
  UpdateContactInput: IUpdateContactInput;
  UpdateContactPayload: Omit<IUpdateContactPayload, 'contact'> & { contact?: Maybe<IResolversParentTypes['Contact']> };
  UpdateCustodianAssetsInput: IUpdateCustodianAssetsInput;
  UpdateCustodianAssetsPayload: Omit<IUpdateCustodianAssetsPayload, 'custodianAssets'> & {
    custodianAssets?: Maybe<Array<IResolversParentTypes['CustodianAsset']>>;
  };
  UpdateCustodianOperationInput: IUpdateCustodianOperationInput;
  UpdateCustodianOperationPayload: Omit<IUpdateCustodianOperationPayload, 'custodianOperation'> & {
    custodianOperation: IResolversParentTypes['CustodianOperation'];
  };
  UpdateCustomIntegrationInput: IUpdateCustomIntegrationInput;
  UpdateCustomIntegrationPayload: Omit<IUpdateCustomIntegrationPayload, 'customIntegration'> & {
    customIntegration?: Maybe<IResolversParentTypes['CustomIntegration']>;
  };
  UpdateDepositSettingsInput: IUpdateDepositSettingsInput;
  UpdateDepositSettingsPayload: Omit<IUpdateDepositSettingsPayload, 'escrow'> & {
    escrow?: Maybe<IResolversParentTypes['Escrow']>;
  };
  UpdateDepositorInput: IUpdateDepositorInput;
  UpdateDepositorPayload: Omit<IUpdateDepositorPayload, 'depositor'> & {
    depositor: IResolversParentTypes['Depositor'];
  };
  UpdateEscrowInput: IUpdateEscrowInput;
  UpdateEscrowInvitationInput: IUpdateEscrowInvitationInput;
  UpdateEscrowInvitationPayload: Omit<IUpdateEscrowInvitationPayload, 'escrowInvitation'> & {
    escrowInvitation: IResolversParentTypes['EscrowInvitation'];
  };
  UpdateEscrowMemberInput: IUpdateEscrowMemberInput;
  UpdateEscrowMemberPayload: Omit<IUpdateEscrowMemberPayload, 'escrowMember'> & {
    escrowMember?: Maybe<IResolversParentTypes['EscrowMember']>;
  };
  UpdateEscrowPayload: Omit<IUpdateEscrowPayload, 'escrow'> & { escrow: IResolversParentTypes['Escrow'] };
  UpdateProfileInfoInput: IUpdateProfileInfoInput;
  UpdateProfileInfoPayload: IUpdateProfileInfoPayload;
  UpdateSaasProviderInput: IUpdateSaasProviderInput;
  UpdateSaasProviderPayload: IUpdateSaasProviderPayload;
  UpdateSuggestedChangeEscrowInput: IUpdateSuggestedChangeEscrowInput;
  UpdateSuggestedChangeEscrowPayload: Omit<IUpdateSuggestedChangeEscrowPayload, 'suggestedChange'> & {
    suggestedChange: IResolversParentTypes['SuggestChange'];
  };
  User: Omit<IUser, 'contacts'> & { contacts?: Maybe<Array<IResolversParentTypes['Contact']>> };
  UserInput: IUserInput;
  UserPage: Omit<IUserPage, 'nodes'> & { nodes: Array<IResolversParentTypes['User']> };
};

export type IClientDirectiveArgs = {};

export type IClientDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = IClientDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IAcceptEscrowInvitationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['AcceptEscrowInvitationPayload'] = IResolversParentTypes['AcceptEscrowInvitationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowInvitation?: Resolver<IResolversTypes['EscrowInvitation'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAcceptExclusiveOfferPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['AcceptExclusiveOfferPayload'] = IResolversParentTypes['AcceptExclusiveOfferPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<IResolversTypes['Company'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAcceptPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AcceptPayload'] = IResolversParentTypes['AcceptPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowTermination?: Resolver<IResolversTypes['EscrowTermination'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IActivateEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ActivateEscrowPayload'] = IResolversParentTypes['ActivateEscrowPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IActivateSaasEnvironmentPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['ActivateSaasEnvironmentPayload'] = IResolversParentTypes['ActivateSaasEnvironmentPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  saasEnvironment?: Resolver<Maybe<IResolversTypes['SaasEnvironment']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IActivityResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Activity'] = IResolversParentTypes['Activity'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  eventType?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  klass?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  payload?: Resolver<IResolversTypes['Payload'], ParentType, ContextType>;
  viewedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IActivityPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ActivityPage'] = IResolversParentTypes['ActivityPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['Activity']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAddPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AddPayload'] = IResolversParentTypes['AddPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  users?: Resolver<Maybe<Array<IResolversTypes['User']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAddonResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Addon'] = IResolversParentTypes['Addon'],
> = {
  currencyCode?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  period?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  periodUnit?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  unitPrice?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAgreementResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Agreement'] = IResolversParentTypes['Agreement'],
> = {
  agreementForm?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  approved?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  approvedByBeneficiary?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  approvedByCodekeeper?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  approvedByDepositor?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  depositType?: Resolver<Array<IResolversTypes['DepositTypeEnum']>, ParentType, ContextType>;
  depositTypeOther?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  draft?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  events?: Resolver<Array<IResolversTypes['AgreementEvent']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  jurisdiction?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  legalContext?: Resolver<Array<IResolversTypes['LegalContextEnum']>, ParentType, ContextType>;
  legalContextOther?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  liability?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['AgreementPolicy'], ParentType, ContextType>;
  releaseBankruptcy?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  releaseCustom?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  releaseCustomConditions?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  releaseInsolvency?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  releaseMaintenance?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  terms?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  termsDownloadDocxUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  termsDownloadPdfUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  termsSignedDownloadPdfUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  thirdPartyAgreement?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAgreementEventResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AgreementEvent'] = IResolversParentTypes['AgreementEvent'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  eventType?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  payload?: Resolver<IResolversTypes['Agreement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAgreementPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AgreementPayload'] = IResolversParentTypes['AgreementPayload'],
> = {
  agreementForm?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  approved?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  approvedByBeneficiary?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  approvedByCodekeeper?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  approvedByDepositor?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  depositType?: Resolver<Array<IResolversTypes['DepositTypeEnum']>, ParentType, ContextType>;
  depositTypeOther?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  draft?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  events?: Resolver<Array<IResolversTypes['AgreementEvent']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  jurisdiction?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  legalContext?: Resolver<Array<IResolversTypes['LegalContextEnum']>, ParentType, ContextType>;
  legalContextOther?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  liability?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['AgreementPolicy'], ParentType, ContextType>;
  releaseBankruptcy?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  releaseCustom?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  releaseCustomConditions?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  releaseInsolvency?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  releaseMaintenance?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  terms?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  termsDownloadDocxUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  termsDownloadPdfUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  termsSignedDownloadPdfUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  thirdPartyAgreement?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAgreementPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AgreementPolicy'] = IResolversParentTypes['AgreementPolicy'],
> = {
  approve?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  generate?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  write?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IApplyCouponPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ApplyCouponPayload'] = IResolversParentTypes['ApplyCouponPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<IResolversTypes['Company'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IApproveAgreementPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['ApproveAgreementPayload'] = IResolversParentTypes['ApproveAgreementPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssetResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Asset'] = IResolversParentTypes['Asset'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  depositMethod?: Resolver<IResolversTypes['DepositMethodEnum'], ParentType, ContextType>;
  depositSource?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  depositStatus?: Resolver<IResolversTypes['DepositStatusEnum'], ParentType, ContextType>;
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  externalId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  integration?: Resolver<Maybe<IResolversTypes['IntegrationInterface']>, ParentType, ContextType>;
  lastUpdateCheck?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  latestDeposit?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['AssetPolicy'], ParentType, ContextType>;
  removalStatus?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<IResolversTypes['BigInt']>, ParentType, ContextType>;
  syncStatus?: Resolver<Maybe<IResolversTypes['SyncStatusEnum']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['AssetTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssetPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AssetPage'] = IResolversParentTypes['AssetPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['Asset']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssetPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AssetPolicy'] = IResolversParentTypes['AssetPolicy'],
> = {
  remove?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssetReportResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AssetReport'] = IResolversParentTypes['AssetReport'],
> = {
  asset?: Resolver<IResolversTypes['Asset'], ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  data?: Resolver<IResolversTypes['ReportData'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  period?: Resolver<IResolversTypes['ISO8601Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssetReportGroupResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AssetReportGroup'] = IResolversParentTypes['AssetReportGroup'],
> = {
  asset?: Resolver<IResolversTypes['Asset'], ParentType, ContextType>;
  assetReports?: Resolver<Array<IResolversTypes['AssetReport']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  lastGenerated?: Resolver<IResolversTypes['ISO8601Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssetReportGroupPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['AssetReportGroupPage'] = IResolversParentTypes['AssetReportGroupPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['AssetReportGroup']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssignBackupMembersPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['AssignBackupMembersPayload'] = IResolversParentTypes['AssignBackupMembersPayload'],
> = {
  backupMembers?: Resolver<Maybe<Array<IResolversTypes['BackupMember']>>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Backup'] = IResolversParentTypes['Backup'],
> = {
  backupAssets?: Resolver<Array<IResolversTypes['BackupAsset']>, ParentType, ContextType>;
  backupMembers?: Resolver<Array<IResolversTypes['BackupMember']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  creator?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  disruptionNotification?: Resolver<IResolversTypes['DisruptionNotificationEnum'], ParentType, ContextType>;
  disruptionNotificationGracePeriod?: Resolver<
    Maybe<IResolversTypes['DisruptionNotificationGracePeriodEnum']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  integration?: Resolver<IResolversTypes['IntegrationInterface'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['BackupPolicy'], ParentType, ContextType>;
  reportsEnabled?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  reportsEnabledAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['BackupStatusEnum'], ParentType, ContextType>;
  stoppedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  storageRegion?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  versionsLimit?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupAssetResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupAsset'] = IResolversParentTypes['BackupAsset'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  lastBackup?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  lastBackupVersionId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  lastUpdateCheck?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<Maybe<IResolversTypes['BigInt']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['BackupAssetStatusEnum'], ParentType, ContextType>;
  type?: Resolver<IResolversTypes['BackupAssetTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupAssetReportResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupAssetReport'] = IResolversParentTypes['BackupAssetReport'],
> = {
  backupAsset?: Resolver<IResolversTypes['BackupAsset'], ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  data?: Resolver<IResolversTypes['ReportData'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  period?: Resolver<IResolversTypes['ISO8601Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupAssetReportGroupResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupAssetReportGroup'] = IResolversParentTypes['BackupAssetReportGroup'],
> = {
  backupAsset?: Resolver<IResolversTypes['BackupAsset'], ParentType, ContextType>;
  backupAssetReports?: Resolver<Array<IResolversTypes['BackupAssetReport']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  lastGenerated?: Resolver<IResolversTypes['ISO8601Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupAssetReportGroupPageResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['BackupAssetReportGroupPage'] = IResolversParentTypes['BackupAssetReportGroupPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['BackupAssetReportGroup']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupMemberResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupMember'] = IResolversParentTypes['BackupMember'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<IResolversTypes['BackupMemberRoleEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  user?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupPage'] = IResolversParentTypes['BackupPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['Backup']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupPolicy'] = IResolversParentTypes['BackupPolicy'],
> = {
  assignMembers?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  destroy?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  download?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  enableReports?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  start?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  stop?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupVersionResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupVersion'] = IResolversParentTypes['BackupVersion'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['BackupVersionStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBackupVersionPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BackupVersionPage'] = IResolversParentTypes['BackupVersionPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['BackupVersion']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBeneficiaryResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Beneficiary'] = IResolversParentTypes['Beneficiary'],
> = {
  beneficiaryOptions?: Resolver<Maybe<IResolversTypes['BeneficiaryOptions']>, ParentType, ContextType>;
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<IResolversTypes['Company']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyRegistrationNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyWebsite?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  contacts?: Resolver<Array<IResolversTypes['Contact']>, ParentType, ContextType>;
  country?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  isStatic?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['BeneficiaryPolicy'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBeneficiaryOptionsResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BeneficiaryOptions'] = IResolversParentTypes['BeneficiaryOptions'],
> = {
  confirmationMessage?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  escrowInformation?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  escrowReview?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  escrowSignatory?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBeneficiaryPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BeneficiaryPolicy'] = IResolversParentTypes['BeneficiaryPolicy'],
> = {
  createEscrowMembers?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  readEscrowMembers?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IBigIntScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type IBillingResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Billing'] = IResolversParentTypes['Billing'],
> = {
  billingAddress?: Resolver<Maybe<IResolversTypes['BillingAddress']>, ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<IResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  subscriptions?: Resolver<Maybe<Array<IResolversTypes['Subscription']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBillingAddressResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['BillingAddress'] = IResolversParentTypes['BillingAddress'],
> = {
  addressLine1?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  addressLine2?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  addressLine3?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyRegistrationNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  zip?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICancelSecureUploadPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CancelSecureUploadPayload'] = IResolversParentTypes['CancelSecureUploadPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICancelSubscriptionPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CancelSubscriptionPayload'] = IResolversParentTypes['CancelSubscriptionPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<IResolversTypes['Company'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IClientChangesRequestedResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ClientChangesRequested'] = IResolversParentTypes['ClientChangesRequested'],
> = {
  creator?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  payload?: Resolver<Maybe<IResolversTypes['JSON']>, ParentType, ContextType>;
  receiver?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IClientChangesRequestedPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['ClientChangesRequestedPayload'] = IResolversParentTypes['ClientChangesRequestedPayload'],
> = {
  agreement?: Resolver<Maybe<IResolversTypes['ClientChangesRequested']>, ParentType, ContextType>;
  beneficiary?: Resolver<Maybe<IResolversTypes['ClientChangesRequested']>, ParentType, ContextType>;
  depositor?: Resolver<Maybe<IResolversTypes['ClientChangesRequested']>, ParentType, ContextType>;
  deposits?: Resolver<Maybe<IResolversTypes['ClientChangesRequested']>, ParentType, ContextType>;
  isRequested?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  notifications?: Resolver<Maybe<IResolversTypes['ClientChangesRequested']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICompanyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Company'] = IResolversParentTypes['Company'],
> = {
  assets?: Resolver<
    IResolversTypes['AssetPage'],
    ParentType,
    ContextType,
    RequireFields<ICompanyAssetsArgs, 'order' | 'orderBy'>
  >;
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyRegistration?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyWebsite?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  invitationStatus?: Resolver<IResolversTypes['CompanyInvitationStatusEnum'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  subscriptionCancellationRequestedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  subscriptionExclusiveCouponAppliedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  updateableBilling?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  users?: Resolver<Array<IResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICompleteSecureUploadPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CompleteSecureUploadPayload'] = IResolversParentTypes['CompleteSecureUploadPayload'],
> = {
  asset?: Resolver<Maybe<IResolversTypes['Asset']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IConfirmEmailChangePayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['ConfirmEmailChangePayload'] = IResolversParentTypes['ConfirmEmailChangePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IContactResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Contact'] = IResolversParentTypes['Contact'],
> = {
  contactType?: Resolver<IResolversTypes['ContactTypeEnum'], ParentType, ContextType>;
  email?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  escrowParty?: Resolver<IResolversTypes['EscrowPartyInterface'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  signatory?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateBackupAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateBackupAssetsPayload'] = IResolversParentTypes['CreateBackupAssetsPayload'],
> = {
  backupAssets?: Resolver<Maybe<Array<IResolversTypes['BackupAsset']>>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateBackupPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CreateBackupPayload'] = IResolversParentTypes['CreateBackupPayload'],
> = {
  backup?: Resolver<Maybe<IResolversTypes['Backup']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateBeneficiaryPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateBeneficiaryPayload'] = IResolversParentTypes['CreateBeneficiaryPayload'],
> = {
  beneficiary?: Resolver<Maybe<IResolversTypes['Beneficiary']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateContactPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CreateContactPayload'] = IResolversParentTypes['CreateContactPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<IResolversTypes['Contact']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateCustodianOperationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateCustodianOperationPayload'] = IResolversParentTypes['CreateCustodianOperationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  custodianOperation?: Resolver<Maybe<IResolversTypes['CustodianOperation']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateCustodianProfilePayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateCustodianProfilePayload'] = IResolversParentTypes['CreateCustodianProfilePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  custodianProfile?: Resolver<Maybe<IResolversTypes['CustodianProfile']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateCustomAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateCustomAssetsPayload'] = IResolversParentTypes['CreateCustomAssetsPayload'],
> = {
  assets?: Resolver<Maybe<Array<IResolversTypes['Asset']>>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateCustomIntegrationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateCustomIntegrationPayload'] = IResolversParentTypes['CreateCustomIntegrationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  customIntegration?: Resolver<Maybe<IResolversTypes['CustomIntegration']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateEscrowMemberPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateEscrowMemberPayload'] = IResolversParentTypes['CreateEscrowMemberPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowMember?: Resolver<Maybe<IResolversTypes['EscrowMember']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CreateEscrowPayload'] = IResolversParentTypes['CreateEscrowPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateFeedbackPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CreateFeedbackPayload'] = IResolversParentTypes['CreateFeedbackPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateOauthAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateOauthAssetsPayload'] = IResolversParentTypes['CreateOauthAssetsPayload'],
> = {
  assets?: Resolver<Maybe<Array<IResolversTypes['Asset']>>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateSaasEnvironmentPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateSaasEnvironmentPayload'] = IResolversParentTypes['CreateSaasEnvironmentPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  saasEnvironment?: Resolver<Maybe<IResolversTypes['SaasEnvironment']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateSaasProviderPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateSaasProviderPayload'] = IResolversParentTypes['CreateSaasProviderPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  saasProvider?: Resolver<Maybe<IResolversTypes['SaasProvider']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateShareFileIntegrationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateShareFileIntegrationPayload'] = IResolversParentTypes['CreateShareFileIntegrationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  shareFileIntegration?: Resolver<Maybe<IResolversTypes['ShareFileIntegration']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICreateSuggestedChangeEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['CreateSuggestedChangeEscrowPayload'] = IResolversParentTypes['CreateSuggestedChangeEscrowPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  suggestedChange?: Resolver<Maybe<IResolversTypes['SuggestChange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustodianAssetResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustodianAsset'] = IResolversParentTypes['CustodianAsset'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  custodianIntegration?: Resolver<IResolversTypes['CustodianIntegration'], ParentType, ContextType>;
  custodianProfile?: Resolver<IResolversTypes['CustodianProfile'], ParentType, ContextType>;
  custodianStatus?: Resolver<IResolversTypes['CustodianAssetCustodianStatusEnum'], ParentType, ContextType>;
  externalId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  operationalStatus?: Resolver<IResolversTypes['CustodianAssetOperationalStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustodianIntegrationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustodianIntegration'] = IResolversParentTypes['CustodianIntegration'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  custodianAssets?: Resolver<Array<IResolversTypes['CustodianAsset']>, ParentType, ContextType>;
  custodianProfile?: Resolver<IResolversTypes['CustodianProfile'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  integration?: Resolver<IResolversTypes['OauthIntegration'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['CustodianIntegrationStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustodianOperationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustodianOperation'] = IResolversParentTypes['CustodianOperation'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  custodianProfile?: Resolver<IResolversTypes['CustodianProfile'], ParentType, ContextType>;
  custodianServiceEvents?: Resolver<
    Array<IResolversTypes['CustodianOperationCustodianServiceEventEnum']>,
    ParentType,
    ContextType
  >;
  custodianServiceEventsCustom?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  dataRetention?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  endOfRetentionPeriod?: Resolver<
    IResolversTypes['CustodianOperationEndOfRetentionPeriodEnum'],
    ParentType,
    ContextType
  >;
  endOfRetentionPeriodCustom?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  operationType?: Resolver<IResolversTypes['CustodianOperationTypeEnum'], ParentType, ContextType>;
  operationTypeCustom?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  regulatoryRequirements?: Resolver<
    Array<IResolversTypes['CustodianOperationRegulatoryRequirementsEnum']>,
    ParentType,
    ContextType
  >;
  regulatoryRequirementsCustom?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustodianProfileResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustodianProfile'] = IResolversParentTypes['CustodianProfile'],
> = {
  activatedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  creator?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
  custodianAssets?: Resolver<Array<IResolversTypes['CustodianAsset']>, ParentType, ContextType>;
  custodianIntegrations?: Resolver<Array<IResolversTypes['CustodianIntegration']>, ParentType, ContextType>;
  custodianOperations?: Resolver<Array<IResolversTypes['CustodianOperation']>, ParentType, ContextType>;
  custodianTermination?: Resolver<Maybe<IResolversTypes['CustodianTermination']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['CustodianProfilePolicy'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['CustodianProfileStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustodianProfilePageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustodianProfilePage'] = IResolversParentTypes['CustodianProfilePage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['CustodianProfile']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustodianProfilePolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustodianProfilePolicy'] = IResolversParentTypes['CustodianProfilePolicy'],
> = {
  activate?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  remove?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestTermination?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  submitForReview?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustodianTerminationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustodianTermination'] = IResolversParentTypes['CustodianTermination'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  custodianProfile?: Resolver<IResolversTypes['CustodianProfile'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  requesterUser?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
  reviewedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['CustodianTerminationStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICustomIntegrationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['CustomIntegration'] = IResolversParentTypes['CustomIntegration'],
> = {
  accountName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  accountUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  backupable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  creator?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  isClientOwner?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  provider?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['IntegrationStatusEnum'], ParentType, ContextType>;
  tenantId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IDepositorResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Depositor'] = IResolversParentTypes['Depositor'],
> = {
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<IResolversTypes['Company'], ParentType, ContextType>;
  companyName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyRegistrationNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyWebsite?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  contacts?: Resolver<Array<IResolversTypes['Contact']>, ParentType, ContextType>;
  country?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['DepositorPolicy'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IDepositorPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['DepositorPolicy'] = IResolversParentTypes['DepositorPolicy'],
> = {
  createEscrowMembers?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  readEscrowMembers?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IDestroyPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['DestroyPayload'] = IResolversParentTypes['DestroyPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IDisableBackupAssetPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['DisableBackupAssetPayload'] = IResolversParentTypes['DisableBackupAssetPayload'],
> = {
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType>;
  backupAsset?: Resolver<Maybe<IResolversTypes['BackupAsset']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEditPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EditPayload'] = IResolversParentTypes['EditPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEnableBackupAssetPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['EnableBackupAssetPayload'] = IResolversParentTypes['EnableBackupAssetPayload'],
> = {
  backupAsset?: Resolver<Maybe<IResolversTypes['BackupAsset']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEnableBackupReportsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['EnableBackupReportsPayload'] = IResolversParentTypes['EnableBackupReportsPayload'],
> = {
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEnableEscrowReportsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['EnableEscrowReportsPayload'] = IResolversParentTypes['EnableEscrowReportsPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Escrow'] = IResolversParentTypes['Escrow'],
> = {
  activationNotification?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreement?: Resolver<Maybe<IResolversTypes['Agreement']>, ParentType, ContextType>;
  agreementSettingsConfigured?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  beneficiary?: Resolver<Maybe<IResolversTypes['Beneficiary']>, ParentType, ContextType>;
  beneficiaryConfigured?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  beneficiaryDepositNotification?: Resolver<IResolversTypes['DepositNotificationEnum'], ParentType, ContextType>;
  beneficiaryDisruptionNotification?: Resolver<IResolversTypes['DisruptionNotificationEnum'], ParentType, ContextType>;
  beneficiaryDisruptionNotificationGracePeriod?: Resolver<
    Maybe<IResolversTypes['DisruptionNotificationGracePeriodEnum']>,
    ParentType,
    ContextType
  >;
  changesRequested?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  currentCompanyRole?: Resolver<IResolversTypes['EscrowPartyTypeEnum'], ParentType, ContextType>;
  depositStatus?: Resolver<IResolversTypes['DepositStatusEnum'], ParentType, ContextType>;
  depositor?: Resolver<Maybe<IResolversTypes['Depositor']>, ParentType, ContextType>;
  depositorConfigured?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  depositorDepositNotification?: Resolver<IResolversTypes['DepositNotificationEnum'], ParentType, ContextType>;
  depositorDisruptionNotification?: Resolver<IResolversTypes['DisruptionNotificationEnum'], ParentType, ContextType>;
  depositorDisruptionNotificationGracePeriod?: Resolver<
    Maybe<IResolversTypes['DisruptionNotificationGracePeriodEnum']>,
    ParentType,
    ContextType
  >;
  escrowInvitation?: Resolver<Maybe<IResolversTypes['EscrowInvitation']>, ParentType, ContextType>;
  escrowTermination?: Resolver<Maybe<IResolversTypes['EscrowTermination']>, ParentType, ContextType>;
  events?: Resolver<Array<IResolversTypes['EscrowEvent']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  legalStatus?: Resolver<Array<IResolversTypes['LegalStatus']>, ParentType, ContextType>;
  pendingActivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['EscrowPolicy'], ParentType, ContextType>;
  reportsEnabled?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  reportsEnabledAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  showDepositInformation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  signatures?: Resolver<Array<IResolversTypes['Signature']>, ParentType, ContextType>;
  startedBy?: Resolver<IResolversTypes['EscrowPartyTypeEnum'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['EscrowStatusEnum'], ParentType, ContextType>;
  storageRegion?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowEventResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowEvent'] = IResolversParentTypes['EscrowEvent'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  eventType?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  payload?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowInvitationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowInvitation'] = IResolversParentTypes['EscrowInvitation'],
> = {
  companyName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  partyType?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['EscrowInvitationPolicy'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['InvitationStatusEnum'], ParentType, ContextType>;
  user?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowInvitationPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowInvitationPage'] = IResolversParentTypes['EscrowInvitationPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['EscrowInvitation']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowInvitationPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowInvitationPolicy'] = IResolversParentTypes['EscrowInvitationPolicy'],
> = {
  accept?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  reject?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowMarkAsViewedPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['EscrowMarkAsViewedPayload'] = IResolversParentTypes['EscrowMarkAsViewedPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowMemberResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowMember'] = IResolversParentTypes['EscrowMember'],
> = {
  escrowParty?: Resolver<IResolversTypes['EscrowPartyInterface'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['EscrowMemberPolicy'], ParentType, ContextType>;
  role?: Resolver<IResolversTypes['EscrowMemberRoleEnum'], ParentType, ContextType>;
  user?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowMemberPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowMemberPage'] = IResolversParentTypes['EscrowMemberPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['EscrowMember']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowMemberPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowMemberPolicy'] = IResolversParentTypes['EscrowMemberPolicy'],
> = {
  remove?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowPage'] = IResolversParentTypes['EscrowPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['Escrow']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowPartyInterfaceResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowPartyInterface'] = IResolversParentTypes['EscrowPartyInterface'],
> = {
  __resolveType: TypeResolveFn<'Beneficiary' | 'Depositor', ParentType, ContextType>;
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyRegistrationNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  companyWebsite?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  contacts?: Resolver<Array<IResolversTypes['Contact']>, ParentType, ContextType>;
  country?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
};

export type IEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowPayload'] = IResolversParentTypes['EscrowPayload'],
> = {
  activationNotification?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreement?: Resolver<Maybe<IResolversTypes['Agreement']>, ParentType, ContextType>;
  agreementSettingsConfigured?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  beneficiary?: Resolver<Maybe<IResolversTypes['Beneficiary']>, ParentType, ContextType>;
  beneficiaryConfigured?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  beneficiaryDepositNotification?: Resolver<IResolversTypes['DepositNotificationEnum'], ParentType, ContextType>;
  beneficiaryDisruptionNotification?: Resolver<IResolversTypes['DisruptionNotificationEnum'], ParentType, ContextType>;
  beneficiaryDisruptionNotificationGracePeriod?: Resolver<
    Maybe<IResolversTypes['DisruptionNotificationGracePeriodEnum']>,
    ParentType,
    ContextType
  >;
  changesRequested?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  currentCompanyRole?: Resolver<IResolversTypes['EscrowPartyTypeEnum'], ParentType, ContextType>;
  depositStatus?: Resolver<IResolversTypes['DepositStatusEnum'], ParentType, ContextType>;
  depositor?: Resolver<Maybe<IResolversTypes['Depositor']>, ParentType, ContextType>;
  depositorConfigured?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  depositorDepositNotification?: Resolver<IResolversTypes['DepositNotificationEnum'], ParentType, ContextType>;
  depositorDisruptionNotification?: Resolver<IResolversTypes['DisruptionNotificationEnum'], ParentType, ContextType>;
  depositorDisruptionNotificationGracePeriod?: Resolver<
    Maybe<IResolversTypes['DisruptionNotificationGracePeriodEnum']>,
    ParentType,
    ContextType
  >;
  escrowInvitation?: Resolver<Maybe<IResolversTypes['EscrowInvitation']>, ParentType, ContextType>;
  escrowTermination?: Resolver<Maybe<IResolversTypes['EscrowTermination']>, ParentType, ContextType>;
  events?: Resolver<Array<IResolversTypes['EscrowEvent']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  legalStatus?: Resolver<Array<IResolversTypes['LegalStatus']>, ParentType, ContextType>;
  pendingActivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['EscrowPolicy'], ParentType, ContextType>;
  reportsEnabled?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  reportsEnabledAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  showDepositInformation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  signatures?: Resolver<Array<IResolversTypes['Signature']>, ParentType, ContextType>;
  startedBy?: Resolver<IResolversTypes['EscrowPartyTypeEnum'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['EscrowStatusEnum'], ParentType, ContextType>;
  storageRegion?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowPolicy'] = IResolversParentTypes['EscrowPolicy'],
> = {
  activate?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  bipartiteUpdate?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createAgreementSuggestedChange?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createBeneficiary?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createBeneficiarySuggestedChange?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createDepositSuggestedChange?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createDepositorSuggestedChange?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createNotificationSuggestedChange?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  deferActivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  depositAssets?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  enableReports?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  inviteBeneficiary?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  inviteDepositor?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  read?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  remove?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestTermination?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  write?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowTerminationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EscrowTermination'] = IResolversParentTypes['EscrowTermination'],
> = {
  acceptedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  adminReviewedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  noticePeriod?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['EscrowTerminationPolicy'], ParentType, ContextType>;
  requestedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  requesterParty?: Resolver<IResolversTypes['EscrowPartyTypeEnum'], ParentType, ContextType>;
  requesterUser?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['EscrowTerminationStatusEnum'], ParentType, ContextType>;
  terminateAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEscrowTerminationPolicyResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['EscrowTerminationPolicy'] = IResolversParentTypes['EscrowTerminationPolicy'],
> = {
  accept?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  reject?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEventInterfaceResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['EventInterface'] = IResolversParentTypes['EventInterface'],
> = {
  __resolveType: TypeResolveFn<'AgreementEvent' | 'EscrowEvent', ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  eventType?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
};

export type IExclusiveOfferPlanResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ExclusiveOfferPlan'] = IResolversParentTypes['ExclusiveOfferPlan'],
> = {
  currencyCode?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  discountPercentage?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  discounted?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  discountedPrice?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  period?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  periodUnit?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IFinishAuthPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['FinishAuthPayload'] = IResolversParentTypes['FinishAuthPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  oauthIntegration?: Resolver<Maybe<IResolversTypes['OauthIntegration']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IGenerateAgreementPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['GenerateAgreementPayload'] = IResolversParentTypes['GenerateAgreementPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IIso8601DateScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['ISO8601Date'], any> {
  name: 'ISO8601Date';
}

export interface IIso8601DateTimeScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['ISO8601DateTime'], any> {
  name: 'ISO8601DateTime';
}

export type IImpersonatePayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ImpersonatePayload'] = IResolversParentTypes['ImpersonatePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IInitAuthPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['InitAuthPayload'] = IResolversParentTypes['InitAuthPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  integrationAuth?: Resolver<Maybe<IResolversTypes['IntegrationAuth']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IInitSecureUploadPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['InitSecureUploadPayload'] = IResolversParentTypes['InitSecureUploadPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  uploadUrls?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IIntegrationAuthResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['IntegrationAuth'] = IResolversParentTypes['IntegrationAuth'],
> = {
  stateToken?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IIntegrationInterfaceResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['IntegrationInterface'] = IResolversParentTypes['IntegrationInterface'],
> = {
  __resolveType: TypeResolveFn<
    'CustomIntegration' | 'OauthIntegration' | 'ShareFileIntegration',
    ParentType,
    ContextType
  >;
  accountName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  accountUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  backupable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  creator?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  provider?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['IntegrationStatusEnum'], ParentType, ContextType>;
  tenantId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
};

export type IIntegrationInterfacePageResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['IntegrationInterfacePage'] = IResolversParentTypes['IntegrationInterfacePage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['IntegrationInterface']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IInviteOwnerBeneficiaryPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['InviteOwnerBeneficiaryPayload'] = IResolversParentTypes['InviteOwnerBeneficiaryPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowInvitation?: Resolver<IResolversTypes['EscrowInvitation'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IInviteOwnerDepositorPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['InviteOwnerDepositorPayload'] = IResolversParentTypes['InviteOwnerDepositorPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowInvitation?: Resolver<IResolversTypes['EscrowInvitation'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IInvoiceResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Invoice'] = IResolversParentTypes['Invoice'],
> = {
  currencyCode?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<IResolversTypes['ISO8601Date'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IInvoicePageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['InvoicePage'] = IResolversParentTypes['InvoicePage'],
> = {
  nextOffset?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['Invoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IInvoicePdfResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['InvoicePdf'] = IResolversParentTypes['InvoicePdf'],
> = {
  downloadUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IJsonScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type ILegacyRepoResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['LegacyRepo'] = IResolversParentTypes['LegacyRepo'],
> = {
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  migrationStatus?: Resolver<IResolversTypes['LegacyRepoMigrationStatusEnum'], ParentType, ContextType>;
  platform?: Resolver<IResolversTypes['LegacyRepoPlatformEnum'], ParentType, ContextType>;
  title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ILegacyRepoPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['LegacyRepoPage'] = IResolversParentTypes['LegacyRepoPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['LegacyRepo']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ILegalStatusResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['LegalStatus'] = IResolversParentTypes['LegalStatus'],
> = {
  role?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  signed?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IMarkAsViewedPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['MarkAsViewedPayload'] = IResolversParentTypes['MarkAsViewedPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IMigrateLegacyRepoPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['MigrateLegacyRepoPayload'] = IResolversParentTypes['MigrateLegacyRepoPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IMigrateLegacyReposPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['MigrateLegacyReposPayload'] = IResolversParentTypes['MigrateLegacyReposPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  migratedLegacyRepos?: Resolver<Array<IResolversTypes['LegacyRepo']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IMutationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Mutation'] = IResolversParentTypes['Mutation'],
> = {
  acceptEscrowInvitation?: Resolver<
    Maybe<IResolversTypes['AcceptEscrowInvitationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationAcceptEscrowInvitationArgs, 'input'>
  >;
  acceptEscrowTermination?: Resolver<
    Maybe<IResolversTypes['AcceptPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationAcceptEscrowTerminationArgs, 'input'>
  >;
  acceptExclusiveOffer?: Resolver<
    Maybe<IResolversTypes['AcceptExclusiveOfferPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationAcceptExclusiveOfferArgs, 'input'>
  >;
  activateEscrow?: Resolver<
    Maybe<IResolversTypes['ActivateEscrowPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationActivateEscrowArgs, 'input'>
  >;
  activateSaasEnvironment?: Resolver<
    Maybe<IResolversTypes['ActivateSaasEnvironmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationActivateSaasEnvironmentArgs, 'input'>
  >;
  addUser?: Resolver<
    Maybe<IResolversTypes['AddPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationAddUserArgs, 'input'>
  >;
  applyCoupon?: Resolver<
    Maybe<IResolversTypes['ApplyCouponPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationApplyCouponArgs, 'input'>
  >;
  approveAgreement?: Resolver<
    Maybe<IResolversTypes['ApproveAgreementPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationApproveAgreementArgs, 'input'>
  >;
  assignBackupMembers?: Resolver<
    Maybe<IResolversTypes['AssignBackupMembersPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationAssignBackupMembersArgs, 'input'>
  >;
  cancelSecureUpload?: Resolver<
    Maybe<IResolversTypes['CancelSecureUploadPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCancelSecureUploadArgs, 'input'>
  >;
  cancelSubscription?: Resolver<
    Maybe<IResolversTypes['CancelSubscriptionPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCancelSubscriptionArgs, 'input'>
  >;
  completeSecureUpload?: Resolver<
    Maybe<IResolversTypes['CompleteSecureUploadPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCompleteSecureUploadArgs, 'input'>
  >;
  confirmEmailChange?: Resolver<
    Maybe<IResolversTypes['ConfirmEmailChangePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationConfirmEmailChangeArgs, 'input'>
  >;
  createBackup?: Resolver<
    Maybe<IResolversTypes['CreateBackupPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateBackupArgs, 'input'>
  >;
  createBackupAssets?: Resolver<
    Maybe<IResolversTypes['CreateBackupAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateBackupAssetsArgs, 'input'>
  >;
  createBeneficiary?: Resolver<
    Maybe<IResolversTypes['CreateBeneficiaryPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateBeneficiaryArgs, 'input'>
  >;
  createContact?: Resolver<
    Maybe<IResolversTypes['CreateContactPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateContactArgs, 'input'>
  >;
  createCustodianOperation?: Resolver<
    Maybe<IResolversTypes['CreateCustodianOperationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateCustodianOperationArgs, 'input'>
  >;
  createCustodianProfile?: Resolver<
    Maybe<IResolversTypes['CreateCustodianProfilePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateCustodianProfileArgs, 'input'>
  >;
  createCustomAssets?: Resolver<
    Maybe<IResolversTypes['CreateCustomAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateCustomAssetsArgs, 'input'>
  >;
  createCustomIntegration?: Resolver<
    Maybe<IResolversTypes['CreateCustomIntegrationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateCustomIntegrationArgs, 'input'>
  >;
  createEscrow?: Resolver<
    Maybe<IResolversTypes['CreateEscrowPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateEscrowArgs, 'input'>
  >;
  createEscrowMember?: Resolver<
    Maybe<IResolversTypes['CreateEscrowMemberPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateEscrowMemberArgs, 'input'>
  >;
  createFeedback?: Resolver<
    Maybe<IResolversTypes['CreateFeedbackPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateFeedbackArgs, 'input'>
  >;
  createOauthAssets?: Resolver<
    Maybe<IResolversTypes['CreateOauthAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateOauthAssetsArgs, 'input'>
  >;
  createSaasEnvironment?: Resolver<
    Maybe<IResolversTypes['CreateSaasEnvironmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateSaasEnvironmentArgs, 'input'>
  >;
  createSaasProvider?: Resolver<
    Maybe<IResolversTypes['CreateSaasProviderPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateSaasProviderArgs, 'input'>
  >;
  createShareFileIntegration?: Resolver<
    Maybe<IResolversTypes['CreateShareFileIntegrationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateShareFileIntegrationArgs, 'input'>
  >;
  createSuggestedChangeEscrow?: Resolver<
    Maybe<IResolversTypes['CreateSuggestedChangeEscrowPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationCreateSuggestedChangeEscrowArgs, 'input'>
  >;
  destroyAsset?: Resolver<
    Maybe<IResolversTypes['DestroyPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationDestroyAssetArgs, 'input'>
  >;
  disableBackupAsset?: Resolver<
    Maybe<IResolversTypes['DisableBackupAssetPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationDisableBackupAssetArgs, 'input'>
  >;
  editUser?: Resolver<
    Maybe<IResolversTypes['EditPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationEditUserArgs, 'input'>
  >;
  enableBackupAsset?: Resolver<
    Maybe<IResolversTypes['EnableBackupAssetPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationEnableBackupAssetArgs, 'input'>
  >;
  enableBackupReports?: Resolver<
    Maybe<IResolversTypes['EnableBackupReportsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationEnableBackupReportsArgs, 'input'>
  >;
  enableEscrowReports?: Resolver<
    Maybe<IResolversTypes['EnableEscrowReportsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationEnableEscrowReportsArgs, 'input'>
  >;
  finishOauthIntegrationAuth?: Resolver<
    Maybe<IResolversTypes['FinishAuthPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationFinishOauthIntegrationAuthArgs, 'input'>
  >;
  generateAgreement?: Resolver<
    Maybe<IResolversTypes['GenerateAgreementPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationGenerateAgreementArgs, 'input'>
  >;
  impersonateUser?: Resolver<
    Maybe<IResolversTypes['ImpersonatePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationImpersonateUserArgs, 'input'>
  >;
  initOauthIntegrationAuth?: Resolver<
    Maybe<IResolversTypes['InitAuthPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationInitOauthIntegrationAuthArgs, 'input'>
  >;
  initSecureUpload?: Resolver<
    Maybe<IResolversTypes['InitSecureUploadPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationInitSecureUploadArgs, 'input'>
  >;
  inviteOwnerBeneficiary?: Resolver<
    Maybe<IResolversTypes['InviteOwnerBeneficiaryPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationInviteOwnerBeneficiaryArgs, 'input'>
  >;
  inviteOwnerDepositor?: Resolver<
    Maybe<IResolversTypes['InviteOwnerDepositorPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationInviteOwnerDepositorArgs, 'input'>
  >;
  markAsViewedEscrow?: Resolver<
    Maybe<IResolversTypes['EscrowMarkAsViewedPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationMarkAsViewedEscrowArgs, 'input'>
  >;
  markAsViewedNotification?: Resolver<
    Maybe<IResolversTypes['MarkAsViewedPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationMarkAsViewedNotificationArgs, 'input'>
  >;
  migrateLegacyRepo?: Resolver<
    Maybe<IResolversTypes['MigrateLegacyRepoPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationMigrateLegacyRepoArgs, 'input'>
  >;
  migrateLegacyRepos?: Resolver<
    Maybe<IResolversTypes['MigrateLegacyReposPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationMigrateLegacyReposArgs, 'input'>
  >;
  rejectEscrowInvitation?: Resolver<
    Maybe<IResolversTypes['RejectEscrowInvitationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRejectEscrowInvitationArgs, 'input'>
  >;
  rejectEscrowTermination?: Resolver<
    Maybe<IResolversTypes['RejectPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRejectEscrowTerminationArgs, 'input'>
  >;
  removeBackup?: Resolver<
    Maybe<IResolversTypes['RemoveBackupPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveBackupArgs, 'input'>
  >;
  removeBackupAsset?: Resolver<
    Maybe<IResolversTypes['RemoveBackupAssetPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveBackupAssetArgs, 'input'>
  >;
  removeContact?: Resolver<
    Maybe<IResolversTypes['RemoveContactPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveContactArgs, 'input'>
  >;
  removeCustodianOperation?: Resolver<
    Maybe<IResolversTypes['RemoveCustodianOperationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveCustodianOperationArgs, 'input'>
  >;
  removeCustodianProfile?: Resolver<
    Maybe<IResolversTypes['RemoveCustodianProfilePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveCustodianProfileArgs, 'input'>
  >;
  removeEscrow?: Resolver<
    Maybe<IResolversTypes['RemoveEscrowPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveEscrowArgs, 'input'>
  >;
  removeEscrowMember?: Resolver<
    Maybe<IResolversTypes['RemoveEscrowMemberPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveEscrowMemberArgs, 'input'>
  >;
  removeSaasEnvironment?: Resolver<
    Maybe<IResolversTypes['RemoveSaasEnvironmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveSaasEnvironmentArgs, 'input'>
  >;
  removeSaasProvider?: Resolver<
    Maybe<IResolversTypes['RemoveSaasProviderPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveSaasProviderArgs, 'input'>
  >;
  removeUser?: Resolver<
    Maybe<IResolversTypes['RemovePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRemoveUserArgs, 'input'>
  >;
  requestBackupVersionDownload?: Resolver<
    Maybe<IResolversTypes['RequestBackupVersionDownloadPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRequestBackupVersionDownloadArgs, 'input'>
  >;
  requestEscrowTermination?: Resolver<
    Maybe<IResolversTypes['TerminatePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationRequestEscrowTerminationArgs, 'input'>
  >;
  reviewSuggestedChangeEscrow?: Resolver<
    Maybe<IResolversTypes['ReviewSuggestedChangeEscrowPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationReviewSuggestedChangeEscrowArgs, 'input'>
  >;
  sendForReviewCustodianProfile?: Resolver<
    Maybe<IResolversTypes['SendForReviewCustodianProfilePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationSendForReviewCustodianProfileArgs, 'input'>
  >;
  startBackup?: Resolver<
    Maybe<IResolversTypes['StartBackupPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationStartBackupArgs, 'input'>
  >;
  stopBackup?: Resolver<
    Maybe<IResolversTypes['StopBackupPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationStopBackupArgs, 'input'>
  >;
  switchToBackupPlan?: Resolver<
    Maybe<IResolversTypes['SwitchToBackupPlanPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationSwitchToBackupPlanArgs, 'input'>
  >;
  terminateCustodianProfile?: Resolver<
    Maybe<IResolversTypes['TerminateCustodianProfilePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationTerminateCustodianProfileArgs, 'input'>
  >;
  terminateSaasEnvironment?: Resolver<
    Maybe<IResolversTypes['TerminateSaasEnvironmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationTerminateSaasEnvironmentArgs, 'input'>
  >;
  toggleIntegration?: Resolver<
    Maybe<IResolversTypes['TogglePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationToggleIntegrationArgs, 'input'>
  >;
  updateAgreement?: Resolver<
    Maybe<IResolversTypes['UpdateAgreementPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateAgreementArgs, 'input'>
  >;
  updateBackup?: Resolver<
    Maybe<IResolversTypes['UpdateBackupPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateBackupArgs, 'input'>
  >;
  updateBeneficiary?: Resolver<
    Maybe<IResolversTypes['UpdateBeneficiaryPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateBeneficiaryArgs, 'input'>
  >;
  updateBillingAddress?: Resolver<
    Maybe<IResolversTypes['UpdateBillingAddressPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateBillingAddressArgs, 'input'>
  >;
  updateCompanyDetails?: Resolver<
    Maybe<IResolversTypes['UpdateCompanyDetailsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateCompanyDetailsArgs, 'input'>
  >;
  updateContact?: Resolver<
    Maybe<IResolversTypes['UpdateContactPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateContactArgs, 'input'>
  >;
  updateCustodianAssets?: Resolver<
    Maybe<IResolversTypes['UpdateCustodianAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateCustodianAssetsArgs, 'input'>
  >;
  updateCustodianOperation?: Resolver<
    Maybe<IResolversTypes['UpdateCustodianOperationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateCustodianOperationArgs, 'input'>
  >;
  updateCustomIntegration?: Resolver<
    Maybe<IResolversTypes['UpdateCustomIntegrationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateCustomIntegrationArgs, 'input'>
  >;
  updateDepositSettings?: Resolver<
    Maybe<IResolversTypes['UpdateDepositSettingsPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateDepositSettingsArgs, 'input'>
  >;
  updateDepositor?: Resolver<
    Maybe<IResolversTypes['UpdateDepositorPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateDepositorArgs, 'input'>
  >;
  updateEscrow?: Resolver<
    Maybe<IResolversTypes['UpdateEscrowPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateEscrowArgs, 'input'>
  >;
  updateEscrowInvitation?: Resolver<
    Maybe<IResolversTypes['UpdateEscrowInvitationPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateEscrowInvitationArgs, 'input'>
  >;
  updateEscrowMember?: Resolver<
    Maybe<IResolversTypes['UpdateEscrowMemberPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateEscrowMemberArgs, 'input'>
  >;
  updatePaymentMethod?: Resolver<
    Maybe<IResolversTypes['RequestUpdatePaymentPagePayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdatePaymentMethodArgs, 'input'>
  >;
  updateProfileInfo?: Resolver<
    Maybe<IResolversTypes['UpdateProfileInfoPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateProfileInfoArgs, 'input'>
  >;
  updateSaasProvider?: Resolver<
    Maybe<IResolversTypes['UpdateSaasProviderPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateSaasProviderArgs, 'input'>
  >;
  updateSuggestedChangeEscrow?: Resolver<
    Maybe<IResolversTypes['UpdateSuggestedChangeEscrowPayload']>,
    ParentType,
    ContextType,
    RequireFields<IMutationUpdateSuggestedChangeEscrowArgs, 'input'>
  >;
};

export type IOauthIntegrationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['OauthIntegration'] = IResolversParentTypes['OauthIntegration'],
> = {
  accountName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  accountUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  backupable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  creator?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  isClientOwner?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  provider?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['IntegrationStatusEnum'], ParentType, ContextType>;
  tenantId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IOauthReposConnResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['OauthReposConn'] = IResolversParentTypes['OauthReposConn'],
> = {
  repositories?: Resolver<Array<IResolversTypes['OauthRepository']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IOauthRepositoryResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['OauthRepository'] = IResolversParentTypes['OauthRepository'],
> = {
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['RepositoryTypeEnum'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Payload'] = IResolversParentTypes['Payload'],
> = {
  __resolveType: TypeResolveFn<'AgreementPayload' | 'EscrowPayload', ParentType, ContextType>;
};

export type IPaymentMethodResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['PaymentMethod'] = IResolversParentTypes['PaymentMethod'],
> = {
  cardLast4?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  cardType?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IPlanResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Plan'] = IResolversParentTypes['Plan'],
> = {
  currencyCode?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  period?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  periodUnit?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IProfileResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Profile'] = IResolversParentTypes['Profile'],
> = {
  email?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  gravatarUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<IResolversTypes['UserRoleEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IQueryResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Query'] = IResolversParentTypes['Query'],
> = {
  activities?: Resolver<IResolversTypes['ActivityPage'], ParentType, ContextType, Partial<IQueryActivitiesArgs>>;
  agreement?: Resolver<
    Maybe<IResolversTypes['Agreement']>,
    ParentType,
    ContextType,
    RequireFields<IQueryAgreementArgs, 'escrowId'>
  >;
  assetReport?: Resolver<
    Maybe<IResolversTypes['AssetReport']>,
    ParentType,
    ContextType,
    RequireFields<IQueryAssetReportArgs, 'assetReportId' | 'escrowId'>
  >;
  assetReportGroups?: Resolver<
    IResolversTypes['AssetReportGroupPage'],
    ParentType,
    ContextType,
    RequireFields<IQueryAssetReportGroupsArgs, 'escrowId' | 'order' | 'orderBy'>
  >;
  assets?: Resolver<
    IResolversTypes['AssetPage'],
    ParentType,
    ContextType,
    RequireFields<IQueryAssetsArgs, 'order' | 'orderBy'>
  >;
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType, RequireFields<IQueryBackupArgs, 'backupId'>>;
  backupAssetReport?: Resolver<
    Maybe<IResolversTypes['BackupAssetReport']>,
    ParentType,
    ContextType,
    RequireFields<IQueryBackupAssetReportArgs, 'backupAssetReportId' | 'backupId'>
  >;
  backupAssetReportGroups?: Resolver<
    IResolversTypes['BackupAssetReportGroupPage'],
    ParentType,
    ContextType,
    RequireFields<IQueryBackupAssetReportGroupsArgs, 'backupId' | 'order' | 'orderBy'>
  >;
  backupVersions?: Resolver<
    IResolversTypes['BackupVersionPage'],
    ParentType,
    ContextType,
    RequireFields<IQueryBackupVersionsArgs, 'backupAssetId'>
  >;
  backups?: Resolver<
    IResolversTypes['BackupPage'],
    ParentType,
    ContextType,
    RequireFields<IQueryBackupsArgs, 'order' | 'orderBy'>
  >;
  billing?: Resolver<Maybe<IResolversTypes['Billing']>, ParentType, ContextType>;
  clientChangesRequested?: Resolver<IResolversTypes['ClientChangesRequestedPayload'], ParentType, ContextType>;
  company?: Resolver<IResolversTypes['Company'], ParentType, ContextType>;
  custodianProfile?: Resolver<
    Maybe<IResolversTypes['CustodianProfile']>,
    ParentType,
    ContextType,
    RequireFields<IQueryCustodianProfileArgs, 'custodianProfileId'>
  >;
  custodianProfiles?: Resolver<
    IResolversTypes['CustodianProfilePage'],
    ParentType,
    ContextType,
    Partial<IQueryCustodianProfilesArgs>
  >;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType, RequireFields<IQueryEscrowArgs, 'escrowId'>>;
  escrowInvitations?: Resolver<
    Maybe<IResolversTypes['EscrowInvitationPage']>,
    ParentType,
    ContextType,
    Partial<IQueryEscrowInvitationsArgs>
  >;
  escrowMembers?: Resolver<
    IResolversTypes['EscrowMemberPage'],
    ParentType,
    ContextType,
    RequireFields<IQueryEscrowMembersArgs, 'escrowPartyId' | 'order' | 'orderBy'>
  >;
  escrows?: Resolver<IResolversTypes['EscrowPage'], ParentType, ContextType, Partial<IQueryEscrowsArgs>>;
  exclusiveOfferPlans?: Resolver<Maybe<Array<IResolversTypes['ExclusiveOfferPlan']>>, ParentType, ContextType>;
  integrations?: Resolver<
    IResolversTypes['IntegrationInterfacePage'],
    ParentType,
    ContextType,
    Partial<IQueryIntegrationsArgs>
  >;
  invoicePdf?: Resolver<
    Maybe<IResolversTypes['InvoicePdf']>,
    ParentType,
    ContextType,
    RequireFields<IQueryInvoicePdfArgs, 'invoiceId'>
  >;
  invoices?: Resolver<IResolversTypes['InvoicePage'], ParentType, ContextType, Partial<IQueryInvoicesArgs>>;
  legacyRepos?: Resolver<
    IResolversTypes['LegacyRepoPage'],
    ParentType,
    ContextType,
    RequireFields<IQueryLegacyReposArgs, 'order' | 'orderBy'>
  >;
  oauthReposConn?: Resolver<
    IResolversTypes['OauthReposConn'],
    ParentType,
    ContextType,
    RequireFields<IQueryOauthReposConnArgs, 'integrationId'>
  >;
  profile?: Resolver<IResolversTypes['Profile'], ParentType, ContextType>;
  saasEnvironment?: Resolver<
    IResolversTypes['SaasEnvironment'],
    ParentType,
    ContextType,
    RequireFields<IQuerySaasEnvironmentArgs, 'saasEnvironmentId'>
  >;
  saasEnvironments?: Resolver<
    IResolversTypes['SaasEnvironmentPage'],
    ParentType,
    ContextType,
    RequireFields<IQuerySaasEnvironmentsArgs, 'order' | 'orderBy'>
  >;
  suggestedChanges?: Resolver<
    IResolversTypes['SuggestChangePage'],
    ParentType,
    ContextType,
    RequireFields<IQuerySuggestedChangesArgs, 'escrowId'>
  >;
  users?: Resolver<IResolversTypes['UserPage'], ParentType, ContextType, Partial<IQueryUsersArgs>>;
};

export type IRejectEscrowInvitationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RejectEscrowInvitationPayload'] = IResolversParentTypes['RejectEscrowInvitationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowInvitation?: Resolver<IResolversTypes['EscrowInvitation'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRejectPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['RejectPayload'] = IResolversParentTypes['RejectPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowTermination?: Resolver<IResolversTypes['EscrowTermination'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveBackupAssetPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RemoveBackupAssetPayload'] = IResolversParentTypes['RemoveBackupAssetPayload'],
> = {
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveBackupPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['RemoveBackupPayload'] = IResolversParentTypes['RemoveBackupPayload'],
> = {
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveContactPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['RemoveContactPayload'] = IResolversParentTypes['RemoveContactPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveCustodianOperationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RemoveCustodianOperationPayload'] = IResolversParentTypes['RemoveCustodianOperationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveCustodianProfilePayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RemoveCustodianProfilePayload'] = IResolversParentTypes['RemoveCustodianProfilePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveEscrowMemberPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RemoveEscrowMemberPayload'] = IResolversParentTypes['RemoveEscrowMemberPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['RemoveEscrowPayload'] = IResolversParentTypes['RemoveEscrowPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemovePayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['RemovePayload'] = IResolversParentTypes['RemovePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveSaasEnvironmentPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RemoveSaasEnvironmentPayload'] = IResolversParentTypes['RemoveSaasEnvironmentPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  saasEnvironment?: Resolver<Maybe<IResolversTypes['SaasEnvironment']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRemoveSaasProviderPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RemoveSaasProviderPayload'] = IResolversParentTypes['RemoveSaasProviderPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IReportDataResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ReportData'] = IResolversParentTypes['ReportData'],
> = {
  collaborators?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  commitsPerDay?: Resolver<Maybe<IResolversTypes['JSON']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<IResolversTypes['JSON']>, ParentType, ContextType>;
  size?: Resolver<Maybe<IResolversTypes['BigInt']>, ParentType, ContextType>;
  totalCommits?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRequestBackupVersionDownloadPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RequestBackupVersionDownloadPayload'] = IResolversParentTypes['RequestBackupVersionDownloadPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IRequestUpdatePaymentPagePayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['RequestUpdatePaymentPagePayload'] = IResolversParentTypes['RequestUpdatePaymentPagePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IReviewSuggestedChangeEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['ReviewSuggestedChangeEscrowPayload'] = IResolversParentTypes['ReviewSuggestedChangeEscrowPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  suggestedChange?: Resolver<IResolversTypes['SuggestChange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISaasEnvironmentResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['SaasEnvironment'] = IResolversParentTypes['SaasEnvironment'],
> = {
  activatedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  creator?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['SaasEnvironmentPolicy'], ParentType, ContextType>;
  saasEnvironmentTermination?: Resolver<Maybe<IResolversTypes['SaasEnvironmentTermination']>, ParentType, ContextType>;
  saasProviders?: Resolver<Array<IResolversTypes['SaasProvider']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['SaasEnvironmentStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISaasEnvironmentPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['SaasEnvironmentPage'] = IResolversParentTypes['SaasEnvironmentPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['SaasEnvironment']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISaasEnvironmentPolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['SaasEnvironmentPolicy'] = IResolversParentTypes['SaasEnvironmentPolicy'],
> = {
  activate?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  remove?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestActivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestTermination?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISaasEnvironmentTerminationResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['SaasEnvironmentTermination'] = IResolversParentTypes['SaasEnvironmentTermination'],
> = {
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  requesterUser?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
  reviewedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  saasEnvironment?: Resolver<IResolversTypes['SaasEnvironment'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['SaasEnvironmentTerminationStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISaasProviderResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['SaasProvider'] = IResolversParentTypes['SaasProvider'],
> = {
  completedAt?: Resolver<Maybe<IResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  contactEmail?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  description?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  monthlyCost?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  platform?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  protectionPeriod?: Resolver<IResolversTypes['SaasProviderProtectionPeriodEnum'], ParentType, ContextType>;
  role?: Resolver<IResolversTypes['SaasProviderRoleEnum'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['SaasProviderStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISendForReviewCustodianProfilePayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['SendForReviewCustodianProfilePayload'] = IResolversParentTypes['SendForReviewCustodianProfilePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  custodianProfile?: Resolver<IResolversTypes['CustodianProfile'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IShareFileIntegrationResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['ShareFileIntegration'] = IResolversParentTypes['ShareFileIntegration'],
> = {
  accountName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  accountUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  backupable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  creator?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  provider?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['IntegrationStatusEnum'], ParentType, ContextType>;
  tenantId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISignatureResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Signature'] = IResolversParentTypes['Signature'],
> = {
  email?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IStartBackupPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['StartBackupPayload'] = IResolversParentTypes['StartBackupPayload'],
> = {
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IStopBackupPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['StopBackupPayload'] = IResolversParentTypes['StopBackupPayload'],
> = {
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISubscriptionResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['Subscription'] = IResolversParentTypes['Subscription'],
> = {
  addons?: SubscriptionResolver<Array<IResolversTypes['Addon']>, 'addons', ParentType, ContextType>;
  billingPeriod?: SubscriptionResolver<IResolversTypes['Int'], 'billingPeriod', ParentType, ContextType>;
  billingPeriodUnit?: SubscriptionResolver<IResolversTypes['String'], 'billingPeriodUnit', ParentType, ContextType>;
  coupons?: SubscriptionResolver<Maybe<Array<IResolversTypes['String']>>, 'coupons', ParentType, ContextType>;
  currencyCode?: SubscriptionResolver<IResolversTypes['String'], 'currencyCode', ParentType, ContextType>;
  nextBillingAt?: SubscriptionResolver<Maybe<IResolversTypes['ISO8601Date']>, 'nextBillingAt', ParentType, ContextType>;
  plans?: SubscriptionResolver<Array<IResolversTypes['Plan']>, 'plans', ParentType, ContextType>;
  status?: SubscriptionResolver<IResolversTypes['String'], 'status', ParentType, ContextType>;
  total?: SubscriptionResolver<IResolversTypes['Int'], 'total', ParentType, ContextType>;
};

export type ISuggestChangeResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['SuggestChange'] = IResolversParentTypes['SuggestChange'],
> = {
  changeType?: Resolver<IResolversTypes['SuggestedChangeChangeTypeEnum'], ParentType, ContextType>;
  creatorType?: Resolver<IResolversTypes['EscrowPartyTypeEnum'], ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  partyStatus?: Resolver<Maybe<IResolversTypes['SuggestedChangeStatusEnum']>, ParentType, ContextType>;
  payload?: Resolver<IResolversTypes['JSON'], ParentType, ContextType>;
  policy?: Resolver<IResolversTypes['SuggestedChangePolicy'], ParentType, ContextType>;
  receiverType?: Resolver<Maybe<IResolversTypes['EscrowPartyTypeEnum']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['SuggestedChangeStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISuggestChangePageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['SuggestChangePage'] = IResolversParentTypes['SuggestChangePage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['SuggestChange']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISuggestedChangePolicyResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['SuggestedChangePolicy'] = IResolversParentTypes['SuggestedChangePolicy'],
> = {
  review?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  update?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISwitchToBackupPlanPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['SwitchToBackupPlanPayload'] = IResolversParentTypes['SwitchToBackupPlanPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITerminateCustodianProfilePayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['TerminateCustodianProfilePayload'] = IResolversParentTypes['TerminateCustodianProfilePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  custodianTermination?: Resolver<Maybe<IResolversTypes['CustodianTermination']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITerminatePayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['TerminatePayload'] = IResolversParentTypes['TerminatePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowTerminationId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITerminateSaasEnvironmentPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['TerminateSaasEnvironmentPayload'] = IResolversParentTypes['TerminateSaasEnvironmentPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  saasEnvironmentTermination?: Resolver<Maybe<IResolversTypes['SaasEnvironmentTermination']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITogglePayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['TogglePayload'] = IResolversParentTypes['TogglePayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  integration?: Resolver<Maybe<IResolversTypes['IntegrationInterface']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateAgreementPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['UpdateAgreementPayload'] = IResolversParentTypes['UpdateAgreementPayload'],
> = {
  agreement?: Resolver<Maybe<IResolversTypes['Agreement']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateBackupPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['UpdateBackupPayload'] = IResolversParentTypes['UpdateBackupPayload'],
> = {
  backup?: Resolver<IResolversTypes['Backup'], ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateBeneficiaryPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateBeneficiaryPayload'] = IResolversParentTypes['UpdateBeneficiaryPayload'],
> = {
  beneficiary?: Resolver<IResolversTypes['Beneficiary'], ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateBillingAddressPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateBillingAddressPayload'] = IResolversParentTypes['UpdateBillingAddressPayload'],
> = {
  billingAddress?: Resolver<Maybe<IResolversTypes['BillingAddress']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateCompanyDetailsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateCompanyDetailsPayload'] = IResolversParentTypes['UpdateCompanyDetailsPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<IResolversTypes['Company']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateContactPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['UpdateContactPayload'] = IResolversParentTypes['UpdateContactPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<IResolversTypes['Contact']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateCustodianAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateCustodianAssetsPayload'] = IResolversParentTypes['UpdateCustodianAssetsPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  custodianAssets?: Resolver<Maybe<Array<IResolversTypes['CustodianAsset']>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateCustodianOperationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateCustodianOperationPayload'] = IResolversParentTypes['UpdateCustodianOperationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  custodianOperation?: Resolver<IResolversTypes['CustodianOperation'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateCustomIntegrationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateCustomIntegrationPayload'] = IResolversParentTypes['UpdateCustomIntegrationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  customIntegration?: Resolver<Maybe<IResolversTypes['CustomIntegration']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateDepositSettingsPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateDepositSettingsPayload'] = IResolversParentTypes['UpdateDepositSettingsPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<Maybe<IResolversTypes['Escrow']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateDepositorPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['UpdateDepositorPayload'] = IResolversParentTypes['UpdateDepositorPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  depositor?: Resolver<IResolversTypes['Depositor'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateEscrowInvitationPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateEscrowInvitationPayload'] = IResolversParentTypes['UpdateEscrowInvitationPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowInvitation?: Resolver<IResolversTypes['EscrowInvitation'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateEscrowMemberPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateEscrowMemberPayload'] = IResolversParentTypes['UpdateEscrowMemberPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrowMember?: Resolver<Maybe<IResolversTypes['EscrowMember']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['UpdateEscrowPayload'] = IResolversParentTypes['UpdateEscrowPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  escrow?: Resolver<IResolversTypes['Escrow'], ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateProfileInfoPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateProfileInfoPayload'] = IResolversParentTypes['UpdateProfileInfoPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  profile?: Resolver<Maybe<IResolversTypes['Profile']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateSaasProviderPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateSaasProviderPayload'] = IResolversParentTypes['UpdateSaasProviderPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  saasProvider?: Resolver<Maybe<IResolversTypes['SaasProvider']>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUpdateSuggestedChangeEscrowPayloadResolvers<
  ContextType = any,
  ParentType extends
    IResolversParentTypes['UpdateSuggestedChangeEscrowPayload'] = IResolversParentTypes['UpdateSuggestedChangeEscrowPayload'],
> = {
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  suggestedChange?: Resolver<IResolversTypes['SuggestChange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUserResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['User'] = IResolversParentTypes['User'],
> = {
  contacts?: Resolver<Maybe<Array<IResolversTypes['Contact']>>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  email?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<IResolversTypes['UserRoleEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUserPageResolvers<
  ContextType = any,
  ParentType extends IResolversParentTypes['UserPage'] = IResolversParentTypes['UserPage'],
> = {
  hasNextPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nodes?: Resolver<Array<IResolversTypes['User']>, ParentType, ContextType>;
  nodesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  pagesCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IResolvers<ContextType = any> = {
  AcceptEscrowInvitationPayload?: IAcceptEscrowInvitationPayloadResolvers<ContextType>;
  AcceptExclusiveOfferPayload?: IAcceptExclusiveOfferPayloadResolvers<ContextType>;
  AcceptPayload?: IAcceptPayloadResolvers<ContextType>;
  ActivateEscrowPayload?: IActivateEscrowPayloadResolvers<ContextType>;
  ActivateSaasEnvironmentPayload?: IActivateSaasEnvironmentPayloadResolvers<ContextType>;
  Activity?: IActivityResolvers<ContextType>;
  ActivityPage?: IActivityPageResolvers<ContextType>;
  AddPayload?: IAddPayloadResolvers<ContextType>;
  Addon?: IAddonResolvers<ContextType>;
  Agreement?: IAgreementResolvers<ContextType>;
  AgreementEvent?: IAgreementEventResolvers<ContextType>;
  AgreementPayload?: IAgreementPayloadResolvers<ContextType>;
  AgreementPolicy?: IAgreementPolicyResolvers<ContextType>;
  ApplyCouponPayload?: IApplyCouponPayloadResolvers<ContextType>;
  ApproveAgreementPayload?: IApproveAgreementPayloadResolvers<ContextType>;
  Asset?: IAssetResolvers<ContextType>;
  AssetPage?: IAssetPageResolvers<ContextType>;
  AssetPolicy?: IAssetPolicyResolvers<ContextType>;
  AssetReport?: IAssetReportResolvers<ContextType>;
  AssetReportGroup?: IAssetReportGroupResolvers<ContextType>;
  AssetReportGroupPage?: IAssetReportGroupPageResolvers<ContextType>;
  AssignBackupMembersPayload?: IAssignBackupMembersPayloadResolvers<ContextType>;
  Backup?: IBackupResolvers<ContextType>;
  BackupAsset?: IBackupAssetResolvers<ContextType>;
  BackupAssetReport?: IBackupAssetReportResolvers<ContextType>;
  BackupAssetReportGroup?: IBackupAssetReportGroupResolvers<ContextType>;
  BackupAssetReportGroupPage?: IBackupAssetReportGroupPageResolvers<ContextType>;
  BackupMember?: IBackupMemberResolvers<ContextType>;
  BackupPage?: IBackupPageResolvers<ContextType>;
  BackupPolicy?: IBackupPolicyResolvers<ContextType>;
  BackupVersion?: IBackupVersionResolvers<ContextType>;
  BackupVersionPage?: IBackupVersionPageResolvers<ContextType>;
  Beneficiary?: IBeneficiaryResolvers<ContextType>;
  BeneficiaryOptions?: IBeneficiaryOptionsResolvers<ContextType>;
  BeneficiaryPolicy?: IBeneficiaryPolicyResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  Billing?: IBillingResolvers<ContextType>;
  BillingAddress?: IBillingAddressResolvers<ContextType>;
  CancelSecureUploadPayload?: ICancelSecureUploadPayloadResolvers<ContextType>;
  CancelSubscriptionPayload?: ICancelSubscriptionPayloadResolvers<ContextType>;
  ClientChangesRequested?: IClientChangesRequestedResolvers<ContextType>;
  ClientChangesRequestedPayload?: IClientChangesRequestedPayloadResolvers<ContextType>;
  Company?: ICompanyResolvers<ContextType>;
  CompleteSecureUploadPayload?: ICompleteSecureUploadPayloadResolvers<ContextType>;
  ConfirmEmailChangePayload?: IConfirmEmailChangePayloadResolvers<ContextType>;
  Contact?: IContactResolvers<ContextType>;
  CreateBackupAssetsPayload?: ICreateBackupAssetsPayloadResolvers<ContextType>;
  CreateBackupPayload?: ICreateBackupPayloadResolvers<ContextType>;
  CreateBeneficiaryPayload?: ICreateBeneficiaryPayloadResolvers<ContextType>;
  CreateContactPayload?: ICreateContactPayloadResolvers<ContextType>;
  CreateCustodianOperationPayload?: ICreateCustodianOperationPayloadResolvers<ContextType>;
  CreateCustodianProfilePayload?: ICreateCustodianProfilePayloadResolvers<ContextType>;
  CreateCustomAssetsPayload?: ICreateCustomAssetsPayloadResolvers<ContextType>;
  CreateCustomIntegrationPayload?: ICreateCustomIntegrationPayloadResolvers<ContextType>;
  CreateEscrowMemberPayload?: ICreateEscrowMemberPayloadResolvers<ContextType>;
  CreateEscrowPayload?: ICreateEscrowPayloadResolvers<ContextType>;
  CreateFeedbackPayload?: ICreateFeedbackPayloadResolvers<ContextType>;
  CreateOauthAssetsPayload?: ICreateOauthAssetsPayloadResolvers<ContextType>;
  CreateSaasEnvironmentPayload?: ICreateSaasEnvironmentPayloadResolvers<ContextType>;
  CreateSaasProviderPayload?: ICreateSaasProviderPayloadResolvers<ContextType>;
  CreateShareFileIntegrationPayload?: ICreateShareFileIntegrationPayloadResolvers<ContextType>;
  CreateSuggestedChangeEscrowPayload?: ICreateSuggestedChangeEscrowPayloadResolvers<ContextType>;
  CustodianAsset?: ICustodianAssetResolvers<ContextType>;
  CustodianIntegration?: ICustodianIntegrationResolvers<ContextType>;
  CustodianOperation?: ICustodianOperationResolvers<ContextType>;
  CustodianProfile?: ICustodianProfileResolvers<ContextType>;
  CustodianProfilePage?: ICustodianProfilePageResolvers<ContextType>;
  CustodianProfilePolicy?: ICustodianProfilePolicyResolvers<ContextType>;
  CustodianTermination?: ICustodianTerminationResolvers<ContextType>;
  CustomIntegration?: ICustomIntegrationResolvers<ContextType>;
  Depositor?: IDepositorResolvers<ContextType>;
  DepositorPolicy?: IDepositorPolicyResolvers<ContextType>;
  DestroyPayload?: IDestroyPayloadResolvers<ContextType>;
  DisableBackupAssetPayload?: IDisableBackupAssetPayloadResolvers<ContextType>;
  EditPayload?: IEditPayloadResolvers<ContextType>;
  EnableBackupAssetPayload?: IEnableBackupAssetPayloadResolvers<ContextType>;
  EnableBackupReportsPayload?: IEnableBackupReportsPayloadResolvers<ContextType>;
  EnableEscrowReportsPayload?: IEnableEscrowReportsPayloadResolvers<ContextType>;
  Escrow?: IEscrowResolvers<ContextType>;
  EscrowEvent?: IEscrowEventResolvers<ContextType>;
  EscrowInvitation?: IEscrowInvitationResolvers<ContextType>;
  EscrowInvitationPage?: IEscrowInvitationPageResolvers<ContextType>;
  EscrowInvitationPolicy?: IEscrowInvitationPolicyResolvers<ContextType>;
  EscrowMarkAsViewedPayload?: IEscrowMarkAsViewedPayloadResolvers<ContextType>;
  EscrowMember?: IEscrowMemberResolvers<ContextType>;
  EscrowMemberPage?: IEscrowMemberPageResolvers<ContextType>;
  EscrowMemberPolicy?: IEscrowMemberPolicyResolvers<ContextType>;
  EscrowPage?: IEscrowPageResolvers<ContextType>;
  EscrowPartyInterface?: IEscrowPartyInterfaceResolvers<ContextType>;
  EscrowPayload?: IEscrowPayloadResolvers<ContextType>;
  EscrowPolicy?: IEscrowPolicyResolvers<ContextType>;
  EscrowTermination?: IEscrowTerminationResolvers<ContextType>;
  EscrowTerminationPolicy?: IEscrowTerminationPolicyResolvers<ContextType>;
  EventInterface?: IEventInterfaceResolvers<ContextType>;
  ExclusiveOfferPlan?: IExclusiveOfferPlanResolvers<ContextType>;
  FinishAuthPayload?: IFinishAuthPayloadResolvers<ContextType>;
  GenerateAgreementPayload?: IGenerateAgreementPayloadResolvers<ContextType>;
  ISO8601Date?: GraphQLScalarType;
  ISO8601DateTime?: GraphQLScalarType;
  ImpersonatePayload?: IImpersonatePayloadResolvers<ContextType>;
  InitAuthPayload?: IInitAuthPayloadResolvers<ContextType>;
  InitSecureUploadPayload?: IInitSecureUploadPayloadResolvers<ContextType>;
  IntegrationAuth?: IIntegrationAuthResolvers<ContextType>;
  IntegrationInterface?: IIntegrationInterfaceResolvers<ContextType>;
  IntegrationInterfacePage?: IIntegrationInterfacePageResolvers<ContextType>;
  InviteOwnerBeneficiaryPayload?: IInviteOwnerBeneficiaryPayloadResolvers<ContextType>;
  InviteOwnerDepositorPayload?: IInviteOwnerDepositorPayloadResolvers<ContextType>;
  Invoice?: IInvoiceResolvers<ContextType>;
  InvoicePage?: IInvoicePageResolvers<ContextType>;
  InvoicePdf?: IInvoicePdfResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  LegacyRepo?: ILegacyRepoResolvers<ContextType>;
  LegacyRepoPage?: ILegacyRepoPageResolvers<ContextType>;
  LegalStatus?: ILegalStatusResolvers<ContextType>;
  MarkAsViewedPayload?: IMarkAsViewedPayloadResolvers<ContextType>;
  MigrateLegacyRepoPayload?: IMigrateLegacyRepoPayloadResolvers<ContextType>;
  MigrateLegacyReposPayload?: IMigrateLegacyReposPayloadResolvers<ContextType>;
  Mutation?: IMutationResolvers<ContextType>;
  OauthIntegration?: IOauthIntegrationResolvers<ContextType>;
  OauthReposConn?: IOauthReposConnResolvers<ContextType>;
  OauthRepository?: IOauthRepositoryResolvers<ContextType>;
  Payload?: IPayloadResolvers<ContextType>;
  PaymentMethod?: IPaymentMethodResolvers<ContextType>;
  Plan?: IPlanResolvers<ContextType>;
  Profile?: IProfileResolvers<ContextType>;
  Query?: IQueryResolvers<ContextType>;
  RejectEscrowInvitationPayload?: IRejectEscrowInvitationPayloadResolvers<ContextType>;
  RejectPayload?: IRejectPayloadResolvers<ContextType>;
  RemoveBackupAssetPayload?: IRemoveBackupAssetPayloadResolvers<ContextType>;
  RemoveBackupPayload?: IRemoveBackupPayloadResolvers<ContextType>;
  RemoveContactPayload?: IRemoveContactPayloadResolvers<ContextType>;
  RemoveCustodianOperationPayload?: IRemoveCustodianOperationPayloadResolvers<ContextType>;
  RemoveCustodianProfilePayload?: IRemoveCustodianProfilePayloadResolvers<ContextType>;
  RemoveEscrowMemberPayload?: IRemoveEscrowMemberPayloadResolvers<ContextType>;
  RemoveEscrowPayload?: IRemoveEscrowPayloadResolvers<ContextType>;
  RemovePayload?: IRemovePayloadResolvers<ContextType>;
  RemoveSaasEnvironmentPayload?: IRemoveSaasEnvironmentPayloadResolvers<ContextType>;
  RemoveSaasProviderPayload?: IRemoveSaasProviderPayloadResolvers<ContextType>;
  ReportData?: IReportDataResolvers<ContextType>;
  RequestBackupVersionDownloadPayload?: IRequestBackupVersionDownloadPayloadResolvers<ContextType>;
  RequestUpdatePaymentPagePayload?: IRequestUpdatePaymentPagePayloadResolvers<ContextType>;
  ReviewSuggestedChangeEscrowPayload?: IReviewSuggestedChangeEscrowPayloadResolvers<ContextType>;
  SaasEnvironment?: ISaasEnvironmentResolvers<ContextType>;
  SaasEnvironmentPage?: ISaasEnvironmentPageResolvers<ContextType>;
  SaasEnvironmentPolicy?: ISaasEnvironmentPolicyResolvers<ContextType>;
  SaasEnvironmentTermination?: ISaasEnvironmentTerminationResolvers<ContextType>;
  SaasProvider?: ISaasProviderResolvers<ContextType>;
  SendForReviewCustodianProfilePayload?: ISendForReviewCustodianProfilePayloadResolvers<ContextType>;
  ShareFileIntegration?: IShareFileIntegrationResolvers<ContextType>;
  Signature?: ISignatureResolvers<ContextType>;
  StartBackupPayload?: IStartBackupPayloadResolvers<ContextType>;
  StopBackupPayload?: IStopBackupPayloadResolvers<ContextType>;
  Subscription?: ISubscriptionResolvers<ContextType>;
  SuggestChange?: ISuggestChangeResolvers<ContextType>;
  SuggestChangePage?: ISuggestChangePageResolvers<ContextType>;
  SuggestedChangePolicy?: ISuggestedChangePolicyResolvers<ContextType>;
  SwitchToBackupPlanPayload?: ISwitchToBackupPlanPayloadResolvers<ContextType>;
  TerminateCustodianProfilePayload?: ITerminateCustodianProfilePayloadResolvers<ContextType>;
  TerminatePayload?: ITerminatePayloadResolvers<ContextType>;
  TerminateSaasEnvironmentPayload?: ITerminateSaasEnvironmentPayloadResolvers<ContextType>;
  TogglePayload?: ITogglePayloadResolvers<ContextType>;
  UpdateAgreementPayload?: IUpdateAgreementPayloadResolvers<ContextType>;
  UpdateBackupPayload?: IUpdateBackupPayloadResolvers<ContextType>;
  UpdateBeneficiaryPayload?: IUpdateBeneficiaryPayloadResolvers<ContextType>;
  UpdateBillingAddressPayload?: IUpdateBillingAddressPayloadResolvers<ContextType>;
  UpdateCompanyDetailsPayload?: IUpdateCompanyDetailsPayloadResolvers<ContextType>;
  UpdateContactPayload?: IUpdateContactPayloadResolvers<ContextType>;
  UpdateCustodianAssetsPayload?: IUpdateCustodianAssetsPayloadResolvers<ContextType>;
  UpdateCustodianOperationPayload?: IUpdateCustodianOperationPayloadResolvers<ContextType>;
  UpdateCustomIntegrationPayload?: IUpdateCustomIntegrationPayloadResolvers<ContextType>;
  UpdateDepositSettingsPayload?: IUpdateDepositSettingsPayloadResolvers<ContextType>;
  UpdateDepositorPayload?: IUpdateDepositorPayloadResolvers<ContextType>;
  UpdateEscrowInvitationPayload?: IUpdateEscrowInvitationPayloadResolvers<ContextType>;
  UpdateEscrowMemberPayload?: IUpdateEscrowMemberPayloadResolvers<ContextType>;
  UpdateEscrowPayload?: IUpdateEscrowPayloadResolvers<ContextType>;
  UpdateProfileInfoPayload?: IUpdateProfileInfoPayloadResolvers<ContextType>;
  UpdateSaasProviderPayload?: IUpdateSaasProviderPayloadResolvers<ContextType>;
  UpdateSuggestedChangeEscrowPayload?: IUpdateSuggestedChangeEscrowPayloadResolvers<ContextType>;
  User?: IUserResolvers<ContextType>;
  UserPage?: IUserPageResolvers<ContextType>;
};

export type IDirectiveResolvers<ContextType = any> = {
  client?: IClientDirectiveResolver<any, any, ContextType>;
};

export type IActivitiesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type IActivitiesQuery = {
  __typename?: 'Query';
  activities: {
    __typename?: 'ActivityPage';
    pagesCount: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'Activity';
      createdAt: any;
      eventType: string;
      id: string;
      klass: string;
      viewedAt?: any | null;
      payload:
        | { __typename?: 'AgreementPayload'; id: string }
        | {
            __typename?: 'EscrowPayload';
            id: string;
            depositor?: { __typename?: 'Depositor'; id: string; companyName?: string | null } | null;
            beneficiary?: { __typename?: 'Beneficiary'; id: string; companyName?: string | null } | null;
          };
    }>;
  };
};

export type IApproveAgreementMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IApproveAgreementMutation = {
  __typename?: 'Mutation';
  approveAgreement?: {
    __typename?: 'ApproveAgreementPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: {
      __typename?: 'Escrow';
      id: string;
      status: EscrowStatusEnum;
      agreement?: { __typename?: 'Agreement'; id: string } | null;
    } | null;
  } | null;
};

export type IGenerateAgreementMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IGenerateAgreementMutation = {
  __typename?: 'Mutation';
  generateAgreement?: {
    __typename?: 'GenerateAgreementPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: { __typename?: 'Escrow'; id: string } | null;
  } | null;
};

export type IUpdateAgreementMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  agreementInput: IAgreementInput;
}>;

export type IUpdateAgreementMutation = {
  __typename?: 'Mutation';
  updateAgreement?: {
    __typename?: 'UpdateAgreementPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: {
      __typename?: 'Escrow';
      id: string;
      agreementSettingsConfigured: boolean;
      agreement?: {
        __typename?: 'Agreement';
        agreementForm?: string | null;
        id: string;
        jurisdiction?: string | null;
        liability?: string | null;
        approvedByBeneficiary?: boolean | null;
        approvedByCodekeeper?: boolean | null;
        approvedByDepositor?: boolean | null;
        releaseBankruptcy: boolean;
        releaseCustom: boolean;
        releaseCustomConditions?: string | null;
        releaseInsolvency: boolean;
        releaseMaintenance: boolean;
        thirdPartyAgreement: boolean;
        updatedAt: any;
        legalContext: Array<LegalContextEnum>;
        legalContextOther?: string | null;
        depositType: Array<DepositTypeEnum>;
        depositTypeOther?: string | null;
        approved: boolean;
        policy: { __typename?: 'AgreementPolicy'; approve: boolean; generate: boolean };
      } | null;
    } | null;
  } | null;
};

export type IAssetsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  escrowId?: InputMaybe<Scalars['ID']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<AssetsOrderByEnum>;
}>;

export type IAssetsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'AssetPage';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'Asset';
      createdAt: any;
      depositSource?: string | null;
      depositStatus: DepositStatusEnum;
      id: string;
      latestDeposit?: any | null;
      lastUpdateCheck?: any | null;
      syncStatus?: SyncStatusEnum | null;
      type: AssetTypeEnum;
      updatedAt: any;
      removalStatus?: string | null;
      error?: string | null;
      size?: any | null;
      integration?:
        | { __typename?: 'CustomIntegration'; id: string; provider: string }
        | { __typename?: 'OauthIntegration'; id: string; provider: string }
        | { __typename?: 'ShareFileIntegration'; id: string; provider: string }
        | null;
      escrow: {
        __typename?: 'Escrow';
        id: string;
        depositor?: { __typename?: 'Depositor'; companyName?: string | null } | null;
        beneficiary?: { __typename?: 'Beneficiary'; companyName?: string | null } | null;
      };
    }>;
  };
};

export type IGetAssetsCountQueryVariables = Exact<{
  escrowId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type IGetAssetsCountQuery = { __typename?: 'Query'; assets: { __typename?: 'AssetPage'; nodesCount: number } };

export type IAssetsDepositSourceQueryVariables = Exact<{
  escrowId?: InputMaybe<Scalars['ID']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type IAssetsDepositSourceQuery = {
  __typename?: 'Query';
  assetsSource: {
    __typename?: 'AssetPage';
    nodes: Array<{ __typename?: 'Asset'; id: string; depositSource?: string | null; type: AssetTypeEnum }>;
  };
};

export type ICreateOauthAssetsMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
  assetsInput: Array<IOauthAssetCreateInput> | IOauthAssetCreateInput;
}>;

export type ICreateOauthAssetsMutation = {
  __typename?: 'Mutation';
  createOauthAssets?: {
    __typename?: 'CreateOauthAssetsPayload';
    success: boolean;
    errors?: Array<string> | null;
    assets?: Array<{
      __typename?: 'Asset';
      id: string;
      escrow: { __typename?: 'Escrow'; id: string; depositorConfigured: boolean };
    }> | null;
  } | null;
};

export type IDestroyAssetMutationVariables = Exact<{
  assetId: Scalars['ID']['input'];
}>;

export type IDestroyAssetMutation = {
  __typename?: 'Mutation';
  destroyAsset?: {
    __typename?: 'DestroyPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow: {
      __typename?: 'Escrow';
      id: string;
      depositStatus: DepositStatusEnum;
      policy: {
        __typename?: 'EscrowPolicy';
        activate: boolean;
        deferActivation: boolean;
        createDepositSuggestedChange: boolean;
      };
    };
  } | null;
};

export type IAssignBackupMembersMutationVariables = Exact<{
  backupId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type IAssignBackupMembersMutation = {
  __typename?: 'Mutation';
  assignBackupMembers?: {
    __typename?: 'AssignBackupMembersPayload';
    success: boolean;
    errors?: Array<string> | null;
    backupMembers?: Array<{
      __typename?: 'BackupMember';
      id: string;
      role: BackupMemberRoleEnum;
      user: { __typename?: 'User'; id: string; name?: string | null; email: string };
    }> | null;
  } | null;
};

export type IBackupQueryVariables = Exact<{
  backupId: Scalars['ID']['input'];
}>;

export type IBackupQuery = {
  __typename?: 'Query';
  me: { __typename?: 'Profile'; id: string; role?: UserRoleEnum | null };
  backup: {
    __typename?: 'Backup';
    id: string;
    status: BackupStatusEnum;
    storageRegion: string;
    disruptionNotification: DisruptionNotificationEnum;
    disruptionNotificationGracePeriod?: DisruptionNotificationGracePeriodEnum | null;
    versionsLimit?: number | null;
    updatedAt: any;
    reportsEnabled: boolean;
    reportsEnabledAt?: any | null;
    integration:
      | { __typename?: 'CustomIntegration'; id: string; provider: string; accountName?: string | null }
      | { __typename?: 'OauthIntegration'; id: string; provider: string; accountName?: string | null }
      | { __typename?: 'ShareFileIntegration'; id: string; provider: string; accountName?: string | null };
    backupAssets: Array<{
      __typename?: 'BackupAsset';
      id: string;
      lastBackupVersionId?: string | null;
      name: string;
      externalId?: string | null;
      status: BackupAssetStatusEnum;
      lastBackup?: any | null;
      lastUpdateCheck?: any | null;
      url?: string | null;
    }>;
    backupMembers: Array<{
      __typename?: 'BackupMember';
      id: string;
      role: BackupMemberRoleEnum;
      user: { __typename?: 'User'; id: string; name?: string | null; email: string };
    }>;
    creator?: { __typename?: 'User'; id: string } | null;
    policy: {
      __typename?: 'BackupPolicy';
      assignMembers: boolean;
      destroy: boolean;
      download: boolean;
      enableReports: boolean;
      start: boolean;
      stop: boolean;
      update: boolean;
    };
  };
};

export type IBackupVersionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type IBackupVersionsQuery = {
  __typename?: 'Query';
  backupVersions: {
    __typename?: 'BackupVersionPage';
    nodes: Array<{ __typename?: 'BackupVersion'; id: string; status: BackupVersionStatusEnum; createdAt: any }>;
  };
};

export type IBackupsQueryVariables = Exact<{
  orderBy?: InputMaybe<BackupsOrderByEnum>;
  order?: InputMaybe<QueryOrderEnum>;
  status?: InputMaybe<BackupStatusEnum>;
  query?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type IBackupsQuery = {
  __typename?: 'Query';
  backups: {
    __typename?: 'BackupPage';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'Backup';
      id: string;
      status: BackupStatusEnum;
      updatedAt: any;
      backupAssets: Array<{ __typename?: 'BackupAsset'; id: string }>;
      integration:
        | {
            __typename?: 'CustomIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'OauthIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'ShareFileIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          };
      backupMembers: Array<{
        __typename?: 'BackupMember';
        id: string;
        role: BackupMemberRoleEnum;
        user: { __typename?: 'User'; id: string };
      }>;
      policy: { __typename?: 'BackupPolicy'; destroy: boolean };
    }>;
  };
};

export type ICreateBackupMutationVariables = Exact<{
  input: ICreateBackupInput;
}>;

export type ICreateBackupMutation = {
  __typename?: 'Mutation';
  createBackup?: {
    __typename?: 'CreateBackupPayload';
    errors?: Array<string> | null;
    success: boolean;
    backup?: {
      __typename?: 'Backup';
      id: string;
      status: BackupStatusEnum;
      updatedAt: any;
      backupAssets: Array<{ __typename?: 'BackupAsset'; id: string }>;
      integration:
        | {
            __typename?: 'CustomIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'OauthIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'ShareFileIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          };
      backupMembers: Array<{
        __typename?: 'BackupMember';
        id: string;
        role: BackupMemberRoleEnum;
        user: { __typename?: 'User'; id: string };
      }>;
      policy: { __typename?: 'BackupPolicy'; destroy: boolean };
    } | null;
  } | null;
};

export type ICreateBackupAssetsMutationVariables = Exact<{
  input: ICreateBackupAssetsInput;
}>;

export type ICreateBackupAssetsMutation = {
  __typename?: 'Mutation';
  createBackupAssets?: {
    __typename?: 'CreateBackupAssetsPayload';
    errors?: Array<string> | null;
    success: boolean;
    backupAssets?: Array<{
      __typename?: 'BackupAsset';
      id: string;
      name: string;
      url?: string | null;
      status: BackupAssetStatusEnum;
      externalId?: string | null;
      lastBackupVersionId?: string | null;
      lastBackup?: any | null;
      lastUpdateCheck?: any | null;
    }> | null;
  } | null;
};

export type IDisableBackupAssetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IDisableBackupAssetMutation = {
  __typename?: 'Mutation';
  disableBackupAsset?: {
    __typename?: 'DisableBackupAssetPayload';
    errors?: Array<string> | null;
    success: boolean;
    backup: { __typename?: 'Backup'; id: string; status: BackupStatusEnum };
    backupAsset?: {
      __typename?: 'BackupAsset';
      id: string;
      name: string;
      externalId?: string | null;
      status: BackupAssetStatusEnum;
    } | null;
  } | null;
};

export type IEnableBackupAssetMutationVariables = Exact<{
  input: IEnableBackupAssetInput;
}>;

export type IEnableBackupAssetMutation = {
  __typename?: 'Mutation';
  enableBackupAsset?: {
    __typename?: 'EnableBackupAssetPayload';
    errors?: Array<string> | null;
    success: boolean;
    backupAsset?: {
      __typename?: 'BackupAsset';
      id: string;
      name: string;
      externalId?: string | null;
      status: BackupAssetStatusEnum;
      lastBackup?: any | null;
      lastBackupVersionId?: string | null;
      lastUpdateCheck?: any | null;
      url?: string | null;
    } | null;
  } | null;
};

export type IRemoveBackupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IRemoveBackupMutation = {
  __typename?: 'Mutation';
  removeBackup?: {
    __typename?: 'RemoveBackupPayload';
    errors?: Array<string> | null;
    success: boolean;
    backup: {
      __typename?: 'Backup';
      id: string;
      status: BackupStatusEnum;
      updatedAt: any;
      backupAssets: Array<{ __typename?: 'BackupAsset'; id: string }>;
      integration:
        | {
            __typename?: 'CustomIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'OauthIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'ShareFileIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          };
      backupMembers: Array<{
        __typename?: 'BackupMember';
        id: string;
        role: BackupMemberRoleEnum;
        user: { __typename?: 'User'; id: string };
      }>;
      policy: {
        __typename?: 'BackupPolicy';
        assignMembers: boolean;
        destroy: boolean;
        download: boolean;
        enableReports: boolean;
        start: boolean;
        stop: boolean;
        update: boolean;
      };
    };
  } | null;
};

export type IRemoveBackupAssetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IRemoveBackupAssetMutation = {
  __typename?: 'Mutation';
  removeBackupAsset?: {
    __typename?: 'RemoveBackupAssetPayload';
    errors?: Array<string> | null;
    success: boolean;
    backup: { __typename?: 'Backup'; id: string; status: BackupStatusEnum };
  } | null;
};

export type IRequestBackupVersionDownloadMutationVariables = Exact<{
  backupAssetId: Scalars['ID']['input'];
  backupVersionId: Scalars['ID']['input'];
}>;

export type IRequestBackupVersionDownloadMutation = {
  __typename?: 'Mutation';
  requestBackupVersionDownload?: {
    __typename?: 'RequestBackupVersionDownloadPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type IStartBackupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IStartBackupMutation = {
  __typename?: 'Mutation';
  startBackup?: {
    __typename?: 'StartBackupPayload';
    errors?: Array<string> | null;
    success: boolean;
    backup: {
      __typename?: 'Backup';
      id: string;
      status: BackupStatusEnum;
      updatedAt: any;
      backupAssets: Array<{ __typename?: 'BackupAsset'; id: string }>;
      integration:
        | {
            __typename?: 'CustomIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'OauthIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          }
        | {
            __typename?: 'ShareFileIntegration';
            id: string;
            accountName?: string | null;
            accountUrl?: string | null;
            provider: string;
          };
    };
  } | null;
};

export type IStopBackupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IStopBackupMutation = {
  __typename?: 'Mutation';
  stopBackup?: {
    __typename?: 'StopBackupPayload';
    errors?: Array<string> | null;
    success: boolean;
    backup: { __typename?: 'Backup'; id: string; status: BackupStatusEnum };
  } | null;
};

export type IUpdateBackupMutationVariables = Exact<{
  input: IUpdateBackupInput;
}>;

export type IUpdateBackupMutation = {
  __typename?: 'Mutation';
  updateBackup?: {
    __typename?: 'UpdateBackupPayload';
    errors?: Array<string> | null;
    success: boolean;
    backup: { __typename?: 'Backup'; id: string };
  } | null;
};

export type ICreateBeneficiaryMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  beneficiaryInputParams: IEscrowPartyInput;
}>;

export type ICreateBeneficiaryMutation = {
  __typename?: 'Mutation';
  createBeneficiary?: {
    __typename?: 'CreateBeneficiaryPayload';
    success: boolean;
    errors?: Array<string> | null;
    beneficiary?: {
      __typename?: 'Beneficiary';
      escrow: {
        __typename?: 'Escrow';
        id: string;
        beneficiaryConfigured: boolean;
        beneficiary?: {
          __typename?: 'Beneficiary';
          id: string;
          companyName?: string | null;
          companyWebsite?: string | null;
          companyRegistrationNumber?: string | null;
          city?: string | null;
          region?: string | null;
          postalCode?: string | null;
          country?: string | null;
          street?: string | null;
          streetNumber?: string | null;
        } | null;
        policy: { __typename?: 'EscrowPolicy'; createBeneficiary: boolean };
      };
    } | null;
  } | null;
};

export type IInviteOwnerBeneficiaryMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  administrativeInputUser: IUserInput;
}>;

export type IInviteOwnerBeneficiaryMutation = {
  __typename?: 'Mutation';
  inviteOwnerBeneficiary?: {
    __typename?: 'InviteOwnerBeneficiaryPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrowInvitation: {
      __typename?: 'EscrowInvitation';
      status: InvitationStatusEnum;
      companyName?: string | null;
      id: string;
      partyType: string;
      escrow: { __typename?: 'Escrow'; id: string; beneficiaryConfigured: boolean };
      user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
      policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
    };
  } | null;
};

export type IUpdateBeneficiaryMutationVariables = Exact<{
  beneficiaryId: Scalars['ID']['input'];
  beneficiaryInputParams: IEscrowPartyInput;
}>;

export type IUpdateBeneficiaryMutation = {
  __typename?: 'Mutation';
  updateBeneficiary?: {
    __typename?: 'UpdateBeneficiaryPayload';
    success: boolean;
    errors?: Array<string> | null;
    beneficiary: {
      __typename?: 'Beneficiary';
      city?: string | null;
      companyName?: string | null;
      companyRegistrationNumber?: string | null;
      companyWebsite?: string | null;
      country?: string | null;
      id: string;
      postalCode?: string | null;
      region?: string | null;
      street?: string | null;
      streetNumber?: string | null;
    };
  } | null;
};

export type IAcceptExclusiveOfferMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type IAcceptExclusiveOfferMutation = {
  __typename?: 'Mutation';
  acceptExclusiveOffer?: {
    __typename?: 'AcceptExclusiveOfferPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type IApplyCouponMutationVariables = Exact<{
  couponCode: Scalars['String']['input'];
}>;

export type IApplyCouponMutation = {
  __typename?: 'Mutation';
  applyCoupon?: {
    __typename?: 'ApplyCouponPayload';
    success: boolean;
    errors?: Array<string> | null;
    company: { __typename?: 'Company'; id: string; subscriptionExclusiveCouponAppliedAt?: any | null };
  } | null;
};

export type IBillingQueryVariables = Exact<{ [key: string]: never }>;

export type IBillingQuery = {
  __typename?: 'Query';
  billing?: {
    __typename?: 'Billing';
    subscriptions?: Array<{
      __typename?: 'Subscription';
      billingPeriod: number;
      billingPeriodUnit: string;
      currencyCode: string;
      coupons?: Array<string> | null;
      status: string;
      total: number;
      plans: Array<{
        __typename?: 'Plan';
        id: string;
        name: string;
        currencyCode: string;
        period: number;
        periodUnit: string;
        price: number;
      }>;
      addons: Array<{
        __typename?: 'Addon';
        name: string;
        price: number;
        currencyCode: string;
        quantity: number;
        unitPrice: number;
        periodUnit: string;
      }>;
    }> | null;
    paymentMethod?: {
      __typename?: 'PaymentMethod';
      cardLast4?: string | null;
      cardType?: string | null;
      status: string;
    } | null;
    billingAddress?: {
      __typename?: 'BillingAddress';
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressLine3?: string | null;
      city?: string | null;
      companyName?: string | null;
      country?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      state?: string | null;
      zip?: string | null;
      email?: string | null;
      companyRegistrationNumber?: string | null;
    } | null;
  } | null;
};

export type ICanCancelSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type ICanCancelSubscriptionQuery = {
  __typename?: 'Query';
  invoices: { __typename?: 'InvoicePage'; nodes: Array<{ __typename?: 'Invoice'; id: string }> };
  terminatedEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
  allEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
  removedBackups: { __typename?: 'BackupPage'; nodesCount: number };
  allBackups: { __typename?: 'BackupPage'; nodesCount: number };
  allSaasEnvironments: { __typename?: 'SaasEnvironmentPage'; nodesCount: number };
  terminatedSaasEnvironments: { __typename?: 'SaasEnvironmentPage'; nodesCount: number };
};

export type ICancelSubscriptionMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ICancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription?: {
    __typename?: 'CancelSubscriptionPayload';
    success: boolean;
    errors?: Array<string> | null;
    company: { __typename?: 'Company'; id: string; subscriptionCancellationRequestedAt?: any | null };
  } | null;
};

export type IExclusiveOfferPlansQueryVariables = Exact<{ [key: string]: never }>;

export type IExclusiveOfferPlansQuery = {
  __typename?: 'Query';
  exclusiveOfferPlans?: Array<{
    __typename?: 'ExclusiveOfferPlan';
    id: string;
    currencyCode: string;
    discounted: boolean;
    discountedPrice?: number | null;
    name: string;
    periodUnit: string;
    price: number;
    status: string;
  }> | null;
};

export type ISubscriptionNextDateQueryVariables = Exact<{ [key: string]: never }>;

export type ISubscriptionNextDateQuery = {
  __typename?: 'Query';
  billing?: {
    __typename?: 'Billing';
    subscriptions?: Array<{ __typename?: 'Subscription'; nextBillingAt?: any | null }> | null;
  } | null;
};

export type ISwitchToBackupPlanMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ISwitchToBackupPlanMutation = {
  __typename?: 'Mutation';
  switchToBackupPlan?: {
    __typename?: 'SwitchToBackupPlanPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type ICompanyQueryVariables = Exact<{ [key: string]: never }>;

export type ICompanyQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    city?: string | null;
    invitationStatus: CompanyInvitationStatusEnum;
    companyName?: string | null;
    companyRegistration?: string | null;
    companyWebsite?: string | null;
    country?: string | null;
    id: string;
    postalCode?: string | null;
    region?: string | null;
    street?: string | null;
    streetNumber?: string | null;
    subscriptionCancellationRequestedAt?: any | null;
    subscriptionExclusiveCouponAppliedAt?: any | null;
    updateableBilling: boolean;
    users: Array<{
      __typename?: 'User';
      role: UserRoleEnum;
      email: string;
      id: string;
      name?: string | null;
      phone?: string | null;
    }>;
  };
};

export type ICompanyUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ICompanyUsersQuery = {
  __typename?: 'Query';
  companyUsers: {
    __typename?: 'Company';
    users: Array<{ __typename?: 'User'; id: string; role: UserRoleEnum; email: string; name?: string | null }>;
  };
};

export type IIsUpdateableBillingQueryVariables = Exact<{ [key: string]: never }>;

export type IIsUpdateableBillingQuery = {
  __typename?: 'Query';
  company: { __typename?: 'Company'; updateableBilling: boolean };
};

export type IUpdateCompanyDetailsMutationVariables = Exact<{
  company: ICompanyInput;
}>;

export type IUpdateCompanyDetailsMutation = {
  __typename?: 'Mutation';
  updateCompanyDetails?: {
    __typename?: 'UpdateCompanyDetailsPayload';
    success: boolean;
    errors?: Array<string> | null;
    company?: {
      __typename?: 'Company';
      city?: string | null;
      companyName?: string | null;
      companyRegistration?: string | null;
      companyWebsite?: string | null;
      country?: string | null;
      id: string;
      postalCode?: string | null;
      region?: string | null;
      street?: string | null;
      invitationStatus: CompanyInvitationStatusEnum;
      streetNumber?: string | null;
    } | null;
  } | null;
};

export type IContactFragmentFragment = {
  __typename?: 'Contact';
  id: string;
  email: string;
  name: string;
  phone?: string | null;
  signatory: boolean;
  contactType: ContactTypeEnum;
};

export type ICreateContactMutationVariables = Exact<{
  escrowPartyId: Scalars['ID']['input'];
  contactParams: IContactCreateInput;
}>;

export type ICreateContactMutation = {
  __typename?: 'Mutation';
  createContact?: {
    __typename?: 'CreateContactPayload';
    success: boolean;
    errors?: Array<string> | null;
    contact?: {
      __typename?: 'Contact';
      id: string;
      email: string;
      name: string;
      phone?: string | null;
      signatory: boolean;
      contactType: ContactTypeEnum;
      escrow: {
        __typename?: 'Escrow';
        id: string;
        depositorConfigured: boolean;
        beneficiaryConfigured: boolean;
        agreement?: { __typename?: 'Agreement'; policy: { __typename?: 'AgreementPolicy'; generate: boolean } } | null;
      };
    } | null;
  } | null;
};

export type IRemoveContactMutationVariables = Exact<{
  contactId: Scalars['ID']['input'];
}>;

export type IRemoveContactMutation = {
  __typename?: 'Mutation';
  removeContact?: {
    __typename?: 'RemoveContactPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: {
      __typename?: 'Escrow';
      id: string;
      depositorConfigured: boolean;
      beneficiaryConfigured: boolean;
      agreement?: { __typename?: 'Agreement'; policy: { __typename?: 'AgreementPolicy'; generate: boolean } } | null;
    } | null;
  } | null;
};

export type IUpdateContactMutationVariables = Exact<{
  contactId: Scalars['ID']['input'];
  contactParams: IContactUpdateInput;
}>;

export type IUpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact?: {
    __typename?: 'UpdateContactPayload';
    success: boolean;
    errors?: Array<string> | null;
    contact?: {
      __typename?: 'Contact';
      id: string;
      email: string;
      name: string;
      phone?: string | null;
      signatory: boolean;
      contactType: ContactTypeEnum;
      escrow: {
        __typename?: 'Escrow';
        id: string;
        depositorConfigured: boolean;
        beneficiaryConfigured: boolean;
        agreement?: { __typename?: 'Agreement'; policy: { __typename?: 'AgreementPolicy'; generate: boolean } } | null;
      };
    } | null;
  } | null;
};

export type ICreateCustodianOperationMutationVariables = Exact<{
  custodianProfileId: Scalars['ID']['input'];
  custodianOperationParams: ICustodianOperationCreateInput;
}>;

export type ICreateCustodianOperationMutation = {
  __typename?: 'Mutation';
  createCustodianOperation?: {
    __typename?: 'CreateCustodianOperationPayload';
    success: boolean;
    errors?: Array<string> | null;
    custodianOperation?: {
      __typename?: 'CustodianOperation';
      id: string;
      custodianServiceEvents: Array<CustodianOperationCustodianServiceEventEnum>;
      custodianServiceEventsCustom?: string | null;
      dataRetention?: number | null;
      endOfRetentionPeriod: CustodianOperationEndOfRetentionPeriodEnum;
      endOfRetentionPeriodCustom?: string | null;
      operationType: CustodianOperationTypeEnum;
      operationTypeCustom?: string | null;
      regulatoryRequirements: Array<CustodianOperationRegulatoryRequirementsEnum>;
      regulatoryRequirementsCustom?: string | null;
    } | null;
  } | null;
};

export type ICreateCustodianProfileMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ICreateCustodianProfileMutation = {
  __typename?: 'Mutation';
  createCustodianProfile?: {
    __typename?: 'CreateCustodianProfilePayload';
    errors?: Array<string> | null;
    success: boolean;
    custodianProfile?: {
      __typename?: 'CustodianProfile';
      id: string;
      name: string;
      status: CustodianProfileStatusEnum;
      custodianAssets: Array<{ __typename?: 'CustodianAsset'; id: string }>;
      custodianIntegrations: Array<{ __typename?: 'CustodianIntegration'; id: string }>;
      custodianOperations: Array<{ __typename?: 'CustodianOperation'; id: string }>;
      custodianTermination?: {
        __typename?: 'CustodianTermination';
        id: string;
        status: CustodianTerminationStatusEnum;
      } | null;
      policy: {
        __typename?: 'CustodianProfilePolicy';
        activate: boolean;
        remove: boolean;
        requestTermination: boolean;
        submitForReview: boolean;
        update: boolean;
      };
    } | null;
  } | null;
};

export type ICustodianProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ICustodianProfileQuery = {
  __typename?: 'Query';
  custodianProfile?: {
    __typename?: 'CustodianProfile';
    id: string;
    name: string;
    status: CustodianProfileStatusEnum;
    custodianAssets: Array<{
      __typename?: 'CustodianAsset';
      id: string;
      name: string;
      operationalStatus: CustodianAssetOperationalStatusEnum;
      externalId: string;
      custodianStatus: CustodianAssetCustodianStatusEnum;
      updatedAt: any;
      custodianIntegration: {
        __typename?: 'CustodianIntegration';
        id: string;
        integration: { __typename?: 'OauthIntegration'; id: string; provider: string };
      };
    }>;
    custodianIntegrations: Array<{
      __typename?: 'CustodianIntegration';
      id: string;
      status: CustodianIntegrationStatusEnum;
      integration: { __typename?: 'OauthIntegration'; id: string; provider: string; accountName?: string | null };
      custodianAssets: Array<{
        __typename?: 'CustodianAsset';
        id: string;
        name: string;
        operationalStatus: CustodianAssetOperationalStatusEnum;
        externalId: string;
        custodianStatus: CustodianAssetCustodianStatusEnum;
      }>;
    }>;
    custodianOperations: Array<{
      __typename?: 'CustodianOperation';
      id: string;
      custodianServiceEvents: Array<CustodianOperationCustodianServiceEventEnum>;
      custodianServiceEventsCustom?: string | null;
      dataRetention?: number | null;
      endOfRetentionPeriod: CustodianOperationEndOfRetentionPeriodEnum;
      endOfRetentionPeriodCustom?: string | null;
      operationType: CustodianOperationTypeEnum;
      operationTypeCustom?: string | null;
      regulatoryRequirements: Array<CustodianOperationRegulatoryRequirementsEnum>;
      regulatoryRequirementsCustom?: string | null;
    }>;
    custodianTermination?: {
      __typename?: 'CustodianTermination';
      id: string;
      status: CustodianTerminationStatusEnum;
    } | null;
    policy: {
      __typename?: 'CustodianProfilePolicy';
      activate: boolean;
      remove: boolean;
      requestTermination: boolean;
      submitForReview: boolean;
      update: boolean;
    };
    creator: { __typename?: 'User'; id: string; name?: string | null };
  } | null;
};

export type ICustodianProfilesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ICustodianProfilesQuery = {
  __typename?: 'Query';
  custodianProfiles: {
    __typename?: 'CustodianProfilePage';
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'CustodianProfile';
      id: string;
      name: string;
      status: CustodianProfileStatusEnum;
      custodianAssets: Array<{ __typename?: 'CustodianAsset'; id: string }>;
      custodianIntegrations: Array<{ __typename?: 'CustodianIntegration'; id: string }>;
      custodianOperations: Array<{ __typename?: 'CustodianOperation'; id: string }>;
      custodianTermination?: {
        __typename?: 'CustodianTermination';
        id: string;
        status: CustodianTerminationStatusEnum;
      } | null;
      policy: { __typename?: 'CustodianProfilePolicy'; remove: boolean };
    }>;
  };
};

export type IRemoveCustodianOperationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IRemoveCustodianOperationMutation = {
  __typename?: 'Mutation';
  removeCustodianOperation?: {
    __typename?: 'RemoveCustodianOperationPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type IRemoveCustodianProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IRemoveCustodianProfileMutation = {
  __typename?: 'Mutation';
  removeCustodianProfile?: {
    __typename?: 'RemoveCustodianProfilePayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type ISendForReviewCustodianProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ISendForReviewCustodianProfileMutation = {
  __typename?: 'Mutation';
  sendForReviewCustodianProfile?: {
    __typename?: 'SendForReviewCustodianProfilePayload';
    success: boolean;
    errors?: Array<string> | null;
    custodianProfile: {
      __typename?: 'CustodianProfile';
      id: string;
      status: CustodianProfileStatusEnum;
      policy: {
        __typename?: 'CustodianProfilePolicy';
        activate: boolean;
        remove: boolean;
        requestTermination: boolean;
        submitForReview: boolean;
        update: boolean;
      };
    };
  } | null;
};

export type ITerminateCustodianProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ITerminateCustodianProfileMutation = {
  __typename?: 'Mutation';
  terminateCustodianProfile?: {
    __typename?: 'TerminateCustodianProfilePayload';
    success: boolean;
    errors?: Array<string> | null;
    custodianTermination?: {
      __typename?: 'CustodianTermination';
      id: string;
      custodianProfile: {
        __typename?: 'CustodianProfile';
        id: string;
        custodianTermination?: {
          __typename?: 'CustodianTermination';
          id: string;
          status: CustodianTerminationStatusEnum;
        } | null;
      };
    } | null;
  } | null;
};

export type IUpdateCustodianAssetsMutationVariables = Exact<{
  custodianProfileId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
  assetsInput: Array<ICustodianAssetCreateInput> | ICustodianAssetCreateInput;
}>;

export type IUpdateCustodianAssetsMutation = {
  __typename?: 'Mutation';
  updateCustodianAssets?: {
    __typename?: 'UpdateCustodianAssetsPayload';
    success: boolean;
    errors?: Array<string> | null;
    custodianAssets?: Array<{ __typename?: 'CustodianAsset'; id: string; name: string }> | null;
  } | null;
};

export type IUpdateCustodianOperationMutationVariables = Exact<{
  custodianOperationId: Scalars['ID']['input'];
  custodianOperationParams: ICustodianOperationUpdateInput;
}>;

export type IUpdateCustodianOperationMutation = {
  __typename?: 'Mutation';
  updateCustodianOperation?: {
    __typename?: 'UpdateCustodianOperationPayload';
    success: boolean;
    errors?: Array<string> | null;
    custodianOperation: {
      __typename?: 'CustodianOperation';
      id: string;
      custodianServiceEvents: Array<CustodianOperationCustodianServiceEventEnum>;
      custodianServiceEventsCustom?: string | null;
      dataRetention?: number | null;
      endOfRetentionPeriod: CustodianOperationEndOfRetentionPeriodEnum;
      endOfRetentionPeriodCustom?: string | null;
      operationType: CustodianOperationTypeEnum;
      operationTypeCustom?: string | null;
      regulatoryRequirements: Array<CustodianOperationRegulatoryRequirementsEnum>;
      regulatoryRequirementsCustom?: string | null;
    };
  } | null;
};

export type IInviteOwnerDepositorMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  administrativeInputUser: IUserInput;
}>;

export type IInviteOwnerDepositorMutation = {
  __typename?: 'Mutation';
  inviteOwnerDepositor?: {
    __typename?: 'InviteOwnerDepositorPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrowInvitation: {
      __typename?: 'EscrowInvitation';
      status: InvitationStatusEnum;
      companyName?: string | null;
      id: string;
      partyType: string;
      escrow: { __typename?: 'Escrow'; id: string; depositorConfigured: boolean };
      user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
      policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
    };
  } | null;
};

export type IUpdateDepositSettingsMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  depositSettings: IDepositSettingsInput;
}>;

export type IUpdateDepositSettingsMutation = {
  __typename?: 'Mutation';
  updateDepositSettings?: {
    __typename?: 'UpdateDepositSettingsPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: {
      __typename?: 'Escrow';
      id: string;
      showDepositInformation: boolean;
      beneficiaryDepositNotification: DepositNotificationEnum;
      beneficiaryDisruptionNotification: DisruptionNotificationEnum;
      beneficiaryDisruptionNotificationGracePeriod?: DisruptionNotificationGracePeriodEnum | null;
      depositorDepositNotification: DepositNotificationEnum;
      depositorDisruptionNotification: DisruptionNotificationEnum;
      depositorDisruptionNotificationGracePeriod?: DisruptionNotificationGracePeriodEnum | null;
      storageRegion?: string | null;
    } | null;
  } | null;
};

export type IUpdateDepositorMutationVariables = Exact<{
  depositorId: Scalars['ID']['input'];
  depositorInputParams: IEscrowPartyInput;
}>;

export type IUpdateDepositorMutation = {
  __typename?: 'Mutation';
  updateDepositor?: {
    __typename?: 'UpdateDepositorPayload';
    success: boolean;
    errors?: Array<string> | null;
    depositor: {
      __typename?: 'Depositor';
      city?: string | null;
      companyName?: string | null;
      companyRegistrationNumber?: string | null;
      companyWebsite?: string | null;
      country?: string | null;
      id: string;
      postalCode?: string | null;
      region?: string | null;
      street?: string | null;
      streetNumber?: string | null;
    };
  } | null;
};

export type IAcceptEscrowInvitationMutationVariables = Exact<{
  escrowInvitationId: Scalars['ID']['input'];
}>;

export type IAcceptEscrowInvitationMutation = {
  __typename?: 'Mutation';
  acceptEscrowInvitation?: {
    __typename?: 'AcceptEscrowInvitationPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrowInvitation: {
      __typename?: 'EscrowInvitation';
      status: InvitationStatusEnum;
      companyName?: string | null;
      id: string;
      partyType: string;
      user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
      policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
    };
  } | null;
};

export type IAcceptEscrowTerminationMutationVariables = Exact<{
  escrowTerminationId: Scalars['ID']['input'];
}>;

export type IAcceptEscrowTerminationMutation = {
  __typename?: 'Mutation';
  acceptEscrowTermination?: { __typename?: 'AcceptPayload'; success: boolean; errors?: Array<string> | null } | null;
};

export type IActivateEscrowMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IActivateEscrowMutation = {
  __typename?: 'Mutation';
  activateEscrow?: {
    __typename?: 'ActivateEscrowPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: { __typename?: 'Escrow'; id: string; status: EscrowStatusEnum } | null;
  } | null;
};

export type ICreateEscrowMutationVariables = Exact<{
  escrowRole: EscrowPartyTypeEnum;
  agreementForm: AgreementFormEnum;
}>;

export type ICreateEscrowMutation = {
  __typename?: 'Mutation';
  createEscrow?: {
    __typename?: 'CreateEscrowPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: {
      __typename?: 'Escrow';
      id: string;
      currentCompanyRole: EscrowPartyTypeEnum;
      changesRequested: boolean;
      startedBy: EscrowPartyTypeEnum;
      depositStatus: DepositStatusEnum;
      status: EscrowStatusEnum;
      agreement?: {
        __typename?: 'Agreement';
        id: string;
        approvedByBeneficiary?: boolean | null;
        approvedByDepositor?: boolean | null;
        approvedByCodekeeper?: boolean | null;
      } | null;
      escrowInvitation?: {
        __typename?: 'EscrowInvitation';
        status: InvitationStatusEnum;
        companyName?: string | null;
        id: string;
        partyType: string;
        user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
        policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
      } | null;
      escrowTermination?: {
        __typename?: 'EscrowTermination';
        status: EscrowTerminationStatusEnum;
        terminateAt?: any | null;
      } | null;
      beneficiary?: { __typename?: 'Beneficiary'; companyName?: string | null } | null;
      depositor?: { __typename?: 'Depositor'; companyName?: string | null } | null;
    } | null;
  } | null;
};

export type IEscrowInvitationFieldsFragment = {
  __typename?: 'EscrowInvitation';
  status: InvitationStatusEnum;
  companyName?: string | null;
  id: string;
  partyType: string;
  user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
  policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
};

export type IEscrowTerminationFieldsFragment = {
  __typename?: 'EscrowTermination';
  status: EscrowTerminationStatusEnum;
  requestedAt: any;
  acceptedAt?: any | null;
  adminReviewedAt?: any | null;
  requesterParty: EscrowPartyTypeEnum;
  id: string;
  terminateAt?: any | null;
  policy: { __typename?: 'EscrowTerminationPolicy'; accept: boolean; reject: boolean };
};

export type IEscrowAgreementFieldsFragment = {
  __typename?: 'Agreement';
  agreementForm?: string | null;
  id: string;
  jurisdiction?: string | null;
  liability?: string | null;
  approvedByBeneficiary?: boolean | null;
  approvedByCodekeeper?: boolean | null;
  approvedByDepositor?: boolean | null;
  releaseBankruptcy: boolean;
  releaseCustom: boolean;
  releaseCustomConditions?: string | null;
  releaseInsolvency: boolean;
  releaseMaintenance: boolean;
  thirdPartyAgreement: boolean;
  updatedAt: any;
  legalContext: Array<LegalContextEnum>;
  legalContextOther?: string | null;
  depositType: Array<DepositTypeEnum>;
  depositTypeOther?: string | null;
  approved: boolean;
  policy: { __typename?: 'AgreementPolicy'; approve: boolean; generate: boolean };
};

export type IEscrowPolicyFragmentFragment = {
  __typename?: 'EscrowPolicy';
  activate: boolean;
  bipartiteUpdate: boolean;
  createBeneficiary: boolean;
  deferActivation: boolean;
  depositAssets: boolean;
  enableReports: boolean;
  inviteBeneficiary: boolean;
  inviteDepositor: boolean;
  read: boolean;
  remove: boolean;
  requestTermination: boolean;
  update: boolean;
  write: boolean;
  createAgreementSuggestedChange: boolean;
  createBeneficiarySuggestedChange: boolean;
  createDepositSuggestedChange: boolean;
  createDepositorSuggestedChange: boolean;
  createNotificationSuggestedChange: boolean;
};

export type IDepositorPolicyFragmentFragment = {
  __typename?: 'DepositorPolicy';
  readEscrowMembers: boolean;
  update: boolean;
  createEscrowMembers: boolean;
};

export type IBeneficiaryPolicyFragmentFragment = {
  __typename?: 'BeneficiaryPolicy';
  readEscrowMembers: boolean;
  update: boolean;
  createEscrowMembers: boolean;
};

export type IEscrowQueryVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IEscrowQuery = {
  __typename?: 'Query';
  escrow: {
    __typename?: 'Escrow';
    status: EscrowStatusEnum;
    activationNotification: boolean;
    agreementSettingsConfigured: boolean;
    id: string;
    currentCompanyRole: EscrowPartyTypeEnum;
    beneficiaryConfigured: boolean;
    depositorConfigured: boolean;
    startedBy: EscrowPartyTypeEnum;
    beneficiaryDepositNotification: DepositNotificationEnum;
    depositorDepositNotification: DepositNotificationEnum;
    depositStatus: DepositStatusEnum;
    beneficiaryDisruptionNotification: DisruptionNotificationEnum;
    beneficiaryDisruptionNotificationGracePeriod?: DisruptionNotificationGracePeriodEnum | null;
    depositorDisruptionNotification: DisruptionNotificationEnum;
    depositorDisruptionNotificationGracePeriod?: DisruptionNotificationGracePeriodEnum | null;
    storageRegion?: string | null;
    showDepositInformation: boolean;
    pendingActivation: boolean;
    reportsEnabled: boolean;
    reportsEnabledAt?: any | null;
    escrowInvitation?: {
      __typename?: 'EscrowInvitation';
      status: InvitationStatusEnum;
      companyName?: string | null;
      id: string;
      partyType: string;
      user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
      policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
    } | null;
    escrowTermination?: {
      __typename?: 'EscrowTermination';
      status: EscrowTerminationStatusEnum;
      requestedAt: any;
      acceptedAt?: any | null;
      adminReviewedAt?: any | null;
      requesterParty: EscrowPartyTypeEnum;
      id: string;
      terminateAt?: any | null;
      policy: { __typename?: 'EscrowTerminationPolicy'; accept: boolean; reject: boolean };
    } | null;
    agreement?: {
      __typename?: 'Agreement';
      agreementForm?: string | null;
      id: string;
      jurisdiction?: string | null;
      liability?: string | null;
      approvedByBeneficiary?: boolean | null;
      approvedByCodekeeper?: boolean | null;
      approvedByDepositor?: boolean | null;
      releaseBankruptcy: boolean;
      releaseCustom: boolean;
      releaseCustomConditions?: string | null;
      releaseInsolvency: boolean;
      releaseMaintenance: boolean;
      thirdPartyAgreement: boolean;
      updatedAt: any;
      legalContext: Array<LegalContextEnum>;
      legalContextOther?: string | null;
      depositType: Array<DepositTypeEnum>;
      depositTypeOther?: string | null;
      approved: boolean;
      policy: { __typename?: 'AgreementPolicy'; approve: boolean; generate: boolean };
    } | null;
    beneficiary?: {
      __typename?: 'Beneficiary';
      city?: string | null;
      companyName?: string | null;
      companyRegistrationNumber?: string | null;
      companyWebsite?: string | null;
      country?: string | null;
      id: string;
      postalCode?: string | null;
      region?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      contacts: Array<{
        __typename?: 'Contact';
        id: string;
        email: string;
        name: string;
        phone?: string | null;
        signatory: boolean;
        contactType: ContactTypeEnum;
      }>;
      policy: {
        __typename?: 'BeneficiaryPolicy';
        readEscrowMembers: boolean;
        update: boolean;
        createEscrowMembers: boolean;
      };
    } | null;
    depositor?: {
      __typename?: 'Depositor';
      city?: string | null;
      companyName?: string | null;
      companyRegistrationNumber?: string | null;
      companyWebsite?: string | null;
      country?: string | null;
      id: string;
      postalCode?: string | null;
      region?: string | null;
      street?: string | null;
      streetNumber?: string | null;
      contacts: Array<{
        __typename?: 'Contact';
        id: string;
        email: string;
        name: string;
        phone?: string | null;
        signatory: boolean;
        contactType: ContactTypeEnum;
      }>;
      policy: {
        __typename?: 'DepositorPolicy';
        readEscrowMembers: boolean;
        update: boolean;
        createEscrowMembers: boolean;
      };
    } | null;
    policy: {
      __typename?: 'EscrowPolicy';
      activate: boolean;
      bipartiteUpdate: boolean;
      createBeneficiary: boolean;
      deferActivation: boolean;
      depositAssets: boolean;
      enableReports: boolean;
      inviteBeneficiary: boolean;
      inviteDepositor: boolean;
      read: boolean;
      remove: boolean;
      requestTermination: boolean;
      update: boolean;
      write: boolean;
      createAgreementSuggestedChange: boolean;
      createBeneficiarySuggestedChange: boolean;
      createDepositSuggestedChange: boolean;
      createDepositorSuggestedChange: boolean;
      createNotificationSuggestedChange: boolean;
    };
  };
};

export type IEscrowAgreementTermsQueryVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IEscrowAgreementTermsQuery = {
  __typename?: 'Query';
  escrowTerms: {
    __typename?: 'Escrow';
    agreement?: {
      __typename?: 'Agreement';
      terms?: string | null;
      termsDownloadPdfUrl?: string | null;
      termsSignedDownloadPdfUrl?: string | null;
      termsDownloadDocxUrl?: string | null;
    } | null;
  };
};

export type IEscrowInvitationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type IEscrowInvitationsQuery = {
  __typename?: 'Query';
  escrowInvitations?: {
    __typename?: 'EscrowInvitationPage';
    pagesCount: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'EscrowInvitation';
      status: InvitationStatusEnum;
      companyName?: string | null;
      id: string;
    }>;
  } | null;
};

export type IEscrowsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<QueryOrderEnum>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  statusFilter?: InputMaybe<EscrowStatusFilterEnum>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
  actionableItems?: InputMaybe<Scalars['Boolean']['input']>;
  actionItemsFilter?: InputMaybe<ActionItemFilterEnum>;
  currentCompanyRole?: InputMaybe<EscrowPartyTypeEnum>;
}>;

export type IEscrowsQuery = {
  __typename?: 'Query';
  escrows: {
    __typename?: 'EscrowPage';
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'Escrow';
      id: string;
      currentCompanyRole: EscrowPartyTypeEnum;
      changesRequested: boolean;
      startedBy: EscrowPartyTypeEnum;
      depositStatus: DepositStatusEnum;
      status: EscrowStatusEnum;
      agreement?: {
        __typename?: 'Agreement';
        id: string;
        approvedByBeneficiary?: boolean | null;
        approvedByDepositor?: boolean | null;
        approvedByCodekeeper?: boolean | null;
        agreementForm?: string | null;
        policy: { __typename?: 'AgreementPolicy'; approve: boolean };
      } | null;
      escrowInvitation?: {
        __typename?: 'EscrowInvitation';
        status: InvitationStatusEnum;
        companyName?: string | null;
        id: string;
        partyType: string;
        user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
        policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
      } | null;
      escrowTermination?: {
        __typename?: 'EscrowTermination';
        status: EscrowTerminationStatusEnum;
        terminateAt?: any | null;
      } | null;
      beneficiary?: { __typename?: 'Beneficiary'; companyName?: string | null } | null;
      depositor?: { __typename?: 'Depositor'; companyName?: string | null } | null;
      policy: { __typename?: 'EscrowPolicy'; remove: boolean };
    }>;
  };
};

export type IEscrowsCountQueryVariables = Exact<{ [key: string]: never }>;

export type IEscrowsCountQuery = {
  __typename?: 'Query';
  onboardingEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
  terminatedEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
  signedEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
  allEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
  invited: { __typename?: 'EscrowPage'; nodesCount: number };
  changesEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
  errorEscrows: { __typename?: 'EscrowPage'; nodesCount: number };
};

export type IMarkAsViewedEscrowMutationVariables = Exact<{
  escrowIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type IMarkAsViewedEscrowMutation = {
  __typename?: 'Mutation';
  markAsViewedEscrow?: {
    __typename?: 'EscrowMarkAsViewedPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type IRejectEscrowInvitationMutationVariables = Exact<{
  escrowInvitationId: Scalars['ID']['input'];
}>;

export type IRejectEscrowInvitationMutation = {
  __typename?: 'Mutation';
  rejectEscrowInvitation?: {
    __typename?: 'RejectEscrowInvitationPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrowInvitation: {
      __typename?: 'EscrowInvitation';
      status: InvitationStatusEnum;
      companyName?: string | null;
      id: string;
      partyType: string;
      user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
      policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
    };
  } | null;
};

export type IRejectEscrowTerminationMutationVariables = Exact<{
  escrowTerminationId: Scalars['ID']['input'];
}>;

export type IRejectEscrowTerminationMutation = {
  __typename?: 'Mutation';
  rejectEscrowTermination?: { __typename?: 'RejectPayload'; success: boolean; errors?: Array<string> | null } | null;
};

export type IRemoveEscrowMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IRemoveEscrowMutation = {
  __typename?: 'Mutation';
  removeEscrow?: { __typename?: 'RemoveEscrowPayload'; success: boolean; errors?: Array<string> | null } | null;
};

export type IRequestEscrowTerminationMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IRequestEscrowTerminationMutation = {
  __typename?: 'Mutation';
  requestEscrowTermination?: {
    __typename?: 'TerminatePayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type ISignedActiveEscrowsQueryVariables = Exact<{
  currentCompanyRole?: InputMaybe<EscrowPartyTypeEnum>;
}>;

export type ISignedActiveEscrowsQuery = {
  __typename?: 'Query';
  signed: {
    __typename?: 'EscrowPage';
    nodes: Array<{
      __typename?: 'Escrow';
      id: string;
      beneficiary?: { __typename?: 'Beneficiary'; companyName?: string | null } | null;
      depositor?: { __typename?: 'Depositor'; companyName?: string | null } | null;
    }>;
  };
  active: {
    __typename?: 'EscrowPage';
    nodes: Array<{
      __typename?: 'Escrow';
      id: string;
      beneficiary?: { __typename?: 'Beneficiary'; companyName?: string | null } | null;
      depositor?: { __typename?: 'Depositor'; companyName?: string | null } | null;
    }>;
  };
};

export type IUpdateEscrowMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  escrowInput: IEscrowInput;
}>;

export type IUpdateEscrowMutation = {
  __typename?: 'Mutation';
  updateEscrow?: {
    __typename?: 'UpdateEscrowPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow: { __typename?: 'Escrow'; id: string; activationNotification: boolean };
  } | null;
};

export type IUpdateEscrowInvitationMutationVariables = Exact<{
  escrowInvitationId: Scalars['ID']['input'];
  administrativeInputUser: IUserInput;
}>;

export type IUpdateEscrowInvitationMutation = {
  __typename?: 'Mutation';
  updateEscrowInvitation?: {
    __typename?: 'UpdateEscrowInvitationPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrowInvitation: {
      __typename?: 'EscrowInvitation';
      status: InvitationStatusEnum;
      companyName?: string | null;
      id: string;
      partyType: string;
      user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
      policy: { __typename?: 'EscrowInvitationPolicy'; accept: boolean; reject: boolean; update: boolean };
    };
  } | null;
};

export type ICreateEscrowMemberMutationVariables = Exact<{
  input: ICreateEscrowMemberInput;
}>;

export type ICreateEscrowMemberMutation = {
  __typename?: 'Mutation';
  createEscrowMember?: {
    __typename?: 'CreateEscrowMemberPayload';
    errors?: Array<string> | null;
    success: boolean;
    escrowMember?: {
      __typename?: 'EscrowMember';
      id: string;
      role: EscrowMemberRoleEnum;
      user: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        phone?: string | null;
        role: UserRoleEnum;
      };
      policy: { __typename?: 'EscrowMemberPolicy'; update: boolean };
    } | null;
  } | null;
};

export type IEscrowMemberFragmentFragment = {
  __typename?: 'EscrowMember';
  id: string;
  role: EscrowMemberRoleEnum;
  user: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: UserRoleEnum;
  };
  policy: { __typename?: 'EscrowMemberPolicy'; update: boolean };
};

export type IEscrowMembersQueryVariables = Exact<{
  escrowPartyId: Scalars['ID']['input'];
  orderBy?: InputMaybe<EscrowMembersOrderByEnum>;
  order?: InputMaybe<QueryOrderEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type IEscrowMembersQuery = {
  __typename?: 'Query';
  escrowMembers: {
    __typename?: 'EscrowMemberPage';
    pagesCount: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'EscrowMember';
      id: string;
      role: EscrowMemberRoleEnum;
      user: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        phone?: string | null;
        role: UserRoleEnum;
      };
      policy: { __typename?: 'EscrowMemberPolicy'; update: boolean };
    }>;
  };
};

export type IRemoveEscrowMemberMutationVariables = Exact<{
  escrowMemberId: Scalars['ID']['input'];
}>;

export type IRemoveEscrowMemberMutation = {
  __typename?: 'Mutation';
  removeEscrowMember?: {
    __typename?: 'RemoveEscrowMemberPayload';
    errors?: Array<string> | null;
    success: boolean;
  } | null;
};

export type IUpdateEscrowMemberMutationVariables = Exact<{
  input: IUpdateEscrowMemberInput;
}>;

export type IUpdateEscrowMemberMutation = {
  __typename?: 'Mutation';
  updateEscrowMember?: {
    __typename?: 'UpdateEscrowMemberPayload';
    errors?: Array<string> | null;
    success: boolean;
    escrowMember?: {
      __typename?: 'EscrowMember';
      id: string;
      role: EscrowMemberRoleEnum;
      user: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        phone?: string | null;
        role: UserRoleEnum;
      };
      policy: { __typename?: 'EscrowMemberPolicy'; update: boolean };
    } | null;
  } | null;
};

export type IBackupReadyIntegrationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<IntegrationTypeEnum>;
}>;

export type IBackupReadyIntegrationsQuery = {
  __typename?: 'Query';
  backupReadyIntegrations: {
    __typename?: 'IntegrationInterfacePage';
    nodes: Array<
      | {
          __typename?: 'CustomIntegration';
          isClientOwner?: boolean | null;
          accountName?: string | null;
          id: string;
          accountUrl?: string | null;
          provider: string;
          backupable: boolean;
          creator?: { __typename?: 'User'; id: string } | null;
        }
      | {
          __typename?: 'OauthIntegration';
          isClientOwner?: boolean | null;
          accountName?: string | null;
          id: string;
          accountUrl?: string | null;
          provider: string;
          backupable: boolean;
          creator?: { __typename?: 'User'; id: string } | null;
        }
      | {
          __typename?: 'ShareFileIntegration';
          accountName?: string | null;
          id: string;
          accountUrl?: string | null;
          provider: string;
          backupable: boolean;
          creator?: { __typename?: 'User'; id: string } | null;
        }
    >;
  };
};

export type ICreateCustomAssetsMutationVariables = Exact<{
  assetsInput: Array<ICustomAssetCreateInput> | ICustomAssetCreateInput;
  escrowId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
}>;

export type ICreateCustomAssetsMutation = {
  __typename?: 'Mutation';
  createCustomAssets?: {
    __typename?: 'CreateCustomAssetsPayload';
    success: boolean;
    errors?: Array<string> | null;
    assets?: Array<{
      __typename?: 'Asset';
      id: string;
      depositStatus: DepositStatusEnum;
      depositMethod: DepositMethodEnum;
      depositSource?: string | null;
      escrow: { __typename?: 'Escrow'; id: string; status: EscrowStatusEnum };
    }> | null;
  } | null;
};

export type ICreateCustomIntegrationMutationVariables = Exact<{
  customIntegrationInput: ICustomIntegrationInputAttributes;
}>;

export type ICreateCustomIntegrationMutation = {
  __typename?: 'Mutation';
  createCustomIntegration?: {
    __typename?: 'CreateCustomIntegrationPayload';
    success: boolean;
    errors?: Array<string> | null;
    customIntegration?: { __typename?: 'CustomIntegration'; provider: string; id: string } | null;
  } | null;
};

export type ICreateShareFileIntegrationMutationVariables = Exact<{
  shareFileIntegrationInput: IShareFileIntegrationInputAttributes;
}>;

export type ICreateShareFileIntegrationMutation = {
  __typename?: 'Mutation';
  createShareFileIntegration?: {
    __typename?: 'CreateShareFileIntegrationPayload';
    success: boolean;
    errors?: Array<string> | null;
    shareFileIntegration?: { __typename?: 'ShareFileIntegration'; provider: string; id: string } | null;
  } | null;
};

export type IFinishOauthIntegrationAuthMutationVariables = Exact<{
  provider: OauthIntegrationProviderEnum;
  clientPayload: IIntegrationAuthClientPayloadInput;
  stateToken: Scalars['String']['input'];
  options?: InputMaybe<IIntegrationAuthOptionsInput>;
}>;

export type IFinishOauthIntegrationAuthMutation = {
  __typename?: 'Mutation';
  finishOauthIntegrationAuth?: {
    __typename?: 'FinishAuthPayload';
    success: boolean;
    errors?: Array<string> | null;
    oauthIntegration?: {
      __typename?: 'OauthIntegration';
      id: string;
      accountName?: string | null;
      provider: string;
    } | null;
  } | null;
};

export type IInitOauthIntegrationAuthMutationVariables = Exact<{
  provider: OauthIntegrationProviderEnum;
  options?: InputMaybe<IIntegrationAuthOptionsInput>;
}>;

export type IInitOauthIntegrationAuthMutation = {
  __typename?: 'Mutation';
  initOauthIntegrationAuth?: {
    __typename?: 'InitAuthPayload';
    success: boolean;
    errors?: Array<string> | null;
    integrationAuth?: { __typename?: 'IntegrationAuth'; url: string; stateToken: string } | null;
  } | null;
};

export type IIntegrationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<IntegrationTypeEnum>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type IIntegrationsQuery = {
  __typename?: 'Query';
  integrations: {
    __typename?: 'IntegrationInterfacePage';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nodesCount: number;
    pagesCount: number;
    nodes: Array<
      | {
          __typename: 'CustomIntegration';
          active: boolean;
          accountName?: string | null;
          id: string;
          accountUrl?: string | null;
          provider: string;
          status: IntegrationStatusEnum;
          tenantId?: string | null;
        }
      | {
          __typename: 'OauthIntegration';
          active: boolean;
          accountName?: string | null;
          id: string;
          accountUrl?: string | null;
          provider: string;
          status: IntegrationStatusEnum;
          tenantId?: string | null;
        }
      | {
          __typename: 'ShareFileIntegration';
          active: boolean;
          accountName?: string | null;
          id: string;
          accountUrl?: string | null;
          provider: string;
          status: IntegrationStatusEnum;
          tenantId?: string | null;
        }
    >;
  };
};

export type IToggleIntegrationMutationVariables = Exact<{
  integrationId: Scalars['ID']['input'];
}>;

export type IToggleIntegrationMutation = {
  __typename?: 'Mutation';
  toggleIntegration?: {
    __typename?: 'TogglePayload';
    success: boolean;
    errors?: Array<string> | null;
    integration?:
      | { __typename?: 'CustomIntegration'; id: string; status: IntegrationStatusEnum; active: boolean }
      | { __typename?: 'OauthIntegration'; id: string; status: IntegrationStatusEnum; active: boolean }
      | { __typename?: 'ShareFileIntegration'; id: string; status: IntegrationStatusEnum; active: boolean }
      | null;
  } | null;
};

export type IUpdateCustomIntegrationMutationVariables = Exact<{
  customIntegrationInput: ICustomIntegrationUpdateInputAttributes;
  integrationId: Scalars['ID']['input'];
}>;

export type IUpdateCustomIntegrationMutation = {
  __typename?: 'Mutation';
  updateCustomIntegration?: {
    __typename?: 'UpdateCustomIntegrationPayload';
    success: boolean;
    errors?: Array<string> | null;
    customIntegration?: {
      __typename?: 'CustomIntegration';
      accountName?: string | null;
      accountUrl?: string | null;
      active: boolean;
      id: string;
      provider: string;
      status: IntegrationStatusEnum;
    } | null;
  } | null;
};

export type IInvoicePdfQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IInvoicePdfQuery = {
  __typename?: 'Query';
  invoicePdf?: { __typename?: 'InvoicePdf'; downloadUrl: string } | null;
};

export type IInvoicesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['String']['input']>;
}>;

export type IInvoicesQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'InvoicePage';
    nextOffset?: string | null;
    nodes: Array<{
      __typename?: 'Invoice';
      id: string;
      date: any;
      total: number;
      currencyCode: string;
      status: InvoiceStatusEnum;
    }>;
  };
};

export type IAwaitingLegacyReposCountQueryVariables = Exact<{ [key: string]: never }>;

export type IAwaitingLegacyReposCountQuery = {
  __typename?: 'Query';
  legacyRepos: { __typename?: 'LegacyRepoPage'; count: number };
};

export type ILegacyReposQueryVariables = Exact<{
  order?: InputMaybe<QueryOrderEnum>;
  queryFilter?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ILegacyReposQuery = {
  __typename?: 'Query';
  legacyRepos: {
    __typename?: 'LegacyRepoPage';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'LegacyRepo';
      migrationStatus: LegacyRepoMigrationStatusEnum;
      platform: LegacyRepoPlatformEnum;
      title: string;
      url: string;
      id: string;
      escrow: {
        __typename?: 'Escrow';
        id: string;
        depositor?: { __typename?: 'Depositor'; companyName?: string | null } | null;
        beneficiary?: { __typename?: 'Beneficiary'; companyName?: string | null } | null;
      };
    }>;
  };
};

export type ILegacyReposCountByEscrowIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  statusFilter?: InputMaybe<LegacyRepoMigrationStatusEnum>;
}>;

export type ILegacyReposCountByEscrowIdQuery = {
  __typename?: 'Query';
  legacyRepos: { __typename?: 'LegacyRepoPage'; count: number };
};

export type IMigrateLegacyRepoMutationVariables = Exact<{
  legacyRepoId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
  oauthRepoId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type IMigrateLegacyRepoMutation = {
  __typename?: 'Mutation';
  migrateLegacyRepo?: {
    __typename?: 'MigrateLegacyRepoPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type IMigrateLegacyReposMutationVariables = Exact<{
  legacyRepoIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
}>;

export type IMigrateLegacyReposMutation = {
  __typename?: 'Mutation';
  migrateLegacyRepos?: {
    __typename?: 'MigrateLegacyReposPayload';
    success: boolean;
    errors?: Array<string> | null;
    migratedLegacyRepos: Array<{ __typename?: 'LegacyRepo'; id: string }>;
  } | null;
};

export type IConfirmEmailChangeMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type IConfirmEmailChangeMutation = {
  __typename?: 'Mutation';
  confirmEmailChange?: {
    __typename?: 'ConfirmEmailChangePayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type ICreateFeedbackMutationVariables = Exact<{
  message: Scalars['String']['input'];
}>;

export type ICreateFeedbackMutation = {
  __typename?: 'Mutation';
  createFeedback?: { __typename?: 'CreateFeedbackPayload'; success: boolean; errors?: Array<string> | null } | null;
};

export type IMarkAsViewedNotificationMutationVariables = Exact<{
  notificationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type IMarkAsViewedNotificationMutation = {
  __typename?: 'Mutation';
  markAsViewedNotification?: {
    __typename?: 'MarkAsViewedPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type IProfileQueryVariables = Exact<{ [key: string]: never }>;

export type IProfileQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'Profile';
    email?: string | null;
    id: string;
    name?: string | null;
    gravatarUrl?: string | null;
    phone?: string | null;
    role?: UserRoleEnum | null;
  };
};

export type IUpdateBillingAddressMutationVariables = Exact<{
  billingAddress: IBillingAddressInput;
}>;

export type IUpdateBillingAddressMutation = {
  __typename?: 'Mutation';
  updateBillingAddress?: {
    __typename?: 'UpdateBillingAddressPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type IUpdatePaymentMethodMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type IUpdatePaymentMethodMutation = {
  __typename?: 'Mutation';
  updatePaymentMethod?: {
    __typename?: 'RequestUpdatePaymentPagePayload';
    success: boolean;
    errors?: Array<string> | null;
    url?: string | null;
  } | null;
};

export type IUpdateProfileInfoMutationVariables = Exact<{
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}>;

export type IUpdateProfileInfoMutation = {
  __typename?: 'Mutation';
  updateProfileInfo?: {
    __typename?: 'UpdateProfileInfoPayload';
    success: boolean;
    errors?: Array<string> | null;
    profile?: { __typename?: 'Profile'; id: string; name?: string | null; email?: string | null } | null;
  } | null;
};

export type IAssetReportQueryVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  assetReportId: Scalars['ID']['input'];
}>;

export type IAssetReportQuery = {
  __typename?: 'Query';
  assetReport?: {
    __typename?: 'AssetReport';
    id: string;
    createdAt: any;
    period: any;
    asset: { __typename?: 'Asset'; id: string; depositSource?: string | null };
    data: {
      __typename?: 'ReportData';
      collaborators?: number | null;
      commitsPerDay?: any | null;
      languages?: any | null;
      size?: any | null;
      totalCommits?: number | null;
    };
  } | null;
};

export type IAssetReportGroupsQueryVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  assetId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<AssetsOrderByEnum>;
  period?: InputMaybe<Scalars['ISO8601Date']['input']>;
}>;

export type IAssetReportGroupsQuery = {
  __typename?: 'Query';
  assetReportGroups: {
    __typename?: 'AssetReportGroupPage';
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'AssetReportGroup';
      id: string;
      lastGenerated: any;
      asset: { __typename?: 'Asset'; id: string; depositSource?: string | null };
      assetReports: Array<{
        __typename?: 'AssetReport';
        id: string;
        createdAt: any;
        period: any;
        asset: { __typename?: 'Asset'; id: string; depositSource?: string | null };
      }>;
    }>;
  };
};

export type IBackupAssetReportQueryVariables = Exact<{
  backupId: Scalars['ID']['input'];
  backupAssetReportId: Scalars['ID']['input'];
}>;

export type IBackupAssetReportQuery = {
  __typename?: 'Query';
  backupAssetReport?: {
    __typename?: 'BackupAssetReport';
    id: string;
    createdAt: any;
    period: any;
    backupAsset: { __typename?: 'BackupAsset'; id: string; name: string };
    data: {
      __typename?: 'ReportData';
      collaborators?: number | null;
      commitsPerDay?: any | null;
      languages?: any | null;
      size?: any | null;
      totalCommits?: number | null;
    };
  } | null;
};

export type IBackupAssetReportGroupsQueryVariables = Exact<{
  backupId: Scalars['ID']['input'];
  backupAssetId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<QueryOrderEnum>;
  orderBy?: InputMaybe<AssetsOrderByEnum>;
  period?: InputMaybe<Scalars['ISO8601Date']['input']>;
}>;

export type IBackupAssetReportGroupsQuery = {
  __typename?: 'Query';
  backupAssetReportGroups: {
    __typename?: 'BackupAssetReportGroupPage';
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'BackupAssetReportGroup';
      id: string;
      lastGenerated: any;
      backupAsset: { __typename?: 'BackupAsset'; id: string; name: string };
      backupAssetReports: Array<{
        __typename?: 'BackupAssetReport';
        id: string;
        createdAt: any;
        period: any;
        backupAsset: { __typename?: 'BackupAsset'; id: string; name: string };
      }>;
    }>;
  };
};

export type IEnableBackupReportsMutationVariables = Exact<{
  backupId: Scalars['ID']['input'];
}>;

export type IEnableBackupReportsMutation = {
  __typename?: 'Mutation';
  enableBackupReports?: {
    __typename?: 'EnableBackupReportsPayload';
    success: boolean;
    errors?: Array<string> | null;
    backup: { __typename?: 'Backup'; id: string; reportsEnabled: boolean };
  } | null;
};

export type IEnableEscrowReportsMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type IEnableEscrowReportsMutation = {
  __typename?: 'Mutation';
  enableEscrowReports?: {
    __typename?: 'EnableEscrowReportsPayload';
    success: boolean;
    errors?: Array<string> | null;
    escrow?: { __typename?: 'Escrow'; id: string; reportsEnabled: boolean } | null;
  } | null;
};

export type IOauthReposConnQueryVariables = Exact<{
  integrationId: Scalars['ID']['input'];
}>;

export type IOauthReposConnQuery = {
  __typename?: 'Query';
  oauthReposConn: {
    __typename?: 'OauthReposConn';
    repositories: Array<{ __typename?: 'OauthRepository'; id: string; name?: string | null; fullName?: string | null }>;
  };
};

export type IActivateSaasEnvironmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IActivateSaasEnvironmentMutation = {
  __typename?: 'Mutation';
  activateSaasEnvironment?: {
    __typename?: 'ActivateSaasEnvironmentPayload';
    errors?: Array<string> | null;
    success: boolean;
    saasEnvironment?: { __typename?: 'SaasEnvironment'; id: string } | null;
  } | null;
};

export type ICreateSaasEnvironmentMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ICreateSaasEnvironmentMutation = {
  __typename?: 'Mutation';
  createSaasEnvironment?: {
    __typename?: 'CreateSaasEnvironmentPayload';
    errors?: Array<string> | null;
    success: boolean;
    saasEnvironment?: { __typename?: 'SaasEnvironment'; id: string } | null;
  } | null;
};

export type ICreateSaasProviderMutationVariables = Exact<{
  input: ICreateSaasProviderInput;
}>;

export type ICreateSaasProviderMutation = {
  __typename?: 'Mutation';
  createSaasProvider?: {
    __typename?: 'CreateSaasProviderPayload';
    errors?: Array<string> | null;
    success: boolean;
    saasProvider?: { __typename?: 'SaasProvider'; id: string } | null;
  } | null;
};

export type IRemoveSaasEnvironmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IRemoveSaasEnvironmentMutation = {
  __typename?: 'Mutation';
  removeSaasEnvironment?: {
    __typename?: 'RemoveSaasEnvironmentPayload';
    errors?: Array<string> | null;
    success: boolean;
  } | null;
};

export type IRemoveSaasProviderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IRemoveSaasProviderMutation = {
  __typename?: 'Mutation';
  removeSaasProvider?: {
    __typename?: 'RemoveSaasProviderPayload';
    errors?: Array<string> | null;
    success: boolean;
  } | null;
};

export type ISaasEnvironmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ISaasEnvironmentQuery = {
  __typename?: 'Query';
  saasEnvironment: {
    __typename?: 'SaasEnvironment';
    id: string;
    name: string;
    status: SaasEnvironmentStatusEnum;
    creator: { __typename?: 'User'; id: string; name?: string | null };
    saasProviders: Array<{
      __typename?: 'SaasProvider';
      id: string;
      platform: string;
      description: string;
      status: SaasProviderStatusEnum;
      role: SaasProviderRoleEnum;
      protectionPeriod: SaasProviderProtectionPeriodEnum;
      monthlyCost: number;
      contactEmail: string;
      completedAt?: any | null;
    }>;
    saasEnvironmentTermination?: {
      __typename?: 'SaasEnvironmentTermination';
      id: string;
      status: SaasEnvironmentTerminationStatusEnum;
    } | null;
    policy: {
      __typename?: 'SaasEnvironmentPolicy';
      requestActivation: boolean;
      requestTermination: boolean;
      update: boolean;
    };
  };
};

export type ISaasEnvironmentsQueryVariables = Exact<{
  orderBy?: InputMaybe<SaasEnvironmentsOrderByEnum>;
  order?: InputMaybe<QueryOrderEnum>;
  status?: InputMaybe<SaasEnvironmentStatusEnum>;
  query?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ISaasEnvironmentsQuery = {
  __typename?: 'Query';
  saasEnvironments: {
    __typename?: 'SaasEnvironmentPage';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nodesCount: number;
    pagesCount: number;
    nodes: Array<{
      __typename?: 'SaasEnvironment';
      id: string;
      name: string;
      status: SaasEnvironmentStatusEnum;
      activatedAt?: any | null;
      saasProviders: Array<{ __typename?: 'SaasProvider'; id: string }>;
      saasEnvironmentTermination?: {
        __typename?: 'SaasEnvironmentTermination';
        id: string;
        status: SaasEnvironmentTerminationStatusEnum;
      } | null;
      policy: { __typename?: 'SaasEnvironmentPolicy'; remove: boolean };
    }>;
  };
};

export type ITerminateSaasEnvironmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ITerminateSaasEnvironmentMutation = {
  __typename?: 'Mutation';
  terminateSaasEnvironment?: {
    __typename?: 'TerminateSaasEnvironmentPayload';
    errors?: Array<string> | null;
    success: boolean;
    saasEnvironmentTermination?: {
      __typename?: 'SaasEnvironmentTermination';
      id: string;
      saasEnvironment: {
        __typename?: 'SaasEnvironment';
        id: string;
        saasEnvironmentTermination?: {
          __typename?: 'SaasEnvironmentTermination';
          id: string;
          status: SaasEnvironmentTerminationStatusEnum;
        } | null;
      };
    } | null;
  } | null;
};

export type IUpdateSaasProviderMutationVariables = Exact<{
  input: IUpdateSaasProviderInput;
}>;

export type IUpdateSaasProviderMutation = {
  __typename?: 'Mutation';
  updateSaasProvider?: {
    __typename?: 'UpdateSaasProviderPayload';
    errors?: Array<string> | null;
    success: boolean;
    saasProvider?: { __typename?: 'SaasProvider'; id: string } | null;
  } | null;
};

export type IClientChangesRequestedQueryVariables = Exact<{ [key: string]: never }>;

export type IClientChangesRequestedQuery = {
  __typename?: 'Query';
  clientChangesRequested: {
    __typename?: 'ClientChangesRequestedPayload';
    isRequested: boolean;
    depositor?: {
      __typename?: 'ClientChangesRequested';
      id: string;
      payload?: any | null;
      status: string;
      creator: string;
      receiver: string;
    } | null;
    beneficiary?: {
      __typename?: 'ClientChangesRequested';
      id: string;
      payload?: any | null;
      status: string;
      creator: string;
      receiver: string;
    } | null;
    deposits?: {
      __typename?: 'ClientChangesRequested';
      id: string;
      payload?: any | null;
      status: string;
      creator: string;
      receiver: string;
    } | null;
    notifications?: {
      __typename?: 'ClientChangesRequested';
      id: string;
      payload?: any | null;
      status: string;
      creator: string;
      receiver: string;
    } | null;
    agreement?: {
      __typename?: 'ClientChangesRequested';
      id: string;
      payload?: any | null;
      status: string;
      creator: string;
      receiver: string;
    } | null;
  };
};

export type ICreateSuggestedChangeEscrowMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  suggestedChangeParams: ISuggestedChangeInput;
}>;

export type ICreateSuggestedChangeEscrowMutation = {
  __typename?: 'Mutation';
  createSuggestedChangeEscrow?: {
    __typename?: 'CreateSuggestedChangeEscrowPayload';
    success: boolean;
    errors?: Array<string> | null;
    suggestedChange?: {
      __typename?: 'SuggestChange';
      id: string;
      payload: any;
      partyStatus?: SuggestedChangeStatusEnum | null;
      status: SuggestedChangeStatusEnum;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
      policy: { __typename?: 'SuggestedChangePolicy'; review: boolean; update: boolean };
    } | null;
  } | null;
};

export type IReviewSuggestedChangeEscrowMutationVariables = Exact<{
  suggestedChangeId: Scalars['ID']['input'];
  verdict: SuggestedChangeVerdictEnum;
}>;

export type IReviewSuggestedChangeEscrowMutation = {
  __typename?: 'Mutation';
  reviewSuggestedChangeEscrow?: {
    __typename?: 'ReviewSuggestedChangeEscrowPayload';
    success: boolean;
    errors?: Array<string> | null;
    suggestedChange: {
      __typename?: 'SuggestChange';
      id: string;
      payload: any;
      partyStatus?: SuggestedChangeStatusEnum | null;
      status: SuggestedChangeStatusEnum;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
      escrow: {
        __typename?: 'Escrow';
        id: string;
        policy: {
          __typename?: 'EscrowPolicy';
          createAgreementSuggestedChange: boolean;
          createBeneficiarySuggestedChange: boolean;
          createDepositSuggestedChange: boolean;
          createDepositorSuggestedChange: boolean;
          createNotificationSuggestedChange: boolean;
        };
      };
      policy: { __typename?: 'SuggestedChangePolicy'; review: boolean; update: boolean };
    };
  } | null;
};

export type ISuggestChangeFieldsFragment = {
  __typename?: 'SuggestChange';
  id: string;
  payload: any;
  partyStatus?: SuggestedChangeStatusEnum | null;
  status: SuggestedChangeStatusEnum;
  creatorType: EscrowPartyTypeEnum;
  receiverType?: EscrowPartyTypeEnum | null;
  policy: { __typename?: 'SuggestedChangePolicy'; review: boolean; update: boolean };
};

export type ISuggestedChangesQueryVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  changeType?: InputMaybe<SuggestedChangeChangeTypeEnum>;
}>;

export type ISuggestedChangesQuery = {
  __typename?: 'Query';
  suggestedChanges: {
    __typename?: 'SuggestChangePage';
    nodes: Array<{
      __typename?: 'SuggestChange';
      id: string;
      payload: any;
      partyStatus?: SuggestedChangeStatusEnum | null;
      status: SuggestedChangeStatusEnum;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
      policy: { __typename?: 'SuggestedChangePolicy'; review: boolean; update: boolean };
    }>;
  };
};

export type ISuggestedChangesNotificationsQueryVariables = Exact<{
  escrowId: Scalars['ID']['input'];
}>;

export type ISuggestedChangesNotificationsQuery = {
  __typename?: 'Query';
  depositorSuggestedChanges: {
    __typename?: 'SuggestChangePage';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'SuggestChange';
      id: string;
      partyStatus?: SuggestedChangeStatusEnum | null;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
    }>;
  };
  beneficiarySuggestedChanges: {
    __typename?: 'SuggestChangePage';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'SuggestChange';
      id: string;
      partyStatus?: SuggestedChangeStatusEnum | null;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
    }>;
  };
  notificationSuggestedChanges: {
    __typename?: 'SuggestChangePage';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'SuggestChange';
      id: string;
      partyStatus?: SuggestedChangeStatusEnum | null;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
    }>;
  };
  depositSuggestedChanges: {
    __typename?: 'SuggestChangePage';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'SuggestChange';
      id: string;
      partyStatus?: SuggestedChangeStatusEnum | null;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
    }>;
  };
  agreementSuggestedChanges: {
    __typename?: 'SuggestChangePage';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'SuggestChange';
      id: string;
      partyStatus?: SuggestedChangeStatusEnum | null;
      creatorType: EscrowPartyTypeEnum;
      receiverType?: EscrowPartyTypeEnum | null;
    }>;
  };
};

export type ICancelSecureUploadMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  uploadId: Scalars['String']['input'];
}>;

export type ICancelSecureUploadMutation = {
  __typename?: 'Mutation';
  cancelSecureUpload?: {
    __typename?: 'CancelSecureUploadPayload';
    success: boolean;
    errors?: Array<string> | null;
  } | null;
};

export type ICompleteSecureUploadMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  uploadId: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  parts: Array<ISecureUploadPartInput> | ISecureUploadPartInput;
  size: Scalars['BigInt']['input'];
}>;

export type ICompleteSecureUploadMutation = {
  __typename?: 'Mutation';
  completeSecureUpload?: {
    __typename?: 'CompleteSecureUploadPayload';
    success: boolean;
    errors?: Array<string> | null;
    asset?: { __typename?: 'Asset'; id: string; depositStatus: DepositStatusEnum } | null;
  } | null;
};

export type IInitSecureUploadMutationVariables = Exact<{
  escrowId: Scalars['ID']['input'];
  numParts: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
}>;

export type IInitSecureUploadMutation = {
  __typename?: 'Mutation';
  initSecureUpload?: {
    __typename?: 'InitSecureUploadPayload';
    success: boolean;
    errors?: Array<string> | null;
    uploadId?: string | null;
    uploadUrls?: Array<string> | null;
  } | null;
};

export type IAddUserMutationVariables = Exact<{
  users: Array<IUserInput> | IUserInput;
}>;

export type IAddUserMutation = {
  __typename?: 'Mutation';
  addUser?: {
    __typename?: 'AddPayload';
    success: boolean;
    errors?: Array<string> | null;
    users?: Array<{ __typename?: 'User'; id: string }> | null;
  } | null;
};

export type IEditUserMutationVariables = Exact<{
  user: IEditUserInput;
  userId: Scalars['ID']['input'];
}>;

export type IEditUserMutation = {
  __typename?: 'Mutation';
  editUser?: {
    __typename?: 'EditPayload';
    success: boolean;
    errors?: Array<string> | null;
    user?: { __typename?: 'User'; id: string; name?: string | null; email: string; phone?: string | null } | null;
  } | null;
};

export type IImpersonateUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type IImpersonateUserMutation = {
  __typename?: 'Mutation';
  impersonateUser?: { __typename?: 'ImpersonatePayload'; success: boolean } | null;
};

export type IRemoveUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type IRemoveUserMutation = {
  __typename?: 'Mutation';
  removeUser?: { __typename?: 'RemovePayload'; success: boolean; errors?: Array<string> | null } | null;
};

export const ContactFragmentFragmentDoc = gql`
  fragment ContactFragment on Contact {
    id
    email
    name
    phone
    signatory
    contactType
  }
`;
export const EscrowInvitationFieldsFragmentDoc = gql`
  fragment EscrowInvitationFields on EscrowInvitation {
    status
    companyName
    id
    partyType
    user {
      id
      name
      email
      phone
    }
    policy {
      accept
      reject
      update
    }
  }
`;
export const EscrowTerminationFieldsFragmentDoc = gql`
  fragment EscrowTerminationFields on EscrowTermination {
    status
    requestedAt
    acceptedAt
    adminReviewedAt
    requesterParty
    id
    terminateAt
    policy {
      accept
      reject
    }
  }
`;
export const EscrowAgreementFieldsFragmentDoc = gql`
  fragment EscrowAgreementFields on Agreement {
    agreementForm
    id
    jurisdiction
    liability
    approvedByBeneficiary
    approvedByCodekeeper
    approvedByDepositor
    releaseBankruptcy
    releaseCustom
    releaseCustomConditions
    releaseInsolvency
    releaseMaintenance
    thirdPartyAgreement
    updatedAt
    legalContext
    legalContextOther
    depositType
    depositTypeOther
    policy {
      approve
      generate
    }
    approved
  }
`;
export const EscrowPolicyFragmentFragmentDoc = gql`
  fragment EscrowPolicyFragment on EscrowPolicy {
    activate
    bipartiteUpdate
    createBeneficiary
    deferActivation
    depositAssets
    enableReports
    inviteBeneficiary
    inviteDepositor
    read
    remove
    requestTermination
    update
    write
    createAgreementSuggestedChange
    createBeneficiarySuggestedChange
    createDepositSuggestedChange
    createDepositorSuggestedChange
    createNotificationSuggestedChange
  }
`;
export const DepositorPolicyFragmentFragmentDoc = gql`
  fragment DepositorPolicyFragment on DepositorPolicy {
    readEscrowMembers
    update
    createEscrowMembers
  }
`;
export const BeneficiaryPolicyFragmentFragmentDoc = gql`
  fragment BeneficiaryPolicyFragment on BeneficiaryPolicy {
    readEscrowMembers
    update
    createEscrowMembers
  }
`;
export const EscrowMemberFragmentFragmentDoc = gql`
  fragment EscrowMemberFragment on EscrowMember {
    id
    role
    user {
      id
      name
      email
      phone
      role
    }
    policy {
      update
    }
  }
`;
export const SuggestChangeFieldsFragmentDoc = gql`
  fragment SuggestChangeFields on SuggestChange {
    id
    payload
    partyStatus
    status
    creatorType
    receiverType
    policy {
      review
      update
    }
  }
`;
export const ActivitiesDocument = gql`
  query activities($page: Int, $perPage: Int) {
    activities(page: $page, perPage: $perPage) {
      pagesCount
      nodesCount
      nodes {
        createdAt
        eventType
        id
        klass
        viewedAt
        payload {
          ... on AgreementPayload {
            id
          }
          ... on EscrowPayload {
            id
            depositor {
              id
              companyName
            }
            beneficiary {
              id
              companyName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<IActivitiesQuery, IActivitiesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IActivitiesQuery, IActivitiesQueryVariables>(ActivitiesDocument, options);
}
export function useActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IActivitiesQuery, IActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IActivitiesQuery, IActivitiesQueryVariables>(ActivitiesDocument, options);
}
export function useActivitiesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IActivitiesQuery, IActivitiesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IActivitiesQuery, IActivitiesQueryVariables>(ActivitiesDocument, options);
}
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesSuspenseQueryHookResult = ReturnType<typeof useActivitiesSuspenseQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<IActivitiesQuery, IActivitiesQueryVariables>;
export const ApproveAgreementDocument = gql`
  mutation approveAgreement($escrowId: ID!) {
    approveAgreement(input: { escrowId: $escrowId }) {
      success
      errors
      escrow {
        id
        status
        agreement {
          id
        }
      }
    }
  }
`;
export type IApproveAgreementMutationFn = Apollo.MutationFunction<
  IApproveAgreementMutation,
  IApproveAgreementMutationVariables
>;

/**
 * __useApproveAgreementMutation__
 *
 * To run a mutation, you first call `useApproveAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAgreementMutation, { data, loading, error }] = useApproveAgreementMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useApproveAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<IApproveAgreementMutation, IApproveAgreementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IApproveAgreementMutation, IApproveAgreementMutationVariables>(
    ApproveAgreementDocument,
    options,
  );
}
export type ApproveAgreementMutationHookResult = ReturnType<typeof useApproveAgreementMutation>;
export type ApproveAgreementMutationResult = Apollo.MutationResult<IApproveAgreementMutation>;
export type ApproveAgreementMutationOptions = Apollo.BaseMutationOptions<
  IApproveAgreementMutation,
  IApproveAgreementMutationVariables
>;
export const GenerateAgreementDocument = gql`
  mutation generateAgreement($escrowId: ID!) {
    generateAgreement(input: { escrowId: $escrowId }) {
      success
      errors
      escrow {
        id
      }
    }
  }
`;
export type IGenerateAgreementMutationFn = Apollo.MutationFunction<
  IGenerateAgreementMutation,
  IGenerateAgreementMutationVariables
>;

/**
 * __useGenerateAgreementMutation__
 *
 * To run a mutation, you first call `useGenerateAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAgreementMutation, { data, loading, error }] = useGenerateAgreementMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useGenerateAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<IGenerateAgreementMutation, IGenerateAgreementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IGenerateAgreementMutation, IGenerateAgreementMutationVariables>(
    GenerateAgreementDocument,
    options,
  );
}
export type GenerateAgreementMutationHookResult = ReturnType<typeof useGenerateAgreementMutation>;
export type GenerateAgreementMutationResult = Apollo.MutationResult<IGenerateAgreementMutation>;
export type GenerateAgreementMutationOptions = Apollo.BaseMutationOptions<
  IGenerateAgreementMutation,
  IGenerateAgreementMutationVariables
>;
export const UpdateAgreementDocument = gql`
  mutation updateAgreement($escrowId: ID!, $agreementInput: AgreementInput!) {
    updateAgreement(input: { escrowId: $escrowId, agreementInput: $agreementInput }) {
      success
      errors
      escrow {
        id
        agreementSettingsConfigured
        agreement {
          ...EscrowAgreementFields
        }
      }
    }
  }
  ${EscrowAgreementFieldsFragmentDoc}
`;
export type IUpdateAgreementMutationFn = Apollo.MutationFunction<
  IUpdateAgreementMutation,
  IUpdateAgreementMutationVariables
>;

/**
 * __useUpdateAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgreementMutation, { data, loading, error }] = useUpdateAgreementMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      agreementInput: // value for 'agreementInput'
 *   },
 * });
 */
export function useUpdateAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateAgreementMutation, IUpdateAgreementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateAgreementMutation, IUpdateAgreementMutationVariables>(
    UpdateAgreementDocument,
    options,
  );
}
export type UpdateAgreementMutationHookResult = ReturnType<typeof useUpdateAgreementMutation>;
export type UpdateAgreementMutationResult = Apollo.MutationResult<IUpdateAgreementMutation>;
export type UpdateAgreementMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAgreementMutation,
  IUpdateAgreementMutationVariables
>;
export const AssetsDocument = gql`
  query assets(
    $page: Int
    $escrowId: ID
    $perPage: Int
    $queryFilter: String
    $order: QueryOrderEnum
    $orderBy: AssetsOrderByEnum
  ) {
    assets(
      page: $page
      perPage: $perPage
      queryFilter: $queryFilter
      order: $order
      orderBy: $orderBy
      escrowId: $escrowId
    ) {
      hasNextPage
      nodes {
        createdAt
        depositSource
        depositStatus
        id
        integration {
          id
          provider
        }
        latestDeposit
        lastUpdateCheck
        syncStatus
        type
        updatedAt
        escrow {
          id
          depositor {
            companyName
          }
          beneficiary {
            companyName
          }
        }
        removalStatus @client
        error
        size
      }
      hasPreviousPage
      nodesCount
      pagesCount
    }
  }
`;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      escrowId: // value for 'escrowId'
 *      perPage: // value for 'perPage'
 *      queryFilter: // value for 'queryFilter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAssetsQuery(baseOptions?: Apollo.QueryHookOptions<IAssetsQuery, IAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetsQuery, IAssetsQueryVariables>(AssetsDocument, options);
}
export function useAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetsQuery, IAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetsQuery, IAssetsQueryVariables>(AssetsDocument, options);
}
export function useAssetsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IAssetsQuery, IAssetsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetsQuery, IAssetsQueryVariables>(AssetsDocument, options);
}
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsSuspenseQueryHookResult = ReturnType<typeof useAssetsSuspenseQuery>;
export type AssetsQueryResult = Apollo.QueryResult<IAssetsQuery, IAssetsQueryVariables>;
export const GetAssetsCountDocument = gql`
  query GetAssetsCount($escrowId: ID) {
    assets(escrowId: $escrowId) {
      nodesCount
    }
  }
`;

/**
 * __useGetAssetsCountQuery__
 *
 * To run a query within a React component, call `useGetAssetsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsCountQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useGetAssetsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetAssetsCountQuery, IGetAssetsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetAssetsCountQuery, IGetAssetsCountQueryVariables>(GetAssetsCountDocument, options);
}
export function useGetAssetsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetAssetsCountQuery, IGetAssetsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetAssetsCountQuery, IGetAssetsCountQueryVariables>(GetAssetsCountDocument, options);
}
export function useGetAssetsCountSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IGetAssetsCountQuery, IGetAssetsCountQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IGetAssetsCountQuery, IGetAssetsCountQueryVariables>(GetAssetsCountDocument, options);
}
export type GetAssetsCountQueryHookResult = ReturnType<typeof useGetAssetsCountQuery>;
export type GetAssetsCountLazyQueryHookResult = ReturnType<typeof useGetAssetsCountLazyQuery>;
export type GetAssetsCountSuspenseQueryHookResult = ReturnType<typeof useGetAssetsCountSuspenseQuery>;
export type GetAssetsCountQueryResult = Apollo.QueryResult<IGetAssetsCountQuery, IGetAssetsCountQueryVariables>;
export const AssetsDepositSourceDocument = gql`
  query assetsDepositSource($escrowId: ID, $perPage: Int) {
    assetsSource: assets(escrowId: $escrowId, page: 1, perPage: $perPage, orderBy: asset_type, order: asc) {
      nodes {
        id
        depositSource
        type
      }
    }
  }
`;

/**
 * __useAssetsDepositSourceQuery__
 *
 * To run a query within a React component, call `useAssetsDepositSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsDepositSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsDepositSourceQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useAssetsDepositSourceQuery(
  baseOptions?: Apollo.QueryHookOptions<IAssetsDepositSourceQuery, IAssetsDepositSourceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetsDepositSourceQuery, IAssetsDepositSourceQueryVariables>(
    AssetsDepositSourceDocument,
    options,
  );
}
export function useAssetsDepositSourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetsDepositSourceQuery, IAssetsDepositSourceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetsDepositSourceQuery, IAssetsDepositSourceQueryVariables>(
    AssetsDepositSourceDocument,
    options,
  );
}
export function useAssetsDepositSourceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IAssetsDepositSourceQuery, IAssetsDepositSourceQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetsDepositSourceQuery, IAssetsDepositSourceQueryVariables>(
    AssetsDepositSourceDocument,
    options,
  );
}
export type AssetsDepositSourceQueryHookResult = ReturnType<typeof useAssetsDepositSourceQuery>;
export type AssetsDepositSourceLazyQueryHookResult = ReturnType<typeof useAssetsDepositSourceLazyQuery>;
export type AssetsDepositSourceSuspenseQueryHookResult = ReturnType<typeof useAssetsDepositSourceSuspenseQuery>;
export type AssetsDepositSourceQueryResult = Apollo.QueryResult<
  IAssetsDepositSourceQuery,
  IAssetsDepositSourceQueryVariables
>;
export const CreateOauthAssetsDocument = gql`
  mutation createOauthAssets($escrowId: ID!, $integrationId: ID!, $assetsInput: [OauthAssetCreateInput!]!) {
    createOauthAssets(input: { escrowId: $escrowId, integrationId: $integrationId, assetsInput: $assetsInput }) {
      success
      errors
      assets {
        id
        escrow {
          id
          depositorConfigured
        }
      }
    }
  }
`;
export type ICreateOauthAssetsMutationFn = Apollo.MutationFunction<
  ICreateOauthAssetsMutation,
  ICreateOauthAssetsMutationVariables
>;

/**
 * __useCreateOauthAssetsMutation__
 *
 * To run a mutation, you first call `useCreateOauthAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOauthAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOauthAssetsMutation, { data, loading, error }] = useCreateOauthAssetsMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      integrationId: // value for 'integrationId'
 *      assetsInput: // value for 'assetsInput'
 *   },
 * });
 */
export function useCreateOauthAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateOauthAssetsMutation, ICreateOauthAssetsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateOauthAssetsMutation, ICreateOauthAssetsMutationVariables>(
    CreateOauthAssetsDocument,
    options,
  );
}
export type CreateOauthAssetsMutationHookResult = ReturnType<typeof useCreateOauthAssetsMutation>;
export type CreateOauthAssetsMutationResult = Apollo.MutationResult<ICreateOauthAssetsMutation>;
export type CreateOauthAssetsMutationOptions = Apollo.BaseMutationOptions<
  ICreateOauthAssetsMutation,
  ICreateOauthAssetsMutationVariables
>;
export const DestroyAssetDocument = gql`
  mutation destroyAsset($assetId: ID!) {
    destroyAsset(input: { assetId: $assetId }) {
      success
      errors
      escrow {
        id
        depositStatus
        policy {
          activate
          deferActivation
          createDepositSuggestedChange
        }
      }
    }
  }
`;
export type IDestroyAssetMutationFn = Apollo.MutationFunction<IDestroyAssetMutation, IDestroyAssetMutationVariables>;

/**
 * __useDestroyAssetMutation__
 *
 * To run a mutation, you first call `useDestroyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAssetMutation, { data, loading, error }] = useDestroyAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useDestroyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IDestroyAssetMutation, IDestroyAssetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDestroyAssetMutation, IDestroyAssetMutationVariables>(DestroyAssetDocument, options);
}
export type DestroyAssetMutationHookResult = ReturnType<typeof useDestroyAssetMutation>;
export type DestroyAssetMutationResult = Apollo.MutationResult<IDestroyAssetMutation>;
export type DestroyAssetMutationOptions = Apollo.BaseMutationOptions<
  IDestroyAssetMutation,
  IDestroyAssetMutationVariables
>;
export const AssignBackupMembersDocument = gql`
  mutation assignBackupMembers($backupId: ID!, $userIds: [ID!]!) {
    assignBackupMembers(input: { backupId: $backupId, userIds: $userIds }) {
      success
      errors
      backupMembers {
        id
        role
        user {
          id
          name
          email
        }
      }
    }
  }
`;
export type IAssignBackupMembersMutationFn = Apollo.MutationFunction<
  IAssignBackupMembersMutation,
  IAssignBackupMembersMutationVariables
>;

/**
 * __useAssignBackupMembersMutation__
 *
 * To run a mutation, you first call `useAssignBackupMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBackupMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBackupMembersMutation, { data, loading, error }] = useAssignBackupMembersMutation({
 *   variables: {
 *      backupId: // value for 'backupId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAssignBackupMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<IAssignBackupMembersMutation, IAssignBackupMembersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IAssignBackupMembersMutation, IAssignBackupMembersMutationVariables>(
    AssignBackupMembersDocument,
    options,
  );
}
export type AssignBackupMembersMutationHookResult = ReturnType<typeof useAssignBackupMembersMutation>;
export type AssignBackupMembersMutationResult = Apollo.MutationResult<IAssignBackupMembersMutation>;
export type AssignBackupMembersMutationOptions = Apollo.BaseMutationOptions<
  IAssignBackupMembersMutation,
  IAssignBackupMembersMutationVariables
>;
export const BackupDocument = gql`
  query backup($backupId: ID!) {
    me: profile {
      id
      role
    }
    backup(backupId: $backupId) {
      id
      status
      storageRegion
      disruptionNotification
      disruptionNotificationGracePeriod
      versionsLimit
      updatedAt
      integration {
        id
        provider
        accountName
      }
      backupAssets {
        id
        lastBackupVersionId
        name
        externalId
        status
        lastBackup
        lastUpdateCheck
        url
      }
      backupMembers {
        id
        role
        user {
          id
          name
          email
        }
      }
      creator {
        id
      }
      reportsEnabled
      reportsEnabledAt
      policy {
        assignMembers
        destroy
        download
        enableReports
        start
        stop
        update
      }
    }
  }
`;

/**
 * __useBackupQuery__
 *
 * To run a query within a React component, call `useBackupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupQuery({
 *   variables: {
 *      backupId: // value for 'backupId'
 *   },
 * });
 */
export function useBackupQuery(
  baseOptions: Apollo.QueryHookOptions<IBackupQuery, IBackupQueryVariables> &
    ({ variables: IBackupQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBackupQuery, IBackupQueryVariables>(BackupDocument, options);
}
export function useBackupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IBackupQuery, IBackupQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBackupQuery, IBackupQueryVariables>(BackupDocument, options);
}
export function useBackupSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IBackupQuery, IBackupQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBackupQuery, IBackupQueryVariables>(BackupDocument, options);
}
export type BackupQueryHookResult = ReturnType<typeof useBackupQuery>;
export type BackupLazyQueryHookResult = ReturnType<typeof useBackupLazyQuery>;
export type BackupSuspenseQueryHookResult = ReturnType<typeof useBackupSuspenseQuery>;
export type BackupQueryResult = Apollo.QueryResult<IBackupQuery, IBackupQueryVariables>;
export const BackupVersionsDocument = gql`
  query backupVersions($id: ID!, $page: Int, $perPage: Int) {
    backupVersions(backupAssetId: $id, page: $page, perPage: $perPage) {
      nodes {
        id
        status
        createdAt
      }
    }
  }
`;

/**
 * __useBackupVersionsQuery__
 *
 * To run a query within a React component, call `useBackupVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupVersionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useBackupVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<IBackupVersionsQuery, IBackupVersionsQueryVariables> &
    ({ variables: IBackupVersionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBackupVersionsQuery, IBackupVersionsQueryVariables>(BackupVersionsDocument, options);
}
export function useBackupVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IBackupVersionsQuery, IBackupVersionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBackupVersionsQuery, IBackupVersionsQueryVariables>(BackupVersionsDocument, options);
}
export function useBackupVersionsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IBackupVersionsQuery, IBackupVersionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBackupVersionsQuery, IBackupVersionsQueryVariables>(BackupVersionsDocument, options);
}
export type BackupVersionsQueryHookResult = ReturnType<typeof useBackupVersionsQuery>;
export type BackupVersionsLazyQueryHookResult = ReturnType<typeof useBackupVersionsLazyQuery>;
export type BackupVersionsSuspenseQueryHookResult = ReturnType<typeof useBackupVersionsSuspenseQuery>;
export type BackupVersionsQueryResult = Apollo.QueryResult<IBackupVersionsQuery, IBackupVersionsQueryVariables>;
export const BackupsDocument = gql`
  query backups(
    $orderBy: BackupsOrderByEnum
    $order: QueryOrderEnum
    $status: BackupStatusEnum
    $query: String
    $page: Int
    $perPage: Int
  ) {
    backups(orderBy: $orderBy, order: $order, status: $status, query: $query, page: $page, perPage: $perPage) {
      hasNextPage
      hasPreviousPage
      nodes {
        id
        status
        updatedAt
        backupAssets {
          id
        }
        integration {
          id
          accountName
          accountUrl
          provider
        }
        backupMembers {
          id
          role
          user {
            id
          }
        }
        policy {
          destroy
        }
      }
      nodesCount
      pagesCount
    }
  }
`;

/**
 * __useBackupsQuery__
 *
 * To run a query within a React component, call `useBackupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      status: // value for 'status'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useBackupsQuery(baseOptions?: Apollo.QueryHookOptions<IBackupsQuery, IBackupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBackupsQuery, IBackupsQueryVariables>(BackupsDocument, options);
}
export function useBackupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IBackupsQuery, IBackupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBackupsQuery, IBackupsQueryVariables>(BackupsDocument, options);
}
export function useBackupsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IBackupsQuery, IBackupsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBackupsQuery, IBackupsQueryVariables>(BackupsDocument, options);
}
export type BackupsQueryHookResult = ReturnType<typeof useBackupsQuery>;
export type BackupsLazyQueryHookResult = ReturnType<typeof useBackupsLazyQuery>;
export type BackupsSuspenseQueryHookResult = ReturnType<typeof useBackupsSuspenseQuery>;
export type BackupsQueryResult = Apollo.QueryResult<IBackupsQuery, IBackupsQueryVariables>;
export const CreateBackupDocument = gql`
  mutation createBackup($input: CreateBackupInput!) {
    createBackup(input: $input) {
      errors
      success
      backup {
        id
        status
        updatedAt
        backupAssets {
          id
        }
        integration {
          id
          accountName
          accountUrl
          provider
        }
        backupMembers {
          id
          role
          user {
            id
          }
        }
        policy {
          destroy
        }
      }
    }
  }
`;
export type ICreateBackupMutationFn = Apollo.MutationFunction<ICreateBackupMutation, ICreateBackupMutationVariables>;

/**
 * __useCreateBackupMutation__
 *
 * To run a mutation, you first call `useCreateBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackupMutation, { data, loading, error }] = useCreateBackupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBackupMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateBackupMutation, ICreateBackupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateBackupMutation, ICreateBackupMutationVariables>(CreateBackupDocument, options);
}
export type CreateBackupMutationHookResult = ReturnType<typeof useCreateBackupMutation>;
export type CreateBackupMutationResult = Apollo.MutationResult<ICreateBackupMutation>;
export type CreateBackupMutationOptions = Apollo.BaseMutationOptions<
  ICreateBackupMutation,
  ICreateBackupMutationVariables
>;
export const CreateBackupAssetsDocument = gql`
  mutation createBackupAssets($input: CreateBackupAssetsInput!) {
    createBackupAssets(input: $input) {
      errors
      success
      backupAssets {
        id
        name
        url
        status
        externalId
        lastBackupVersionId
        lastBackup
        lastUpdateCheck
      }
    }
  }
`;
export type ICreateBackupAssetsMutationFn = Apollo.MutationFunction<
  ICreateBackupAssetsMutation,
  ICreateBackupAssetsMutationVariables
>;

/**
 * __useCreateBackupAssetsMutation__
 *
 * To run a mutation, you first call `useCreateBackupAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackupAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackupAssetsMutation, { data, loading, error }] = useCreateBackupAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBackupAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateBackupAssetsMutation, ICreateBackupAssetsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateBackupAssetsMutation, ICreateBackupAssetsMutationVariables>(
    CreateBackupAssetsDocument,
    options,
  );
}
export type CreateBackupAssetsMutationHookResult = ReturnType<typeof useCreateBackupAssetsMutation>;
export type CreateBackupAssetsMutationResult = Apollo.MutationResult<ICreateBackupAssetsMutation>;
export type CreateBackupAssetsMutationOptions = Apollo.BaseMutationOptions<
  ICreateBackupAssetsMutation,
  ICreateBackupAssetsMutationVariables
>;
export const DisableBackupAssetDocument = gql`
  mutation disableBackupAsset($id: ID!) {
    disableBackupAsset(input: { backupAssetId: $id }) {
      errors
      success
      backup {
        id
        status
      }
      backupAsset {
        id
        name
        externalId
        status
      }
    }
  }
`;
export type IDisableBackupAssetMutationFn = Apollo.MutationFunction<
  IDisableBackupAssetMutation,
  IDisableBackupAssetMutationVariables
>;

/**
 * __useDisableBackupAssetMutation__
 *
 * To run a mutation, you first call `useDisableBackupAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableBackupAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableBackupAssetMutation, { data, loading, error }] = useDisableBackupAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableBackupAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IDisableBackupAssetMutation, IDisableBackupAssetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDisableBackupAssetMutation, IDisableBackupAssetMutationVariables>(
    DisableBackupAssetDocument,
    options,
  );
}
export type DisableBackupAssetMutationHookResult = ReturnType<typeof useDisableBackupAssetMutation>;
export type DisableBackupAssetMutationResult = Apollo.MutationResult<IDisableBackupAssetMutation>;
export type DisableBackupAssetMutationOptions = Apollo.BaseMutationOptions<
  IDisableBackupAssetMutation,
  IDisableBackupAssetMutationVariables
>;
export const EnableBackupAssetDocument = gql`
  mutation enableBackupAsset($input: EnableBackupAssetInput!) {
    enableBackupAsset(input: $input) {
      errors
      success
      backupAsset {
        id
        name
        externalId
        status
        lastBackup
        lastBackupVersionId
        lastUpdateCheck
        url
      }
    }
  }
`;
export type IEnableBackupAssetMutationFn = Apollo.MutationFunction<
  IEnableBackupAssetMutation,
  IEnableBackupAssetMutationVariables
>;

/**
 * __useEnableBackupAssetMutation__
 *
 * To run a mutation, you first call `useEnableBackupAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableBackupAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableBackupAssetMutation, { data, loading, error }] = useEnableBackupAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableBackupAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IEnableBackupAssetMutation, IEnableBackupAssetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IEnableBackupAssetMutation, IEnableBackupAssetMutationVariables>(
    EnableBackupAssetDocument,
    options,
  );
}
export type EnableBackupAssetMutationHookResult = ReturnType<typeof useEnableBackupAssetMutation>;
export type EnableBackupAssetMutationResult = Apollo.MutationResult<IEnableBackupAssetMutation>;
export type EnableBackupAssetMutationOptions = Apollo.BaseMutationOptions<
  IEnableBackupAssetMutation,
  IEnableBackupAssetMutationVariables
>;
export const RemoveBackupDocument = gql`
  mutation removeBackup($id: ID!) {
    removeBackup(input: { backupId: $id }) {
      errors
      success
      backup {
        id
        status
        updatedAt
        backupAssets {
          id
        }
        integration {
          id
          accountName
          accountUrl
          provider
        }
        backupMembers {
          id
          role
          user {
            id
          }
        }
        policy {
          assignMembers
          destroy
          download
          enableReports
          start
          stop
          update
        }
      }
    }
  }
`;
export type IRemoveBackupMutationFn = Apollo.MutationFunction<IRemoveBackupMutation, IRemoveBackupMutationVariables>;

/**
 * __useRemoveBackupMutation__
 *
 * To run a mutation, you first call `useRemoveBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBackupMutation, { data, loading, error }] = useRemoveBackupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBackupMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveBackupMutation, IRemoveBackupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveBackupMutation, IRemoveBackupMutationVariables>(RemoveBackupDocument, options);
}
export type RemoveBackupMutationHookResult = ReturnType<typeof useRemoveBackupMutation>;
export type RemoveBackupMutationResult = Apollo.MutationResult<IRemoveBackupMutation>;
export type RemoveBackupMutationOptions = Apollo.BaseMutationOptions<
  IRemoveBackupMutation,
  IRemoveBackupMutationVariables
>;
export const RemoveBackupAssetDocument = gql`
  mutation removeBackupAsset($id: ID!) {
    removeBackupAsset(input: { backupAssetId: $id }) {
      errors
      success
      backup {
        id
        status
      }
    }
  }
`;
export type IRemoveBackupAssetMutationFn = Apollo.MutationFunction<
  IRemoveBackupAssetMutation,
  IRemoveBackupAssetMutationVariables
>;

/**
 * __useRemoveBackupAssetMutation__
 *
 * To run a mutation, you first call `useRemoveBackupAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBackupAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBackupAssetMutation, { data, loading, error }] = useRemoveBackupAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBackupAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveBackupAssetMutation, IRemoveBackupAssetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveBackupAssetMutation, IRemoveBackupAssetMutationVariables>(
    RemoveBackupAssetDocument,
    options,
  );
}
export type RemoveBackupAssetMutationHookResult = ReturnType<typeof useRemoveBackupAssetMutation>;
export type RemoveBackupAssetMutationResult = Apollo.MutationResult<IRemoveBackupAssetMutation>;
export type RemoveBackupAssetMutationOptions = Apollo.BaseMutationOptions<
  IRemoveBackupAssetMutation,
  IRemoveBackupAssetMutationVariables
>;
export const RequestBackupVersionDownloadDocument = gql`
  mutation requestBackupVersionDownload($backupAssetId: ID!, $backupVersionId: ID!) {
    requestBackupVersionDownload(input: { backupAssetId: $backupAssetId, backupVersionId: $backupVersionId }) {
      success
      errors
    }
  }
`;
export type IRequestBackupVersionDownloadMutationFn = Apollo.MutationFunction<
  IRequestBackupVersionDownloadMutation,
  IRequestBackupVersionDownloadMutationVariables
>;

/**
 * __useRequestBackupVersionDownloadMutation__
 *
 * To run a mutation, you first call `useRequestBackupVersionDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBackupVersionDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBackupVersionDownloadMutation, { data, loading, error }] = useRequestBackupVersionDownloadMutation({
 *   variables: {
 *      backupAssetId: // value for 'backupAssetId'
 *      backupVersionId: // value for 'backupVersionId'
 *   },
 * });
 */
export function useRequestBackupVersionDownloadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRequestBackupVersionDownloadMutation,
    IRequestBackupVersionDownloadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRequestBackupVersionDownloadMutation, IRequestBackupVersionDownloadMutationVariables>(
    RequestBackupVersionDownloadDocument,
    options,
  );
}
export type RequestBackupVersionDownloadMutationHookResult = ReturnType<typeof useRequestBackupVersionDownloadMutation>;
export type RequestBackupVersionDownloadMutationResult = Apollo.MutationResult<IRequestBackupVersionDownloadMutation>;
export type RequestBackupVersionDownloadMutationOptions = Apollo.BaseMutationOptions<
  IRequestBackupVersionDownloadMutation,
  IRequestBackupVersionDownloadMutationVariables
>;
export const StartBackupDocument = gql`
  mutation startBackup($id: ID!) {
    startBackup(input: { backupId: $id }) {
      errors
      success
      backup {
        id
        status
        updatedAt
        backupAssets {
          id
        }
        integration {
          id
          accountName
          accountUrl
          provider
        }
      }
    }
  }
`;
export type IStartBackupMutationFn = Apollo.MutationFunction<IStartBackupMutation, IStartBackupMutationVariables>;

/**
 * __useStartBackupMutation__
 *
 * To run a mutation, you first call `useStartBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startBackupMutation, { data, loading, error }] = useStartBackupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartBackupMutation(
  baseOptions?: Apollo.MutationHookOptions<IStartBackupMutation, IStartBackupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IStartBackupMutation, IStartBackupMutationVariables>(StartBackupDocument, options);
}
export type StartBackupMutationHookResult = ReturnType<typeof useStartBackupMutation>;
export type StartBackupMutationResult = Apollo.MutationResult<IStartBackupMutation>;
export type StartBackupMutationOptions = Apollo.BaseMutationOptions<
  IStartBackupMutation,
  IStartBackupMutationVariables
>;
export const StopBackupDocument = gql`
  mutation stopBackup($id: ID!) {
    stopBackup(input: { backupId: $id }) {
      errors
      success
      backup {
        id
        status
      }
    }
  }
`;
export type IStopBackupMutationFn = Apollo.MutationFunction<IStopBackupMutation, IStopBackupMutationVariables>;

/**
 * __useStopBackupMutation__
 *
 * To run a mutation, you first call `useStopBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopBackupMutation, { data, loading, error }] = useStopBackupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStopBackupMutation(
  baseOptions?: Apollo.MutationHookOptions<IStopBackupMutation, IStopBackupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IStopBackupMutation, IStopBackupMutationVariables>(StopBackupDocument, options);
}
export type StopBackupMutationHookResult = ReturnType<typeof useStopBackupMutation>;
export type StopBackupMutationResult = Apollo.MutationResult<IStopBackupMutation>;
export type StopBackupMutationOptions = Apollo.BaseMutationOptions<IStopBackupMutation, IStopBackupMutationVariables>;
export const UpdateBackupDocument = gql`
  mutation updateBackup($input: UpdateBackupInput!) {
    updateBackup(input: $input) {
      errors
      success
      backup {
        id
      }
    }
  }
`;
export type IUpdateBackupMutationFn = Apollo.MutationFunction<IUpdateBackupMutation, IUpdateBackupMutationVariables>;

/**
 * __useUpdateBackupMutation__
 *
 * To run a mutation, you first call `useUpdateBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBackupMutation, { data, loading, error }] = useUpdateBackupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBackupMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateBackupMutation, IUpdateBackupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateBackupMutation, IUpdateBackupMutationVariables>(UpdateBackupDocument, options);
}
export type UpdateBackupMutationHookResult = ReturnType<typeof useUpdateBackupMutation>;
export type UpdateBackupMutationResult = Apollo.MutationResult<IUpdateBackupMutation>;
export type UpdateBackupMutationOptions = Apollo.BaseMutationOptions<
  IUpdateBackupMutation,
  IUpdateBackupMutationVariables
>;
export const CreateBeneficiaryDocument = gql`
  mutation createBeneficiary($escrowId: ID!, $beneficiaryInputParams: EscrowPartyInput!) {
    createBeneficiary(input: { escrowId: $escrowId, beneficiaryInputParams: $beneficiaryInputParams }) {
      success
      errors
      beneficiary {
        escrow {
          id
          beneficiaryConfigured
          beneficiary {
            id
            companyName
            companyWebsite
            companyRegistrationNumber
            city
            region
            postalCode
            country
            street
            streetNumber
          }
          policy {
            createBeneficiary
          }
        }
      }
    }
  }
`;
export type ICreateBeneficiaryMutationFn = Apollo.MutationFunction<
  ICreateBeneficiaryMutation,
  ICreateBeneficiaryMutationVariables
>;

/**
 * __useCreateBeneficiaryMutation__
 *
 * To run a mutation, you first call `useCreateBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBeneficiaryMutation, { data, loading, error }] = useCreateBeneficiaryMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      beneficiaryInputParams: // value for 'beneficiaryInputParams'
 *   },
 * });
 */
export function useCreateBeneficiaryMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateBeneficiaryMutation, ICreateBeneficiaryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateBeneficiaryMutation, ICreateBeneficiaryMutationVariables>(
    CreateBeneficiaryDocument,
    options,
  );
}
export type CreateBeneficiaryMutationHookResult = ReturnType<typeof useCreateBeneficiaryMutation>;
export type CreateBeneficiaryMutationResult = Apollo.MutationResult<ICreateBeneficiaryMutation>;
export type CreateBeneficiaryMutationOptions = Apollo.BaseMutationOptions<
  ICreateBeneficiaryMutation,
  ICreateBeneficiaryMutationVariables
>;
export const InviteOwnerBeneficiaryDocument = gql`
  mutation inviteOwnerBeneficiary($escrowId: ID!, $administrativeInputUser: UserInput!) {
    inviteOwnerBeneficiary(input: { escrowId: $escrowId, administrativeInputUser: $administrativeInputUser }) {
      success
      errors
      escrowInvitation {
        ...EscrowInvitationFields
        escrow {
          id
          beneficiaryConfigured
        }
      }
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
`;
export type IInviteOwnerBeneficiaryMutationFn = Apollo.MutationFunction<
  IInviteOwnerBeneficiaryMutation,
  IInviteOwnerBeneficiaryMutationVariables
>;

/**
 * __useInviteOwnerBeneficiaryMutation__
 *
 * To run a mutation, you first call `useInviteOwnerBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOwnerBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOwnerBeneficiaryMutation, { data, loading, error }] = useInviteOwnerBeneficiaryMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      administrativeInputUser: // value for 'administrativeInputUser'
 *   },
 * });
 */
export function useInviteOwnerBeneficiaryMutation(
  baseOptions?: Apollo.MutationHookOptions<IInviteOwnerBeneficiaryMutation, IInviteOwnerBeneficiaryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IInviteOwnerBeneficiaryMutation, IInviteOwnerBeneficiaryMutationVariables>(
    InviteOwnerBeneficiaryDocument,
    options,
  );
}
export type InviteOwnerBeneficiaryMutationHookResult = ReturnType<typeof useInviteOwnerBeneficiaryMutation>;
export type InviteOwnerBeneficiaryMutationResult = Apollo.MutationResult<IInviteOwnerBeneficiaryMutation>;
export type InviteOwnerBeneficiaryMutationOptions = Apollo.BaseMutationOptions<
  IInviteOwnerBeneficiaryMutation,
  IInviteOwnerBeneficiaryMutationVariables
>;
export const UpdateBeneficiaryDocument = gql`
  mutation updateBeneficiary($beneficiaryId: ID!, $beneficiaryInputParams: EscrowPartyInput!) {
    updateBeneficiary(input: { beneficiaryId: $beneficiaryId, beneficiaryInputParams: $beneficiaryInputParams }) {
      success
      errors
      beneficiary {
        city
        companyName
        companyRegistrationNumber
        companyWebsite
        country
        id
        postalCode
        region
        street
        streetNumber
      }
    }
  }
`;
export type IUpdateBeneficiaryMutationFn = Apollo.MutationFunction<
  IUpdateBeneficiaryMutation,
  IUpdateBeneficiaryMutationVariables
>;

/**
 * __useUpdateBeneficiaryMutation__
 *
 * To run a mutation, you first call `useUpdateBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBeneficiaryMutation, { data, loading, error }] = useUpdateBeneficiaryMutation({
 *   variables: {
 *      beneficiaryId: // value for 'beneficiaryId'
 *      beneficiaryInputParams: // value for 'beneficiaryInputParams'
 *   },
 * });
 */
export function useUpdateBeneficiaryMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateBeneficiaryMutation, IUpdateBeneficiaryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateBeneficiaryMutation, IUpdateBeneficiaryMutationVariables>(
    UpdateBeneficiaryDocument,
    options,
  );
}
export type UpdateBeneficiaryMutationHookResult = ReturnType<typeof useUpdateBeneficiaryMutation>;
export type UpdateBeneficiaryMutationResult = Apollo.MutationResult<IUpdateBeneficiaryMutation>;
export type UpdateBeneficiaryMutationOptions = Apollo.BaseMutationOptions<
  IUpdateBeneficiaryMutation,
  IUpdateBeneficiaryMutationVariables
>;
export const AcceptExclusiveOfferDocument = gql`
  mutation acceptExclusiveOffer($clientMutationId: String) {
    acceptExclusiveOffer(input: { clientMutationId: $clientMutationId }) {
      success
      errors
    }
  }
`;
export type IAcceptExclusiveOfferMutationFn = Apollo.MutationFunction<
  IAcceptExclusiveOfferMutation,
  IAcceptExclusiveOfferMutationVariables
>;

/**
 * __useAcceptExclusiveOfferMutation__
 *
 * To run a mutation, you first call `useAcceptExclusiveOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptExclusiveOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptExclusiveOfferMutation, { data, loading, error }] = useAcceptExclusiveOfferMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useAcceptExclusiveOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<IAcceptExclusiveOfferMutation, IAcceptExclusiveOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IAcceptExclusiveOfferMutation, IAcceptExclusiveOfferMutationVariables>(
    AcceptExclusiveOfferDocument,
    options,
  );
}
export type AcceptExclusiveOfferMutationHookResult = ReturnType<typeof useAcceptExclusiveOfferMutation>;
export type AcceptExclusiveOfferMutationResult = Apollo.MutationResult<IAcceptExclusiveOfferMutation>;
export type AcceptExclusiveOfferMutationOptions = Apollo.BaseMutationOptions<
  IAcceptExclusiveOfferMutation,
  IAcceptExclusiveOfferMutationVariables
>;
export const ApplyCouponDocument = gql`
  mutation applyCoupon($couponCode: String!) {
    applyCoupon(input: { couponCode: $couponCode }) {
      success
      errors
      company {
        id
        subscriptionExclusiveCouponAppliedAt
      }
    }
  }
`;
export type IApplyCouponMutationFn = Apollo.MutationFunction<IApplyCouponMutation, IApplyCouponMutationVariables>;

/**
 * __useApplyCouponMutation__
 *
 * To run a mutation, you first call `useApplyCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCouponMutation, { data, loading, error }] = useApplyCouponMutation({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useApplyCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<IApplyCouponMutation, IApplyCouponMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IApplyCouponMutation, IApplyCouponMutationVariables>(ApplyCouponDocument, options);
}
export type ApplyCouponMutationHookResult = ReturnType<typeof useApplyCouponMutation>;
export type ApplyCouponMutationResult = Apollo.MutationResult<IApplyCouponMutation>;
export type ApplyCouponMutationOptions = Apollo.BaseMutationOptions<
  IApplyCouponMutation,
  IApplyCouponMutationVariables
>;
export const BillingDocument = gql`
  query billing {
    billing {
      subscriptions {
        plans {
          id
          name
          currencyCode
          period
          periodUnit
          price
        }
        billingPeriod
        billingPeriodUnit
        currencyCode
        coupons
        status
        addons {
          name
          price
          currencyCode
          quantity
          unitPrice
          periodUnit
        }
        total
      }
      paymentMethod {
        cardLast4
        cardType
        status
      }
      billingAddress {
        addressLine1
        addressLine2
        addressLine3
        city
        companyName
        country
        firstName
        lastName
        state
        zip
        email
        companyRegistrationNumber
      }
    }
  }
`;

/**
 * __useBillingQuery__
 *
 * To run a query within a React component, call `useBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingQuery(baseOptions?: Apollo.QueryHookOptions<IBillingQuery, IBillingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBillingQuery, IBillingQueryVariables>(BillingDocument, options);
}
export function useBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IBillingQuery, IBillingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBillingQuery, IBillingQueryVariables>(BillingDocument, options);
}
export function useBillingSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IBillingQuery, IBillingQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBillingQuery, IBillingQueryVariables>(BillingDocument, options);
}
export type BillingQueryHookResult = ReturnType<typeof useBillingQuery>;
export type BillingLazyQueryHookResult = ReturnType<typeof useBillingLazyQuery>;
export type BillingSuspenseQueryHookResult = ReturnType<typeof useBillingSuspenseQuery>;
export type BillingQueryResult = Apollo.QueryResult<IBillingQuery, IBillingQueryVariables>;
export const CanCancelSubscriptionDocument = gql`
  query canCancelSubscription {
    invoices(unpaid: true) {
      nodes {
        id
      }
    }
    terminatedEscrows: escrows(statusFilter: terminated) {
      nodesCount
    }
    allEscrows: escrows {
      nodesCount
    }
    removedBackups: backups(status: removed) {
      nodesCount
    }
    allBackups: backups {
      nodesCount
    }
    allSaasEnvironments: saasEnvironments {
      nodesCount
    }
    terminatedSaasEnvironments: saasEnvironments(status: terminated) {
      nodesCount
    }
  }
`;

/**
 * __useCanCancelSubscriptionQuery__
 *
 * To run a query within a React component, call `useCanCancelSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanCancelSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanCancelSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanCancelSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<ICanCancelSubscriptionQuery, ICanCancelSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICanCancelSubscriptionQuery, ICanCancelSubscriptionQueryVariables>(
    CanCancelSubscriptionDocument,
    options,
  );
}
export function useCanCancelSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICanCancelSubscriptionQuery, ICanCancelSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICanCancelSubscriptionQuery, ICanCancelSubscriptionQueryVariables>(
    CanCancelSubscriptionDocument,
    options,
  );
}
export function useCanCancelSubscriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ICanCancelSubscriptionQuery, ICanCancelSubscriptionQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICanCancelSubscriptionQuery, ICanCancelSubscriptionQueryVariables>(
    CanCancelSubscriptionDocument,
    options,
  );
}
export type CanCancelSubscriptionQueryHookResult = ReturnType<typeof useCanCancelSubscriptionQuery>;
export type CanCancelSubscriptionLazyQueryHookResult = ReturnType<typeof useCanCancelSubscriptionLazyQuery>;
export type CanCancelSubscriptionSuspenseQueryHookResult = ReturnType<typeof useCanCancelSubscriptionSuspenseQuery>;
export type CanCancelSubscriptionQueryResult = Apollo.QueryResult<
  ICanCancelSubscriptionQuery,
  ICanCancelSubscriptionQueryVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription($clientMutationId: String) {
    cancelSubscription(input: { clientMutationId: $clientMutationId }) {
      success
      errors
      company {
        id
        subscriptionCancellationRequestedAt
      }
    }
  }
`;
export type ICancelSubscriptionMutationFn = Apollo.MutationFunction<
  ICancelSubscriptionMutation,
  ICancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<ICancelSubscriptionMutation, ICancelSubscriptionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICancelSubscriptionMutation, ICancelSubscriptionMutationVariables>(
    CancelSubscriptionDocument,
    options,
  );
}
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<ICancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ICancelSubscriptionMutation,
  ICancelSubscriptionMutationVariables
>;
export const ExclusiveOfferPlansDocument = gql`
  query exclusiveOfferPlans {
    exclusiveOfferPlans {
      id
      currencyCode
      discounted
      discountedPrice
      name
      periodUnit
      price
      status
    }
  }
`;

/**
 * __useExclusiveOfferPlansQuery__
 *
 * To run a query within a React component, call `useExclusiveOfferPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useExclusiveOfferPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExclusiveOfferPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useExclusiveOfferPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<IExclusiveOfferPlansQuery, IExclusiveOfferPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IExclusiveOfferPlansQuery, IExclusiveOfferPlansQueryVariables>(
    ExclusiveOfferPlansDocument,
    options,
  );
}
export function useExclusiveOfferPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IExclusiveOfferPlansQuery, IExclusiveOfferPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IExclusiveOfferPlansQuery, IExclusiveOfferPlansQueryVariables>(
    ExclusiveOfferPlansDocument,
    options,
  );
}
export function useExclusiveOfferPlansSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IExclusiveOfferPlansQuery, IExclusiveOfferPlansQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IExclusiveOfferPlansQuery, IExclusiveOfferPlansQueryVariables>(
    ExclusiveOfferPlansDocument,
    options,
  );
}
export type ExclusiveOfferPlansQueryHookResult = ReturnType<typeof useExclusiveOfferPlansQuery>;
export type ExclusiveOfferPlansLazyQueryHookResult = ReturnType<typeof useExclusiveOfferPlansLazyQuery>;
export type ExclusiveOfferPlansSuspenseQueryHookResult = ReturnType<typeof useExclusiveOfferPlansSuspenseQuery>;
export type ExclusiveOfferPlansQueryResult = Apollo.QueryResult<
  IExclusiveOfferPlansQuery,
  IExclusiveOfferPlansQueryVariables
>;
export const SubscriptionNextDateDocument = gql`
  query subscriptionNextDate {
    billing {
      subscriptions {
        nextBillingAt
      }
    }
  }
`;

/**
 * __useSubscriptionNextDateQuery__
 *
 * To run a query within a React component, call `useSubscriptionNextDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionNextDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionNextDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionNextDateQuery(
  baseOptions?: Apollo.QueryHookOptions<ISubscriptionNextDateQuery, ISubscriptionNextDateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISubscriptionNextDateQuery, ISubscriptionNextDateQueryVariables>(
    SubscriptionNextDateDocument,
    options,
  );
}
export function useSubscriptionNextDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISubscriptionNextDateQuery, ISubscriptionNextDateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISubscriptionNextDateQuery, ISubscriptionNextDateQueryVariables>(
    SubscriptionNextDateDocument,
    options,
  );
}
export function useSubscriptionNextDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ISubscriptionNextDateQuery, ISubscriptionNextDateQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISubscriptionNextDateQuery, ISubscriptionNextDateQueryVariables>(
    SubscriptionNextDateDocument,
    options,
  );
}
export type SubscriptionNextDateQueryHookResult = ReturnType<typeof useSubscriptionNextDateQuery>;
export type SubscriptionNextDateLazyQueryHookResult = ReturnType<typeof useSubscriptionNextDateLazyQuery>;
export type SubscriptionNextDateSuspenseQueryHookResult = ReturnType<typeof useSubscriptionNextDateSuspenseQuery>;
export type SubscriptionNextDateQueryResult = Apollo.QueryResult<
  ISubscriptionNextDateQuery,
  ISubscriptionNextDateQueryVariables
>;
export const SwitchToBackupPlanDocument = gql`
  mutation switchToBackupPlan($clientMutationId: String) {
    switchToBackupPlan(input: { clientMutationId: $clientMutationId }) {
      success
      errors
    }
  }
`;
export type ISwitchToBackupPlanMutationFn = Apollo.MutationFunction<
  ISwitchToBackupPlanMutation,
  ISwitchToBackupPlanMutationVariables
>;

/**
 * __useSwitchToBackupPlanMutation__
 *
 * To run a mutation, you first call `useSwitchToBackupPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchToBackupPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchToBackupPlanMutation, { data, loading, error }] = useSwitchToBackupPlanMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useSwitchToBackupPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<ISwitchToBackupPlanMutation, ISwitchToBackupPlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISwitchToBackupPlanMutation, ISwitchToBackupPlanMutationVariables>(
    SwitchToBackupPlanDocument,
    options,
  );
}
export type SwitchToBackupPlanMutationHookResult = ReturnType<typeof useSwitchToBackupPlanMutation>;
export type SwitchToBackupPlanMutationResult = Apollo.MutationResult<ISwitchToBackupPlanMutation>;
export type SwitchToBackupPlanMutationOptions = Apollo.BaseMutationOptions<
  ISwitchToBackupPlanMutation,
  ISwitchToBackupPlanMutationVariables
>;
export const CompanyDocument = gql`
  query company {
    company {
      city
      invitationStatus
      companyName
      companyRegistration
      companyWebsite
      country
      id
      postalCode
      region
      street
      streetNumber
      users {
        role
        email
        id
        name
        phone
      }
      subscriptionCancellationRequestedAt
      subscriptionExclusiveCouponAppliedAt
      updateableBilling
    }
  }
`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyQuery(baseOptions?: Apollo.QueryHookOptions<ICompanyQuery, ICompanyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICompanyQuery, ICompanyQueryVariables>(CompanyDocument, options);
}
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICompanyQuery, ICompanyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICompanyQuery, ICompanyQueryVariables>(CompanyDocument, options);
}
export function useCompanySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ICompanyQuery, ICompanyQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICompanyQuery, ICompanyQueryVariables>(CompanyDocument, options);
}
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanySuspenseQueryHookResult = ReturnType<typeof useCompanySuspenseQuery>;
export type CompanyQueryResult = Apollo.QueryResult<ICompanyQuery, ICompanyQueryVariables>;
export const CompanyUsersDocument = gql`
  query companyUsers {
    companyUsers: company {
      users {
        id
        role
        email
        name
      }
    }
  }
`;

/**
 * __useCompanyUsersQuery__
 *
 * To run a query within a React component, call `useCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ICompanyUsersQuery, ICompanyUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICompanyUsersQuery, ICompanyUsersQueryVariables>(CompanyUsersDocument, options);
}
export function useCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICompanyUsersQuery, ICompanyUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICompanyUsersQuery, ICompanyUsersQueryVariables>(CompanyUsersDocument, options);
}
export function useCompanyUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ICompanyUsersQuery, ICompanyUsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICompanyUsersQuery, ICompanyUsersQueryVariables>(CompanyUsersDocument, options);
}
export type CompanyUsersQueryHookResult = ReturnType<typeof useCompanyUsersQuery>;
export type CompanyUsersLazyQueryHookResult = ReturnType<typeof useCompanyUsersLazyQuery>;
export type CompanyUsersSuspenseQueryHookResult = ReturnType<typeof useCompanyUsersSuspenseQuery>;
export type CompanyUsersQueryResult = Apollo.QueryResult<ICompanyUsersQuery, ICompanyUsersQueryVariables>;
export const IsUpdateableBillingDocument = gql`
  query isUpdateableBilling {
    company {
      updateableBilling
    }
  }
`;

/**
 * __useIsUpdateableBillingQuery__
 *
 * To run a query within a React component, call `useIsUpdateableBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUpdateableBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUpdateableBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUpdateableBillingQuery(
  baseOptions?: Apollo.QueryHookOptions<IIsUpdateableBillingQuery, IIsUpdateableBillingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IIsUpdateableBillingQuery, IIsUpdateableBillingQueryVariables>(
    IsUpdateableBillingDocument,
    options,
  );
}
export function useIsUpdateableBillingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IIsUpdateableBillingQuery, IIsUpdateableBillingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IIsUpdateableBillingQuery, IIsUpdateableBillingQueryVariables>(
    IsUpdateableBillingDocument,
    options,
  );
}
export function useIsUpdateableBillingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IIsUpdateableBillingQuery, IIsUpdateableBillingQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IIsUpdateableBillingQuery, IIsUpdateableBillingQueryVariables>(
    IsUpdateableBillingDocument,
    options,
  );
}
export type IsUpdateableBillingQueryHookResult = ReturnType<typeof useIsUpdateableBillingQuery>;
export type IsUpdateableBillingLazyQueryHookResult = ReturnType<typeof useIsUpdateableBillingLazyQuery>;
export type IsUpdateableBillingSuspenseQueryHookResult = ReturnType<typeof useIsUpdateableBillingSuspenseQuery>;
export type IsUpdateableBillingQueryResult = Apollo.QueryResult<
  IIsUpdateableBillingQuery,
  IIsUpdateableBillingQueryVariables
>;
export const UpdateCompanyDetailsDocument = gql`
  mutation updateCompanyDetails($company: CompanyInput!) {
    updateCompanyDetails(input: { company: $company }) {
      success
      errors
      company {
        city
        companyName
        companyRegistration
        companyWebsite
        country
        id
        postalCode
        region
        street
        invitationStatus
        streetNumber
      }
    }
  }
`;
export type IUpdateCompanyDetailsMutationFn = Apollo.MutationFunction<
  IUpdateCompanyDetailsMutation,
  IUpdateCompanyDetailsMutationVariables
>;

/**
 * __useUpdateCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyDetailsMutation, { data, loading, error }] = useUpdateCompanyDetailsMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateCompanyDetailsMutation, IUpdateCompanyDetailsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateCompanyDetailsMutation, IUpdateCompanyDetailsMutationVariables>(
    UpdateCompanyDetailsDocument,
    options,
  );
}
export type UpdateCompanyDetailsMutationHookResult = ReturnType<typeof useUpdateCompanyDetailsMutation>;
export type UpdateCompanyDetailsMutationResult = Apollo.MutationResult<IUpdateCompanyDetailsMutation>;
export type UpdateCompanyDetailsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCompanyDetailsMutation,
  IUpdateCompanyDetailsMutationVariables
>;
export const CreateContactDocument = gql`
  mutation createContact($escrowPartyId: ID!, $contactParams: ContactCreateInput!) {
    createContact(input: { escrowPartyId: $escrowPartyId, contactParams: $contactParams }) {
      success
      errors
      contact {
        ...ContactFragment
        escrow {
          id
          depositorConfigured
          beneficiaryConfigured
          agreement {
            policy {
              generate
            }
          }
        }
      }
    }
  }
  ${ContactFragmentFragmentDoc}
`;
export type ICreateContactMutationFn = Apollo.MutationFunction<ICreateContactMutation, ICreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      escrowPartyId: // value for 'escrowPartyId'
 *      contactParams: // value for 'contactParams'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateContactMutation, ICreateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateContactMutation, ICreateContactMutationVariables>(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<ICreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  ICreateContactMutation,
  ICreateContactMutationVariables
>;
export const RemoveContactDocument = gql`
  mutation removeContact($contactId: ID!) {
    removeContact(input: { contactId: $contactId }) {
      success
      errors
      escrow {
        id
        depositorConfigured
        beneficiaryConfigured
        agreement {
          policy {
            generate
          }
        }
      }
    }
  }
`;
export type IRemoveContactMutationFn = Apollo.MutationFunction<IRemoveContactMutation, IRemoveContactMutationVariables>;

/**
 * __useRemoveContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactMutation, { data, loading, error }] = useRemoveContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useRemoveContactMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveContactMutation, IRemoveContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveContactMutation, IRemoveContactMutationVariables>(RemoveContactDocument, options);
}
export type RemoveContactMutationHookResult = ReturnType<typeof useRemoveContactMutation>;
export type RemoveContactMutationResult = Apollo.MutationResult<IRemoveContactMutation>;
export type RemoveContactMutationOptions = Apollo.BaseMutationOptions<
  IRemoveContactMutation,
  IRemoveContactMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation updateContact($contactId: ID!, $contactParams: ContactUpdateInput!) {
    updateContact(input: { contactId: $contactId, contactParams: $contactParams }) {
      success
      errors
      contact {
        ...ContactFragment
        escrow {
          id
          depositorConfigured
          beneficiaryConfigured
          agreement {
            policy {
              generate
            }
          }
        }
      }
    }
  }
  ${ContactFragmentFragmentDoc}
`;
export type IUpdateContactMutationFn = Apollo.MutationFunction<IUpdateContactMutation, IUpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      contactParams: // value for 'contactParams'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateContactMutation, IUpdateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateContactMutation, IUpdateContactMutationVariables>(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<IUpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  IUpdateContactMutation,
  IUpdateContactMutationVariables
>;
export const CreateCustodianOperationDocument = gql`
  mutation createCustodianOperation(
    $custodianProfileId: ID!
    $custodianOperationParams: CustodianOperationCreateInput!
  ) {
    createCustodianOperation(
      input: { custodianProfileId: $custodianProfileId, custodianOperationParams: $custodianOperationParams }
    ) {
      success
      errors
      custodianOperation {
        id
        custodianServiceEvents
        custodianServiceEventsCustom
        dataRetention
        endOfRetentionPeriod
        endOfRetentionPeriodCustom
        operationType
        operationTypeCustom
        regulatoryRequirements
        regulatoryRequirementsCustom
      }
    }
  }
`;
export type ICreateCustodianOperationMutationFn = Apollo.MutationFunction<
  ICreateCustodianOperationMutation,
  ICreateCustodianOperationMutationVariables
>;

/**
 * __useCreateCustodianOperationMutation__
 *
 * To run a mutation, you first call `useCreateCustodianOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustodianOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustodianOperationMutation, { data, loading, error }] = useCreateCustodianOperationMutation({
 *   variables: {
 *      custodianProfileId: // value for 'custodianProfileId'
 *      custodianOperationParams: // value for 'custodianOperationParams'
 *   },
 * });
 */
export function useCreateCustodianOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateCustodianOperationMutation,
    ICreateCustodianOperationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateCustodianOperationMutation, ICreateCustodianOperationMutationVariables>(
    CreateCustodianOperationDocument,
    options,
  );
}
export type CreateCustodianOperationMutationHookResult = ReturnType<typeof useCreateCustodianOperationMutation>;
export type CreateCustodianOperationMutationResult = Apollo.MutationResult<ICreateCustodianOperationMutation>;
export type CreateCustodianOperationMutationOptions = Apollo.BaseMutationOptions<
  ICreateCustodianOperationMutation,
  ICreateCustodianOperationMutationVariables
>;
export const CreateCustodianProfileDocument = gql`
  mutation createCustodianProfile($clientMutationId: String) {
    createCustodianProfile(input: { clientMutationId: $clientMutationId }) {
      errors
      success
      custodianProfile {
        id
        name
        status
        custodianAssets {
          id
        }
        custodianIntegrations {
          id
        }
        custodianOperations {
          id
        }
        custodianTermination {
          id
          status
        }
        policy {
          activate
          remove
          requestTermination
          submitForReview
          update
        }
      }
    }
  }
`;
export type ICreateCustodianProfileMutationFn = Apollo.MutationFunction<
  ICreateCustodianProfileMutation,
  ICreateCustodianProfileMutationVariables
>;

/**
 * __useCreateCustodianProfileMutation__
 *
 * To run a mutation, you first call `useCreateCustodianProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustodianProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustodianProfileMutation, { data, loading, error }] = useCreateCustodianProfileMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateCustodianProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateCustodianProfileMutation, ICreateCustodianProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateCustodianProfileMutation, ICreateCustodianProfileMutationVariables>(
    CreateCustodianProfileDocument,
    options,
  );
}
export type CreateCustodianProfileMutationHookResult = ReturnType<typeof useCreateCustodianProfileMutation>;
export type CreateCustodianProfileMutationResult = Apollo.MutationResult<ICreateCustodianProfileMutation>;
export type CreateCustodianProfileMutationOptions = Apollo.BaseMutationOptions<
  ICreateCustodianProfileMutation,
  ICreateCustodianProfileMutationVariables
>;
export const CustodianProfileDocument = gql`
  query custodianProfile($id: ID!) {
    custodianProfile(custodianProfileId: $id) {
      id
      name
      status
      custodianAssets {
        id
        name
        operationalStatus
        externalId
        custodianStatus
        custodianIntegration {
          id
          integration {
            id
            provider
          }
        }
        updatedAt
      }
      custodianIntegrations {
        id
        status
        integration {
          id
          provider
          accountName
        }
        custodianAssets {
          id
          name
          operationalStatus
          externalId
          custodianStatus
        }
      }
      custodianOperations {
        id
        custodianServiceEvents
        custodianServiceEventsCustom
        dataRetention
        endOfRetentionPeriod
        endOfRetentionPeriodCustom
        operationType
        operationTypeCustom
        regulatoryRequirements
        regulatoryRequirementsCustom
      }
      custodianTermination {
        id
        status
      }
      policy {
        activate
        remove
        requestTermination
        submitForReview
        update
      }
      creator {
        id
        name
      }
    }
  }
`;

/**
 * __useCustodianProfileQuery__
 *
 * To run a query within a React component, call `useCustodianProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustodianProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustodianProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustodianProfileQuery(
  baseOptions: Apollo.QueryHookOptions<ICustodianProfileQuery, ICustodianProfileQueryVariables> &
    ({ variables: ICustodianProfileQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICustodianProfileQuery, ICustodianProfileQueryVariables>(CustodianProfileDocument, options);
}
export function useCustodianProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICustodianProfileQuery, ICustodianProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICustodianProfileQuery, ICustodianProfileQueryVariables>(
    CustodianProfileDocument,
    options,
  );
}
export function useCustodianProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ICustodianProfileQuery, ICustodianProfileQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICustodianProfileQuery, ICustodianProfileQueryVariables>(
    CustodianProfileDocument,
    options,
  );
}
export type CustodianProfileQueryHookResult = ReturnType<typeof useCustodianProfileQuery>;
export type CustodianProfileLazyQueryHookResult = ReturnType<typeof useCustodianProfileLazyQuery>;
export type CustodianProfileSuspenseQueryHookResult = ReturnType<typeof useCustodianProfileSuspenseQuery>;
export type CustodianProfileQueryResult = Apollo.QueryResult<ICustodianProfileQuery, ICustodianProfileQueryVariables>;
export const CustodianProfilesDocument = gql`
  query custodianProfiles($page: Int, $perPage: Int) {
    custodianProfiles(page: $page, perPage: $perPage) {
      nodesCount
      pagesCount
      nodes {
        id
        name
        status
        custodianAssets {
          id
        }
        custodianIntegrations {
          id
        }
        custodianOperations {
          id
        }
        custodianTermination {
          id
          status
        }
        policy {
          remove
        }
      }
    }
  }
`;

/**
 * __useCustodianProfilesQuery__
 *
 * To run a query within a React component, call `useCustodianProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustodianProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustodianProfilesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useCustodianProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<ICustodianProfilesQuery, ICustodianProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICustodianProfilesQuery, ICustodianProfilesQueryVariables>(CustodianProfilesDocument, options);
}
export function useCustodianProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICustodianProfilesQuery, ICustodianProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICustodianProfilesQuery, ICustodianProfilesQueryVariables>(
    CustodianProfilesDocument,
    options,
  );
}
export function useCustodianProfilesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ICustodianProfilesQuery, ICustodianProfilesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ICustodianProfilesQuery, ICustodianProfilesQueryVariables>(
    CustodianProfilesDocument,
    options,
  );
}
export type CustodianProfilesQueryHookResult = ReturnType<typeof useCustodianProfilesQuery>;
export type CustodianProfilesLazyQueryHookResult = ReturnType<typeof useCustodianProfilesLazyQuery>;
export type CustodianProfilesSuspenseQueryHookResult = ReturnType<typeof useCustodianProfilesSuspenseQuery>;
export type CustodianProfilesQueryResult = Apollo.QueryResult<
  ICustodianProfilesQuery,
  ICustodianProfilesQueryVariables
>;
export const RemoveCustodianOperationDocument = gql`
  mutation removeCustodianOperation($id: ID!) {
    removeCustodianOperation(input: { custodianOperationId: $id }) {
      success
      errors
    }
  }
`;
export type IRemoveCustodianOperationMutationFn = Apollo.MutationFunction<
  IRemoveCustodianOperationMutation,
  IRemoveCustodianOperationMutationVariables
>;

/**
 * __useRemoveCustodianOperationMutation__
 *
 * To run a mutation, you first call `useRemoveCustodianOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustodianOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustodianOperationMutation, { data, loading, error }] = useRemoveCustodianOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCustodianOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRemoveCustodianOperationMutation,
    IRemoveCustodianOperationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveCustodianOperationMutation, IRemoveCustodianOperationMutationVariables>(
    RemoveCustodianOperationDocument,
    options,
  );
}
export type RemoveCustodianOperationMutationHookResult = ReturnType<typeof useRemoveCustodianOperationMutation>;
export type RemoveCustodianOperationMutationResult = Apollo.MutationResult<IRemoveCustodianOperationMutation>;
export type RemoveCustodianOperationMutationOptions = Apollo.BaseMutationOptions<
  IRemoveCustodianOperationMutation,
  IRemoveCustodianOperationMutationVariables
>;
export const RemoveCustodianProfileDocument = gql`
  mutation removeCustodianProfile($id: ID!) {
    removeCustodianProfile(input: { custodianProfileId: $id }) {
      success
      errors
    }
  }
`;
export type IRemoveCustodianProfileMutationFn = Apollo.MutationFunction<
  IRemoveCustodianProfileMutation,
  IRemoveCustodianProfileMutationVariables
>;

/**
 * __useRemoveCustodianProfileMutation__
 *
 * To run a mutation, you first call `useRemoveCustodianProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustodianProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustodianProfileMutation, { data, loading, error }] = useRemoveCustodianProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCustodianProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveCustodianProfileMutation, IRemoveCustodianProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveCustodianProfileMutation, IRemoveCustodianProfileMutationVariables>(
    RemoveCustodianProfileDocument,
    options,
  );
}
export type RemoveCustodianProfileMutationHookResult = ReturnType<typeof useRemoveCustodianProfileMutation>;
export type RemoveCustodianProfileMutationResult = Apollo.MutationResult<IRemoveCustodianProfileMutation>;
export type RemoveCustodianProfileMutationOptions = Apollo.BaseMutationOptions<
  IRemoveCustodianProfileMutation,
  IRemoveCustodianProfileMutationVariables
>;
export const SendForReviewCustodianProfileDocument = gql`
  mutation sendForReviewCustodianProfile($id: ID!) {
    sendForReviewCustodianProfile(input: { custodianProfileId: $id }) {
      success
      errors
      custodianProfile {
        id
        status
        policy {
          activate
          remove
          requestTermination
          submitForReview
          update
        }
      }
    }
  }
`;
export type ISendForReviewCustodianProfileMutationFn = Apollo.MutationFunction<
  ISendForReviewCustodianProfileMutation,
  ISendForReviewCustodianProfileMutationVariables
>;

/**
 * __useSendForReviewCustodianProfileMutation__
 *
 * To run a mutation, you first call `useSendForReviewCustodianProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendForReviewCustodianProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendForReviewCustodianProfileMutation, { data, loading, error }] = useSendForReviewCustodianProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendForReviewCustodianProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISendForReviewCustodianProfileMutation,
    ISendForReviewCustodianProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISendForReviewCustodianProfileMutation, ISendForReviewCustodianProfileMutationVariables>(
    SendForReviewCustodianProfileDocument,
    options,
  );
}
export type SendForReviewCustodianProfileMutationHookResult = ReturnType<
  typeof useSendForReviewCustodianProfileMutation
>;
export type SendForReviewCustodianProfileMutationResult = Apollo.MutationResult<ISendForReviewCustodianProfileMutation>;
export type SendForReviewCustodianProfileMutationOptions = Apollo.BaseMutationOptions<
  ISendForReviewCustodianProfileMutation,
  ISendForReviewCustodianProfileMutationVariables
>;
export const TerminateCustodianProfileDocument = gql`
  mutation terminateCustodianProfile($id: ID!) {
    terminateCustodianProfile(input: { custodianProfileId: $id }) {
      success
      errors
      custodianTermination {
        id
        custodianProfile {
          id
          custodianTermination {
            id
            status
          }
        }
      }
    }
  }
`;
export type ITerminateCustodianProfileMutationFn = Apollo.MutationFunction<
  ITerminateCustodianProfileMutation,
  ITerminateCustodianProfileMutationVariables
>;

/**
 * __useTerminateCustodianProfileMutation__
 *
 * To run a mutation, you first call `useTerminateCustodianProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCustodianProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCustodianProfileMutation, { data, loading, error }] = useTerminateCustodianProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTerminateCustodianProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ITerminateCustodianProfileMutation,
    ITerminateCustodianProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ITerminateCustodianProfileMutation, ITerminateCustodianProfileMutationVariables>(
    TerminateCustodianProfileDocument,
    options,
  );
}
export type TerminateCustodianProfileMutationHookResult = ReturnType<typeof useTerminateCustodianProfileMutation>;
export type TerminateCustodianProfileMutationResult = Apollo.MutationResult<ITerminateCustodianProfileMutation>;
export type TerminateCustodianProfileMutationOptions = Apollo.BaseMutationOptions<
  ITerminateCustodianProfileMutation,
  ITerminateCustodianProfileMutationVariables
>;
export const UpdateCustodianAssetsDocument = gql`
  mutation updateCustodianAssets(
    $custodianProfileId: ID!
    $integrationId: ID!
    $assetsInput: [CustodianAssetCreateInput!]!
  ) {
    updateCustodianAssets(
      input: { custodianProfileId: $custodianProfileId, integrationId: $integrationId, assetsInput: $assetsInput }
    ) {
      success
      errors
      custodianAssets {
        id
        name
      }
    }
  }
`;
export type IUpdateCustodianAssetsMutationFn = Apollo.MutationFunction<
  IUpdateCustodianAssetsMutation,
  IUpdateCustodianAssetsMutationVariables
>;

/**
 * __useUpdateCustodianAssetsMutation__
 *
 * To run a mutation, you first call `useUpdateCustodianAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustodianAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustodianAssetsMutation, { data, loading, error }] = useUpdateCustodianAssetsMutation({
 *   variables: {
 *      custodianProfileId: // value for 'custodianProfileId'
 *      integrationId: // value for 'integrationId'
 *      assetsInput: // value for 'assetsInput'
 *   },
 * });
 */
export function useUpdateCustodianAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateCustodianAssetsMutation, IUpdateCustodianAssetsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateCustodianAssetsMutation, IUpdateCustodianAssetsMutationVariables>(
    UpdateCustodianAssetsDocument,
    options,
  );
}
export type UpdateCustodianAssetsMutationHookResult = ReturnType<typeof useUpdateCustodianAssetsMutation>;
export type UpdateCustodianAssetsMutationResult = Apollo.MutationResult<IUpdateCustodianAssetsMutation>;
export type UpdateCustodianAssetsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCustodianAssetsMutation,
  IUpdateCustodianAssetsMutationVariables
>;
export const UpdateCustodianOperationDocument = gql`
  mutation updateCustodianOperation(
    $custodianOperationId: ID!
    $custodianOperationParams: CustodianOperationUpdateInput!
  ) {
    updateCustodianOperation(
      input: { custodianOperationId: $custodianOperationId, custodianOperationParams: $custodianOperationParams }
    ) {
      success
      errors
      custodianOperation {
        id
        custodianServiceEvents
        custodianServiceEventsCustom
        dataRetention
        endOfRetentionPeriod
        endOfRetentionPeriodCustom
        operationType
        operationTypeCustom
        regulatoryRequirements
        regulatoryRequirementsCustom
      }
    }
  }
`;
export type IUpdateCustodianOperationMutationFn = Apollo.MutationFunction<
  IUpdateCustodianOperationMutation,
  IUpdateCustodianOperationMutationVariables
>;

/**
 * __useUpdateCustodianOperationMutation__
 *
 * To run a mutation, you first call `useUpdateCustodianOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustodianOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustodianOperationMutation, { data, loading, error }] = useUpdateCustodianOperationMutation({
 *   variables: {
 *      custodianOperationId: // value for 'custodianOperationId'
 *      custodianOperationParams: // value for 'custodianOperationParams'
 *   },
 * });
 */
export function useUpdateCustodianOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateCustodianOperationMutation,
    IUpdateCustodianOperationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateCustodianOperationMutation, IUpdateCustodianOperationMutationVariables>(
    UpdateCustodianOperationDocument,
    options,
  );
}
export type UpdateCustodianOperationMutationHookResult = ReturnType<typeof useUpdateCustodianOperationMutation>;
export type UpdateCustodianOperationMutationResult = Apollo.MutationResult<IUpdateCustodianOperationMutation>;
export type UpdateCustodianOperationMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCustodianOperationMutation,
  IUpdateCustodianOperationMutationVariables
>;
export const InviteOwnerDepositorDocument = gql`
  mutation inviteOwnerDepositor($escrowId: ID!, $administrativeInputUser: UserInput!) {
    inviteOwnerDepositor(input: { escrowId: $escrowId, administrativeInputUser: $administrativeInputUser }) {
      success
      errors
      escrowInvitation {
        ...EscrowInvitationFields
        escrow {
          id
          depositorConfigured
        }
      }
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
`;
export type IInviteOwnerDepositorMutationFn = Apollo.MutationFunction<
  IInviteOwnerDepositorMutation,
  IInviteOwnerDepositorMutationVariables
>;

/**
 * __useInviteOwnerDepositorMutation__
 *
 * To run a mutation, you first call `useInviteOwnerDepositorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOwnerDepositorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOwnerDepositorMutation, { data, loading, error }] = useInviteOwnerDepositorMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      administrativeInputUser: // value for 'administrativeInputUser'
 *   },
 * });
 */
export function useInviteOwnerDepositorMutation(
  baseOptions?: Apollo.MutationHookOptions<IInviteOwnerDepositorMutation, IInviteOwnerDepositorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IInviteOwnerDepositorMutation, IInviteOwnerDepositorMutationVariables>(
    InviteOwnerDepositorDocument,
    options,
  );
}
export type InviteOwnerDepositorMutationHookResult = ReturnType<typeof useInviteOwnerDepositorMutation>;
export type InviteOwnerDepositorMutationResult = Apollo.MutationResult<IInviteOwnerDepositorMutation>;
export type InviteOwnerDepositorMutationOptions = Apollo.BaseMutationOptions<
  IInviteOwnerDepositorMutation,
  IInviteOwnerDepositorMutationVariables
>;
export const UpdateDepositSettingsDocument = gql`
  mutation updateDepositSettings($escrowId: ID!, $depositSettings: DepositSettingsInput!) {
    updateDepositSettings(input: { escrowId: $escrowId, depositSettings: $depositSettings }) {
      success
      errors
      escrow {
        id
        showDepositInformation
        beneficiaryDepositNotification
        beneficiaryDisruptionNotification
        beneficiaryDisruptionNotificationGracePeriod
        depositorDepositNotification
        depositorDisruptionNotification
        depositorDisruptionNotificationGracePeriod
        storageRegion
      }
    }
  }
`;
export type IUpdateDepositSettingsMutationFn = Apollo.MutationFunction<
  IUpdateDepositSettingsMutation,
  IUpdateDepositSettingsMutationVariables
>;

/**
 * __useUpdateDepositSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDepositSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepositSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepositSettingsMutation, { data, loading, error }] = useUpdateDepositSettingsMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      depositSettings: // value for 'depositSettings'
 *   },
 * });
 */
export function useUpdateDepositSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateDepositSettingsMutation, IUpdateDepositSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateDepositSettingsMutation, IUpdateDepositSettingsMutationVariables>(
    UpdateDepositSettingsDocument,
    options,
  );
}
export type UpdateDepositSettingsMutationHookResult = ReturnType<typeof useUpdateDepositSettingsMutation>;
export type UpdateDepositSettingsMutationResult = Apollo.MutationResult<IUpdateDepositSettingsMutation>;
export type UpdateDepositSettingsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateDepositSettingsMutation,
  IUpdateDepositSettingsMutationVariables
>;
export const UpdateDepositorDocument = gql`
  mutation updateDepositor($depositorId: ID!, $depositorInputParams: EscrowPartyInput!) {
    updateDepositor(input: { depositorId: $depositorId, depositorInputParams: $depositorInputParams }) {
      success
      errors
      depositor {
        city
        companyName
        companyRegistrationNumber
        companyWebsite
        country
        id
        postalCode
        region
        street
        streetNumber
      }
    }
  }
`;
export type IUpdateDepositorMutationFn = Apollo.MutationFunction<
  IUpdateDepositorMutation,
  IUpdateDepositorMutationVariables
>;

/**
 * __useUpdateDepositorMutation__
 *
 * To run a mutation, you first call `useUpdateDepositorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepositorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepositorMutation, { data, loading, error }] = useUpdateDepositorMutation({
 *   variables: {
 *      depositorId: // value for 'depositorId'
 *      depositorInputParams: // value for 'depositorInputParams'
 *   },
 * });
 */
export function useUpdateDepositorMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateDepositorMutation, IUpdateDepositorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateDepositorMutation, IUpdateDepositorMutationVariables>(
    UpdateDepositorDocument,
    options,
  );
}
export type UpdateDepositorMutationHookResult = ReturnType<typeof useUpdateDepositorMutation>;
export type UpdateDepositorMutationResult = Apollo.MutationResult<IUpdateDepositorMutation>;
export type UpdateDepositorMutationOptions = Apollo.BaseMutationOptions<
  IUpdateDepositorMutation,
  IUpdateDepositorMutationVariables
>;
export const AcceptEscrowInvitationDocument = gql`
  mutation acceptEscrowInvitation($escrowInvitationId: ID!) {
    acceptEscrowInvitation(input: { escrowInvitationId: $escrowInvitationId }) {
      success
      errors
      escrowInvitation {
        ...EscrowInvitationFields
      }
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
`;
export type IAcceptEscrowInvitationMutationFn = Apollo.MutationFunction<
  IAcceptEscrowInvitationMutation,
  IAcceptEscrowInvitationMutationVariables
>;

/**
 * __useAcceptEscrowInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptEscrowInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEscrowInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEscrowInvitationMutation, { data, loading, error }] = useAcceptEscrowInvitationMutation({
 *   variables: {
 *      escrowInvitationId: // value for 'escrowInvitationId'
 *   },
 * });
 */
export function useAcceptEscrowInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<IAcceptEscrowInvitationMutation, IAcceptEscrowInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IAcceptEscrowInvitationMutation, IAcceptEscrowInvitationMutationVariables>(
    AcceptEscrowInvitationDocument,
    options,
  );
}
export type AcceptEscrowInvitationMutationHookResult = ReturnType<typeof useAcceptEscrowInvitationMutation>;
export type AcceptEscrowInvitationMutationResult = Apollo.MutationResult<IAcceptEscrowInvitationMutation>;
export type AcceptEscrowInvitationMutationOptions = Apollo.BaseMutationOptions<
  IAcceptEscrowInvitationMutation,
  IAcceptEscrowInvitationMutationVariables
>;
export const AcceptEscrowTerminationDocument = gql`
  mutation acceptEscrowTermination($escrowTerminationId: ID!) {
    acceptEscrowTermination(input: { escrowTerminationId: $escrowTerminationId }) {
      success
      errors
    }
  }
`;
export type IAcceptEscrowTerminationMutationFn = Apollo.MutationFunction<
  IAcceptEscrowTerminationMutation,
  IAcceptEscrowTerminationMutationVariables
>;

/**
 * __useAcceptEscrowTerminationMutation__
 *
 * To run a mutation, you first call `useAcceptEscrowTerminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEscrowTerminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEscrowTerminationMutation, { data, loading, error }] = useAcceptEscrowTerminationMutation({
 *   variables: {
 *      escrowTerminationId: // value for 'escrowTerminationId'
 *   },
 * });
 */
export function useAcceptEscrowTerminationMutation(
  baseOptions?: Apollo.MutationHookOptions<IAcceptEscrowTerminationMutation, IAcceptEscrowTerminationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IAcceptEscrowTerminationMutation, IAcceptEscrowTerminationMutationVariables>(
    AcceptEscrowTerminationDocument,
    options,
  );
}
export type AcceptEscrowTerminationMutationHookResult = ReturnType<typeof useAcceptEscrowTerminationMutation>;
export type AcceptEscrowTerminationMutationResult = Apollo.MutationResult<IAcceptEscrowTerminationMutation>;
export type AcceptEscrowTerminationMutationOptions = Apollo.BaseMutationOptions<
  IAcceptEscrowTerminationMutation,
  IAcceptEscrowTerminationMutationVariables
>;
export const ActivateEscrowDocument = gql`
  mutation activateEscrow($escrowId: ID!) {
    activateEscrow(input: { escrowId: $escrowId }) {
      success
      errors
      escrow {
        id
        status
      }
    }
  }
`;
export type IActivateEscrowMutationFn = Apollo.MutationFunction<
  IActivateEscrowMutation,
  IActivateEscrowMutationVariables
>;

/**
 * __useActivateEscrowMutation__
 *
 * To run a mutation, you first call `useActivateEscrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateEscrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateEscrowMutation, { data, loading, error }] = useActivateEscrowMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useActivateEscrowMutation(
  baseOptions?: Apollo.MutationHookOptions<IActivateEscrowMutation, IActivateEscrowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IActivateEscrowMutation, IActivateEscrowMutationVariables>(ActivateEscrowDocument, options);
}
export type ActivateEscrowMutationHookResult = ReturnType<typeof useActivateEscrowMutation>;
export type ActivateEscrowMutationResult = Apollo.MutationResult<IActivateEscrowMutation>;
export type ActivateEscrowMutationOptions = Apollo.BaseMutationOptions<
  IActivateEscrowMutation,
  IActivateEscrowMutationVariables
>;
export const CreateEscrowDocument = gql`
  mutation createEscrow($escrowRole: EscrowPartyTypeEnum!, $agreementForm: AgreementFormEnum!) {
    createEscrow(input: { escrowRole: $escrowRole, agreementForm: $agreementForm }) {
      success
      errors
      escrow {
        id
        currentCompanyRole
        changesRequested
        agreement {
          id
          approvedByBeneficiary
          approvedByDepositor
          approvedByCodekeeper
        }
        escrowInvitation {
          ...EscrowInvitationFields
        }
        escrowTermination {
          status
          terminateAt
        }
        startedBy
        depositStatus
        status
        status
        beneficiary {
          companyName
        }
        depositor {
          companyName
        }
      }
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
`;
export type ICreateEscrowMutationFn = Apollo.MutationFunction<ICreateEscrowMutation, ICreateEscrowMutationVariables>;

/**
 * __useCreateEscrowMutation__
 *
 * To run a mutation, you first call `useCreateEscrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEscrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEscrowMutation, { data, loading, error }] = useCreateEscrowMutation({
 *   variables: {
 *      escrowRole: // value for 'escrowRole'
 *      agreementForm: // value for 'agreementForm'
 *   },
 * });
 */
export function useCreateEscrowMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateEscrowMutation, ICreateEscrowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateEscrowMutation, ICreateEscrowMutationVariables>(CreateEscrowDocument, options);
}
export type CreateEscrowMutationHookResult = ReturnType<typeof useCreateEscrowMutation>;
export type CreateEscrowMutationResult = Apollo.MutationResult<ICreateEscrowMutation>;
export type CreateEscrowMutationOptions = Apollo.BaseMutationOptions<
  ICreateEscrowMutation,
  ICreateEscrowMutationVariables
>;
export const EscrowDocument = gql`
  query escrow($escrowId: ID!) {
    escrow(escrowId: $escrowId) {
      status
      activationNotification
      agreementSettingsConfigured
      id
      currentCompanyRole
      beneficiaryConfigured
      depositorConfigured
      startedBy
      beneficiaryDepositNotification
      depositorDepositNotification
      depositStatus
      beneficiaryDisruptionNotification
      beneficiaryDisruptionNotificationGracePeriod
      depositorDisruptionNotification
      depositorDisruptionNotificationGracePeriod
      storageRegion
      showDepositInformation
      escrowInvitation {
        ...EscrowInvitationFields
      }
      escrowTermination {
        ...EscrowTerminationFields
      }
      agreement {
        ...EscrowAgreementFields
      }
      beneficiary {
        city
        companyName
        companyRegistrationNumber
        companyWebsite
        country
        id
        postalCode
        region
        street
        streetNumber
        contacts {
          ...ContactFragment
        }
        policy {
          ...BeneficiaryPolicyFragment
        }
      }
      depositor {
        city
        companyName
        companyRegistrationNumber
        companyWebsite
        country
        id
        postalCode
        region
        street
        streetNumber
        contacts {
          ...ContactFragment
        }
        policy {
          ...DepositorPolicyFragment
        }
      }
      pendingActivation
      reportsEnabled
      reportsEnabledAt
      policy {
        ...EscrowPolicyFragment
      }
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
  ${EscrowTerminationFieldsFragmentDoc}
  ${EscrowAgreementFieldsFragmentDoc}
  ${ContactFragmentFragmentDoc}
  ${BeneficiaryPolicyFragmentFragmentDoc}
  ${DepositorPolicyFragmentFragmentDoc}
  ${EscrowPolicyFragmentFragmentDoc}
`;

/**
 * __useEscrowQuery__
 *
 * To run a query within a React component, call `useEscrowQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscrowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscrowQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useEscrowQuery(
  baseOptions: Apollo.QueryHookOptions<IEscrowQuery, IEscrowQueryVariables> &
    ({ variables: IEscrowQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEscrowQuery, IEscrowQueryVariables>(EscrowDocument, options);
}
export function useEscrowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEscrowQuery, IEscrowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEscrowQuery, IEscrowQueryVariables>(EscrowDocument, options);
}
export function useEscrowSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IEscrowQuery, IEscrowQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEscrowQuery, IEscrowQueryVariables>(EscrowDocument, options);
}
export type EscrowQueryHookResult = ReturnType<typeof useEscrowQuery>;
export type EscrowLazyQueryHookResult = ReturnType<typeof useEscrowLazyQuery>;
export type EscrowSuspenseQueryHookResult = ReturnType<typeof useEscrowSuspenseQuery>;
export type EscrowQueryResult = Apollo.QueryResult<IEscrowQuery, IEscrowQueryVariables>;
export const EscrowAgreementTermsDocument = gql`
  query escrowAgreementTerms($escrowId: ID!) {
    escrowTerms: escrow(escrowId: $escrowId) {
      agreement {
        terms
        termsDownloadPdfUrl
        termsSignedDownloadPdfUrl
        termsDownloadDocxUrl
      }
    }
  }
`;

/**
 * __useEscrowAgreementTermsQuery__
 *
 * To run a query within a React component, call `useEscrowAgreementTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscrowAgreementTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscrowAgreementTermsQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useEscrowAgreementTermsQuery(
  baseOptions: Apollo.QueryHookOptions<IEscrowAgreementTermsQuery, IEscrowAgreementTermsQueryVariables> &
    ({ variables: IEscrowAgreementTermsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEscrowAgreementTermsQuery, IEscrowAgreementTermsQueryVariables>(
    EscrowAgreementTermsDocument,
    options,
  );
}
export function useEscrowAgreementTermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEscrowAgreementTermsQuery, IEscrowAgreementTermsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEscrowAgreementTermsQuery, IEscrowAgreementTermsQueryVariables>(
    EscrowAgreementTermsDocument,
    options,
  );
}
export function useEscrowAgreementTermsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IEscrowAgreementTermsQuery, IEscrowAgreementTermsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEscrowAgreementTermsQuery, IEscrowAgreementTermsQueryVariables>(
    EscrowAgreementTermsDocument,
    options,
  );
}
export type EscrowAgreementTermsQueryHookResult = ReturnType<typeof useEscrowAgreementTermsQuery>;
export type EscrowAgreementTermsLazyQueryHookResult = ReturnType<typeof useEscrowAgreementTermsLazyQuery>;
export type EscrowAgreementTermsSuspenseQueryHookResult = ReturnType<typeof useEscrowAgreementTermsSuspenseQuery>;
export type EscrowAgreementTermsQueryResult = Apollo.QueryResult<
  IEscrowAgreementTermsQuery,
  IEscrowAgreementTermsQueryVariables
>;
export const EscrowInvitationsDocument = gql`
  query escrowInvitations($page: Int, $perPage: Int) {
    escrowInvitations(page: $page, perPage: $perPage) {
      nodes {
        status
        companyName
        id
      }
      pagesCount
      nodesCount
    }
  }
`;

/**
 * __useEscrowInvitationsQuery__
 *
 * To run a query within a React component, call `useEscrowInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscrowInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscrowInvitationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useEscrowInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IEscrowInvitationsQuery, IEscrowInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEscrowInvitationsQuery, IEscrowInvitationsQueryVariables>(EscrowInvitationsDocument, options);
}
export function useEscrowInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEscrowInvitationsQuery, IEscrowInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEscrowInvitationsQuery, IEscrowInvitationsQueryVariables>(
    EscrowInvitationsDocument,
    options,
  );
}
export function useEscrowInvitationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IEscrowInvitationsQuery, IEscrowInvitationsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEscrowInvitationsQuery, IEscrowInvitationsQueryVariables>(
    EscrowInvitationsDocument,
    options,
  );
}
export type EscrowInvitationsQueryHookResult = ReturnType<typeof useEscrowInvitationsQuery>;
export type EscrowInvitationsLazyQueryHookResult = ReturnType<typeof useEscrowInvitationsLazyQuery>;
export type EscrowInvitationsSuspenseQueryHookResult = ReturnType<typeof useEscrowInvitationsSuspenseQuery>;
export type EscrowInvitationsQueryResult = Apollo.QueryResult<
  IEscrowInvitationsQuery,
  IEscrowInvitationsQueryVariables
>;
export const EscrowsDocument = gql`
  query escrows(
    $page: Int
    $order: QueryOrderEnum
    $perPage: Int
    $statusFilter: EscrowStatusFilterEnum
    $createdAfter: ISO8601DateTime
    $createdBefore: ISO8601DateTime
    $queryFilter: String
    $actionableItems: Boolean
    $actionItemsFilter: ActionItemFilterEnum
    $currentCompanyRole: EscrowPartyTypeEnum
  ) {
    escrows(
      page: $page
      order: $order
      perPage: $perPage
      statusFilter: $statusFilter
      createdBefore: $createdBefore
      createdAfter: $createdAfter
      queryFilter: $queryFilter
      actionableItems: $actionableItems
      actionItemsFilter: $actionItemsFilter
      currentCompanyRole: $currentCompanyRole
    ) {
      nodes {
        id
        currentCompanyRole
        changesRequested
        agreement {
          id
          approvedByBeneficiary
          approvedByDepositor
          approvedByCodekeeper
          agreementForm
          policy {
            approve
          }
        }
        escrowInvitation {
          status
          companyName
          id
          partyType
          user {
            id
            name
            email
            phone
          }
          policy {
            accept
            reject
            update
          }
        }
        escrowTermination {
          status
          terminateAt
        }
        startedBy
        depositStatus
        status
        status
        escrowInvitation {
          ...EscrowInvitationFields
        }
        escrowTermination {
          status
          terminateAt
        }
        beneficiary {
          companyName
        }
        depositor {
          companyName
        }
        policy {
          remove
        }
      }
      nodesCount
      pagesCount
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
`;

/**
 * __useEscrowsQuery__
 *
 * To run a query within a React component, call `useEscrowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscrowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscrowsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      order: // value for 'order'
 *      perPage: // value for 'perPage'
 *      statusFilter: // value for 'statusFilter'
 *      createdAfter: // value for 'createdAfter'
 *      createdBefore: // value for 'createdBefore'
 *      queryFilter: // value for 'queryFilter'
 *      actionableItems: // value for 'actionableItems'
 *      actionItemsFilter: // value for 'actionItemsFilter'
 *      currentCompanyRole: // value for 'currentCompanyRole'
 *   },
 * });
 */
export function useEscrowsQuery(baseOptions?: Apollo.QueryHookOptions<IEscrowsQuery, IEscrowsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEscrowsQuery, IEscrowsQueryVariables>(EscrowsDocument, options);
}
export function useEscrowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEscrowsQuery, IEscrowsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEscrowsQuery, IEscrowsQueryVariables>(EscrowsDocument, options);
}
export function useEscrowsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IEscrowsQuery, IEscrowsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEscrowsQuery, IEscrowsQueryVariables>(EscrowsDocument, options);
}
export type EscrowsQueryHookResult = ReturnType<typeof useEscrowsQuery>;
export type EscrowsLazyQueryHookResult = ReturnType<typeof useEscrowsLazyQuery>;
export type EscrowsSuspenseQueryHookResult = ReturnType<typeof useEscrowsSuspenseQuery>;
export type EscrowsQueryResult = Apollo.QueryResult<IEscrowsQuery, IEscrowsQueryVariables>;
export const EscrowsCountDocument = gql`
  query escrowsCount {
    onboardingEscrows: escrows(statusFilter: draft_and_onboarding) {
      nodesCount
    }
    terminatedEscrows: escrows(statusFilter: termination_requested) {
      nodesCount
    }
    signedEscrows: escrows(statusFilter: signed) {
      nodesCount
    }
    allEscrows: escrows(statusFilter: all) {
      nodesCount
    }
    invited: escrows(statusFilter: invited) {
      nodesCount
    }
    changesEscrows: escrows(statusFilter: changes_suggested) {
      nodesCount
    }
    errorEscrows: escrows(statusFilter: error) {
      nodesCount
    }
  }
`;

/**
 * __useEscrowsCountQuery__
 *
 * To run a query within a React component, call `useEscrowsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscrowsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscrowsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useEscrowsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<IEscrowsCountQuery, IEscrowsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEscrowsCountQuery, IEscrowsCountQueryVariables>(EscrowsCountDocument, options);
}
export function useEscrowsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEscrowsCountQuery, IEscrowsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEscrowsCountQuery, IEscrowsCountQueryVariables>(EscrowsCountDocument, options);
}
export function useEscrowsCountSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IEscrowsCountQuery, IEscrowsCountQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEscrowsCountQuery, IEscrowsCountQueryVariables>(EscrowsCountDocument, options);
}
export type EscrowsCountQueryHookResult = ReturnType<typeof useEscrowsCountQuery>;
export type EscrowsCountLazyQueryHookResult = ReturnType<typeof useEscrowsCountLazyQuery>;
export type EscrowsCountSuspenseQueryHookResult = ReturnType<typeof useEscrowsCountSuspenseQuery>;
export type EscrowsCountQueryResult = Apollo.QueryResult<IEscrowsCountQuery, IEscrowsCountQueryVariables>;
export const MarkAsViewedEscrowDocument = gql`
  mutation markAsViewedEscrow($escrowIds: [ID!]!) {
    markAsViewedEscrow(input: { escrowIds: $escrowIds }) {
      success
      errors
    }
  }
`;
export type IMarkAsViewedEscrowMutationFn = Apollo.MutationFunction<
  IMarkAsViewedEscrowMutation,
  IMarkAsViewedEscrowMutationVariables
>;

/**
 * __useMarkAsViewedEscrowMutation__
 *
 * To run a mutation, you first call `useMarkAsViewedEscrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsViewedEscrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsViewedEscrowMutation, { data, loading, error }] = useMarkAsViewedEscrowMutation({
 *   variables: {
 *      escrowIds: // value for 'escrowIds'
 *   },
 * });
 */
export function useMarkAsViewedEscrowMutation(
  baseOptions?: Apollo.MutationHookOptions<IMarkAsViewedEscrowMutation, IMarkAsViewedEscrowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IMarkAsViewedEscrowMutation, IMarkAsViewedEscrowMutationVariables>(
    MarkAsViewedEscrowDocument,
    options,
  );
}
export type MarkAsViewedEscrowMutationHookResult = ReturnType<typeof useMarkAsViewedEscrowMutation>;
export type MarkAsViewedEscrowMutationResult = Apollo.MutationResult<IMarkAsViewedEscrowMutation>;
export type MarkAsViewedEscrowMutationOptions = Apollo.BaseMutationOptions<
  IMarkAsViewedEscrowMutation,
  IMarkAsViewedEscrowMutationVariables
>;
export const RejectEscrowInvitationDocument = gql`
  mutation rejectEscrowInvitation($escrowInvitationId: ID!) {
    rejectEscrowInvitation(input: { escrowInvitationId: $escrowInvitationId }) {
      success
      errors
      escrowInvitation {
        ...EscrowInvitationFields
      }
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
`;
export type IRejectEscrowInvitationMutationFn = Apollo.MutationFunction<
  IRejectEscrowInvitationMutation,
  IRejectEscrowInvitationMutationVariables
>;

/**
 * __useRejectEscrowInvitationMutation__
 *
 * To run a mutation, you first call `useRejectEscrowInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectEscrowInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectEscrowInvitationMutation, { data, loading, error }] = useRejectEscrowInvitationMutation({
 *   variables: {
 *      escrowInvitationId: // value for 'escrowInvitationId'
 *   },
 * });
 */
export function useRejectEscrowInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<IRejectEscrowInvitationMutation, IRejectEscrowInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRejectEscrowInvitationMutation, IRejectEscrowInvitationMutationVariables>(
    RejectEscrowInvitationDocument,
    options,
  );
}
export type RejectEscrowInvitationMutationHookResult = ReturnType<typeof useRejectEscrowInvitationMutation>;
export type RejectEscrowInvitationMutationResult = Apollo.MutationResult<IRejectEscrowInvitationMutation>;
export type RejectEscrowInvitationMutationOptions = Apollo.BaseMutationOptions<
  IRejectEscrowInvitationMutation,
  IRejectEscrowInvitationMutationVariables
>;
export const RejectEscrowTerminationDocument = gql`
  mutation rejectEscrowTermination($escrowTerminationId: ID!) {
    rejectEscrowTermination(input: { escrowTerminationId: $escrowTerminationId }) {
      success
      errors
    }
  }
`;
export type IRejectEscrowTerminationMutationFn = Apollo.MutationFunction<
  IRejectEscrowTerminationMutation,
  IRejectEscrowTerminationMutationVariables
>;

/**
 * __useRejectEscrowTerminationMutation__
 *
 * To run a mutation, you first call `useRejectEscrowTerminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectEscrowTerminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectEscrowTerminationMutation, { data, loading, error }] = useRejectEscrowTerminationMutation({
 *   variables: {
 *      escrowTerminationId: // value for 'escrowTerminationId'
 *   },
 * });
 */
export function useRejectEscrowTerminationMutation(
  baseOptions?: Apollo.MutationHookOptions<IRejectEscrowTerminationMutation, IRejectEscrowTerminationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRejectEscrowTerminationMutation, IRejectEscrowTerminationMutationVariables>(
    RejectEscrowTerminationDocument,
    options,
  );
}
export type RejectEscrowTerminationMutationHookResult = ReturnType<typeof useRejectEscrowTerminationMutation>;
export type RejectEscrowTerminationMutationResult = Apollo.MutationResult<IRejectEscrowTerminationMutation>;
export type RejectEscrowTerminationMutationOptions = Apollo.BaseMutationOptions<
  IRejectEscrowTerminationMutation,
  IRejectEscrowTerminationMutationVariables
>;
export const RemoveEscrowDocument = gql`
  mutation removeEscrow($escrowId: ID!) {
    removeEscrow(input: { escrowId: $escrowId }) {
      success
      errors
    }
  }
`;
export type IRemoveEscrowMutationFn = Apollo.MutationFunction<IRemoveEscrowMutation, IRemoveEscrowMutationVariables>;

/**
 * __useRemoveEscrowMutation__
 *
 * To run a mutation, you first call `useRemoveEscrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEscrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEscrowMutation, { data, loading, error }] = useRemoveEscrowMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useRemoveEscrowMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveEscrowMutation, IRemoveEscrowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveEscrowMutation, IRemoveEscrowMutationVariables>(RemoveEscrowDocument, options);
}
export type RemoveEscrowMutationHookResult = ReturnType<typeof useRemoveEscrowMutation>;
export type RemoveEscrowMutationResult = Apollo.MutationResult<IRemoveEscrowMutation>;
export type RemoveEscrowMutationOptions = Apollo.BaseMutationOptions<
  IRemoveEscrowMutation,
  IRemoveEscrowMutationVariables
>;
export const RequestEscrowTerminationDocument = gql`
  mutation requestEscrowTermination($escrowId: ID!) {
    requestEscrowTermination(input: { escrowId: $escrowId }) {
      success
      errors
    }
  }
`;
export type IRequestEscrowTerminationMutationFn = Apollo.MutationFunction<
  IRequestEscrowTerminationMutation,
  IRequestEscrowTerminationMutationVariables
>;

/**
 * __useRequestEscrowTerminationMutation__
 *
 * To run a mutation, you first call `useRequestEscrowTerminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEscrowTerminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEscrowTerminationMutation, { data, loading, error }] = useRequestEscrowTerminationMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useRequestEscrowTerminationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRequestEscrowTerminationMutation,
    IRequestEscrowTerminationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRequestEscrowTerminationMutation, IRequestEscrowTerminationMutationVariables>(
    RequestEscrowTerminationDocument,
    options,
  );
}
export type RequestEscrowTerminationMutationHookResult = ReturnType<typeof useRequestEscrowTerminationMutation>;
export type RequestEscrowTerminationMutationResult = Apollo.MutationResult<IRequestEscrowTerminationMutation>;
export type RequestEscrowTerminationMutationOptions = Apollo.BaseMutationOptions<
  IRequestEscrowTerminationMutation,
  IRequestEscrowTerminationMutationVariables
>;
export const SignedActiveEscrowsDocument = gql`
  query signedActiveEscrows($currentCompanyRole: EscrowPartyTypeEnum) {
    signed: escrows(perPage: 100, statusFilter: signed, currentCompanyRole: $currentCompanyRole) {
      nodes {
        id
        beneficiary {
          companyName
        }
        depositor {
          companyName
        }
      }
    }
    active: escrows(perPage: 100, statusFilter: active, currentCompanyRole: $currentCompanyRole) {
      nodes {
        id
        beneficiary {
          companyName
        }
        depositor {
          companyName
        }
      }
    }
  }
`;

/**
 * __useSignedActiveEscrowsQuery__
 *
 * To run a query within a React component, call `useSignedActiveEscrowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignedActiveEscrowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignedActiveEscrowsQuery({
 *   variables: {
 *      currentCompanyRole: // value for 'currentCompanyRole'
 *   },
 * });
 */
export function useSignedActiveEscrowsQuery(
  baseOptions?: Apollo.QueryHookOptions<ISignedActiveEscrowsQuery, ISignedActiveEscrowsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISignedActiveEscrowsQuery, ISignedActiveEscrowsQueryVariables>(
    SignedActiveEscrowsDocument,
    options,
  );
}
export function useSignedActiveEscrowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISignedActiveEscrowsQuery, ISignedActiveEscrowsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISignedActiveEscrowsQuery, ISignedActiveEscrowsQueryVariables>(
    SignedActiveEscrowsDocument,
    options,
  );
}
export function useSignedActiveEscrowsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ISignedActiveEscrowsQuery, ISignedActiveEscrowsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISignedActiveEscrowsQuery, ISignedActiveEscrowsQueryVariables>(
    SignedActiveEscrowsDocument,
    options,
  );
}
export type SignedActiveEscrowsQueryHookResult = ReturnType<typeof useSignedActiveEscrowsQuery>;
export type SignedActiveEscrowsLazyQueryHookResult = ReturnType<typeof useSignedActiveEscrowsLazyQuery>;
export type SignedActiveEscrowsSuspenseQueryHookResult = ReturnType<typeof useSignedActiveEscrowsSuspenseQuery>;
export type SignedActiveEscrowsQueryResult = Apollo.QueryResult<
  ISignedActiveEscrowsQuery,
  ISignedActiveEscrowsQueryVariables
>;
export const UpdateEscrowDocument = gql`
  mutation updateEscrow($escrowId: ID!, $escrowInput: EscrowInput!) {
    updateEscrow(input: { escrowId: $escrowId, escrowInput: $escrowInput }) {
      success
      errors
      escrow {
        id
        activationNotification
      }
    }
  }
`;
export type IUpdateEscrowMutationFn = Apollo.MutationFunction<IUpdateEscrowMutation, IUpdateEscrowMutationVariables>;

/**
 * __useUpdateEscrowMutation__
 *
 * To run a mutation, you first call `useUpdateEscrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEscrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEscrowMutation, { data, loading, error }] = useUpdateEscrowMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      escrowInput: // value for 'escrowInput'
 *   },
 * });
 */
export function useUpdateEscrowMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateEscrowMutation, IUpdateEscrowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateEscrowMutation, IUpdateEscrowMutationVariables>(UpdateEscrowDocument, options);
}
export type UpdateEscrowMutationHookResult = ReturnType<typeof useUpdateEscrowMutation>;
export type UpdateEscrowMutationResult = Apollo.MutationResult<IUpdateEscrowMutation>;
export type UpdateEscrowMutationOptions = Apollo.BaseMutationOptions<
  IUpdateEscrowMutation,
  IUpdateEscrowMutationVariables
>;
export const UpdateEscrowInvitationDocument = gql`
  mutation updateEscrowInvitation($escrowInvitationId: ID!, $administrativeInputUser: UserInput!) {
    updateEscrowInvitation(
      input: { escrowInvitationId: $escrowInvitationId, administrativeInputUser: $administrativeInputUser }
    ) {
      success
      errors
      escrowInvitation {
        ...EscrowInvitationFields
      }
    }
  }
  ${EscrowInvitationFieldsFragmentDoc}
`;
export type IUpdateEscrowInvitationMutationFn = Apollo.MutationFunction<
  IUpdateEscrowInvitationMutation,
  IUpdateEscrowInvitationMutationVariables
>;

/**
 * __useUpdateEscrowInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateEscrowInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEscrowInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEscrowInvitationMutation, { data, loading, error }] = useUpdateEscrowInvitationMutation({
 *   variables: {
 *      escrowInvitationId: // value for 'escrowInvitationId'
 *      administrativeInputUser: // value for 'administrativeInputUser'
 *   },
 * });
 */
export function useUpdateEscrowInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateEscrowInvitationMutation, IUpdateEscrowInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateEscrowInvitationMutation, IUpdateEscrowInvitationMutationVariables>(
    UpdateEscrowInvitationDocument,
    options,
  );
}
export type UpdateEscrowInvitationMutationHookResult = ReturnType<typeof useUpdateEscrowInvitationMutation>;
export type UpdateEscrowInvitationMutationResult = Apollo.MutationResult<IUpdateEscrowInvitationMutation>;
export type UpdateEscrowInvitationMutationOptions = Apollo.BaseMutationOptions<
  IUpdateEscrowInvitationMutation,
  IUpdateEscrowInvitationMutationVariables
>;
export const CreateEscrowMemberDocument = gql`
  mutation createEscrowMember($input: CreateEscrowMemberInput!) {
    createEscrowMember(input: $input) {
      errors
      success
      escrowMember {
        ...EscrowMemberFragment
      }
    }
  }
  ${EscrowMemberFragmentFragmentDoc}
`;
export type ICreateEscrowMemberMutationFn = Apollo.MutationFunction<
  ICreateEscrowMemberMutation,
  ICreateEscrowMemberMutationVariables
>;

/**
 * __useCreateEscrowMemberMutation__
 *
 * To run a mutation, you first call `useCreateEscrowMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEscrowMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEscrowMemberMutation, { data, loading, error }] = useCreateEscrowMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEscrowMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateEscrowMemberMutation, ICreateEscrowMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateEscrowMemberMutation, ICreateEscrowMemberMutationVariables>(
    CreateEscrowMemberDocument,
    options,
  );
}
export type CreateEscrowMemberMutationHookResult = ReturnType<typeof useCreateEscrowMemberMutation>;
export type CreateEscrowMemberMutationResult = Apollo.MutationResult<ICreateEscrowMemberMutation>;
export type CreateEscrowMemberMutationOptions = Apollo.BaseMutationOptions<
  ICreateEscrowMemberMutation,
  ICreateEscrowMemberMutationVariables
>;
export const EscrowMembersDocument = gql`
  query escrowMembers(
    $escrowPartyId: ID!
    $orderBy: EscrowMembersOrderByEnum
    $order: QueryOrderEnum
    $page: Int
    $perPage: Int
  ) {
    escrowMembers(escrowPartyId: $escrowPartyId, orderBy: $orderBy, order: $order, page: $page, perPage: $perPage) {
      nodes {
        ...EscrowMemberFragment
      }
      pagesCount
      nodesCount
    }
  }
  ${EscrowMemberFragmentFragmentDoc}
`;

/**
 * __useEscrowMembersQuery__
 *
 * To run a query within a React component, call `useEscrowMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscrowMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscrowMembersQuery({
 *   variables: {
 *      escrowPartyId: // value for 'escrowPartyId'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useEscrowMembersQuery(
  baseOptions: Apollo.QueryHookOptions<IEscrowMembersQuery, IEscrowMembersQueryVariables> &
    ({ variables: IEscrowMembersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IEscrowMembersQuery, IEscrowMembersQueryVariables>(EscrowMembersDocument, options);
}
export function useEscrowMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEscrowMembersQuery, IEscrowMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IEscrowMembersQuery, IEscrowMembersQueryVariables>(EscrowMembersDocument, options);
}
export function useEscrowMembersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IEscrowMembersQuery, IEscrowMembersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IEscrowMembersQuery, IEscrowMembersQueryVariables>(EscrowMembersDocument, options);
}
export type EscrowMembersQueryHookResult = ReturnType<typeof useEscrowMembersQuery>;
export type EscrowMembersLazyQueryHookResult = ReturnType<typeof useEscrowMembersLazyQuery>;
export type EscrowMembersSuspenseQueryHookResult = ReturnType<typeof useEscrowMembersSuspenseQuery>;
export type EscrowMembersQueryResult = Apollo.QueryResult<IEscrowMembersQuery, IEscrowMembersQueryVariables>;
export const RemoveEscrowMemberDocument = gql`
  mutation removeEscrowMember($escrowMemberId: ID!) {
    removeEscrowMember(input: { escrowMemberId: $escrowMemberId }) {
      errors
      success
    }
  }
`;
export type IRemoveEscrowMemberMutationFn = Apollo.MutationFunction<
  IRemoveEscrowMemberMutation,
  IRemoveEscrowMemberMutationVariables
>;

/**
 * __useRemoveEscrowMemberMutation__
 *
 * To run a mutation, you first call `useRemoveEscrowMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEscrowMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEscrowMemberMutation, { data, loading, error }] = useRemoveEscrowMemberMutation({
 *   variables: {
 *      escrowMemberId: // value for 'escrowMemberId'
 *   },
 * });
 */
export function useRemoveEscrowMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveEscrowMemberMutation, IRemoveEscrowMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveEscrowMemberMutation, IRemoveEscrowMemberMutationVariables>(
    RemoveEscrowMemberDocument,
    options,
  );
}
export type RemoveEscrowMemberMutationHookResult = ReturnType<typeof useRemoveEscrowMemberMutation>;
export type RemoveEscrowMemberMutationResult = Apollo.MutationResult<IRemoveEscrowMemberMutation>;
export type RemoveEscrowMemberMutationOptions = Apollo.BaseMutationOptions<
  IRemoveEscrowMemberMutation,
  IRemoveEscrowMemberMutationVariables
>;
export const UpdateEscrowMemberDocument = gql`
  mutation updateEscrowMember($input: UpdateEscrowMemberInput!) {
    updateEscrowMember(input: $input) {
      errors
      success
      escrowMember {
        ...EscrowMemberFragment
      }
    }
  }
  ${EscrowMemberFragmentFragmentDoc}
`;
export type IUpdateEscrowMemberMutationFn = Apollo.MutationFunction<
  IUpdateEscrowMemberMutation,
  IUpdateEscrowMemberMutationVariables
>;

/**
 * __useUpdateEscrowMemberMutation__
 *
 * To run a mutation, you first call `useUpdateEscrowMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEscrowMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEscrowMemberMutation, { data, loading, error }] = useUpdateEscrowMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEscrowMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateEscrowMemberMutation, IUpdateEscrowMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateEscrowMemberMutation, IUpdateEscrowMemberMutationVariables>(
    UpdateEscrowMemberDocument,
    options,
  );
}
export type UpdateEscrowMemberMutationHookResult = ReturnType<typeof useUpdateEscrowMemberMutation>;
export type UpdateEscrowMemberMutationResult = Apollo.MutationResult<IUpdateEscrowMemberMutation>;
export type UpdateEscrowMemberMutationOptions = Apollo.BaseMutationOptions<
  IUpdateEscrowMemberMutation,
  IUpdateEscrowMemberMutationVariables
>;
export const BackupReadyIntegrationsDocument = gql`
  query backupReadyIntegrations($page: Int, $perPage: Int, $type: IntegrationTypeEnum) {
    backupReadyIntegrations: integrations(page: $page, perPage: $perPage, type: $type, active: true) {
      nodes {
        ... on OauthIntegration {
          isClientOwner @client
        }
        ... on CustomIntegration {
          isClientOwner @client
        }
        accountName
        id
        accountUrl
        provider
        creator {
          id
        }
        backupable
      }
    }
  }
`;

/**
 * __useBackupReadyIntegrationsQuery__
 *
 * To run a query within a React component, call `useBackupReadyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupReadyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupReadyIntegrationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useBackupReadyIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IBackupReadyIntegrationsQuery, IBackupReadyIntegrationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBackupReadyIntegrationsQuery, IBackupReadyIntegrationsQueryVariables>(
    BackupReadyIntegrationsDocument,
    options,
  );
}
export function useBackupReadyIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IBackupReadyIntegrationsQuery, IBackupReadyIntegrationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBackupReadyIntegrationsQuery, IBackupReadyIntegrationsQueryVariables>(
    BackupReadyIntegrationsDocument,
    options,
  );
}
export function useBackupReadyIntegrationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IBackupReadyIntegrationsQuery, IBackupReadyIntegrationsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBackupReadyIntegrationsQuery, IBackupReadyIntegrationsQueryVariables>(
    BackupReadyIntegrationsDocument,
    options,
  );
}
export type BackupReadyIntegrationsQueryHookResult = ReturnType<typeof useBackupReadyIntegrationsQuery>;
export type BackupReadyIntegrationsLazyQueryHookResult = ReturnType<typeof useBackupReadyIntegrationsLazyQuery>;
export type BackupReadyIntegrationsSuspenseQueryHookResult = ReturnType<typeof useBackupReadyIntegrationsSuspenseQuery>;
export type BackupReadyIntegrationsQueryResult = Apollo.QueryResult<
  IBackupReadyIntegrationsQuery,
  IBackupReadyIntegrationsQueryVariables
>;
export const CreateCustomAssetsDocument = gql`
  mutation createCustomAssets($assetsInput: [CustomAssetCreateInput!]!, $escrowId: ID!, $integrationId: ID!) {
    createCustomAssets(input: { assetsInput: $assetsInput, escrowId: $escrowId, integrationId: $integrationId }) {
      success
      errors
      assets {
        id
        depositStatus
        depositMethod
        depositSource
        escrow {
          id
          status
        }
      }
    }
  }
`;
export type ICreateCustomAssetsMutationFn = Apollo.MutationFunction<
  ICreateCustomAssetsMutation,
  ICreateCustomAssetsMutationVariables
>;

/**
 * __useCreateCustomAssetsMutation__
 *
 * To run a mutation, you first call `useCreateCustomAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomAssetsMutation, { data, loading, error }] = useCreateCustomAssetsMutation({
 *   variables: {
 *      assetsInput: // value for 'assetsInput'
 *      escrowId: // value for 'escrowId'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useCreateCustomAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateCustomAssetsMutation, ICreateCustomAssetsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateCustomAssetsMutation, ICreateCustomAssetsMutationVariables>(
    CreateCustomAssetsDocument,
    options,
  );
}
export type CreateCustomAssetsMutationHookResult = ReturnType<typeof useCreateCustomAssetsMutation>;
export type CreateCustomAssetsMutationResult = Apollo.MutationResult<ICreateCustomAssetsMutation>;
export type CreateCustomAssetsMutationOptions = Apollo.BaseMutationOptions<
  ICreateCustomAssetsMutation,
  ICreateCustomAssetsMutationVariables
>;
export const CreateCustomIntegrationDocument = gql`
  mutation createCustomIntegration($customIntegrationInput: CustomIntegrationInputAttributes!) {
    createCustomIntegration(input: { customIntegrationInput: $customIntegrationInput }) {
      success
      errors
      customIntegration {
        provider
        id
      }
    }
  }
`;
export type ICreateCustomIntegrationMutationFn = Apollo.MutationFunction<
  ICreateCustomIntegrationMutation,
  ICreateCustomIntegrationMutationVariables
>;

/**
 * __useCreateCustomIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateCustomIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomIntegrationMutation, { data, loading, error }] = useCreateCustomIntegrationMutation({
 *   variables: {
 *      customIntegrationInput: // value for 'customIntegrationInput'
 *   },
 * });
 */
export function useCreateCustomIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateCustomIntegrationMutation, ICreateCustomIntegrationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateCustomIntegrationMutation, ICreateCustomIntegrationMutationVariables>(
    CreateCustomIntegrationDocument,
    options,
  );
}
export type CreateCustomIntegrationMutationHookResult = ReturnType<typeof useCreateCustomIntegrationMutation>;
export type CreateCustomIntegrationMutationResult = Apollo.MutationResult<ICreateCustomIntegrationMutation>;
export type CreateCustomIntegrationMutationOptions = Apollo.BaseMutationOptions<
  ICreateCustomIntegrationMutation,
  ICreateCustomIntegrationMutationVariables
>;
export const CreateShareFileIntegrationDocument = gql`
  mutation createShareFileIntegration($shareFileIntegrationInput: ShareFileIntegrationInputAttributes!) {
    createShareFileIntegration(input: { shareFileIntegrationInput: $shareFileIntegrationInput }) {
      success
      errors
      shareFileIntegration {
        provider
        id
      }
    }
  }
`;
export type ICreateShareFileIntegrationMutationFn = Apollo.MutationFunction<
  ICreateShareFileIntegrationMutation,
  ICreateShareFileIntegrationMutationVariables
>;

/**
 * __useCreateShareFileIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateShareFileIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareFileIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareFileIntegrationMutation, { data, loading, error }] = useCreateShareFileIntegrationMutation({
 *   variables: {
 *      shareFileIntegrationInput: // value for 'shareFileIntegrationInput'
 *   },
 * });
 */
export function useCreateShareFileIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateShareFileIntegrationMutation,
    ICreateShareFileIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateShareFileIntegrationMutation, ICreateShareFileIntegrationMutationVariables>(
    CreateShareFileIntegrationDocument,
    options,
  );
}
export type CreateShareFileIntegrationMutationHookResult = ReturnType<typeof useCreateShareFileIntegrationMutation>;
export type CreateShareFileIntegrationMutationResult = Apollo.MutationResult<ICreateShareFileIntegrationMutation>;
export type CreateShareFileIntegrationMutationOptions = Apollo.BaseMutationOptions<
  ICreateShareFileIntegrationMutation,
  ICreateShareFileIntegrationMutationVariables
>;
export const FinishOauthIntegrationAuthDocument = gql`
  mutation finishOauthIntegrationAuth(
    $provider: OauthIntegrationProviderEnum!
    $clientPayload: IntegrationAuthClientPayloadInput!
    $stateToken: String!
    $options: IntegrationAuthOptionsInput
  ) {
    finishOauthIntegrationAuth(
      input: { provider: $provider, clientPayload: $clientPayload, stateToken: $stateToken, options: $options }
    ) {
      success
      errors
      oauthIntegration {
        id
        accountName
        provider
      }
    }
  }
`;
export type IFinishOauthIntegrationAuthMutationFn = Apollo.MutationFunction<
  IFinishOauthIntegrationAuthMutation,
  IFinishOauthIntegrationAuthMutationVariables
>;

/**
 * __useFinishOauthIntegrationAuthMutation__
 *
 * To run a mutation, you first call `useFinishOauthIntegrationAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishOauthIntegrationAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishOauthIntegrationAuthMutation, { data, loading, error }] = useFinishOauthIntegrationAuthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      clientPayload: // value for 'clientPayload'
 *      stateToken: // value for 'stateToken'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useFinishOauthIntegrationAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IFinishOauthIntegrationAuthMutation,
    IFinishOauthIntegrationAuthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IFinishOauthIntegrationAuthMutation, IFinishOauthIntegrationAuthMutationVariables>(
    FinishOauthIntegrationAuthDocument,
    options,
  );
}
export type FinishOauthIntegrationAuthMutationHookResult = ReturnType<typeof useFinishOauthIntegrationAuthMutation>;
export type FinishOauthIntegrationAuthMutationResult = Apollo.MutationResult<IFinishOauthIntegrationAuthMutation>;
export type FinishOauthIntegrationAuthMutationOptions = Apollo.BaseMutationOptions<
  IFinishOauthIntegrationAuthMutation,
  IFinishOauthIntegrationAuthMutationVariables
>;
export const InitOauthIntegrationAuthDocument = gql`
  mutation initOauthIntegrationAuth($provider: OauthIntegrationProviderEnum!, $options: IntegrationAuthOptionsInput) {
    initOauthIntegrationAuth(input: { provider: $provider, options: $options }) {
      success
      errors
      integrationAuth {
        url
        stateToken
      }
    }
  }
`;
export type IInitOauthIntegrationAuthMutationFn = Apollo.MutationFunction<
  IInitOauthIntegrationAuthMutation,
  IInitOauthIntegrationAuthMutationVariables
>;

/**
 * __useInitOauthIntegrationAuthMutation__
 *
 * To run a mutation, you first call `useInitOauthIntegrationAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitOauthIntegrationAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initOauthIntegrationAuthMutation, { data, loading, error }] = useInitOauthIntegrationAuthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInitOauthIntegrationAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IInitOauthIntegrationAuthMutation,
    IInitOauthIntegrationAuthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IInitOauthIntegrationAuthMutation, IInitOauthIntegrationAuthMutationVariables>(
    InitOauthIntegrationAuthDocument,
    options,
  );
}
export type InitOauthIntegrationAuthMutationHookResult = ReturnType<typeof useInitOauthIntegrationAuthMutation>;
export type InitOauthIntegrationAuthMutationResult = Apollo.MutationResult<IInitOauthIntegrationAuthMutation>;
export type InitOauthIntegrationAuthMutationOptions = Apollo.BaseMutationOptions<
  IInitOauthIntegrationAuthMutation,
  IInitOauthIntegrationAuthMutationVariables
>;
export const IntegrationsDocument = gql`
  query integrations($page: Int, $perPage: Int, $type: IntegrationTypeEnum, $active: Boolean) {
    integrations(page: $page, perPage: $perPage, type: $type, active: $active) {
      hasNextPage
      hasPreviousPage
      nodes {
        active
        accountName
        id
        accountUrl
        provider
        status
        tenantId
        __typename
      }
      nodesCount
      pagesCount
    }
  }
`;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      type: // value for 'type'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IIntegrationsQuery, IIntegrationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IIntegrationsQuery, IIntegrationsQueryVariables>(IntegrationsDocument, options);
}
export function useIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IIntegrationsQuery, IIntegrationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IIntegrationsQuery, IIntegrationsQueryVariables>(IntegrationsDocument, options);
}
export function useIntegrationsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IIntegrationsQuery, IIntegrationsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IIntegrationsQuery, IIntegrationsQueryVariables>(IntegrationsDocument, options);
}
export type IntegrationsQueryHookResult = ReturnType<typeof useIntegrationsQuery>;
export type IntegrationsLazyQueryHookResult = ReturnType<typeof useIntegrationsLazyQuery>;
export type IntegrationsSuspenseQueryHookResult = ReturnType<typeof useIntegrationsSuspenseQuery>;
export type IntegrationsQueryResult = Apollo.QueryResult<IIntegrationsQuery, IIntegrationsQueryVariables>;
export const ToggleIntegrationDocument = gql`
  mutation toggleIntegration($integrationId: ID!) {
    toggleIntegration(input: { integrationId: $integrationId }) {
      success
      errors
      integration {
        id
        status
        active
      }
    }
  }
`;
export type IToggleIntegrationMutationFn = Apollo.MutationFunction<
  IToggleIntegrationMutation,
  IToggleIntegrationMutationVariables
>;

/**
 * __useToggleIntegrationMutation__
 *
 * To run a mutation, you first call `useToggleIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIntegrationMutation, { data, loading, error }] = useToggleIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useToggleIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<IToggleIntegrationMutation, IToggleIntegrationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IToggleIntegrationMutation, IToggleIntegrationMutationVariables>(
    ToggleIntegrationDocument,
    options,
  );
}
export type ToggleIntegrationMutationHookResult = ReturnType<typeof useToggleIntegrationMutation>;
export type ToggleIntegrationMutationResult = Apollo.MutationResult<IToggleIntegrationMutation>;
export type ToggleIntegrationMutationOptions = Apollo.BaseMutationOptions<
  IToggleIntegrationMutation,
  IToggleIntegrationMutationVariables
>;
export const UpdateCustomIntegrationDocument = gql`
  mutation updateCustomIntegration(
    $customIntegrationInput: CustomIntegrationUpdateInputAttributes!
    $integrationId: ID!
  ) {
    updateCustomIntegration(input: { customIntegrationInput: $customIntegrationInput, integrationId: $integrationId }) {
      success
      errors
      customIntegration {
        accountName
        accountUrl
        active
        id
        provider
        status
      }
    }
  }
`;
export type IUpdateCustomIntegrationMutationFn = Apollo.MutationFunction<
  IUpdateCustomIntegrationMutation,
  IUpdateCustomIntegrationMutationVariables
>;

/**
 * __useUpdateCustomIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateCustomIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomIntegrationMutation, { data, loading, error }] = useUpdateCustomIntegrationMutation({
 *   variables: {
 *      customIntegrationInput: // value for 'customIntegrationInput'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useUpdateCustomIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateCustomIntegrationMutation, IUpdateCustomIntegrationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateCustomIntegrationMutation, IUpdateCustomIntegrationMutationVariables>(
    UpdateCustomIntegrationDocument,
    options,
  );
}
export type UpdateCustomIntegrationMutationHookResult = ReturnType<typeof useUpdateCustomIntegrationMutation>;
export type UpdateCustomIntegrationMutationResult = Apollo.MutationResult<IUpdateCustomIntegrationMutation>;
export type UpdateCustomIntegrationMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCustomIntegrationMutation,
  IUpdateCustomIntegrationMutationVariables
>;
export const InvoicePdfDocument = gql`
  query invoicePdf($id: ID!) {
    invoicePdf(invoiceId: $id) {
      downloadUrl
    }
  }
`;

/**
 * __useInvoicePdfQuery__
 *
 * To run a query within a React component, call `useInvoicePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoicePdfQuery(
  baseOptions: Apollo.QueryHookOptions<IInvoicePdfQuery, IInvoicePdfQueryVariables> &
    ({ variables: IInvoicePdfQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IInvoicePdfQuery, IInvoicePdfQueryVariables>(InvoicePdfDocument, options);
}
export function useInvoicePdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IInvoicePdfQuery, IInvoicePdfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IInvoicePdfQuery, IInvoicePdfQueryVariables>(InvoicePdfDocument, options);
}
export function useInvoicePdfSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IInvoicePdfQuery, IInvoicePdfQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IInvoicePdfQuery, IInvoicePdfQueryVariables>(InvoicePdfDocument, options);
}
export type InvoicePdfQueryHookResult = ReturnType<typeof useInvoicePdfQuery>;
export type InvoicePdfLazyQueryHookResult = ReturnType<typeof useInvoicePdfLazyQuery>;
export type InvoicePdfSuspenseQueryHookResult = ReturnType<typeof useInvoicePdfSuspenseQuery>;
export type InvoicePdfQueryResult = Apollo.QueryResult<IInvoicePdfQuery, IInvoicePdfQueryVariables>;
export const InvoicesDocument = gql`
  query invoices($offset: String) {
    invoices(offset: $offset) {
      nodes {
        id
        date
        total
        currencyCode
        status
      }
      nextOffset
    }
  }
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<IInvoicesQuery, IInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IInvoicesQuery, IInvoicesQueryVariables>(InvoicesDocument, options);
}
export function useInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IInvoicesQuery, IInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IInvoicesQuery, IInvoicesQueryVariables>(InvoicesDocument, options);
}
export function useInvoicesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IInvoicesQuery, IInvoicesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IInvoicesQuery, IInvoicesQueryVariables>(InvoicesDocument, options);
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesSuspenseQueryHookResult = ReturnType<typeof useInvoicesSuspenseQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<IInvoicesQuery, IInvoicesQueryVariables>;
export const AwaitingLegacyReposCountDocument = gql`
  query awaitingLegacyReposCount {
    legacyRepos(statusFilter: awaiting) {
      count: nodesCount
    }
  }
`;

/**
 * __useAwaitingLegacyReposCountQuery__
 *
 * To run a query within a React component, call `useAwaitingLegacyReposCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwaitingLegacyReposCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwaitingLegacyReposCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAwaitingLegacyReposCountQuery(
  baseOptions?: Apollo.QueryHookOptions<IAwaitingLegacyReposCountQuery, IAwaitingLegacyReposCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAwaitingLegacyReposCountQuery, IAwaitingLegacyReposCountQueryVariables>(
    AwaitingLegacyReposCountDocument,
    options,
  );
}
export function useAwaitingLegacyReposCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAwaitingLegacyReposCountQuery, IAwaitingLegacyReposCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAwaitingLegacyReposCountQuery, IAwaitingLegacyReposCountQueryVariables>(
    AwaitingLegacyReposCountDocument,
    options,
  );
}
export function useAwaitingLegacyReposCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IAwaitingLegacyReposCountQuery, IAwaitingLegacyReposCountQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAwaitingLegacyReposCountQuery, IAwaitingLegacyReposCountQueryVariables>(
    AwaitingLegacyReposCountDocument,
    options,
  );
}
export type AwaitingLegacyReposCountQueryHookResult = ReturnType<typeof useAwaitingLegacyReposCountQuery>;
export type AwaitingLegacyReposCountLazyQueryHookResult = ReturnType<typeof useAwaitingLegacyReposCountLazyQuery>;
export type AwaitingLegacyReposCountSuspenseQueryHookResult = ReturnType<
  typeof useAwaitingLegacyReposCountSuspenseQuery
>;
export type AwaitingLegacyReposCountQueryResult = Apollo.QueryResult<
  IAwaitingLegacyReposCountQuery,
  IAwaitingLegacyReposCountQueryVariables
>;
export const LegacyReposDocument = gql`
  query legacyRepos($order: QueryOrderEnum, $queryFilter: String, $page: Int, $perPage: Int) {
    legacyRepos(order: $order, queryFilter: $queryFilter, statusFilter: awaiting, page: $page, perPage: $perPage) {
      hasNextPage
      nodes {
        migrationStatus
        platform
        title
        url
        id
        escrow {
          id
          depositor {
            companyName
          }
          beneficiary {
            companyName
          }
        }
      }
      hasPreviousPage
      nodesCount
      pagesCount
    }
  }
`;

/**
 * __useLegacyReposQuery__
 *
 * To run a query within a React component, call `useLegacyReposQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyReposQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyReposQuery({
 *   variables: {
 *      order: // value for 'order'
 *      queryFilter: // value for 'queryFilter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useLegacyReposQuery(
  baseOptions?: Apollo.QueryHookOptions<ILegacyReposQuery, ILegacyReposQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILegacyReposQuery, ILegacyReposQueryVariables>(LegacyReposDocument, options);
}
export function useLegacyReposLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ILegacyReposQuery, ILegacyReposQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILegacyReposQuery, ILegacyReposQueryVariables>(LegacyReposDocument, options);
}
export function useLegacyReposSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ILegacyReposQuery, ILegacyReposQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ILegacyReposQuery, ILegacyReposQueryVariables>(LegacyReposDocument, options);
}
export type LegacyReposQueryHookResult = ReturnType<typeof useLegacyReposQuery>;
export type LegacyReposLazyQueryHookResult = ReturnType<typeof useLegacyReposLazyQuery>;
export type LegacyReposSuspenseQueryHookResult = ReturnType<typeof useLegacyReposSuspenseQuery>;
export type LegacyReposQueryResult = Apollo.QueryResult<ILegacyReposQuery, ILegacyReposQueryVariables>;
export const LegacyReposCountByEscrowIdDocument = gql`
  query legacyReposCountByEscrowId($id: ID!, $statusFilter: LegacyRepoMigrationStatusEnum) {
    legacyRepos(escrowId: $id, statusFilter: $statusFilter) {
      count: nodesCount
    }
  }
`;

/**
 * __useLegacyReposCountByEscrowIdQuery__
 *
 * To run a query within a React component, call `useLegacyReposCountByEscrowIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyReposCountByEscrowIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyReposCountByEscrowIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function useLegacyReposCountByEscrowIdQuery(
  baseOptions: Apollo.QueryHookOptions<ILegacyReposCountByEscrowIdQuery, ILegacyReposCountByEscrowIdQueryVariables> &
    ({ variables: ILegacyReposCountByEscrowIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILegacyReposCountByEscrowIdQuery, ILegacyReposCountByEscrowIdQueryVariables>(
    LegacyReposCountByEscrowIdDocument,
    options,
  );
}
export function useLegacyReposCountByEscrowIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ILegacyReposCountByEscrowIdQuery,
    ILegacyReposCountByEscrowIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILegacyReposCountByEscrowIdQuery, ILegacyReposCountByEscrowIdQueryVariables>(
    LegacyReposCountByEscrowIdDocument,
    options,
  );
}
export function useLegacyReposCountByEscrowIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ILegacyReposCountByEscrowIdQuery, ILegacyReposCountByEscrowIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ILegacyReposCountByEscrowIdQuery, ILegacyReposCountByEscrowIdQueryVariables>(
    LegacyReposCountByEscrowIdDocument,
    options,
  );
}
export type LegacyReposCountByEscrowIdQueryHookResult = ReturnType<typeof useLegacyReposCountByEscrowIdQuery>;
export type LegacyReposCountByEscrowIdLazyQueryHookResult = ReturnType<typeof useLegacyReposCountByEscrowIdLazyQuery>;
export type LegacyReposCountByEscrowIdSuspenseQueryHookResult = ReturnType<
  typeof useLegacyReposCountByEscrowIdSuspenseQuery
>;
export type LegacyReposCountByEscrowIdQueryResult = Apollo.QueryResult<
  ILegacyReposCountByEscrowIdQuery,
  ILegacyReposCountByEscrowIdQueryVariables
>;
export const MigrateLegacyRepoDocument = gql`
  mutation migrateLegacyRepo($legacyRepoId: ID!, $integrationId: ID!, $oauthRepoId: ID) {
    migrateLegacyRepo(
      input: { legacyRepoId: $legacyRepoId, integrationId: $integrationId, oauthRepoId: $oauthRepoId }
    ) {
      success
      errors
    }
  }
`;
export type IMigrateLegacyRepoMutationFn = Apollo.MutationFunction<
  IMigrateLegacyRepoMutation,
  IMigrateLegacyRepoMutationVariables
>;

/**
 * __useMigrateLegacyRepoMutation__
 *
 * To run a mutation, you first call `useMigrateLegacyRepoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateLegacyRepoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateLegacyRepoMutation, { data, loading, error }] = useMigrateLegacyRepoMutation({
 *   variables: {
 *      legacyRepoId: // value for 'legacyRepoId'
 *      integrationId: // value for 'integrationId'
 *      oauthRepoId: // value for 'oauthRepoId'
 *   },
 * });
 */
export function useMigrateLegacyRepoMutation(
  baseOptions?: Apollo.MutationHookOptions<IMigrateLegacyRepoMutation, IMigrateLegacyRepoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IMigrateLegacyRepoMutation, IMigrateLegacyRepoMutationVariables>(
    MigrateLegacyRepoDocument,
    options,
  );
}
export type MigrateLegacyRepoMutationHookResult = ReturnType<typeof useMigrateLegacyRepoMutation>;
export type MigrateLegacyRepoMutationResult = Apollo.MutationResult<IMigrateLegacyRepoMutation>;
export type MigrateLegacyRepoMutationOptions = Apollo.BaseMutationOptions<
  IMigrateLegacyRepoMutation,
  IMigrateLegacyRepoMutationVariables
>;
export const MigrateLegacyReposDocument = gql`
  mutation migrateLegacyRepos($legacyRepoIds: [ID!]!, $integrationId: ID!) {
    migrateLegacyRepos(input: { legacyRepoIds: $legacyRepoIds, integrationId: $integrationId }) {
      success
      errors
      migratedLegacyRepos {
        id
      }
    }
  }
`;
export type IMigrateLegacyReposMutationFn = Apollo.MutationFunction<
  IMigrateLegacyReposMutation,
  IMigrateLegacyReposMutationVariables
>;

/**
 * __useMigrateLegacyReposMutation__
 *
 * To run a mutation, you first call `useMigrateLegacyReposMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateLegacyReposMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateLegacyReposMutation, { data, loading, error }] = useMigrateLegacyReposMutation({
 *   variables: {
 *      legacyRepoIds: // value for 'legacyRepoIds'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useMigrateLegacyReposMutation(
  baseOptions?: Apollo.MutationHookOptions<IMigrateLegacyReposMutation, IMigrateLegacyReposMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IMigrateLegacyReposMutation, IMigrateLegacyReposMutationVariables>(
    MigrateLegacyReposDocument,
    options,
  );
}
export type MigrateLegacyReposMutationHookResult = ReturnType<typeof useMigrateLegacyReposMutation>;
export type MigrateLegacyReposMutationResult = Apollo.MutationResult<IMigrateLegacyReposMutation>;
export type MigrateLegacyReposMutationOptions = Apollo.BaseMutationOptions<
  IMigrateLegacyReposMutation,
  IMigrateLegacyReposMutationVariables
>;
export const ConfirmEmailChangeDocument = gql`
  mutation confirmEmailChange($code: String!) {
    confirmEmailChange(input: { code: $code }) {
      success
      errors
    }
  }
`;
export type IConfirmEmailChangeMutationFn = Apollo.MutationFunction<
  IConfirmEmailChangeMutation,
  IConfirmEmailChangeMutationVariables
>;

/**
 * __useConfirmEmailChangeMutation__
 *
 * To run a mutation, you first call `useConfirmEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailChangeMutation, { data, loading, error }] = useConfirmEmailChangeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmEmailChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<IConfirmEmailChangeMutation, IConfirmEmailChangeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IConfirmEmailChangeMutation, IConfirmEmailChangeMutationVariables>(
    ConfirmEmailChangeDocument,
    options,
  );
}
export type ConfirmEmailChangeMutationHookResult = ReturnType<typeof useConfirmEmailChangeMutation>;
export type ConfirmEmailChangeMutationResult = Apollo.MutationResult<IConfirmEmailChangeMutation>;
export type ConfirmEmailChangeMutationOptions = Apollo.BaseMutationOptions<
  IConfirmEmailChangeMutation,
  IConfirmEmailChangeMutationVariables
>;
export const CreateFeedbackDocument = gql`
  mutation createFeedback($message: String!) {
    createFeedback(input: { message: $message }) {
      success
      errors
    }
  }
`;
export type ICreateFeedbackMutationFn = Apollo.MutationFunction<
  ICreateFeedbackMutation,
  ICreateFeedbackMutationVariables
>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateFeedbackMutation, ICreateFeedbackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateFeedbackMutation, ICreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
}
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<ICreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<
  ICreateFeedbackMutation,
  ICreateFeedbackMutationVariables
>;
export const MarkAsViewedNotificationDocument = gql`
  mutation markAsViewedNotification($notificationIds: [ID!]!) {
    markAsViewedNotification(input: { notificationIds: $notificationIds }) {
      success
      errors
    }
  }
`;
export type IMarkAsViewedNotificationMutationFn = Apollo.MutationFunction<
  IMarkAsViewedNotificationMutation,
  IMarkAsViewedNotificationMutationVariables
>;

/**
 * __useMarkAsViewedNotificationMutation__
 *
 * To run a mutation, you first call `useMarkAsViewedNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsViewedNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsViewedNotificationMutation, { data, loading, error }] = useMarkAsViewedNotificationMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useMarkAsViewedNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IMarkAsViewedNotificationMutation,
    IMarkAsViewedNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IMarkAsViewedNotificationMutation, IMarkAsViewedNotificationMutationVariables>(
    MarkAsViewedNotificationDocument,
    options,
  );
}
export type MarkAsViewedNotificationMutationHookResult = ReturnType<typeof useMarkAsViewedNotificationMutation>;
export type MarkAsViewedNotificationMutationResult = Apollo.MutationResult<IMarkAsViewedNotificationMutation>;
export type MarkAsViewedNotificationMutationOptions = Apollo.BaseMutationOptions<
  IMarkAsViewedNotificationMutation,
  IMarkAsViewedNotificationMutationVariables
>;
export const ProfileDocument = gql`
  query profile {
    profile {
      email
      id
      name
      gravatarUrl
      phone
      role
    }
  }
`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<IProfileQuery, IProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IProfileQuery, IProfileQueryVariables>(ProfileDocument, options);
}
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProfileQuery, IProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IProfileQuery, IProfileQueryVariables>(ProfileDocument, options);
}
export function useProfileSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IProfileQuery, IProfileQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IProfileQuery, IProfileQueryVariables>(ProfileDocument, options);
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileSuspenseQueryHookResult = ReturnType<typeof useProfileSuspenseQuery>;
export type ProfileQueryResult = Apollo.QueryResult<IProfileQuery, IProfileQueryVariables>;
export const UpdateBillingAddressDocument = gql`
  mutation updateBillingAddress($billingAddress: BillingAddressInput!) {
    updateBillingAddress(input: { billingAddress: $billingAddress }) {
      success
      errors
    }
  }
`;
export type IUpdateBillingAddressMutationFn = Apollo.MutationFunction<
  IUpdateBillingAddressMutation,
  IUpdateBillingAddressMutationVariables
>;

/**
 * __useUpdateBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingAddressMutation, { data, loading, error }] = useUpdateBillingAddressMutation({
 *   variables: {
 *      billingAddress: // value for 'billingAddress'
 *   },
 * });
 */
export function useUpdateBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateBillingAddressMutation, IUpdateBillingAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateBillingAddressMutation, IUpdateBillingAddressMutationVariables>(
    UpdateBillingAddressDocument,
    options,
  );
}
export type UpdateBillingAddressMutationHookResult = ReturnType<typeof useUpdateBillingAddressMutation>;
export type UpdateBillingAddressMutationResult = Apollo.MutationResult<IUpdateBillingAddressMutation>;
export type UpdateBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  IUpdateBillingAddressMutation,
  IUpdateBillingAddressMutationVariables
>;
export const UpdatePaymentMethodDocument = gql`
  mutation updatePaymentMethod($clientMutationId: String) {
    updatePaymentMethod(input: { clientMutationId: $clientMutationId }) {
      success
      errors
      url
    }
  }
`;
export type IUpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  IUpdatePaymentMethodMutation,
  IUpdatePaymentMethodMutationVariables
>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdatePaymentMethodMutation, IUpdatePaymentMethodMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdatePaymentMethodMutation, IUpdatePaymentMethodMutationVariables>(
    UpdatePaymentMethodDocument,
    options,
  );
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<IUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  IUpdatePaymentMethodMutation,
  IUpdatePaymentMethodMutationVariables
>;
export const UpdateProfileInfoDocument = gql`
  mutation updateProfileInfo($name: String!, $phone: String) {
    updateProfileInfo(input: { name: $name, phone: $phone }) {
      success
      errors
      profile {
        id
        name
        email
      }
    }
  }
`;
export type IUpdateProfileInfoMutationFn = Apollo.MutationFunction<
  IUpdateProfileInfoMutation,
  IUpdateProfileInfoMutationVariables
>;

/**
 * __useUpdateProfileInfoMutation__
 *
 * To run a mutation, you first call `useUpdateProfileInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileInfoMutation, { data, loading, error }] = useUpdateProfileInfoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateProfileInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateProfileInfoMutation, IUpdateProfileInfoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateProfileInfoMutation, IUpdateProfileInfoMutationVariables>(
    UpdateProfileInfoDocument,
    options,
  );
}
export type UpdateProfileInfoMutationHookResult = ReturnType<typeof useUpdateProfileInfoMutation>;
export type UpdateProfileInfoMutationResult = Apollo.MutationResult<IUpdateProfileInfoMutation>;
export type UpdateProfileInfoMutationOptions = Apollo.BaseMutationOptions<
  IUpdateProfileInfoMutation,
  IUpdateProfileInfoMutationVariables
>;
export const AssetReportDocument = gql`
  query assetReport($escrowId: ID!, $assetReportId: ID!) {
    assetReport(escrowId: $escrowId, assetReportId: $assetReportId) {
      id
      createdAt
      period
      asset {
        id
        depositSource
      }
      data {
        collaborators
        commitsPerDay
        languages
        size
        totalCommits
      }
    }
  }
`;

/**
 * __useAssetReportQuery__
 *
 * To run a query within a React component, call `useAssetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetReportQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      assetReportId: // value for 'assetReportId'
 *   },
 * });
 */
export function useAssetReportQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetReportQuery, IAssetReportQueryVariables> &
    ({ variables: IAssetReportQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetReportQuery, IAssetReportQueryVariables>(AssetReportDocument, options);
}
export function useAssetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetReportQuery, IAssetReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetReportQuery, IAssetReportQueryVariables>(AssetReportDocument, options);
}
export function useAssetReportSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IAssetReportQuery, IAssetReportQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetReportQuery, IAssetReportQueryVariables>(AssetReportDocument, options);
}
export type AssetReportQueryHookResult = ReturnType<typeof useAssetReportQuery>;
export type AssetReportLazyQueryHookResult = ReturnType<typeof useAssetReportLazyQuery>;
export type AssetReportSuspenseQueryHookResult = ReturnType<typeof useAssetReportSuspenseQuery>;
export type AssetReportQueryResult = Apollo.QueryResult<IAssetReportQuery, IAssetReportQueryVariables>;
export const AssetReportGroupsDocument = gql`
  query assetReportGroups(
    $escrowId: ID!
    $assetId: ID
    $page: Int
    $perPage: Int
    $order: QueryOrderEnum
    $orderBy: AssetsOrderByEnum
    $period: ISO8601Date
  ) {
    assetReportGroups(
      escrowId: $escrowId
      assetId: $assetId
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
      period: $period
    ) {
      nodesCount
      pagesCount
      nodes {
        id
        lastGenerated
        asset {
          id
          depositSource
        }
        assetReports {
          id
          asset {
            id
            depositSource
          }
          createdAt
          period
        }
      }
    }
  }
`;

/**
 * __useAssetReportGroupsQuery__
 *
 * To run a query within a React component, call `useAssetReportGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetReportGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetReportGroupsQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      assetId: // value for 'assetId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useAssetReportGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<IAssetReportGroupsQuery, IAssetReportGroupsQueryVariables> &
    ({ variables: IAssetReportGroupsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAssetReportGroupsQuery, IAssetReportGroupsQueryVariables>(AssetReportGroupsDocument, options);
}
export function useAssetReportGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAssetReportGroupsQuery, IAssetReportGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAssetReportGroupsQuery, IAssetReportGroupsQueryVariables>(
    AssetReportGroupsDocument,
    options,
  );
}
export function useAssetReportGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IAssetReportGroupsQuery, IAssetReportGroupsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IAssetReportGroupsQuery, IAssetReportGroupsQueryVariables>(
    AssetReportGroupsDocument,
    options,
  );
}
export type AssetReportGroupsQueryHookResult = ReturnType<typeof useAssetReportGroupsQuery>;
export type AssetReportGroupsLazyQueryHookResult = ReturnType<typeof useAssetReportGroupsLazyQuery>;
export type AssetReportGroupsSuspenseQueryHookResult = ReturnType<typeof useAssetReportGroupsSuspenseQuery>;
export type AssetReportGroupsQueryResult = Apollo.QueryResult<
  IAssetReportGroupsQuery,
  IAssetReportGroupsQueryVariables
>;
export const BackupAssetReportDocument = gql`
  query backupAssetReport($backupId: ID!, $backupAssetReportId: ID!) {
    backupAssetReport(backupId: $backupId, backupAssetReportId: $backupAssetReportId) {
      id
      createdAt
      period
      backupAsset {
        id
        name
      }
      data {
        collaborators
        commitsPerDay
        languages
        size
        totalCommits
      }
    }
  }
`;

/**
 * __useBackupAssetReportQuery__
 *
 * To run a query within a React component, call `useBackupAssetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupAssetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupAssetReportQuery({
 *   variables: {
 *      backupId: // value for 'backupId'
 *      backupAssetReportId: // value for 'backupAssetReportId'
 *   },
 * });
 */
export function useBackupAssetReportQuery(
  baseOptions: Apollo.QueryHookOptions<IBackupAssetReportQuery, IBackupAssetReportQueryVariables> &
    ({ variables: IBackupAssetReportQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBackupAssetReportQuery, IBackupAssetReportQueryVariables>(BackupAssetReportDocument, options);
}
export function useBackupAssetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IBackupAssetReportQuery, IBackupAssetReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBackupAssetReportQuery, IBackupAssetReportQueryVariables>(
    BackupAssetReportDocument,
    options,
  );
}
export function useBackupAssetReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IBackupAssetReportQuery, IBackupAssetReportQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBackupAssetReportQuery, IBackupAssetReportQueryVariables>(
    BackupAssetReportDocument,
    options,
  );
}
export type BackupAssetReportQueryHookResult = ReturnType<typeof useBackupAssetReportQuery>;
export type BackupAssetReportLazyQueryHookResult = ReturnType<typeof useBackupAssetReportLazyQuery>;
export type BackupAssetReportSuspenseQueryHookResult = ReturnType<typeof useBackupAssetReportSuspenseQuery>;
export type BackupAssetReportQueryResult = Apollo.QueryResult<
  IBackupAssetReportQuery,
  IBackupAssetReportQueryVariables
>;
export const BackupAssetReportGroupsDocument = gql`
  query backupAssetReportGroups(
    $backupId: ID!
    $backupAssetId: ID
    $page: Int
    $perPage: Int
    $order: QueryOrderEnum
    $orderBy: AssetsOrderByEnum
    $period: ISO8601Date
  ) {
    backupAssetReportGroups(
      backupId: $backupId
      backupAssetId: $backupAssetId
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
      period: $period
    ) {
      nodesCount
      pagesCount
      nodes {
        id
        lastGenerated
        backupAsset {
          id
          name
        }
        backupAssetReports {
          id
          createdAt
          period
          backupAsset {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useBackupAssetReportGroupsQuery__
 *
 * To run a query within a React component, call `useBackupAssetReportGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupAssetReportGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupAssetReportGroupsQuery({
 *   variables: {
 *      backupId: // value for 'backupId'
 *      backupAssetId: // value for 'backupAssetId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useBackupAssetReportGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<IBackupAssetReportGroupsQuery, IBackupAssetReportGroupsQueryVariables> &
    ({ variables: IBackupAssetReportGroupsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IBackupAssetReportGroupsQuery, IBackupAssetReportGroupsQueryVariables>(
    BackupAssetReportGroupsDocument,
    options,
  );
}
export function useBackupAssetReportGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IBackupAssetReportGroupsQuery, IBackupAssetReportGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IBackupAssetReportGroupsQuery, IBackupAssetReportGroupsQueryVariables>(
    BackupAssetReportGroupsDocument,
    options,
  );
}
export function useBackupAssetReportGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IBackupAssetReportGroupsQuery, IBackupAssetReportGroupsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IBackupAssetReportGroupsQuery, IBackupAssetReportGroupsQueryVariables>(
    BackupAssetReportGroupsDocument,
    options,
  );
}
export type BackupAssetReportGroupsQueryHookResult = ReturnType<typeof useBackupAssetReportGroupsQuery>;
export type BackupAssetReportGroupsLazyQueryHookResult = ReturnType<typeof useBackupAssetReportGroupsLazyQuery>;
export type BackupAssetReportGroupsSuspenseQueryHookResult = ReturnType<typeof useBackupAssetReportGroupsSuspenseQuery>;
export type BackupAssetReportGroupsQueryResult = Apollo.QueryResult<
  IBackupAssetReportGroupsQuery,
  IBackupAssetReportGroupsQueryVariables
>;
export const EnableBackupReportsDocument = gql`
  mutation enableBackupReports($backupId: ID!) {
    enableBackupReports(input: { backupId: $backupId }) {
      success
      errors
      backup {
        id
        reportsEnabled
      }
    }
  }
`;
export type IEnableBackupReportsMutationFn = Apollo.MutationFunction<
  IEnableBackupReportsMutation,
  IEnableBackupReportsMutationVariables
>;

/**
 * __useEnableBackupReportsMutation__
 *
 * To run a mutation, you first call `useEnableBackupReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableBackupReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableBackupReportsMutation, { data, loading, error }] = useEnableBackupReportsMutation({
 *   variables: {
 *      backupId: // value for 'backupId'
 *   },
 * });
 */
export function useEnableBackupReportsMutation(
  baseOptions?: Apollo.MutationHookOptions<IEnableBackupReportsMutation, IEnableBackupReportsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IEnableBackupReportsMutation, IEnableBackupReportsMutationVariables>(
    EnableBackupReportsDocument,
    options,
  );
}
export type EnableBackupReportsMutationHookResult = ReturnType<typeof useEnableBackupReportsMutation>;
export type EnableBackupReportsMutationResult = Apollo.MutationResult<IEnableBackupReportsMutation>;
export type EnableBackupReportsMutationOptions = Apollo.BaseMutationOptions<
  IEnableBackupReportsMutation,
  IEnableBackupReportsMutationVariables
>;
export const EnableEscrowReportsDocument = gql`
  mutation enableEscrowReports($escrowId: ID!) {
    enableEscrowReports(input: { escrowId: $escrowId }) {
      success
      errors
      escrow {
        id
        reportsEnabled
      }
    }
  }
`;
export type IEnableEscrowReportsMutationFn = Apollo.MutationFunction<
  IEnableEscrowReportsMutation,
  IEnableEscrowReportsMutationVariables
>;

/**
 * __useEnableEscrowReportsMutation__
 *
 * To run a mutation, you first call `useEnableEscrowReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableEscrowReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableEscrowReportsMutation, { data, loading, error }] = useEnableEscrowReportsMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useEnableEscrowReportsMutation(
  baseOptions?: Apollo.MutationHookOptions<IEnableEscrowReportsMutation, IEnableEscrowReportsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IEnableEscrowReportsMutation, IEnableEscrowReportsMutationVariables>(
    EnableEscrowReportsDocument,
    options,
  );
}
export type EnableEscrowReportsMutationHookResult = ReturnType<typeof useEnableEscrowReportsMutation>;
export type EnableEscrowReportsMutationResult = Apollo.MutationResult<IEnableEscrowReportsMutation>;
export type EnableEscrowReportsMutationOptions = Apollo.BaseMutationOptions<
  IEnableEscrowReportsMutation,
  IEnableEscrowReportsMutationVariables
>;
export const OauthReposConnDocument = gql`
  query oauthReposConn($integrationId: ID!) {
    oauthReposConn(integrationId: $integrationId) {
      repositories {
        id
        name
        fullName
      }
    }
  }
`;

/**
 * __useOauthReposConnQuery__
 *
 * To run a query within a React component, call `useOauthReposConnQuery` and pass it any options that fit your needs.
 * When your component renders, `useOauthReposConnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOauthReposConnQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useOauthReposConnQuery(
  baseOptions: Apollo.QueryHookOptions<IOauthReposConnQuery, IOauthReposConnQueryVariables> &
    ({ variables: IOauthReposConnQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOauthReposConnQuery, IOauthReposConnQueryVariables>(OauthReposConnDocument, options);
}
export function useOauthReposConnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IOauthReposConnQuery, IOauthReposConnQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOauthReposConnQuery, IOauthReposConnQueryVariables>(OauthReposConnDocument, options);
}
export function useOauthReposConnSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IOauthReposConnQuery, IOauthReposConnQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IOauthReposConnQuery, IOauthReposConnQueryVariables>(OauthReposConnDocument, options);
}
export type OauthReposConnQueryHookResult = ReturnType<typeof useOauthReposConnQuery>;
export type OauthReposConnLazyQueryHookResult = ReturnType<typeof useOauthReposConnLazyQuery>;
export type OauthReposConnSuspenseQueryHookResult = ReturnType<typeof useOauthReposConnSuspenseQuery>;
export type OauthReposConnQueryResult = Apollo.QueryResult<IOauthReposConnQuery, IOauthReposConnQueryVariables>;
export const ActivateSaasEnvironmentDocument = gql`
  mutation activateSaasEnvironment($id: ID!) {
    activateSaasEnvironment(input: { saasEnvironmentId: $id }) {
      errors
      success
      saasEnvironment {
        id
      }
    }
  }
`;
export type IActivateSaasEnvironmentMutationFn = Apollo.MutationFunction<
  IActivateSaasEnvironmentMutation,
  IActivateSaasEnvironmentMutationVariables
>;

/**
 * __useActivateSaasEnvironmentMutation__
 *
 * To run a mutation, you first call `useActivateSaasEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSaasEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSaasEnvironmentMutation, { data, loading, error }] = useActivateSaasEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSaasEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<IActivateSaasEnvironmentMutation, IActivateSaasEnvironmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IActivateSaasEnvironmentMutation, IActivateSaasEnvironmentMutationVariables>(
    ActivateSaasEnvironmentDocument,
    options,
  );
}
export type ActivateSaasEnvironmentMutationHookResult = ReturnType<typeof useActivateSaasEnvironmentMutation>;
export type ActivateSaasEnvironmentMutationResult = Apollo.MutationResult<IActivateSaasEnvironmentMutation>;
export type ActivateSaasEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  IActivateSaasEnvironmentMutation,
  IActivateSaasEnvironmentMutationVariables
>;
export const CreateSaasEnvironmentDocument = gql`
  mutation createSaasEnvironment($clientMutationId: String) {
    createSaasEnvironment(input: { clientMutationId: $clientMutationId }) {
      errors
      success
      saasEnvironment {
        id
      }
    }
  }
`;
export type ICreateSaasEnvironmentMutationFn = Apollo.MutationFunction<
  ICreateSaasEnvironmentMutation,
  ICreateSaasEnvironmentMutationVariables
>;

/**
 * __useCreateSaasEnvironmentMutation__
 *
 * To run a mutation, you first call `useCreateSaasEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaasEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaasEnvironmentMutation, { data, loading, error }] = useCreateSaasEnvironmentMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateSaasEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateSaasEnvironmentMutation, ICreateSaasEnvironmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateSaasEnvironmentMutation, ICreateSaasEnvironmentMutationVariables>(
    CreateSaasEnvironmentDocument,
    options,
  );
}
export type CreateSaasEnvironmentMutationHookResult = ReturnType<typeof useCreateSaasEnvironmentMutation>;
export type CreateSaasEnvironmentMutationResult = Apollo.MutationResult<ICreateSaasEnvironmentMutation>;
export type CreateSaasEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  ICreateSaasEnvironmentMutation,
  ICreateSaasEnvironmentMutationVariables
>;
export const CreateSaasProviderDocument = gql`
  mutation createSaasProvider($input: CreateSaasProviderInput!) {
    createSaasProvider(input: $input) {
      errors
      success
      saasProvider {
        id
      }
    }
  }
`;
export type ICreateSaasProviderMutationFn = Apollo.MutationFunction<
  ICreateSaasProviderMutation,
  ICreateSaasProviderMutationVariables
>;

/**
 * __useCreateSaasProviderMutation__
 *
 * To run a mutation, you first call `useCreateSaasProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaasProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaasProviderMutation, { data, loading, error }] = useCreateSaasProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSaasProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateSaasProviderMutation, ICreateSaasProviderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateSaasProviderMutation, ICreateSaasProviderMutationVariables>(
    CreateSaasProviderDocument,
    options,
  );
}
export type CreateSaasProviderMutationHookResult = ReturnType<typeof useCreateSaasProviderMutation>;
export type CreateSaasProviderMutationResult = Apollo.MutationResult<ICreateSaasProviderMutation>;
export type CreateSaasProviderMutationOptions = Apollo.BaseMutationOptions<
  ICreateSaasProviderMutation,
  ICreateSaasProviderMutationVariables
>;
export const RemoveSaasEnvironmentDocument = gql`
  mutation removeSaasEnvironment($id: ID!) {
    removeSaasEnvironment(input: { saasEnvironmentId: $id }) {
      errors
      success
    }
  }
`;
export type IRemoveSaasEnvironmentMutationFn = Apollo.MutationFunction<
  IRemoveSaasEnvironmentMutation,
  IRemoveSaasEnvironmentMutationVariables
>;

/**
 * __useRemoveSaasEnvironmentMutation__
 *
 * To run a mutation, you first call `useRemoveSaasEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSaasEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSaasEnvironmentMutation, { data, loading, error }] = useRemoveSaasEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSaasEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveSaasEnvironmentMutation, IRemoveSaasEnvironmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveSaasEnvironmentMutation, IRemoveSaasEnvironmentMutationVariables>(
    RemoveSaasEnvironmentDocument,
    options,
  );
}
export type RemoveSaasEnvironmentMutationHookResult = ReturnType<typeof useRemoveSaasEnvironmentMutation>;
export type RemoveSaasEnvironmentMutationResult = Apollo.MutationResult<IRemoveSaasEnvironmentMutation>;
export type RemoveSaasEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  IRemoveSaasEnvironmentMutation,
  IRemoveSaasEnvironmentMutationVariables
>;
export const RemoveSaasProviderDocument = gql`
  mutation removeSaasProvider($id: ID!) {
    removeSaasProvider(input: { saasProviderId: $id }) {
      errors
      success
    }
  }
`;
export type IRemoveSaasProviderMutationFn = Apollo.MutationFunction<
  IRemoveSaasProviderMutation,
  IRemoveSaasProviderMutationVariables
>;

/**
 * __useRemoveSaasProviderMutation__
 *
 * To run a mutation, you first call `useRemoveSaasProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSaasProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSaasProviderMutation, { data, loading, error }] = useRemoveSaasProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSaasProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveSaasProviderMutation, IRemoveSaasProviderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveSaasProviderMutation, IRemoveSaasProviderMutationVariables>(
    RemoveSaasProviderDocument,
    options,
  );
}
export type RemoveSaasProviderMutationHookResult = ReturnType<typeof useRemoveSaasProviderMutation>;
export type RemoveSaasProviderMutationResult = Apollo.MutationResult<IRemoveSaasProviderMutation>;
export type RemoveSaasProviderMutationOptions = Apollo.BaseMutationOptions<
  IRemoveSaasProviderMutation,
  IRemoveSaasProviderMutationVariables
>;
export const SaasEnvironmentDocument = gql`
  query saasEnvironment($id: ID!) {
    saasEnvironment(saasEnvironmentId: $id) {
      id
      name
      status
      creator {
        id
        name
      }
      saasProviders {
        id
        platform
        description
        status
        role
        protectionPeriod
        monthlyCost
        contactEmail
        completedAt
      }
      saasEnvironmentTermination {
        id
        status
      }
      policy {
        requestActivation
        requestTermination
        update
      }
    }
  }
`;

/**
 * __useSaasEnvironmentQuery__
 *
 * To run a query within a React component, call `useSaasEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaasEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaasEnvironmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaasEnvironmentQuery(
  baseOptions: Apollo.QueryHookOptions<ISaasEnvironmentQuery, ISaasEnvironmentQueryVariables> &
    ({ variables: ISaasEnvironmentQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISaasEnvironmentQuery, ISaasEnvironmentQueryVariables>(SaasEnvironmentDocument, options);
}
export function useSaasEnvironmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISaasEnvironmentQuery, ISaasEnvironmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISaasEnvironmentQuery, ISaasEnvironmentQueryVariables>(SaasEnvironmentDocument, options);
}
export function useSaasEnvironmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ISaasEnvironmentQuery, ISaasEnvironmentQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISaasEnvironmentQuery, ISaasEnvironmentQueryVariables>(
    SaasEnvironmentDocument,
    options,
  );
}
export type SaasEnvironmentQueryHookResult = ReturnType<typeof useSaasEnvironmentQuery>;
export type SaasEnvironmentLazyQueryHookResult = ReturnType<typeof useSaasEnvironmentLazyQuery>;
export type SaasEnvironmentSuspenseQueryHookResult = ReturnType<typeof useSaasEnvironmentSuspenseQuery>;
export type SaasEnvironmentQueryResult = Apollo.QueryResult<ISaasEnvironmentQuery, ISaasEnvironmentQueryVariables>;
export const SaasEnvironmentsDocument = gql`
  query saasEnvironments(
    $orderBy: SaasEnvironmentsOrderByEnum
    $order: QueryOrderEnum
    $status: SaasEnvironmentStatusEnum
    $query: String
    $page: Int
    $perPage: Int
  ) {
    saasEnvironments(orderBy: $orderBy, order: $order, status: $status, query: $query, page: $page, perPage: $perPage) {
      hasNextPage
      hasPreviousPage
      nodes {
        id
        name
        status
        saasProviders {
          id
        }
        activatedAt
        saasEnvironmentTermination {
          id
          status
        }
        policy {
          remove
        }
      }
      nodesCount
      pagesCount
    }
  }
`;

/**
 * __useSaasEnvironmentsQuery__
 *
 * To run a query within a React component, call `useSaasEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaasEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaasEnvironmentsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      status: // value for 'status'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useSaasEnvironmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>(SaasEnvironmentsDocument, options);
}
export function useSaasEnvironmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>(
    SaasEnvironmentsDocument,
    options,
  );
}
export function useSaasEnvironmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>(
    SaasEnvironmentsDocument,
    options,
  );
}
export type SaasEnvironmentsQueryHookResult = ReturnType<typeof useSaasEnvironmentsQuery>;
export type SaasEnvironmentsLazyQueryHookResult = ReturnType<typeof useSaasEnvironmentsLazyQuery>;
export type SaasEnvironmentsSuspenseQueryHookResult = ReturnType<typeof useSaasEnvironmentsSuspenseQuery>;
export type SaasEnvironmentsQueryResult = Apollo.QueryResult<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>;
export const TerminateSaasEnvironmentDocument = gql`
  mutation terminateSaasEnvironment($id: ID!) {
    terminateSaasEnvironment(input: { saasEnvironmentId: $id }) {
      errors
      success
      saasEnvironmentTermination {
        id
        saasEnvironment {
          id
          saasEnvironmentTermination {
            id
            status
          }
        }
      }
    }
  }
`;
export type ITerminateSaasEnvironmentMutationFn = Apollo.MutationFunction<
  ITerminateSaasEnvironmentMutation,
  ITerminateSaasEnvironmentMutationVariables
>;

/**
 * __useTerminateSaasEnvironmentMutation__
 *
 * To run a mutation, you first call `useTerminateSaasEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateSaasEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateSaasEnvironmentMutation, { data, loading, error }] = useTerminateSaasEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTerminateSaasEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ITerminateSaasEnvironmentMutation,
    ITerminateSaasEnvironmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ITerminateSaasEnvironmentMutation, ITerminateSaasEnvironmentMutationVariables>(
    TerminateSaasEnvironmentDocument,
    options,
  );
}
export type TerminateSaasEnvironmentMutationHookResult = ReturnType<typeof useTerminateSaasEnvironmentMutation>;
export type TerminateSaasEnvironmentMutationResult = Apollo.MutationResult<ITerminateSaasEnvironmentMutation>;
export type TerminateSaasEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  ITerminateSaasEnvironmentMutation,
  ITerminateSaasEnvironmentMutationVariables
>;
export const UpdateSaasProviderDocument = gql`
  mutation updateSaasProvider($input: UpdateSaasProviderInput!) {
    updateSaasProvider(input: $input) {
      errors
      success
      saasProvider {
        id
      }
    }
  }
`;
export type IUpdateSaasProviderMutationFn = Apollo.MutationFunction<
  IUpdateSaasProviderMutation,
  IUpdateSaasProviderMutationVariables
>;

/**
 * __useUpdateSaasProviderMutation__
 *
 * To run a mutation, you first call `useUpdateSaasProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaasProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaasProviderMutation, { data, loading, error }] = useUpdateSaasProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaasProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateSaasProviderMutation, IUpdateSaasProviderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateSaasProviderMutation, IUpdateSaasProviderMutationVariables>(
    UpdateSaasProviderDocument,
    options,
  );
}
export type UpdateSaasProviderMutationHookResult = ReturnType<typeof useUpdateSaasProviderMutation>;
export type UpdateSaasProviderMutationResult = Apollo.MutationResult<IUpdateSaasProviderMutation>;
export type UpdateSaasProviderMutationOptions = Apollo.BaseMutationOptions<
  IUpdateSaasProviderMutation,
  IUpdateSaasProviderMutationVariables
>;
export const ClientChangesRequestedDocument = gql`
  query clientChangesRequested {
    clientChangesRequested @client {
      isRequested
      depositor {
        id
        payload
        status
        creator
        receiver
      }
      beneficiary {
        id
        payload
        status
        creator
        receiver
      }
      deposits {
        id
        payload
        status
        creator
        receiver
      }
      notifications {
        id
        payload
        status
        creator
        receiver
      }
      agreement {
        id
        payload
        status
        creator
        receiver
      }
    }
  }
`;

/**
 * __useClientChangesRequestedQuery__
 *
 * To run a query within a React component, call `useClientChangesRequestedQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientChangesRequestedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientChangesRequestedQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientChangesRequestedQuery(
  baseOptions?: Apollo.QueryHookOptions<IClientChangesRequestedQuery, IClientChangesRequestedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IClientChangesRequestedQuery, IClientChangesRequestedQueryVariables>(
    ClientChangesRequestedDocument,
    options,
  );
}
export function useClientChangesRequestedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IClientChangesRequestedQuery, IClientChangesRequestedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IClientChangesRequestedQuery, IClientChangesRequestedQueryVariables>(
    ClientChangesRequestedDocument,
    options,
  );
}
export function useClientChangesRequestedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IClientChangesRequestedQuery, IClientChangesRequestedQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IClientChangesRequestedQuery, IClientChangesRequestedQueryVariables>(
    ClientChangesRequestedDocument,
    options,
  );
}
export type ClientChangesRequestedQueryHookResult = ReturnType<typeof useClientChangesRequestedQuery>;
export type ClientChangesRequestedLazyQueryHookResult = ReturnType<typeof useClientChangesRequestedLazyQuery>;
export type ClientChangesRequestedSuspenseQueryHookResult = ReturnType<typeof useClientChangesRequestedSuspenseQuery>;
export type ClientChangesRequestedQueryResult = Apollo.QueryResult<
  IClientChangesRequestedQuery,
  IClientChangesRequestedQueryVariables
>;
export const CreateSuggestedChangeEscrowDocument = gql`
  mutation createSuggestedChangeEscrow($escrowId: ID!, $suggestedChangeParams: SuggestedChangeInput!) {
    createSuggestedChangeEscrow(input: { escrowId: $escrowId, suggestedChangeParams: $suggestedChangeParams }) {
      success
      errors
      suggestedChange {
        ...SuggestChangeFields
      }
    }
  }
  ${SuggestChangeFieldsFragmentDoc}
`;
export type ICreateSuggestedChangeEscrowMutationFn = Apollo.MutationFunction<
  ICreateSuggestedChangeEscrowMutation,
  ICreateSuggestedChangeEscrowMutationVariables
>;

/**
 * __useCreateSuggestedChangeEscrowMutation__
 *
 * To run a mutation, you first call `useCreateSuggestedChangeEscrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestedChangeEscrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestedChangeEscrowMutation, { data, loading, error }] = useCreateSuggestedChangeEscrowMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      suggestedChangeParams: // value for 'suggestedChangeParams'
 *   },
 * });
 */
export function useCreateSuggestedChangeEscrowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateSuggestedChangeEscrowMutation,
    ICreateSuggestedChangeEscrowMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateSuggestedChangeEscrowMutation, ICreateSuggestedChangeEscrowMutationVariables>(
    CreateSuggestedChangeEscrowDocument,
    options,
  );
}
export type CreateSuggestedChangeEscrowMutationHookResult = ReturnType<typeof useCreateSuggestedChangeEscrowMutation>;
export type CreateSuggestedChangeEscrowMutationResult = Apollo.MutationResult<ICreateSuggestedChangeEscrowMutation>;
export type CreateSuggestedChangeEscrowMutationOptions = Apollo.BaseMutationOptions<
  ICreateSuggestedChangeEscrowMutation,
  ICreateSuggestedChangeEscrowMutationVariables
>;
export const ReviewSuggestedChangeEscrowDocument = gql`
  mutation reviewSuggestedChangeEscrow($suggestedChangeId: ID!, $verdict: SuggestedChangeVerdictEnum!) {
    reviewSuggestedChangeEscrow(input: { suggestedChangeId: $suggestedChangeId, verdict: $verdict }) {
      success
      errors
      suggestedChange {
        ...SuggestChangeFields
        escrow {
          id
          policy {
            createAgreementSuggestedChange
            createBeneficiarySuggestedChange
            createDepositSuggestedChange
            createDepositorSuggestedChange
            createNotificationSuggestedChange
          }
        }
      }
    }
  }
  ${SuggestChangeFieldsFragmentDoc}
`;
export type IReviewSuggestedChangeEscrowMutationFn = Apollo.MutationFunction<
  IReviewSuggestedChangeEscrowMutation,
  IReviewSuggestedChangeEscrowMutationVariables
>;

/**
 * __useReviewSuggestedChangeEscrowMutation__
 *
 * To run a mutation, you first call `useReviewSuggestedChangeEscrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewSuggestedChangeEscrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewSuggestedChangeEscrowMutation, { data, loading, error }] = useReviewSuggestedChangeEscrowMutation({
 *   variables: {
 *      suggestedChangeId: // value for 'suggestedChangeId'
 *      verdict: // value for 'verdict'
 *   },
 * });
 */
export function useReviewSuggestedChangeEscrowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IReviewSuggestedChangeEscrowMutation,
    IReviewSuggestedChangeEscrowMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IReviewSuggestedChangeEscrowMutation, IReviewSuggestedChangeEscrowMutationVariables>(
    ReviewSuggestedChangeEscrowDocument,
    options,
  );
}
export type ReviewSuggestedChangeEscrowMutationHookResult = ReturnType<typeof useReviewSuggestedChangeEscrowMutation>;
export type ReviewSuggestedChangeEscrowMutationResult = Apollo.MutationResult<IReviewSuggestedChangeEscrowMutation>;
export type ReviewSuggestedChangeEscrowMutationOptions = Apollo.BaseMutationOptions<
  IReviewSuggestedChangeEscrowMutation,
  IReviewSuggestedChangeEscrowMutationVariables
>;
export const SuggestedChangesDocument = gql`
  query SuggestedChanges($escrowId: ID!, $changeType: SuggestedChangeChangeTypeEnum) {
    suggestedChanges(escrowId: $escrowId, changeType: $changeType, status: pending) {
      nodes {
        ...SuggestChangeFields
      }
    }
  }
  ${SuggestChangeFieldsFragmentDoc}
`;

/**
 * __useSuggestedChangesQuery__
 *
 * To run a query within a React component, call `useSuggestedChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedChangesQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      changeType: // value for 'changeType'
 *   },
 * });
 */
export function useSuggestedChangesQuery(
  baseOptions: Apollo.QueryHookOptions<ISuggestedChangesQuery, ISuggestedChangesQueryVariables> &
    ({ variables: ISuggestedChangesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISuggestedChangesQuery, ISuggestedChangesQueryVariables>(SuggestedChangesDocument, options);
}
export function useSuggestedChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISuggestedChangesQuery, ISuggestedChangesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISuggestedChangesQuery, ISuggestedChangesQueryVariables>(
    SuggestedChangesDocument,
    options,
  );
}
export function useSuggestedChangesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ISuggestedChangesQuery, ISuggestedChangesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISuggestedChangesQuery, ISuggestedChangesQueryVariables>(
    SuggestedChangesDocument,
    options,
  );
}
export type SuggestedChangesQueryHookResult = ReturnType<typeof useSuggestedChangesQuery>;
export type SuggestedChangesLazyQueryHookResult = ReturnType<typeof useSuggestedChangesLazyQuery>;
export type SuggestedChangesSuspenseQueryHookResult = ReturnType<typeof useSuggestedChangesSuspenseQuery>;
export type SuggestedChangesQueryResult = Apollo.QueryResult<ISuggestedChangesQuery, ISuggestedChangesQueryVariables>;
export const SuggestedChangesNotificationsDocument = gql`
  query suggestedChangesNotifications($escrowId: ID!) {
    depositorSuggestedChanges: suggestedChanges(
      escrowId: $escrowId
      status: pending
      changeType: DepositorSuggestedChange
    ) {
      nodesCount
      nodes {
        id
        partyStatus
        creatorType
        receiverType
      }
    }
    beneficiarySuggestedChanges: suggestedChanges(
      escrowId: $escrowId
      status: pending
      changeType: BeneficiarySuggestedChange
    ) {
      nodesCount
      nodes {
        id
        partyStatus
        creatorType
        receiverType
      }
    }
    notificationSuggestedChanges: suggestedChanges(
      escrowId: $escrowId
      status: pending
      changeType: NotificationSuggestedChange
    ) {
      nodesCount
      nodes {
        id
        partyStatus
        creatorType
        receiverType
      }
    }
    depositSuggestedChanges: suggestedChanges(
      escrowId: $escrowId
      status: pending
      changeType: DepositSuggestedChange
    ) {
      nodesCount
      nodes {
        id
        partyStatus
        creatorType
        receiverType
      }
    }
    agreementSuggestedChanges: suggestedChanges(
      escrowId: $escrowId
      status: pending
      changeType: AgreementSuggestedChange
    ) {
      nodesCount
      nodes {
        id
        partyStatus
        creatorType
        receiverType
      }
    }
  }
`;

/**
 * __useSuggestedChangesNotificationsQuery__
 *
 * To run a query within a React component, call `useSuggestedChangesNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedChangesNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedChangesNotificationsQuery({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *   },
 * });
 */
export function useSuggestedChangesNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ISuggestedChangesNotificationsQuery,
    ISuggestedChangesNotificationsQueryVariables
  > &
    ({ variables: ISuggestedChangesNotificationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISuggestedChangesNotificationsQuery, ISuggestedChangesNotificationsQueryVariables>(
    SuggestedChangesNotificationsDocument,
    options,
  );
}
export function useSuggestedChangesNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISuggestedChangesNotificationsQuery,
    ISuggestedChangesNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISuggestedChangesNotificationsQuery, ISuggestedChangesNotificationsQueryVariables>(
    SuggestedChangesNotificationsDocument,
    options,
  );
}
export function useSuggestedChangesNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ISuggestedChangesNotificationsQuery,
        ISuggestedChangesNotificationsQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ISuggestedChangesNotificationsQuery, ISuggestedChangesNotificationsQueryVariables>(
    SuggestedChangesNotificationsDocument,
    options,
  );
}
export type SuggestedChangesNotificationsQueryHookResult = ReturnType<typeof useSuggestedChangesNotificationsQuery>;
export type SuggestedChangesNotificationsLazyQueryHookResult = ReturnType<
  typeof useSuggestedChangesNotificationsLazyQuery
>;
export type SuggestedChangesNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useSuggestedChangesNotificationsSuspenseQuery
>;
export type SuggestedChangesNotificationsQueryResult = Apollo.QueryResult<
  ISuggestedChangesNotificationsQuery,
  ISuggestedChangesNotificationsQueryVariables
>;
export const CancelSecureUploadDocument = gql`
  mutation cancelSecureUpload($escrowId: ID!, $uploadId: String!) {
    cancelSecureUpload(input: { escrowId: $escrowId, uploadId: $uploadId }) {
      success
      errors
    }
  }
`;
export type ICancelSecureUploadMutationFn = Apollo.MutationFunction<
  ICancelSecureUploadMutation,
  ICancelSecureUploadMutationVariables
>;

/**
 * __useCancelSecureUploadMutation__
 *
 * To run a mutation, you first call `useCancelSecureUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSecureUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSecureUploadMutation, { data, loading, error }] = useCancelSecureUploadMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useCancelSecureUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<ICancelSecureUploadMutation, ICancelSecureUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICancelSecureUploadMutation, ICancelSecureUploadMutationVariables>(
    CancelSecureUploadDocument,
    options,
  );
}
export type CancelSecureUploadMutationHookResult = ReturnType<typeof useCancelSecureUploadMutation>;
export type CancelSecureUploadMutationResult = Apollo.MutationResult<ICancelSecureUploadMutation>;
export type CancelSecureUploadMutationOptions = Apollo.BaseMutationOptions<
  ICancelSecureUploadMutation,
  ICancelSecureUploadMutationVariables
>;
export const CompleteSecureUploadDocument = gql`
  mutation completeSecureUpload(
    $escrowId: ID!
    $uploadId: String!
    $filename: String!
    $parts: [SecureUploadPartInput!]!
    $size: BigInt!
  ) {
    completeSecureUpload(
      input: { escrowId: $escrowId, uploadId: $uploadId, filename: $filename, parts: $parts, size: $size }
    ) {
      success
      errors
      asset {
        id
        depositStatus
      }
    }
  }
`;
export type ICompleteSecureUploadMutationFn = Apollo.MutationFunction<
  ICompleteSecureUploadMutation,
  ICompleteSecureUploadMutationVariables
>;

/**
 * __useCompleteSecureUploadMutation__
 *
 * To run a mutation, you first call `useCompleteSecureUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSecureUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSecureUploadMutation, { data, loading, error }] = useCompleteSecureUploadMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      uploadId: // value for 'uploadId'
 *      filename: // value for 'filename'
 *      parts: // value for 'parts'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCompleteSecureUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<ICompleteSecureUploadMutation, ICompleteSecureUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICompleteSecureUploadMutation, ICompleteSecureUploadMutationVariables>(
    CompleteSecureUploadDocument,
    options,
  );
}
export type CompleteSecureUploadMutationHookResult = ReturnType<typeof useCompleteSecureUploadMutation>;
export type CompleteSecureUploadMutationResult = Apollo.MutationResult<ICompleteSecureUploadMutation>;
export type CompleteSecureUploadMutationOptions = Apollo.BaseMutationOptions<
  ICompleteSecureUploadMutation,
  ICompleteSecureUploadMutationVariables
>;
export const InitSecureUploadDocument = gql`
  mutation initSecureUpload($escrowId: ID!, $numParts: Int!, $filename: String!) {
    initSecureUpload(input: { escrowId: $escrowId, numParts: $numParts, filename: $filename }) {
      success
      errors
      uploadId
      uploadUrls
    }
  }
`;
export type IInitSecureUploadMutationFn = Apollo.MutationFunction<
  IInitSecureUploadMutation,
  IInitSecureUploadMutationVariables
>;

/**
 * __useInitSecureUploadMutation__
 *
 * To run a mutation, you first call `useInitSecureUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitSecureUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initSecureUploadMutation, { data, loading, error }] = useInitSecureUploadMutation({
 *   variables: {
 *      escrowId: // value for 'escrowId'
 *      numParts: // value for 'numParts'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useInitSecureUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<IInitSecureUploadMutation, IInitSecureUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IInitSecureUploadMutation, IInitSecureUploadMutationVariables>(
    InitSecureUploadDocument,
    options,
  );
}
export type InitSecureUploadMutationHookResult = ReturnType<typeof useInitSecureUploadMutation>;
export type InitSecureUploadMutationResult = Apollo.MutationResult<IInitSecureUploadMutation>;
export type InitSecureUploadMutationOptions = Apollo.BaseMutationOptions<
  IInitSecureUploadMutation,
  IInitSecureUploadMutationVariables
>;
export const AddUserDocument = gql`
  mutation addUser($users: [UserInput!]!) {
    addUser(input: { users: $users }) {
      success
      errors
      users {
        id
      }
    }
  }
`;
export type IAddUserMutationFn = Apollo.MutationFunction<IAddUserMutation, IAddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useAddUserMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddUserMutation, IAddUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IAddUserMutation, IAddUserMutationVariables>(AddUserDocument, options);
}
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<IAddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<IAddUserMutation, IAddUserMutationVariables>;
export const EditUserDocument = gql`
  mutation editUser($user: EditUserInput!, $userId: ID!) {
    editUser(input: { user: $user, userId: $userId }) {
      success
      errors
      user {
        id
        name
        email
        phone
      }
    }
  }
`;
export type IEditUserMutationFn = Apollo.MutationFunction<IEditUserMutation, IEditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<IEditUserMutation, IEditUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IEditUserMutation, IEditUserMutationVariables>(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<IEditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<IEditUserMutation, IEditUserMutationVariables>;
export const ImpersonateUserDocument = gql`
  mutation impersonateUser($userId: ID!) {
    impersonateUser(input: { userId: $userId }) {
      success
    }
  }
`;
export type IImpersonateUserMutationFn = Apollo.MutationFunction<
  IImpersonateUserMutation,
  IImpersonateUserMutationVariables
>;

/**
 * __useImpersonateUserMutation__
 *
 * To run a mutation, you first call `useImpersonateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUserMutation, { data, loading, error }] = useImpersonateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useImpersonateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<IImpersonateUserMutation, IImpersonateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IImpersonateUserMutation, IImpersonateUserMutationVariables>(
    ImpersonateUserDocument,
    options,
  );
}
export type ImpersonateUserMutationHookResult = ReturnType<typeof useImpersonateUserMutation>;
export type ImpersonateUserMutationResult = Apollo.MutationResult<IImpersonateUserMutation>;
export type ImpersonateUserMutationOptions = Apollo.BaseMutationOptions<
  IImpersonateUserMutation,
  IImpersonateUserMutationVariables
>;
export const RemoveUserDocument = gql`
  mutation removeUser($userId: ID!) {
    removeUser(input: { userId: $userId }) {
      success
      errors
    }
  }
`;
export type IRemoveUserMutationFn = Apollo.MutationFunction<IRemoveUserMutation, IRemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<IRemoveUserMutation, IRemoveUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRemoveUserMutation, IRemoveUserMutationVariables>(RemoveUserDocument, options);
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<IRemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<IRemoveUserMutation, IRemoveUserMutationVariables>;
