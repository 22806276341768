import { Outlet, useNavigate, useLocation } from 'react-router';
import { Tabs, Skeleton } from 'antd';

import { useProfile } from 'src/context/profile';
import Loader from 'src/components/Loader/Loader';

import { profileTabsItems } from '../profile.enum';

import styles from './ProfileLayout.module.scss';

const ProfileLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { profile, isLoading } = useProfile();

  const onTabClick = (url: string) => navigate(url);

  let items = [...profileTabsItems];

  if (isLoading)
    items = items.map((item) => ({
      ...item,
      children: (
        <div className={styles.loader}>
          <Loader />
        </div>
      ),
    }));
  else items = items.map((item) => ({ ...item, children: <Outlet /> }));

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {isLoading ? (
          <div className={styles['header-skeleton']} aria-label="Loading...">
            <Skeleton.Button active block />
          </div>
        ) : (
          <h6 className={styles.heading}>{profile.name}</h6>
        )}
      </div>

      <Tabs activeKey={pathname} onTabClick={onTabClick} items={items} className="ck-profile__tabs" />
    </div>
  );
};

export default ProfileLayout;
