import { Link } from 'react-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { IActivitiesQuery } from 'src/graphql/schema';
import Loader from 'src/components/Loader/Loader';
import { getEvent } from 'src/utils/helpers';

dayjs.extend(relativeTime);

type Props = {
  activities: IActivitiesQuery['activities']['nodes'];
  isLoading?: boolean;
};

const NotificationMenuItems = ({ activities, isLoading = false }: Props) => {
  if (isLoading)
    return [
      {
        label: <Loader />,
        key: 'loading',
      },
    ];

  const activityItems = activities.map((item) => ({
    label: (
      <Link className="nav__menu-item" to="escrows">
        {/* @ts-expect-error fix this */}
        {getEvent(item)}
        <span className="nav__menu-item-ago">
          <br />
          {dayjs(item.createdAt, 'YYYYMMDD').fromNow()}
        </span>
        <br />
      </Link>
    ),
    key: item.createdAt,
  }));

  const seeAllActivities = [
    {
      label: (
        <Link to="/activity" className="nav__menu-see">
          See all
        </Link>
      ),
      key: 'see',
    },
  ];

  const menuItems = [
    {
      label: <p className="nav__menu-title p-0 -fixed">Activity</p>,
      key: 'activity',
    },
  ];

  if (activities.length) return menuItems.concat(activityItems, seeAllActivities);
  else return menuItems.concat({ label: <span>No activity</span>, key: 'no-activity' });
};

export default NotificationMenuItems;
