import { Link } from 'react-router';

type Props = {
  userName: string;
  isImpersonating: boolean;
  stopImpersonating: () => void;
  signOut: () => void;
};

const ProfileMenuItems = ({ userName, isImpersonating, stopImpersonating, signOut }: Props) => [
  {
    label: <p className="nav__menu-title pl-0">{userName}</p>,
    key: 'name',
  },
  {
    label: (
      <Link className="nav__menu-link" to="/profile">
        Profile
      </Link>
    ),
    key: 'profile',
  },
  {
    label: (
      <Link className="nav__menu-link" to="/activity">
        Activity
      </Link>
    ),
    key: 'activity',
  },
  {
    label: (
      <Link className="nav__menu-link" to="/feedback">
        Feedback
      </Link>
    ),
    key: 'feedback',
  },
  {
    label: (
      <p className="nav__menu-link mb-0 cursor-pointer" onClick={signOut}>
        Sign out
      </p>
    ),
    key: 'signOut',
  },
  ...(isImpersonating
    ? [
        {
          label: (
            <p className="nav__menu-link mb-0 cursor-pointer" onClick={stopImpersonating}>
              Stop impersonating
            </p>
          ),
          key: 'stopImpersonating',
        },
      ]
    : []),
];

export default ProfileMenuItems;
